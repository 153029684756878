<div id="popup" class="modal-overlay">
  <div class="cuadroCentral">
      <div class="aviso">
          <div class=" pointerMouse d-flex justify-content-end"><img (click)="close()" width="15px"
                  src="../../../assets/img/close.png" alt=""></div>

        <!-- <div class="close" (click)="close()">
          <a id="cerrar" class="close">
            <img src="assets/{{ claveEmpresa }}/img/body/cerrar.svg" alt=""
          /></a>
        </div> -->
        <div class="izquierda">
          <p>{{ 'SELECCIONE_TIPO_ENTRADA' | translate }}</p>
        </div>
        <div class="derecha">
          <p *ngIf="butaca">
            {{ butaca.info.infoGeneral.nombreRecinto }}<br />{{ 'FILA' | translate }}
            {{ butaca.dataNumFila }} - {{ 'BUTACA' | translate }} {{ butaca.dataNumButaca }}
          </p>
        </div>
        <div class="tablaTarifas">
          <table class="centro">
            <tr class="tituloTabCent">
              <td>{{ 'ENTRADA' | translate }}</td>

              <td>{{ 'PRECIO' | translate }}</td>
            </tr>
            <ng-container *ngIf="butaca">
              <tr
                *ngFor="let item of butaca.tarifas; let i = index"
                class="click"
                [class]="'click '+[+item.Habilitado !== 1 ? 'deshabilitado' : '' ]"
                (click)="selectTipo(item, i)"
              >
                <td>
                  {{ item.NombreEntrada }}
                  <span class="descrip">
                    <br />
                    {{ item.DescripcionEntrada }}
                  </span>
                </td>
                <td>
                  {{ item.PrecioInternet | myCurrency:',':2}}€
                  <!-- <button class="btnAcces" [disabled]="+item.Habilitado !== 1">
                    {{ 'SELECCIONAR' | translate }}
                  </button> -->
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
    </div>
  </div>
</div>
