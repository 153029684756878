import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_CalendariosTiposControl_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    CodigoTipoControl: string;
}

//insUpd
export class peticion_Insercion_Upd_CalendariosTiposControl {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    TipoControlId: string;
    CodigoTipoControl: string;
    NombreTipoControl: string;
    FechaAcceso: string;
    FechaAccesoFin: string;
    Funcion: string;
}

//del
export class peticion_Del_CalendariosTiposControl {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    CodigoTipoControlId: string;
}