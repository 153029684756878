import { CLienteAPi } from "../userRequest/client_api";

export class Request_tarifa_list {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    fecIniVisita: string;
    fecFinVisita: string;
    feciniCompra: string;
    fecFinCompra: string;
    alaVenta: string;
    tipoBusqueda: string;
    descripcion: string;
    taquilla: string;
    internet: string;
    taquillaAutomatica: string;
    tipoEntradaId: string;
    numeroRegistros: string;
    pagina: string;
    filtro: string;
}
