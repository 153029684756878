import { Injectable } from "@angular/core";
import { ApiTokenService } from "../api/token/api-token.service";
import { Observable } from "rxjs";
import { MicroserviceTokenResponse } from "@sharedV11/interfaces/microservice/microservice.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";

const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
		"Cache-Control": "no-cache",
	}),
};

@Injectable({
	providedIn: "root",
})
export class MicroserviceService {
  
	private urlApi = "";
  
	constructor(private tokenService: ApiTokenService, private http: HttpClient) {
		this.urlApi = this.tokenService.getConfig("API_MICROSERVICIOS");
	}

	getTokenMicroservice(
		username: string,
		password: string
	): Observable<MicroserviceTokenResponse> {
		const body = {
			username,
			password,
		};

		const URL = `${this.urlApi}/Token`;

		const response = this.http.post<MicroserviceTokenResponse>(URL, body, httpOptions);

		return response;
	}


	refreshToken(data: MicroserviceTokenResponse) {
		const body = {
			token: data.token,
			refreshToken: data.refreshToken,
		};

		const URL = `${this.urlApi}/Refresh`;

		const response = this.http.post<MicroserviceTokenResponse>(URL, body, httpOptions);

		return response;
	}


	loginMicroservice(data: MicroserviceTokenResponse) {
    localStorage.setItem("tokenMicroservice", data.token);
    localStorage.setItem("microserviceData", JSON.stringify(data));
  }
}
