//Modelo IACPOS

import {Deserializable} from "./deserializable.model";

export class EnlaceMenu implements Deserializable {

  pkid: number;
  chNombre: string;
  chDescripcion: string;
  iSinFecha?: number;
  iDiasValidezSinFecha?: number;
  chCalendario: string;
  iAudioguia?: number;
  iVerCodigosPromocionales?: number;
  iActivo?: number;
  iVisible?: number;
  dtTiempoVenta?: string;
  dtFechaMinVenta?: string;
  dtTiempoReservar?: string;
  dtFechaMinReserva?: string;
  iNivel?: any;
  iPadre?: any;
  iOrden?: number;
  chSeleccionProductos: string;
  chBotones: string;
  iTipoEnlace: number;
  chTablaTarifas: string;
  iMaximoGratuitas?: number;
  iValidarGratuitas?: number;
  iDiasCalendario?: number;
  iTiempoVentaMin?: any;
  chTiempoVentaMinTipo: string;
  iTiempoVentaMax?: any;
  chTiempoVentaMaxTipo: string;
  iTiempoReservaMin?: any;
  chTiempoReservaMinTipo: string;
  iTiempoReservaMax?: any;
  chTiempoReservaMaxTipo: string;
  dtSinFechaHasta?: any;
  iCheckAcepto?: number;
  iLogueo?: number;
  iTodasMisReservas?: number;
  iTodasMisCompras?: number;
  iNoMostrarPrecioGratuitas?: number;
  iEsCliente?: number;
  iAsignarAgrupadas?: any;
  iTotalizarAgrupadas?: any;
  iSolicitaVerificarRegistro?: any;
  iSolicitaFactura?: number;
  chTiposClientes: string;
  iRecorrido?: number;
  GruposInternetAsociados: string;
  DatosEMListados?: EMListados[];
  CaracteristicasAdicionales?: CaracteristicasAdicionales;
  

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_EnlaceMenu implements Deserializable {
  LstEnlacesMenu: EnlaceMenu[]; 

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class CaracteristicasAdicionales implements Deserializable {
  LetraLocalizador?: any;
  ValidarHoraVisita?: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class EMListados implements Deserializable {
  pkid: number;
  iTipoReservaId?: any;
  iTipoentradaId?: any;
  chNombre: string;
  chTipo: string;
  chNotas: string;
  chFichero: string;
  iCBVisible?: any;
  iCBEjeX?: any;
  iCBEjeY?: any;
  iCB2DVisible?: any;
  iCB2DEjeX?: any;
  iCB2DEjeY?: any;
  chFontName: string;
  fFontSize?: any;
  chFontStyle: string;
  chPageSize: string;
  iMargenLeft?: any;
  iMargenRight?: any;
  iMargenTop?: any;
  iMargenBottom?: any;
  iTipoImpresion?: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

