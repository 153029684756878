import {CLienteAPi} from "./cliente-api.model";
import {IdiomaGR} from "./grupos-recintos-Respuesta.model"
import {TipoPromocionEntrada} from "./promocion-Respuesta.model"

//get
export class peticion_Promocion_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    Id: string;
    pkId: string;
    Nombre: string;
    Descripcion: string;
    Alaventa: string;
}

//insUpd
export class peticion_Insercion_Upd_Promocion {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    PkId: string;
    Codigo: string;
    Descripcion: string;
    Importe: string;
    BonoAutomatico: string;
    ImprimirPrecio: string;
    CanjearAuto: string;
    TipoIva: string;
    TipoValidez: string;
    Validez: string;
    DescripcionNombre: string;
    AlaVenta: string;
    idDivisa: string;
    idTipoventa: string;
    idGrupo: string;
    TeclaRapida: string;
    Negrita: string;
    Cursiva: string;
    Color: string;
    ImprimirPromo: string;
    TipoPromocionEntrada: TipoPromocionEntrada[];

    Idiomas: IdiomaGR[];

    Funcion: string;
}

//del
export class peticion_Del_Promocion {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}

