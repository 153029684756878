import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { user_request } from "../../../classes/userRequest/login/user_request";
import { GlobalService } from "../../global/global.service";

import { Request_userTypes_list } from "@sharedV11/classes/userRequest/users/userProfile/request_userTypes_list";
import { User_types_request } from "@sharedV11/classes/userRequest/users/userProfile/user_types_request";
import { catchError, map } from "rxjs/operators";
import { ApiTokenService } from "../token/api-token.service";

import { grupos_cliente_Respuesta } from "@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model";
import { peticion_GruposClientes_Lista } from "@sharedV11/classes/clients/client/peticion-grupos-clientes.model";
import { peticion_Grupos_Tipos_Entrada_Lista } from "@sharedV11/classes/clients/client/peticion-grupos-tipos-entrada.model";
import { peticion_Idiomas_Lista } from "@sharedV11/classes/clients/client/peticion-idiomas.model";
import { EntryType } from "@sharedV11/classes/entradas-y-promociones/tipo-entrada/entry-type";
import { TipoBono } from "@sharedV11/classes/entradas-y-promociones/tipo-entrada/tipobono";
import { peticion_Generica } from "@sharedV11/classes/peticion-generic";
import { peticion_TiposReserva_Lista } from "@sharedV11/classes/tarifaResponse/peticion-enlaces-menu.model";
import { PeticionInternetGroupLista } from "@sharedV11/classes/tarifas/api-gruposinternet.model";
import { PeticionClienteLista } from "@sharedV11/classes/userRequest/clientes/api-clientes.model";
import { peticion_FormasPago_Lista } from "@sharedV11/classes/userRequest/clientes/peticion-formas-pago.model";
import { peticion_TiposCliente_Lista } from "@sharedV11/classes/userRequest/clientes/peticion-tipos-cliente.model";
import { peticion_Del_FormasPago } from "@sharedV11/classes/userRequest/clientes/peticion_Del_FormasPago";
import { tipos_cliente_Respuesta } from "@sharedV11/classes/userRequest/clientes/tipos-cliente-Respuesta.model";
import { Request_insertion_upd_userTypes } from "@sharedV11/classes/userRequest/users/userProfile/request_insertion_upd_userTypes";
import { peticion_Insercion_Upd_Usuarios } from "src/app/shared/classes/userRequest/peticion_Insercion_Upd_Usuarios/peticion_Insercion_Upd_Usuarios";
import { peticion_Usuarios_Borrar } from "src/app/shared/classes/userRequest/peticion_Usuarios_Borrar/peticion_Usuarios_Borrar";
import { peticion_Usuarios_Lista } from "src/app/shared/classes/userRequest/peticion_Usuarios_Lista/peticion_Usuarios_Lista";
import { productosData, TiendaProductosDataResponse } from "src/app/components/tienda/productos/productosData";

// Sistema de API FAKE http://localhost:3000
// https://blog.angulartraining.com/fake-your-angular-backend-until-you-make-it-8d145f713e14

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ConectarApiService {
  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private tokenService: ApiTokenService
  ) {}

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getPermisosVentana(ruta, AplicacionCod, IdAplicacion) {
    /*     peticion["ClaveEmpresa"] = this.tokenService.getConfig('chClaveEmpresa')
    peticion["ProyectoOnLine"] = this.tokenService.getConfig('chProyectoOnLine')
    peticion["ConexionEntorno"] = this.tokenService.getConfig('chEntornoConexion')
    peticion["ConexionIacpos"] = parseInt(localStorage.getItem('conexionIACPOS')) */

    const body = {
      clienteAPI: this.global.clienteapi,
      NombreUsuario: JSON.parse(sessionStorage.getItem("currentUser"))
        .DatosResult.NombreUsuario,
      Ventana: ruta,
      IdAplicacion: IdAplicacion,
      AplicacionCod: AplicacionCod,
      ModuloId: "",
      ConexionIacpos: parseInt(localStorage.getItem("conexionIACPOS")),
    };

    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Usuario/ModulosAccesos",
        body,
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getClientes(pagination, filters): Observable<any> {
    const peticion = new PeticionClienteLista(pagination);
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;

    peticion.filters = filters;
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/BuscadorClienteAgrupado",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerDatosClientes() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "codcliente";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerClientes() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "cliente";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerGruposClientes() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "gruposclientes";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerTiposClientes() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "tipoCliente";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerEntradas() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "entradas";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerGruposEntradas() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "gruposentradas";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerTiposBonos() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "tipobono0";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerGruposTipoBono() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "grupostipobono";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerProcesosVenta() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "procesosventa";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerEspectaculos() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "espectaculo";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerTarifas() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "tarifa";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  traerGruposTarifas() {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO
    peticion["Usuario"] = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion["NombreTabla"] = "grupotarifa";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getItemsMotivosGrupo(paginator?, filters?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.paginator = paginator; //TODO
    peticion.filters = filters; //TODO
    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanItemsMotivosGrupos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getGruposTipoBono(paginator?, filters?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.paginator = paginator; //TODO
    peticion.filters = filters; //TODO
    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getTiposControl(paginator, filters): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.paginator = paginator; //TODO
    peticion.filters = filters; //TODO
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getGruposInternet(pagination?, filters?): Observable<any> {
    const peticion = new PeticionInternetGroupLista();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    peticion.Usuario = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposInternet",
        peticion
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getTiposSesion(pagination?, filters?): Observable<any> {
    const peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    if (pagination) peticion.paginator = pagination;
    if (filters) peticion.filters = filters;
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTipoSesion",
        peticion
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getRecintos(pagination?, filters?): Observable<any> {
    const peticion = new peticion_Generica(pagination);
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    if (filters) peticion.filters = filters;
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerRecintos",
        peticion
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  restorePassword(user): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = user.username;
    peticion.Email = user.email;
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Usuario/CrearTokenCambioContrasenaMail",
        peticion
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getSinButacas(pagination, filters): Observable<any> {
    const peticion = new peticion_Generica(pagination);
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    peticion.filters = filters;
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerRecintoSesion",
        peticion
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getUsuario(
    usuario: string,
    contrasena: string,
    tokenOrigen?: string,
    CodigoApp?: string,
    CodigoSec?: string
  ): Observable<any> {
    //Para los Permisos del Usuario
    //Montamos la peticion
    let peticion: user_request = new user_request();
    peticion.clienteAPI = this.global.clienteapi; //TODO meter en global al arrancar
    /*     peticion.NombreUsuario = usuario;
    peticion.ClaveUsuario = contrasena; */
    peticion.IdAplicacion = this.global.idAplicacion.toString();
    peticion.NombreUsuario = usuario;
    if (CodigoApp) peticion.AplicacionCod = CodigoApp;

    if (tokenOrigen) peticion.TokenOrigen = tokenOrigen;
    else if (CodigoSec) peticion.CodigoAuth = CodigoSec;

    let base64userpass = btoa(usuario + ":" + contrasena);
    let httpOptionsLogin = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Basic " + base64userpass,
      }),
    };

    //httpOptionsLogin.headers.append("Authorization","Basic ASDFGHJKL");
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Usuarios",
        JSON.stringify(peticion),
        httpOptionsLogin
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  changePassword(data, passwordOld, nombre, id): Observable<any> {
    //Para los Permisos del Usuario
    //Montamos la peticion
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO meter en global al arrancar
    peticion.Nombre = nombre;
    peticion.Clave = passwordOld;
    peticion.NuevaClave = data.password;
    peticion.funcion = "C";
    peticion.pkId = id;
    peticion.IdAplicacion = this.global.idAplicacion.toString();
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ActualizarContrasenaCustomanUsuarios ",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  logOut(): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Id = JSON.parse(localStorage.getItem("currentToken")).Id;
    peticion.Token = JSON.parse(localStorage.getItem("currentToken")).Token;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Usuarios/Logout",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(map(this.extractData), catchError(this.handleError<any>("Logout")));
  }

  GetAppConfig(userId) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: userId,
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Configuracion/GetAppConfig ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  GetDatosInicio() {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      CodigoApp: "CUSTOMAN",
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/GetDatosInicio ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getDivisas(nombre?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Maestros/GetDivisas ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getLugares(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanLugares",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getLugares"))
      );
  }

  async getUsuarioAsync(usuario, contrasena): Promise<any> {
    //Para los Permisos del Usuario
    //Montamos la peticion
    let peticion: user_request = new user_request();
    peticion.clienteAPI = this.global.clienteapi; //TODO meter en global al arrancar
    peticion.NombreUsuario = usuario;
    peticion.ClaveUsuario = contrasena;
    peticion.IdAplicacion = this.global.idAplicacion.toString();
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Usuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  async getTiposUsuarioAsync(paginator?, filters?): Promise<any> {
    //Montamos la peticion Listado de Tipos Usuario
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (paginator) {
      peticion.paginator = paginator; //TODO
      peticion.filters = filters; //TODO
    }

    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  async getUsuariosAsync(paginator?, filters?): Promise<any> {
    //Montamos la peticion Listado de Usuario (necesitamos una generica no es peticionUsuario)
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (paginator) {
      peticion.paginator = paginator; //TODO
      peticion.filters = filters; //TODO
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  delTiposUsuario(pkId: string): Observable<any> {
    let peticion: User_types_request = new User_types_request();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  insUpdTiposUsuario(
    pkId: string,
    Nombre: string,
    Descripcion: string,
    Privilegios: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion: Request_insertion_upd_userTypes =
      new Request_insertion_upd_userTypes();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = Nombre;
    peticion.Descripcion = Descripcion == null ? "" : Descripcion;
    peticion.Privilegios = Privilegios == null ? "" : Privilegios;
    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarCustomanActualizarTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getTiposUsuario(nombre?: string, descripcion?: string): Observable<any> {
    //Montamos la peticion Listado de Tipos Usuario
    let peticion: Request_userTypes_list = new Request_userTypes_list();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getTiposEntradas(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  getTiposGrupos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("ObtenerCustomanGrupoTipoBono"))
      );
    return respuesta;
  }

  GetTiposProductos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/GetTiposProductos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  GetSubCategorias(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanSubCategorias",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  GetProveedores(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerProveedoresTienda",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  GetTiposVisita(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoVisita",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  GetTiposNivel(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/GetTiposNiveles",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  GetGruposAsociados() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/GetGruposAsociados ",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  GetCategorias(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCategoria",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  GetMotivosGrupos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanItemsMotivosGrupos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  GetTiposEntradaDatos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/GetTiposEntradaDatos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  getGruposRecintos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposRecintos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  getGruposActividadTaller(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGruposActividadTaller",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }
  getCategorias(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCategoria",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  /*   getEspectaculos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination
      peticion.filters = filters
    }

    //Enviamos la peticion
    let respuesta = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetEspectaculos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
  } */

  /*   getTPVs(pagination?, filters?): Observable<any> {
    const peticion = {
      clienteAPI: this.global.clienteapi
    };

    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTPVS', peticion)
    .pipe(
      map(data => {
        if (data.DatosResult) {
          return data.DatosResult;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      }),
    );
  }
 */

  getTPVs(pagination?, filters?): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    (peticion.pkId = ""),
      (peticion.Nombre = ""),
      (peticion.Descripcion = ""),
      (peticion.LugarId = ""),
      (peticion.DireccionIP = ""),
      (peticion.VMPath = ""),
      (peticion.Asignado = ""),
      (peticion.Usuario = ""),
      (peticion.ConexionIacpos = ""),
      (peticion.Password = "");

    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerCustomanTPV",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
    /*     return this.http.get<any>('/api/getTpvsNew').pipe(
      map(data => {
        if (data) {
          return data;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      }),
    ); */
  }

  getFestivos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanFestivos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  getTiposreservas(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoReserva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  getTiposBonos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    let respuesta = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return respuesta;
  }

  async getTarifasAsync(paginator, filters): Promise<any> {
    //tipob?:string, ALaVenta?:string
    //Montamos la peticion Listado
    //Enviamos la peticion (Cambiamos por filtro)
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.paginator = paginator; //TODO
    peticion.filters = filters; //TODO

    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTarifas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  async getGruposTarifasAsync(paginator, filters): Promise<any> {
    //tipob?:string, ALaVenta?:string
    //Montamos la peticion Listado
    //Enviamos la peticion (Cambiamos por filtro)
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.paginator = paginator; //TODO
    peticion.filters = filters; //TODO

    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerGruposTarifas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  async getTiendaProductosAsync(paginator, filters): Promise<TiendaProductosDataResponse> {
    //tipob?:string, ALaVenta?:string
    //Montamos la peticion Listado
    //Enviamos la peticion (Cambiamos por filtro)
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.paginator = paginator; //TODO
    peticion.filters = filters; //TODO

    // let respuesta = await this.http
    //   .post<any>(
    //     this.tokenService.getConfig("API_URL") + "Customan/ObtenerProductosTienda",
    //     JSON.stringify(peticion),
    //     httpOptions
    //   )
    //   .pipe(
    //     map((data) => {
    //       return data;
    //     }),
    //     catchError((error: HttpErrorResponse) => {
    //       return throwError(error);
    //     })
    //   )
    //   .toPromise();
    // return respuesta;
    return of(productosData).pipe(catchError((error: HttpErrorResponse) => {
        return throwError(error);
    })).toPromise()
  }

  async getReglasNegocioAsync(paginator, filters): Promise<any> {
    //tipob?:string, ALaVenta?:string
    //Montamos la peticion Listado
    //Enviamos la peticion (Cambiamos por filtro)
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.paginator = paginator; //TODO
    peticion.filters = filters; //TODO

    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerReglasNegocioTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  //V6 services
  //Usuarios V6
  insUpdUsuarios(
    pkId: string,
    Personal: string,
    Nombre: string,
    Clave: string,
    Tipo: string,
    Baja: boolean,
    Conectado: boolean,
    Empleado: string
  ): Observable<any> {
    // let Baja1:boolean = Baja.trim().toLowerCase() == "true" || Baja.trim().toLowerCase() == "1"?true:false;
    // let Conectado1:boolean = Conectado.trim().toLowerCase() == "true" || Conectado.trim().toLowerCase() == "1"?true:false;
    //Montamos la peticion Listado de Usuario (necesitamos una generica no es peticionUsuario)
    let peticion: peticion_Insercion_Upd_Usuarios =
      new peticion_Insercion_Upd_Usuarios();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = Nombre;
    peticion.Clave = Clave;
    peticion.Tipo = Tipo;
    if (Empleado != null) {
      peticion.Empleado = Empleado;
    } else {
      peticion.Empleado = "";
    }
    if (Personal != null) {
      peticion.Personal = Personal;
    } else {
      peticion.Personal = "";
    }
    if (Baja) {
      peticion.Baja = "1";
    } else {
      peticion.Baja = "0";
    }
    if (Conectado) {
      peticion.Conectado = "1";
    } else {
      peticion.Conectado = "0";
    }
    if (pkId != null && pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = pkId;
    } else {
      peticion.Funcion = "I";
      peticion.pkId = "0";
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getUsuarios(usuario?: string, tipousuario?: number): Observable<any> {
    //Montamos la peticion Listado de Usuario (necesitamos una generica no es peticionUsuario)
    let peticion: peticion_Usuarios_Lista = new peticion_Usuarios_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (usuario != null) {
      peticion.NombreUsuario = usuario;
    } else {
      peticion.NombreUsuario = "";
    }
    if (usuario != null) {
      peticion.iTipoUsuario = tipousuario;
    } else {
      peticion.iTipoUsuario = 0;
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delUsuarios(pkId: string): Observable<any> {
    let peticion: peticion_Usuarios_Borrar = new peticion_Usuarios_Borrar();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    //Enviamos la peticion

    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delTarifas(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTarifas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delGrupoTarifa(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarGruposTarifas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          console.log('data de grupoTarifa: ', data)
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delTiendaProductos(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    // Enviamos la peticion
    // const response = this.http
    //   .post<any>(
    //     this.tokenService.getConfig("API_URL") +
    //       "Customan/ObtenerProductosTienda",
    //     JSON.stringify(peticion),
    //     httpOptions
    //   )
    //   .pipe(
    //     map((data) => {
    //       return data;
    //     }),
    //     catchError((error: HttpErrorResponse) => {
    //       return throwError(error);
    //     })
    //   );
    // return response;

    return of(productosData).pipe(catchError((error: HttpErrorResponse) => {
      return throwError(error);
     }))
  }

  delReglaNegocio(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarReglasNegocioTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  async delUsuariosAsync(pkId: string): Promise<any> {
    let peticion: peticion_Usuarios_Borrar = new peticion_Usuarios_Borrar();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanUsuarios",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  /*   getTiposCliente(nombre?: string, descripcion?: string): Observable<tipos_cliente_Respuesta> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposCliente_Lista = new peticion_TiposCliente_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) { peticion.NombreTipoCliente = nombre; } else { peticion.NombreTipoCliente = ""; }
    if (descripcion != null) { peticion.CodigoTipoCliente = descripcion; } else { peticion.CodigoTipoCliente = ""; }
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTiposClientes', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  } */
  async getTiposClienteAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposCliente_Lista =
      new peticion_TiposCliente_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) {
      peticion.NombreTipoCliente = nombre;
    } else {
      peticion.NombreTipoCliente = "";
    }
    if (descripcion != null) {
      peticion.CodigoTipoCliente = descripcion;
    } else {
      peticion.CodigoTipoCliente = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  getFormasPago(paginator?, filters?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (paginator != null) {
      peticion.paginator = paginator;
      peticion.filters = filters;
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  async getFormasPagoAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_FormasPago_Lista = new peticion_FormasPago_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) {
      peticion.Denominacion = nombre;
    } else {
      peticion.Denominacion = "";
    }
    if (descripcion != null) {
      peticion.Descripcion = descripcion;
    } else {
      peticion.Descripcion = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }
  delFormasPago(pkId: string): Observable<any> {
    let peticion: peticion_Del_FormasPago = new peticion_Del_FormasPago();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  async delFormasPagoAsync(pkId: string): Promise<any> {
    let peticion: peticion_Del_FormasPago = new peticion_Del_FormasPago();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    peticion.pkId = pkId;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanFormaPago",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  getTiposReserva(): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposReserva_Lista =
      new peticion_TiposReserva_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    //if (nombre != null) { peticion.Nombre = nombre; } else { peticion.NombreTipoCliente = ""; }
    //if (descripcion != null) { peticion.CodigoTipoCliente = descripcion; } else { peticion.CodigoTipoCliente = ""; }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoReserva",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getEnlacesMenu(): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion["ClaveEmpresa"] = this.tokenService.getConfig("chClaveEmpresa");
    peticion["ProyectoOnLine"] =
      this.tokenService.getConfig("chProyectoOnLine");
    peticion["ConexionEntorno"] =
      this.tokenService.getConfig("chEntornoConexion");
    peticion["ConexionIacpos"] = parseInt(
      localStorage.getItem("conexionIACPOS")
    );

    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "TraerEnlacesMenuPorEmpresa",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getIdiomas(): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion["Activo"] = 1;
    peticion.GrupoInternet = "";
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerIdiomas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  async getIdiomasAync(): Promise<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion["Activo"] = 1;
    peticion.GrupoInternet = "";
    const response = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerIdiomas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return response;
  }

  async getTiposReservasync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_TiposCliente_Lista =
      new peticion_TiposCliente_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) {
      peticion.NombreTipoCliente = nombre;
    } else {
      peticion.NombreTipoCliente = "";
    }
    if (descripcion != null) {
      peticion.CodigoTipoCliente = descripcion;
    } else {
      peticion.CodigoTipoCliente = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  getZonaComercial(): Observable<any> {
    const peticion = {
      NombreTabla: "zonacomercial",
    };

    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas",
        peticion,
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  async getGruposClienteAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_GruposClientes_Lista =
      new peticion_GruposClientes_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoCliente",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }
  async ObtenerCustomanCanales(): Promise<any> {
    let body = {
      // "IdCanal": 0,
      Canal: "",
      Seleccionable: "",
      IdSubCanal: "",
      SubCanal: "",
      CodSubCanal: "",
    };
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCanales",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }
  async getIdiomasAsync(
    nombre?: string,
    codigo?: string,
    activo?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion: peticion_Idiomas_Lista = new peticion_Idiomas_Lista();
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (codigo != null) {
      peticion.Codigo = codigo;
    } else {
      peticion.Codigo = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerIdiomas",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }
  getGruposTiposEntrada(paginator?, filters?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (paginator) {
      peticion.paginator = paginator;
      peticion.filters = filters;
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  //InsertarActualizarCustomanTPV
  setTPV(TpvData, modo) {
    TpvData.clienteAPI = this.global.clienteapi; //TODO
    TpvData.Funcion = modo;

    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTPV",
        JSON.stringify(TpvData),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delTpv(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    //peticion.PkId = pkId;

    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/EliminarCustomanTPV",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getTiposAbonados(paginator?, filters?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoAbonado",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getTPVCajas(TPVId, fecha?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.TPVId = TPVId;
    if (fecha) {
      var month = fecha.getMonth() + 1; //months from 1-12
      var day = fecha.getDate();
      var year = fecha.getFullYear();
      peticion.Fecha = year + "/" + month + "/" + day;
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/GetCustomanCajasTPVbyDate",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  setTPVCerrarCaja(
    UsuarioId,
    CajaId,
    FechaCierre,
    DivisaId,
    importe?,
    comentario?,
    desglose?
  ): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.CajaId = CajaId;
    peticion.DivisaId = DivisaId;
    peticion.Fecha = FechaCierre;
    peticion.TipoOperacion = "CC";
    peticion.UsuarioId = UsuarioId;
    peticion.Comentario = "Customan";
    peticion.Importe = "0";
    if (importe) peticion.Importe = importe;
    if (comentario) peticion.Comentario = comentario;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "VentaTaquilla/CajaGestion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  async getGruposTiposEntradaAsync(
    nombre?: string,
    descripcion?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Grupos_Tipos_Entrada_Lista =
      new peticion_Grupos_Tipos_Entrada_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (nombre != null) {
      peticion.NombreGrupo = nombre;
    } else {
      peticion.NombreGrupo = "";
    }
    if (descripcion != null) {
      peticion.chDescripcion = descripcion;
    } else {
      peticion.chDescripcion = "";
    }

    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return response;
  }
  getGrupoTipoBono(paginator?, filters?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (paginator) {
      peticion.paginator = paginator;
      peticion.filters = filters;
    }

    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  async ObtenerCustomanGrupoTipoAbonado(): Promise<any> {
    let body = {
      // "pkId": 0,
      Nombre: "",
      Descripcion: "",
    };
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoTipoAbonado",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }
  getGruposCliente(
    pagination?,
    filters?
  ): Observable<grupos_cliente_Respuesta> {
    //Montamos la peticion Listado
    const peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanGrupoCliente",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getTiposCliente(pagination?, filters?): Observable<tipos_cliente_Respuesta> {
    //Montamos la peticion Listado
    const peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(
      sessionStorage.getItem("currentUser")
    ).DatosResult.NombreUsuario;
    if (pagination) {
      peticion.paginator = pagination;
      peticion.filters = filters;
    }
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTiposClientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delGruposdeInternet(pkId: string): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion["ClaveEmpresa"] = this.tokenService.getConfig("chClaveEmpresa");
    peticion["ProyectoOnLine"] =
      this.tokenService.getConfig("chProyectoOnLine");
    peticion["ConexionEntorno"] =
      this.tokenService.getConfig("chEntornoConexion");

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delTipoSesion(pkId: string): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion["ClaveEmpresa"] = this.tokenService.getConfig("chClaveEmpresa");
    peticion["ProyectoOnLine"] =
      this.tokenService.getConfig("chProyectoOnLine");
    peticion["ConexionEntorno"] =
      this.tokenService.getConfig("chEntornoConexion");

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delSinButaca(pkId: string): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion["ClaveEmpresa"] = this.tokenService.getConfig("chClaveEmpresa");
    peticion["ProyectoOnLine"] =
      this.tokenService.getConfig("chProyectoOnLine");
    peticion["ConexionEntorno"] =
      this.tokenService.getConfig("chEntornoConexion");

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanRecintoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delTipoCLiente(pkId: string): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    //peticion["ClaveEmpresa"] = this.tokenService.getConfig('chClaveEmpresa')
    //peticion["ProyectoOnLine"] = this.tokenService.getConfig('chProyectoOnLine')
    //peticion["ConexionEntorno"] = this.tokenService.getConfig('chEntornoConexion')

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTipoCLientes",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delTipoControl(pkId: string): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    //peticion["ClaveEmpresa"] = this.tokenService.getConfig('chClaveEmpresa')
    //peticion["ProyectoOnLine"] = this.tokenService.getConfig('chProyectoOnLine')
    //peticion["ConexionEntorno"] = this.tokenService.getConfig('chEntornoConexion')

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  deEspectaculo(pkId: string): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    //peticion["ClaveEmpresa"] = this.tokenService.getConfig('chClaveEmpresa')
    //peticion["ProyectoOnLine"] = this.tokenService.getConfig('chProyectoOnLine')
    //peticion["ConexionEntorno"] = this.tokenService.getConfig('chEntornoConexion')

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/DelEspectaculos",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  deSesionEspectaculo(
    iEspectaculoId,
    dtFechaInicio,
    dtHoraInicio
  ): Observable<any> {
    let peticion: peticion_Generica = new peticion_Generica();
    //peticion.clienteAPI = this.global.clienteapi; //TODO

    /*
    
    var espe = {"iEspectaculoId":iEspectaculoId};
    var fech = {"dtFechaInicio":dtFechaInicio};
    var hora = {"dtHoraInicio":dtHoraInicio};

   */

    var espe = iEspectaculoId;
    var fech = dtFechaInicio;
    var hora = dtHoraInicio;

    let peticionfinal = {
      iEspectaculoId: iEspectaculoId,
      dtFechaInicio: dtFechaInicio,
      dtHoraInicio: dtHoraInicio,
    };

    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/EliminarSesiones",
        JSON.stringify(peticionfinal),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getTiposIVA(id?: string, nombre?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      pkId: id ? id : "",
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTiposIva ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getCalendariosTiposIVA(id?: string) {
    const body = {
      id: id ? id : "",
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/GetTipoIvaCalendario ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getTiposAbonado(id?: string, nombre?: string, alaventa?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      pkId: id ? id : "",
      Alaventa: alaventa ? alaventa : "",
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerTiposAbonado ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  getGruposInternetTarifas(idreserva?: string, nombre?: string) {
    const body = {
      Nombre: nombre ? nombre : "",
      TipoReserva: idreserva ? idreserva : "",
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposInternet ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  async getTiposBonoAsync(
    pkId?: string,
    nombre?: string,
    alaventa?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    //let peticion: peticion_Promocion_Lista = new peticion_Promocion_Lista();
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (pkId != null) {
      peticion.pkId = pkId;
    } else {
      peticion.pkId = "";
    }
    if (alaventa != null) {
      peticion.Alaventa = alaventa;
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoBono",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }
  GetTipoEntrada() {
    const body = {
      CodigoApp: "CUSTOMAN",
      Id: "",
    };
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetTipoEntrada ",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  InsertarActualizarCustomanTarifas(body) {
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanTarifas",
        JSON.stringify(body),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  insUpdTiposEntradas(entrada: EntryType, mode): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    //entrada.nombre = entrada.nombre.trim()
    peticion.Nombre = entrada.Nombre.trim();
    peticion.Descripcion = entrada.Descripcion;
    peticion.PrecioUnitario = entrada.PrecioUnitario;
    peticion.DivisaId = entrada.DivisaId;
    peticion.ALaVenta = entrada.ALaVenta;
    peticion.FormatoId = entrada.FormatoId;
    peticion.TextoPantalla = entrada.TextoPantalla;
    peticion.CondicionesVenta = entrada.CondicionesVenta;
    peticion.TextoImpresion = entrada.TextoImpresion;
    peticion.TextoDisplay = entrada.TextoDisplay;
    peticion.CodigoBarras = entrada.CodigoBarras;
    peticion.IVA = entrada.IVA;
    peticion.BaseImponible = entrada.BaseImponible;
    peticion.TipoAbreviado = entrada.TipoAbreviado;
    peticion.MaximoPorDia = entrada.MaximoPorDia;
    peticion.GrupoId = entrada.GrupoId;
    peticion.ImprimirTicket = entrada.ImprimirTicket;
    peticion.ControlarAcceso = entrada.ControlarAcceso;
    peticion.CodigoTipoControl = entrada.CodigoTipoControl;
    peticion.TeclaRapida = entrada.TeclaRapida;
    peticion.GrupoMotivoId = entrada.GrupoMotivoId;
    peticion.ImpresoraSecundaria = entrada.ImpresoraSecundaria;
    peticion.TicketVendido = entrada.TicketVendido;
    peticion.ImprimirPrecio = entrada.ImprimirPrecio;
    peticion.TipoButaca = entrada.TipoButaca;
    peticion.CuentaVisitante = entrada.CuentaVisitante;
    peticion.CaracteristicaId = entrada.CaracteristicaId;
    peticion.ArgumentosCaracteristica = entrada.ArgumentosCaracteristica;
    peticion.TipoButacaId = entrada.TipoButacaId;
    peticion.BarCodeInternet = entrada.BarCodeInternet;
    peticion.RecintosAsociados = entrada.RecintosAsociados;
    peticion.ActividadesAsociadas = entrada.ActividadesAsociadas;
    // Nuevos campos
    peticion.TipoProducto = entrada.TipoProducto;
    peticion.GrupoAsociado = entrada.GrupoAsociado;
    peticion.TipoVisita = entrada.TipoVisita;
    peticion.TipoNivel = entrada.TipoNivel;
    peticion.Categoria = entrada.Categoria;
    peticion.TEDDatos = entrada.TEDDatos;
    peticion.ConfiguracionAdicional = entrada.ConfiguracionAdicional;
    peticion.CentrosIds = entrada.CentrosIds;
    peticion.CentroCosteId = entrada.CentroCosteId;
    peticion.CanalId = entrada.CanalId;

    peticion.Funcion = mode;
    peticion.EntradaId = entrada.pkId;

    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/InsUpdTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delTiposEntradas(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.entradaId = pkId;
    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/DelTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
  delGruposTiposEntrada(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    peticion.PkId = pkId;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCustomanGrupoTipoEntrada",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  insTipoBono(tipobono: TipoBono, mode): Observable<any> {
    // Montamos la peticion insercion/Update
    tipobono["clienteAPI"] = this.global.clienteapi;
    //tipobono["GrupoMotivoId"] = "";

    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarCustomanTipoBonoModel",
        JSON.stringify(tipobono),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  updTipoBono(tipobono: TipoBono, mode): Observable<any> {
    tipobono["clienteAPI"] = this.global.clienteapi;
    //delete tipobono.chCodigo; // el servicio da error si se envía un código para actualización
    // Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ActualizarCustomanTipoBonoModel",
        JSON.stringify(tipobono),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  getTiposCodigosPromocionales_tiposEntrada(
    paginator?,
    filters?
  ): Observable<any> {
    const body = {};
    //const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerGruposInternet ', body).pipe(
    const response = this.http
      .get<any>("/api/tipoCodigoPromocional_tiposEntrada")
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  //getTiposCodigosPromocionales_tiposPromociones(paginator?, filters?): Observable<any> {
  /*   getTiposCodigosPromocionales_tiposPromociones(parent_pkId): Observable<any> {
    const body = {

        };
        //const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerGruposInternet ', body).pipe(
          const response = this.http.get<any>('/api/tipoCodigoPromocional_tiposPromociones').pipe(
          map(data => {
            return data;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        );
        return response; 
  } */

  // se usa???
  getTiposCodigosPromocionales_tiposPromociones(parent_pkId): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    peticion.pkId = parent_pkId;
    //const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCategoria ', JSON.stringify(peticion), httpOptions).pipe(
    //const response = this.http.get<any>('/api/tbTiposCodigosPromocionales').pipe(map(data => {
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanTipoCodigoPromocional",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  // ---------------------------------
  /// RECINTOS / CENTROS

  getCentros(
    nombre?: string,
    clave?: string,
    activo?: string
  ): Observable<any> {
    // Montamos la peticion Listado
    let peticion = {
      ConexionIacpos: null,
      clienteAPI: {},
      Nombre: "",
      Activo: "",
      Clave: "",
    };
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (clave != null) {
      peticion.Clave = clave;
    } else {
      peticion.Clave = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerCentros",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCentros"))
      );
  }
  async getCentrosAsync(
    nombre?: string,
    clave?: string,
    activo?: string
  ): Promise<any> {
    // Montamos la peticion Listado
    let peticion = {
      ConexionIacpos: null,
      clienteAPI: {},
      Nombre: "",
      Activo: "",
      Clave: "",
    };

    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) {
      peticion.Nombre = nombre;
    } else {
      peticion.Nombre = "";
    }
    if (clave != null) {
      peticion.Clave = clave;
    } else {
      peticion.Clave = "";
    }
    if (activo != null) {
      peticion.Activo = activo;
    } else {
      peticion.Activo = "";
    }
    // Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerCentros",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCentros"))
      )
      .toPromise();
    return respuesta;
  }

  /// F TiposControl
  //---------------------------------
  /// MAESTROS / CalendariosTiposControl

  getCalendariosTiposControl(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO

    if (nombre != null) {
      peticion["Nombre"] = nombre;
    } else {
      peticion["Nombre"] = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCalendariosTiposControl"))
      );
  }

  getCalendariosTiposControlbyId(CodigoTipoControl?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO

    if (CodigoTipoControl != null) {
      peticion["CodigoTipoControl"] = CodigoTipoControl;
    } else {
      peticion["CodigoTipoControl"] = "";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCalendariosTiposControl"))
      );
  }

  async getCalendariosTiposControlAsync(
    nombre?: string,
    codigo?: string
  ): Promise<any> {
    //Montamos la peticion Listado
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO

    if (nombre != null) {
      peticion["Nombre"] = nombre;
    } else {
      peticion["Nombre"] = "";
    }
    if (codigo != null) {
      peticion["CodigoTipoControl"] = codigo;
    } else {
      peticion["CodigoTipoControl"] = "";
    }

    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("getCalendariosTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  insUpdCalendariosTiposControl(
    pkId: string,
    TipoControlId: string,
    CodigoTipoControl: string,
    NombreTipoControl: string,
    FechaAcceso: string,
    FechaAccesoFin: string
  ): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO

    peticion["TipoControlId"] = TipoControlId;
    peticion["NombreTipoControl"] = NombreTipoControl;
    peticion["CodigoTipoControl"] = CodigoTipoControl;
    peticion["FechaAcceso"] = FechaAcceso;
    peticion["FechaAccesoFin"] = FechaAccesoFin;

    if (pkId != null && pkId != "0") {
      peticion["Funcion"] = "U";
      peticion["pkId"] = pkId;
    } else {
      peticion["Funcion"] = "I";
      peticion["pkId"] = "0";
    }
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdCalendariosTiposControl"))
      );
  }
  async insUpdCalendariosTiposControlAsync(
    pkId: string,
    TipoControlId: string,
    CodigoTipoControl: string,
    NombreTipoControl: string,
    FechaAcceso: string,
    FechaAccesoFin: string
  ): Promise<any> {
    //Montamos la peticion insercion/Update
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO

    peticion["TipoControlId"] = TipoControlId;
    peticion["NombreTipoControl"] = NombreTipoControl;
    peticion["CodigoTipoControl"] = CodigoTipoControl;
    peticion["FechaAcceso"] = FechaAcceso;
    peticion["FechaAccesoFin"] = FechaAccesoFin;

    if (pkId != null && pkId != "0") {
      peticion["Funcion"] = "U";
      peticion["pkId"] = pkId;
    } else {
      peticion["Funcion"] = "I";
      peticion["pkId"] = "0";
    }
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("insUpdCalendariosTiposControl"))
      )
      .toPromise();
    return respuesta;
  }

  delCalendariosTiposControl(pkId: string, codigo: string): Observable<any> {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO

    peticion["pkId"] = pkId;
    peticion["CodigoTipoControlId"] = codigo;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delCalendariosTiposControl"))
      );
  }

  async delCalendariosTiposControlAsync(
    pkId: string,
    codigo: string
  ): Promise<any> {
    let peticion = {};
    peticion["clienteAPI"] = this.global.clienteapi; //TODO

    peticion["pkId"] = pkId;
    peticion["CodigoTipoControlId"] = codigo;
    //Enviamos la peticion
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/EliminarCustomanCalendariosTiposControl",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("delCalendariosTiposControl"))
      )
      .toPromise();
    return respuesta;
  }
  /// F TiposControl
  //---------------------------------

  TraerDatosListasAsync(tabla) {
    const body = {
      NombreTabla: tabla,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Configuracion/TraerDatosListas ",
        body
      )
      .pipe(map((res) => res));
  }

  GetTipoButacasPorRecintoAsync(
    espectaculoId,
    fechaSesion,
    horaSesion,
    grupoRecintoId
  ) {
    const body = {
      EspectaculoId: espectaculoId,
      RecintosId: null,
      FechaSesion: fechaSesion,
      HoraInicioSesion: horaSesion,
      SesionInternetId: null,
      GrupoRecintoId: grupoRecintoId,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/GetTipoButacasPorRecinto ",
        body
      )
      .pipe(map((res) => res));
  }

  ButacaDetalleAsync(recintosButacasId) {
    const body = {
      RecintosButacasId: recintosButacasId,
      GruposInternetId: "",
      TipoEntrada: 0,
      Espectaculo: "",
      Nombre: "",
      Contrasena: "",
      chPlataforma: "",
    };
    return this.http
      .post<any>(this.tokenService.getConfig("API_URL") + "/ButacaInfo ", body)
      .pipe(map((res) => res));
  }

  MarcarDesmarcarButacaAsync(marcado, marcar) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      Marcado: marcado,
      Marcar: marcar,
      Taquilla: "-1",
      SesionInternetId: token.Id,
      ValidarTarifa:0
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "/MarcarDesmarcarButaca ",
        body
      )
      .pipe(map((res) => res));
  }

  async MarcarDesmarcarButacaSync(marcado, marcar) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      Marcado: marcado,
      Marcar: marcar,
      Taquilla: "-1",
      SesionInternetId: token.Id,
      ValidarTarifa:0
    };
    let respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "/MarcarDesmarcarButaca ",
        body
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .toPromise();
    return respuesta;
  }

  MarcarDesmarcarButacaNoNumeradaAsync(
    idRecinto,
    idEspectaculo,
    fecha,
    hora,
    tipoButaca,
    marcado
  ) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      RecintoId: idRecinto,
      EspectaculoId: idEspectaculo,
      FechaEspectaculo: fecha,
      HoraEspectaculo: hora,
      Marcar: 2,
      Taquilla: -1,
      TipoButaca: 0,
      TipoButacaN: tipoButaca,
      SesionInternetId: token.Id,
      Marcado: marcado,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/MarcarDesmarcarNoNumerada ",
        body
      )
      .pipe(map((res) => res));
  }

  DesamarcarButacasBySesionId() {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      Accion: 0,
      RecintosSesionesId: 0,
      IdEntrada: 0,
      SesionInternetId: token.Id,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "/DesamarcarButacasBySesionId ",
        body
      )
      .pipe(map((res) => res));
  }

  RecintosButacasCambioTipoEstado(estadoButaca, tipoButaca) {
    const token = JSON.parse(localStorage.getItem("currentToken"));
    const body = {
      TipoButaca: tipoButaca,
      EstadoButaca: estadoButaca,
      SesionInternetId: token.Id,
    };
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "/RecintosButacasCambioTipoEstado ",
        body
      )
      .pipe(map((res) => res));
  }

  async getButacasAsync(idGrupoRecinto, idSesion, fecha, hora) {
    const chParams = {
      pageSize: 9200,
      page: 1,
      byIdSesionButaca: idSesion,
      byIdGrupoRecinto: idGrupoRecinto,
      byFecha: fecha,
      byHora: hora,
    };
    const body = {
      IdGrupoRecinto: idGrupoRecinto,
      IdSesionButaca: idSesion,
      Fecha: fecha,
      Hora: hora,
      paginator: {
        page: 1,
        pagelements: 100000,
        orderby: "pkId",
        ordertype: "ASC",
      },
      //"chParams": JSON.stringify(chParams)
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/GetButacas",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("GetButacas"))
      )
      .toPromise();
    return respuesta;
  }

  async EstadosButacasAsync() {
    const body = {};
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/EstadosButacas",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("EstadosButacas"))
      )
      .toPromise();
    return respuesta;
  }

  async EstadosButacasPorRecintoAsync(
    espectaculoId,
    fecha,
    hora,
    grupoRecintoId
  ) {
    const body = {
      EspectaculoId: espectaculoId,
      FechaSesion: fecha,
      HoraInicioSesion: hora,
      GrupoRecinto: grupoRecintoId,
    };
    const respuesta = await this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "/EstadosButacas",
        body
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("EstadosButacas"))
      )
      .toPromise();
    return respuesta;
  }
}
