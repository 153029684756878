import { Deserializable } from "@sharedV11/classes/deserializable";
import { Message } from "../../message/message";
import { data_result_userType } from "./data_result_userType";


export class user_types_response implements Deserializable {
    DatosResult?: data_result_userType;
    Mensajes: Message[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}
