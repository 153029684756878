import {Deserializable} from "./deserializable.model";

export class usuarios_Respuesta implements Deserializable {
    DatosResult?: DatosResult;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class CustomanUsuario implements Deserializable {
        pkId: string;
        Personal: string;
        Nombre: string;
        Clave: string;
        Tipo: string;
        Baja: string;
        Conectado: string;
        Empleado: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult implements Deserializable {
    customanUsuarios: CustomanUsuario[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}