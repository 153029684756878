import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { grupos_cliente_Respuesta } from '@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model';
import { peticion_Del_GruposClientes, peticion_Insercion_Upd_GruposClientes } from '@sharedV11/classes/clients/client/peticion-grupos-clientes.model';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EditClientGroupService {

  constructor(private http: HttpClient,
    private global: GlobalService,
    private tokenService: ApiTokenService) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  //------Codigo de Mario-------------------------------------------

  ///Function delGrupoCLiente
  delGruposCliente(pkId: string): Observable<any> {
    let peticion: peticion_Del_GruposClientes = new peticion_Del_GruposClientes();
    peticion.clienteAPI = this.global.clienteapi; //TODO

    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanCustomanGrupoCliente', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  insGruposCliente(clientegrupo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanGrupoCliente ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
  updGruposCliente(clientegrupo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanGrupoCliente', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  

  updTipoControl(clientegrupo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }


  async updTipoControlAsync(clientegrupo,mode): Promise<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion

    let respuesta =
    await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    ).toPromise();
    return respuesta;

/*     return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    ); */
  }

  
  insTipoControl(clientegrupo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  async insTipoControlAsync(clientegrupo,mode): Promise<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    let respuesta =
    await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    ).toPromise();
    return respuesta;

/*     return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    ); */
  }

  updTiposCliente(clientetipo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientetipo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanTipoClientes', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  insTiposCliente(clientegrupo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanTipoCliente', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }

  insUpdGruposCliente(clientegrupo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGrupoCliente', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  findGruposClienteByPkId(pkId): Observable<grupos_cliente_Respuesta> {
    //Montamos la peticion Listado
    const peticion: any = {}
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(sessionStorage.getItem("currentUser")).DatosResult.NombreUsuario;
    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoCliente', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }


  findTiposControlByPkId(pkId): Observable<grupos_cliente_Respuesta> {
    //Montamos la peticion Listado
    const peticion: any = {}
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(sessionStorage.getItem("currentUser")).DatosResult.NombreUsuario;
    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  findTipoClienteByPkId(pkId): Observable<grupos_cliente_Respuesta> {
    //Montamos la peticion Listado
    const peticion: any = {}
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(sessionStorage.getItem("currentUser")).DatosResult.NombreUsuario;
    peticion.pkId = pkId;
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTiposClientes', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  insTipoCliente(clientegrupo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = clientegrupo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanTipoClientes', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }


}
