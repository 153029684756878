export function isEqual(obj1: any, obj2: any): boolean {
  // Check if both objects are the same instance
  if (obj1 === obj2) {
      return true;
  }

  // Check if both objects are not objects or null
  if (!(obj1 instanceof Object) || !(obj2 instanceof Object) || obj1 === null || obj2 === null) {
      return false;
  }

  // Get the keys of the objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
      return false;
  }

  // Check if all keys in obj1 exist in obj2 and have the same values
  for (const key of keys1) {
      if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
          return false;
      }
  }

  return true;
}
