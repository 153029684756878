import {Deserializable} from "./deserializable.model";

export class grupos_tipos_entrada_Respuesta implements Deserializable {
    DatosResult?: DatosResult_grupos_tipos_entrada;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class GrupoTiposEntrada implements Deserializable {
    pkId: string;
    NombreGrupo: string;
    Descripcion: string;
    ConMotivo: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_grupos_tipos_entrada implements Deserializable {
    customanGrupoTipoEntrada: GrupoTiposEntrada[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}