import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { LayoutService } from "../../services/layouts/layout.service";
import { Translator } from "@sharedV11/services/translator/translator.service";

@Component({
  selector: "app-sidemenu",
  templateUrl: "./sidemenu.component.html",
  styleUrls: ["./sidemenu.component.scss"],
})
export class SidemenuComponent implements OnInit {
  menuDevelopment: any = [];
  logueado: boolean;
  Botones;
  Version: string;
  events: string[] = [];
  opened: boolean;
  modelo = [];
  submenuTitulo = "";
  layoutservices: LayoutService;
  constructor(
    private autentificador: AuthenticationService,
    private router: Router,
    private _ruta: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _layoutservices: LayoutService,
    private translator: Translator
  ) {
    this.layoutservices = _layoutservices;
  }

  ngOnInit(): void {
    if (!environment.production) {
      this.initializeDataListDevelopment();
    }

    // this.Version = environment.version;
    this.logueado = !this.autentificador.ocultarMenusQueryString(this._ruta);
    if (this.logueado) {
      this.Botones = this.autentificador.getPermisosComoBotones();
      console.log('botones', this.Botones);
      if (this.Botones.length === 0) {
        //this.router.navigate(['login']);
      } else {
      }
    }

    this.layoutservices.urlclicked.subscribe((u) => {
      if (u && u !== undefined) this.clicksubmenu(u);
    });
  }

  async initializeTranslate() {
    let lang = this.translator.getLocale() || "es-ES";

    if (!localStorage.getItem("Language")) {
      localStorage.setItem("Language", lang);
    }
    if (localStorage.getItem("Language")) {
      lang = localStorage.getItem("Language");
    }

    this.translator.setLocale(lang);
    await this.translator.use(lang);
  }

  initializeDataListDevelopment() {
    this.menuDevelopment = [
      {
        name: "Espacios",
        subMenu: [
          { name: "Espectáculos", route: "/Recintos/Espectaculos" },
          {
            name: "Sesiones con Butacas",
            route: "/Recintos/SesionesConButaca",
          },
        ],
      },
      {
        name: "Codigos Promocionales",
        subMenu: [
          {
            name: "CodigoPromocional",
            route: "/CodigosPromocionales/CodigoPromocional",
          },
          {
            name: "TipoCodigoPromocional",
            route: "/CodigosPromocionales/TipoCodigoPromocional",
          },
        ],
      } /*
      {"name":"Clientes V11","subMenu":[
        {"name":"Cliente V11","route":"/Clientes/ClienteV11"},
        {"name":"Client Group V11","route":"/Clientes/GrupoClienteV11"},
        {"name":"Formas de Pago V11","route":"/Clientes/FormasPagoV11"}


      ]},
      {"name":"Maestros V11","subMenu":[
        {"name":"Type access control  V11","route":"/Maestros/TypeAccessControlV11"}

      ]},*/,
      {
        name: "Tarifas",
        subMenu: [
          {
            name: "Tipo Reserva",
            route: "/Tarifas/TipoReserva"
          },
          {
            name: "Grupos Tarifas",
            route: "/Tarifas/GruposTarifas"
          }
        ],
      },
      {
        name: "Tienda",
        subMenu: [
          {
            name: "Proveedores",
            route: "/tienda/Proveedores"
          },
          {
            name: "Productos",
            route: "/tienda/ProductosTienda"
          },
          {
            name: "Productos Promociones",
            route: "/tienda/ProductosPromociones"
          },
          {
            name: "Gestión de Inventario",
            route: "/tienda/gestioninventario"
          },
        ],
      },
      {
        name: 'Entradas y Promociones',
        subMenu: [
          {
            name: 'Reglas de negocio',
            route: '/EntradasPromociones/ReglasNegocio'
          }
        ]
      }
    ];
  }

  clicksubmenu(ruta) {
    var allRoutes: string[] = [];

    for (var i = 0; i < this.router.config.length; i++) {
      allRoutes.push(this.router.config[i].path);
    }
    for (var i = 0; i < this.router.config[0].children.length; i++) {
      allRoutes.push(this.router.config[0].children[i].path);
      if (this.router.config[0].children[i].children) {
        for (
          var j = 0;
          j < this.router.config[0].children[i].children.length;
          j++
        ) {
          allRoutes.push(
            this.router.config[0].children[i].path +
              "/" +
              this.router.config[0].children[i].children[j].path
          );
        }
      }
    }

    if (allRoutes.find((x) => "/" + x == ruta)) {
      this.router.navigate([ruta]);
    } else {
      this.openSnackBar("Opción No Disponible", "Acepar");
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2500,
      panelClass: ["blue-snackbar"],
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }
}
