import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class BonoTypeService {

  constructor(
    private tokenService: ApiTokenService,
    private http: HttpClient,
    private global: GlobalService,
  ) { }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }


  GetGrupoTipoBono() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoBono ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  GetTiposProductos() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetTiposProductos ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  GetGruposAsociados() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetGruposAsociados ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  GetTipoVisitas() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoVisita ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  };

  GetTipoNiveles() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetTiposNiveles ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  GetCategorias() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCategoria ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  getGruposTiposEntrada(paginator?, filters?): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (paginator) {
      peticion.paginator = paginator;
      peticion.filters = filters;

    }
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  GetTEDatos() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetTiposEntradaDatos ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  GetTipoEntrada() {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntrada ', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  getTiposBono(pagination, filters): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination
      peticion.filters = filters
    }
    //Enviamos la peticion
    let respuesta = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
  }

  delTipoBono(pkId: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    peticion.pkId = pkId;
    //Enviamos la peticion
    let respuesta = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/DelTipoBono', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
  }

  /// Botonera
  //Get
  async getBotoneraAsync(id?: string, maximo?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    //if(id != null) {peticion.id = id;} else {peticion.id ="";}
    if (maximo != null) { peticion.Max = maximo; } else { peticion.Max = null; }
    //Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntradaBotonera', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }
}
