import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ApiTokenService } from '../../api/token/api-token.service';
import * as data from "@sharedV11/jsons/user/user.json"
import { Request_insertion_upd_userTypes } from '@sharedV11/classes/userRequest/users/userProfile/request_insertion_upd_userTypes';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Request_insertion_upd_user } from '@sharedV11/classes/userRequest/users/user/request_insertion_upd_user';
import { catchError, map } from 'rxjs/operators';
import { Request_userTypes_list } from '@sharedV11/classes/userRequest/users/userProfile/request_userTypes_list';
import { Formas } from '@sharedV11/classes/FormasPago/formas';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}
@Injectable({
  providedIn: 'root'
})
export class EditFormasPagoService {

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService,
  ) { }

  findFormaByPkId(pkId: string): Observable<any> {
    let peticion: Request_userTypes_list = new Request_userTypes_list();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;

    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanFormaPago', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  findFormaByPkIdLocal(pkId: string): Observable<any> {
    return of(data["default"])
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  insFormasPago(formapago, mode): Observable<any> {
    formapago.SiNumVencimiento = formapago.siNumVencimientos;
    let peticion = formapago;
    peticion.clienteAPI = this.global.clienteapi;
    peticion.Funcion = mode;
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanFormaPago ', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarCustomanFormaPago '))
    );
  }
  updFormasPago(formapago, mode): Observable<any> {
    formapago.SiNumVencimiento = formapago.siNumVencimientos;
    let peticion = formapago;
    peticion.clienteAPI = this.global.clienteapi;
    peticion.Funcion = mode;
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanFormaPago ', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('ActualizarCustomanFormaPago '))
    );
  }
  
  insUpdFormasPago(formapago, mode): Observable<any> {
    formapago.SiNumVencimiento = formapago.siNumVencimientos;
    let peticion = formapago;
    peticion.clienteAPI = this.global.clienteapi;
    peticion.Funcion = mode;
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanFormaPago', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  assignDataByObj(obj, objData, columnName, columnNameData) {
    obj[columnName] = objData[columnNameData];
  }

  assignDataByValue(obj, columnName, data) {
    obj[columnName] = data;
  }
}
