import {CLienteAPi} from "./cliente-api.model";

//getTiposUsuario
export class peticion_Tipos_Usuario_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Descripcion: string;
}

//insUpdTiposUsuario
export class peticion_Insercion_Upd_TiposUsuario {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    Nombre: string;
    Descripcion: string;
    Privilegios: string;
    Funcion: string;
}

//delTiposUsuario
export class peticion_Del_TiposUsuario {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}