<div id="popup" class="modal-overlay">
  <div class="cuadroCentral">
    <div class="close"  style="text-align: right;" (click)="close()">x</div>
  <!--<div class="iconCierre click" (click)="close()">
      <a id="cerrar" class="iconoCerrar">
        <img src="assets/{{ claveEmpresa }}/img/body/cerrar.svg" alt=""
      /></a>
    </div>-->
    <div class="izquierda">
      <p>{{ 'SELECCIONE_TIPO_ENTRADA' | translate }}</p>
    </div>
    <div class="derecha">
      <p *ngIf="butaca">
        {{ 'RECINTO_NO_NUMERADO_MODAL' | translate }}<br />
        {{ butaca.info.infoGeneral.nombreRecinto }}
      </p>
    </div>
    <div class="containerTarifasNoNumeradas">
    <div class="tarifasNoNumeradas"*ngFor="let itemTarifa1 of butacaMarcada">
    <iacpos-selector-no-numeradas *ngIf="selector" [itemTarifa]="itemTarifa1" [firstInit]="true"  [variosTiposDeButaca]="variosTiposButaca" class="tarifaNoNumeradaEnModal"></iacpos-selector-no-numeradas>

  </div>

    </div>
    <button class="modalNoNumBtn" (click)="close()"> {{ 'ACEPTAR_MODAL_NO_NUMERADA' | translate }} </button>
  </div>
</div>
