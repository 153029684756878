import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { DivisaObject } from '@sharedV11/interfaces/DivisaObject.interface';

@Directive({
  selector: '[appCustomDecimalNumber]'
})
export class CustomDecimalNumberDirective {

  @Input() customMaxDecimal: number = 0;
  private decimalSeparator: string = ',';
  private maximalDecimal: number = 2;
  private regex: RegExp = new RegExp(/^-?\d*[.,]?\d{0,2}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef) {
    this.decimalSeparator = localStorage.getItem('SimboloDecimal') || '.';
    this.maximalDecimal = Number(localStorage.getItem('NumeroDecimales')) || 2;
    this.regex = new RegExp(`^-?\\d*[${this.decimalSeparator}]?\\d{0,${this.maximalDecimal}}$`, 'g');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    
    const divisaObject: DivisaObject = JSON.parse(localStorage.getItem('divisaObject')) || {
      "PkId": "2",
      "pkId": "2",
      "Nombre": "EUROS",
      "simbolo": "€",
      "NumeroDecimales": "2",
      "OpsTarjeta": "True",
      "PosicionDivisa": "D",
      "SimboloDecimal": ",",
      "SimboloDerecha": "",
      "CodISO": "978",
      "SimbSeparacionMiles": ""
    };

    const decimalSeparator = divisaObject.SimboloDecimal || ',';
    const maximalDecimal =this.customMaxDecimal > 0 ? this.customMaxDecimal : Number(divisaObject.NumeroDecimales);
    const regex = new RegExp(`^-?\\d*[${decimalSeparator}]?\\d{0,${maximalDecimal}}$`, 'g');
    
    // Allow special keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    
    // Do not allow more than one separator
    const current: string = this.el.nativeElement.value;

    const position: number = this.el.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key == '.' ? decimalSeparator : event.key, current.slice(position)].join('');

    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

/*   @HostListener('focus', ['$event'])
  onFocus(event: FocusEvent) {
    this.el.nativeElement.value = this.el.nativeElement.value.replace('.', this.decimalSeparator);
    console.log('onFocus::', this.el.nativeElement.value)
  }

  @HostListener('blur', ['$event'])
  onBlur(event: FocusEvent) {
    this.el.nativeElement.value = this.el.nativeElement.value.replace(this.decimalSeparator, '.');
    console.log('onBlur', this.el.nativeElement.value)
  }
 */
}
