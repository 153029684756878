import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditSesionesConButacasComponent } from "./edit-sesionesconbutacas/edit-sesionesconbutacas.component";

@Component({
  selector: "app-sesionesconbutacas",
  templateUrl: "./sesionesconbutacas.component.html",
  styleUrls: ["./sesionesconbutacas.component.scss"],
})
export class SesionesConButacasComponent extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }
  openPanel() {
    this.customanpanel = EditSesionesConButacasComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
