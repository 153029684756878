import { Injectable } from '@angular/core';
import { usuario_Respuesta, Permiso } from '../_modelos/usuario-Respuesta.model';
import { ActivatedRoute } from '@angular/router';
import { ConectarAPIService } from '../_API/conectar-api.service';

@Injectable({
  providedIn: 'root'
})
export class AutentificacionService {

  constructor(private servicioAPI: ConectarAPIService) { }

  verificar(): boolean {
    if (sessionStorage.getItem('currentUser') != null) {
      return true;
    } else {
      return false;
    }
  }

  getPermisos(): Permiso[] {
    if (sessionStorage.getItem('currentUser') != null) {
      return (new usuario_Respuesta().deserialize(JSON.parse(sessionStorage.getItem('currentUser')))).DatosResult.Permisos;
    }
    else { return []; }
  }

  getPermisosComoBotones(): Array<any> {
    if (sessionStorage.getItem('currentUser') != null) {
      var permisos = (new usuario_Respuesta().deserialize(JSON.parse(sessionStorage.getItem('currentUser')))).DatosResult.Permisos;
      if (permisos.length > 0) {
        // Metemos los permisos en hijos y padres seleccionados por VER
        var permisosbotonespadre = permisos.filter((x) => x.NombreAccion.trim().toUpperCase() == 'VER' && x.ModuloIdPadre == 0);
        var permisosbotoneshijos = permisos.filter((x) => x.NombreAccion.trim().toUpperCase() == 'VER' && x.ModuloIdPadre != 0);
        return permisosbotonespadre.map(function (x) {
          return {
            id: x.AccionId.toString(), texto: x.NombreModuloIdioma, icono: x.IconModulo, submenu:
              permisosbotoneshijos.filter((y) => y.ModuloIdPadre == x.ModuloId).map(function (z) { return { id: z.AccionId.toString(), texto: z.NombreModuloIdioma, icono: z.IconModulo, route: z.Ventana } })
          };
        })
      }
      else {
        return [];
      }
    }
    else { return []; }
  }

  visualizacionAccion(ruta: string, accion: string): boolean {
    if (sessionStorage.getItem('currentUser') != null) {
      var permisos = (new usuario_Respuesta().deserialize(JSON.parse(sessionStorage.getItem('currentUser')))).DatosResult.Permisos;
      if (permisos.length > 0) {

        var permisosboton = permisos.filter((x) => x.NombreAccion.trim().toUpperCase() == accion && x.Ventana == ruta);
        if (permisosboton.length > 0) { return true; } else { return false; }

      }
      else {
        return false;
      }
    }
    else { return false; }
  }

  devolverNombre(ruta: string): string {
    if (sessionStorage.getItem('currentUser') != null) {
      var permisos = (new usuario_Respuesta().deserialize(JSON.parse(sessionStorage.getItem('currentUser')))).DatosResult.Permisos;
      if (permisos.length > 0) {

        var permisosboton = permisos.filter((x) => x.NombreAccion.trim().toUpperCase() == 'VER' && x.Ventana == ruta);
        if (permisosboton.length > 0) { return permisosboton[0].NombreModuloIdioma.trim(); } else { return ""; }

      }
      else {
        return "";
      }
    }
    else { return ""; }
  }
  //Parametros aceptados en la ruta:
  //  SM (=1) Sin Menu Lateral (ocultarMenu)
  //  US Usuario
  //  PW Paswword (encriptada TODO)
  async verificarAccesoQueryString(_ruta: ActivatedRoute) {
    if (_ruta !== null) {
      let _usuario = _ruta.snapshot.queryParamMap.get("US");
      let _pwd = _ruta.snapshot.queryParamMap.get("PW");
      let _usuarioO: usuario_Respuesta;
      if (_usuario !== null && _pwd !== null) {
        // COMPROBAR QUE EL USUARIO SEA DIFERENTE del de memoria o el de memoria sea null,
        if (sessionStorage.getItem('currentUser') == null) {
          //esta llamada ha de ser asincrona, esperamos a que acabe por eso la pasamos a una funcion async
          await this.verificarAccesologinAPI(_usuario, _pwd);
        } else {
          _usuarioO = new usuario_Respuesta().deserialize(JSON.parse(sessionStorage.getItem('currentUser')));
          if (_usuarioO.DatosResult.NombreUsuario.trim() != _usuario.trim()) {
            //esta llamada ha de ser asincrona, esperamos a que acabe por eso la pasamos a una funcion async
            await this.verificarAccesologinAPI(_usuario, _pwd);
          }
        }

      }
    }
  }

  async verificarAccesologinAPI(usuario: string, pwd: string) {
    await this.servicioAPI.getUsuarioAsync(usuario, pwd)
      .then(
        data => {
          let usuarioenSession = new usuario_Respuesta().deserialize(data);
          if (usuarioenSession.DatosResult != null && usuarioenSession.DatosResult.DeBaja.toLowerCase() == "false") {
            this.login(usuarioenSession);
          }
          else {
            //this.alertaServicio.error('No se ha iniciado sesión correctamente o tu cuenta está deshabilitada temporalmente.');
          }

        },
        () => {
          //this.alertaServicio.error(error);
        });
  }

  login(usuario: usuario_Respuesta) {
    sessionStorage.setItem('currentUser', JSON.stringify(usuario));
  }

  logout() {
    // quitar usuario del sessionStorage para desloguearse
    sessionStorage.removeItem('currentUser');
  }

  ocultarMenusQueryString(_ruta: ActivatedRoute) :boolean
  {
    this.verificarAccesoQueryString(_ruta);
    if (_ruta.snapshot.queryParamMap.get("SM") !==null && _ruta.snapshot.queryParamMap.get("SM") ==="1")
    {
      sessionStorage.setItem('OcultarMenu', "true");
      return true;
    }
    else
    {
      if (sessionStorage.getItem('OcultarMenu')!=null && sessionStorage.getItem('OcultarMenu')=="true")
      {
        if (_ruta.snapshot.queryParamMap.get("SM") !==null && _ruta.snapshot.queryParamMap.get("SM") !=="1")
        {
          sessionStorage.setItem('OcultarMenu', "false");
          return false;
        }
        else
        {
        return true;
        }
      }
      else
      {
        return false;
      }
    }
  }
}

