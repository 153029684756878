import { Component, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import { APP_FORMATOS_FECHA, AppDateAdapter } from '@sharedV11/classes/dataAdapter/date-format.adapter';
import { StringNumber } from '@sharedV11/classes/EntradasPromociones/ReglasNegocio/reglas-negocio';
import { BodyInsertCodigoPromocionalBines, BodyUpdateCodigoPromocionalBines, ResponseCodigoPromocionalBin, ResponseGetCodigoPromocionalBines, ValueForm } from '@sharedV11/interfaces/microservice/CodigoPromocionalBines/index.interface';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { CodigoPromocionalBinesService } from '@sharedV11/services/components/codigo-promocional-bines/codigo-promocional-bines.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { TranslateService } from '@sharedV11/translate.service';
import { isEqual } from '@sharedV11/utils/lodash';
import { DualListComponent } from 'angular-dual-listbox';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-codigo-promocional-bines',
  templateUrl: './edit-codigo-promocional-bines.component.html',
  styleUrls: ['./edit-codigo-promocional-bines.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_FORMATOS_FECHA,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EditCodigoPromocionalBinesComponent implements OnInit {

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  private setManualValueInputFilter: Function;

  private ngUnsubscribe = new Subject();

  loading = true;

  codigoPromocionalBinFind: ValueForm;

  editRegistroForm: FormGroup;

  translation: any;

	codigosPromocionales = [];

  //centros
	centrosSinAsignar = [];
	centrosAsignados = [];
	tipoCentros: any[] = [];


  //Dual List Config
	format = {
		add: "Añadir",
		remove: "Borrar",
		all: "Todos",
		none: "Ninguno",
		direction: DualListComponent.LTR,
		draggable: true,
		locale: "es",
	};

  constructor(
    private translator: Translator,
    private gsCentros: GlobalServicCentros,
    private panelService: PanelService,
    private fb: FormBuilder,
    private codigoPromocionalBinesService: CodigoPromocionalBinesService,
		private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
  ) {
    
  }

  async ngOnInit(){
		try {
			this.initializeFormWithoutData();
			this.initializeTranslate();
			await this.loadCentros(),
			this.codigoPromocionalBinesService.listCodigosPromocionales()
        .subscribe((data) => {
          this.codigosPromocionales = data?.DatosResult?.ListaCodigoPromocional ?? [];
          this.manageForm(this.pkId, this.mode);
        });
		} catch (err) {
			console.error(err);
		}
	}

  initializeTranslate() {
		this.translator.dataLanguage$.subscribe((data) => {
			if (data) {
				this.translation = this.translator.GetTranslations();
				this.format = {
					add: this.translation.Anadir,
					remove: this.translation.Borrar,
					all: this.translation.Todos,
					none: this.translation.Ninguno,
					direction: DualListComponent.LTR,
					draggable: true,
					locale: "es",
				};
			}
		});
		
	}

  initializeFormWithoutData() {
    this.editRegistroForm = this.fb.group({
      pkId: [""],

      //Solo letras, numeros y espacios
      Nombre: [""],
      CodigoPromocionalId: ["", Validators.required],
      NumeroTarjeta: ["", [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern(/^[0-9]+$/),
      ]],
    });
  }

	async loadCentros() {
		await this.gsCentros.getCentros();
	}

  manageForm(pkId, mode) {
		if (mode == "I") {
			this.codigoPromocionalBinFind = this.editRegistroForm.value;
      this.codigoPromocionalBinFind.CentrosIds = '';

			//centros
			this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
			this.centrosAsignados = [];

			this.loading = false;
			this.valuedChanged();
		}
		if (pkId && pkId !== "") {
			this.findCodigoPromocionalBines(pkId);
		}
	}


  valuedChanged() {
		this.editRegistroForm.valueChanges.subscribe((value) => {
			if (this.codigoPromocionalBinFind != null) {

				this.panelService.setDiscardChanges(false);

				const keysForm = Object.keys(value);

				const valuesDismatch = [];

				for (const keyForm of keysForm) {
					const valueForm = value[keyForm];
					const valueObjectEdit = this.codigoPromocionalBinFind[keyForm];

					if (
						!isEqual(valueForm, valueObjectEdit)
					) {
						valuesDismatch.push(1);
					}
				}

				if (valuesDismatch.length === 0) {
					this.panelService.setDiscardChanges(true);
				}
			}
		});
	}

	getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if (fc_pv) {
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError("pattern")) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }

    return "Error";
  }

	validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
  }


	closeInputFilterCodigoPromocional(enventValues) {
    this.editRegistroForm.get(enventValues.name).setValue(enventValues.value);
  }

  callbacksOnSelectCodigoPromocional(event) {
    this.setManualValueInputFilter = event.methods.setManualValueForm;
  }


  changeValueForm(value: ResponseCodigoPromocionalBin) {
    this.editRegistroForm.get("pkId").setValue(value.pkId);
    this.editRegistroForm.get("Nombre").setValue(value.nombre);
    this.editRegistroForm.get("NumeroTarjeta").setValue(value.numeroTarjeta);
    this.editRegistroForm.get("CodigoPromocionalId").setValue(value.codigoPromocional);

    if (this.setManualValueInputFilter) {
      this.setManualValueInputFilter(value.codigoPromocional);
    }
  }

	discard() {
    const valueToReset: ResponseCodigoPromocionalBin = {
      pkId: this.codigoPromocionalBinFind.pkId,
      nombre: this.codigoPromocionalBinFind.Nombre,
      numeroTarjeta: this.codigoPromocionalBinFind.NumeroTarjeta,
      codigoPromocional: this.codigoPromocionalBinFind.CodigoPromocionalId,
    };

		this.changeValueForm(valueToReset);
    this.discardCentros();
	}

  discardCentros() {
    this.centrosAsignados = this.gsCentros.daTiposCentros(this.codigoPromocionalBinFind);
  }

  get formValue() {
    return this.editRegistroForm.value;
  }

  findCodigoPromocionalBines(pkId: string) {
		this.codigoPromocionalBinesService
			.findCodigoPromocionalBinByPkId(pkId)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response: ResponseGetCodigoPromocionalBines) => {

          if (response.mensajes.length > 0) {
            this.snackvar_service.openSnackBar(
              "",
              "",
              "red-snackbar",
              "ERROR_OBTENER_REGISTRO"
            );
          } else {

            let codigoPromocionalBin: ResponseCodigoPromocionalBin = response.datosResult.listado[0];
  
            if (codigoPromocionalBin != null) {

              this.changeValueForm(codigoPromocionalBin);
              this.codigoPromocionalBinFind = this.editRegistroForm.value;
              this.codigoPromocionalBinFind.CentrosIds = codigoPromocionalBin.centrosIds;
  
              this.valuedChanged();
  
              //centros
              this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
              this.centrosAsignados = this.gsCentros.daTiposCentros(this.codigoPromocionalBinFind);
  
              this.loading = false;
            }
          }

				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}


	submit(cerrarpanel: boolean = true) {

      this.editRegistroForm.markAllAsTouched();


      if (this.editRegistroForm.valid) {

        this.manageApi(this.mode);

      } else {
        this.snackvar_service.openSnackBar(this.translation['ErrorFaltanDatosPorIntroducir'], "", "red-snackbar");
      }
  }

	manageApi(mode) {

    this.loading = true;

    if (this.mode == "I" || this.mode == "D") {
      let codigoPromocionalBin: BodyInsertCodigoPromocionalBines = {
        pkId: "",
        Bines: [{
          Nombre: this.editRegistroForm.get("Nombre").value,
          NumeroTarjeta: this.editRegistroForm.get("NumeroTarjeta").value,
          CodigoPromocional: this.editRegistroForm.get("CodigoPromocionalId").value,
        }],
        CentrosIds: this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados),
      };

      this.InsertCodigoPromocionalBin(codigoPromocionalBin);
    } else {
      let codigoPromocionalBin: BodyUpdateCodigoPromocionalBines = {
        pkId: this.pkId,
        Nombre: this.editRegistroForm.get("Nombre").value,
        NumeroTarjeta: this.editRegistroForm.get("NumeroTarjeta").value,
        CodigoPromocional: this.editRegistroForm.get("CodigoPromocionalId").value,
        CentrosIds: this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados),
      };

      this.UpdateCodigoPromocionalBin(codigoPromocionalBin);
    }
  }

  InsertCodigoPromocionalBin(codigoPromocional) {
    this.codigoPromocionalBinesService
      .insertCodigoPromocionalBin(codigoPromocional)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
          this.loading = false;
        }
      );
  }

  UpdateCodigoPromocionalBin(codigoPromocional: BodyUpdateCodigoPromocionalBines) {
    this.codigoPromocionalBinesService
      .updateCodigoPromocionalBin(codigoPromocional)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.nextWindow(response);
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
          this.loading = false;
        }
      );
  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  nextWindow(response: any) {
		if (response.mensajes.length > 0) {
			response.mensajes.forEach((element) => {
				this.snackvar_service.openSnackBar(
					element.descripcionMensaje,
					"",
					"red-snackbar",
					element.codigoMensaje,
					4000
				);
			});
		} else {
			this.snackvar_service.openSnackBar(
				this.translation["MensajeSuccessSnackBar"],
				"",
				"green-snackbar"
			);

      this.save();
      this.destroy();
		}
	}

  save() {
		this.loading = false;
		this.panelService.setSavePanel(null);
	}
}
