import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import data from 'src/app/shared/json/botones.json';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class RecintosVisitaService {


  constructor(
    private http: HttpClient,
    private global: GlobalService,
    private tokenService: ApiTokenService,
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getGruposActividadTaller(nombre?: string, descripcion?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (descripcion != null) { peticion.Descripcion = descripcion; } else { peticion.Descripcion = ""; }
    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGruposActividadTaller', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  getTiposControl(nombre?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  getIdiomas(nombre?: string, codigo?: string, activo?: string) {
    const body = {
      "Nombre": nombre ? nombre : "",
      "Codigo": codigo ? codigo : "",
      "Activo": activo ? activo : ""
    };
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerIdiomas ', body)
  };

  getGruposRecintos(nombre?: string, subgrupo?: string, activo?: string): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (subgrupo != null) { peticion.Subgrupo = subgrupo; } else { peticion.Subgrupo = ""; }
    if (activo != null) { peticion.Activo = activo; } else { peticion.Activo = ""; }
    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerGruposRecintos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  async getTiposEntradasAsync(id?: string, nombre?: string, recinto?: string, talleractividad?: string,
    grupotipoentrada?: string, tipomotivo?: string, controlacceso?: string, alaventa?: string, filtro?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (id != null) { peticion.id = id; } else { peticion.id = ""; }
    if (recinto != null) { peticion.Recinto = recinto; } else { peticion.Recinto = null; }
    if (talleractividad != null) { peticion.TallerActividad = talleractividad; } else { peticion.TallerActividad = null; }
    if (grupotipoentrada != null) { peticion.GrupoTipoEntrada = grupotipoentrada; } else { peticion.GrupoTipoEntrada = null; }
    if (tipomotivo != null) { peticion.TipoMotivo = tipomotivo; } else { peticion.TipoMotivo = null; }
    if (controlacceso != null) { peticion.ControlAcceso = controlacceso; } else { peticion.ControlAcceso = null; }
    if (alaventa != null && alaventa.trim() != '') {
      alaventa.toLowerCase().trim() == 'si' ?
        peticion.ALaVenta = '1' : peticion.ALaVenta = '0';
    } else { peticion.ALaVenta = null; }
    if (filtro != null) { peticion.Filtro = filtro; } else { peticion.Filtro = null; }


    //Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  async getGruposRecintosAsync(nombre?: string, subgrupo?: string, activo?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (subgrupo != null) { peticion.Subgrupo = subgrupo; } else { peticion.Subgrupo = ""; }
    if (activo != null) { peticion.Activo = activo; } else { peticion.Activo = ""; }
    // Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerGruposRecintos', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  async getTiposControlAsync(nombre?: string): Promise<any> {
    //Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    //Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTiposControl', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  async getGruposActividadTallerAsync(nombre?: string, descripcion?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {}
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (descripcion != null) { peticion.Descripcion = descripcion; } else { peticion.Descripcion = ""; }
    // Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGruposActividadTaller', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  async getRecintosAsync(nombre?: string, grupo?: string, activo?: string, idioma?: string): Promise<any> {
    // Montamos la peticion Listado
    let peticion: any = {}
    peticion.clienteAPI = this.global.clienteapi; // TODO

    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (grupo != null) { peticion.Grupo = grupo; } else { peticion.Grupo = ""; }
    if (activo != null) { peticion.Activo = activo; } else { peticion.Activo = ""; }
    if (idioma != null) { peticion.Idioma = idioma; } else { peticion.Idioma = ""; }
    // Enviamos la peticion
    let respuesta =
      await this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerRecintos', JSON.stringify(peticion), httpOptions).pipe(
        map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      ).toPromise();
    return respuesta;
  }

  insUpdRecintosByBody(body: any): Observable<any> {
    // Enviamos la peticion
    let peticion: any = body;
    peticion.clienteAPI = this.global.clienteapi; // TODO
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanRecintos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  /*
  getGruposInternet(pagination, filters): Observable<any> {
    const peticion = new PeticionInternetGroupLista(pagination);
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Usuario = JSON.parse(sessionStorage.getItem("currentUser")).DatosResult.NombreUsuario;
    peticion.filters = filters
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerGruposInternet', peticion).pipe(
      map(data => {
        if (data.DatosResult != null) {
          return data;
        } else {

          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      }),
    );
  }
  */
  /*
  map(this.extractData),
       catchError(this.handleError<any>('delRecintos Problema reportado por mario'))
  */

  delRecintos(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanRecintos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
}
