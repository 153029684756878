<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
	<app-buttons
		(save)="submit()"
		(close)="destroy()"
		(discard)="discard()"
		[buttonsWrap]="'panel'"
		[modificar]="modificar"
	></app-buttons>
	<div class="editor-registro">
		<div class="divForm">
			<form [formGroup]="editRegistroForm" (onSubmit)="submit()">
					<div class="col-12">
						<app-input-filter
							*ngIf="
								codigosPromocionales &&
								codigosPromocionales.length > 0
							"
							formControlText="CodigoPromocionalId"
							[disabled]="codigosPromocionales.length < 1"
							[label]="translation.CodigoPromocional"
							[placeHolderTranslation]="translation.CodigoPromocional"
							[list]="codigosPromocionales"
							textShow="Codigo"
							dataInput="Codigo"
							required="true"
							[data]="formValue.CodigoPromocionalId"
							(close)="closeInputFilterCodigoPromocional($event)"
							(getMethods)="callbacksOnSelectCodigoPromocional($event)"
						></app-input-filter>
					</div>

					<div class="col-12">
						<mat-form-field appearance="outline">
							<mat-label>{{ 'Nombre' | translateV2 }}</mat-label>
							<input
								matInput
								formControlName="Nombre"
								class="editor-campo-registro"
							/>
							<mat-error *ngIf="validateFields('Nombre')">
								{{ getErrorMessage("Nombre") }}
							</mat-error>
						</mat-form-field>
					</div>

					<div class="col-12">
						<mat-form-field appearance="outline">
							<mat-label>{{ 'NumeroTarjeta' | translateV2 }}</mat-label>
							<input
								matInput
								formControlName="NumeroTarjeta"
								class="editor-campo-registro"
							/>
							<mat-error *ngIf="validateFields('NumeroTarjeta')">
								{{ getErrorMessage("NumeroTarjeta") }}
							</mat-error>
						</mat-form-field>
					</div>

				<div style="margin: 20px 5px 0 5px; text-align: center">
					<mat-label>{{ translation.Centros }}</mat-label>

					<customan-dual-list
						[source]="centrosSinAsignar"
						[(destination)]="centrosAsignados"
						key="pkId"
						display="Nombre"
						[format]="format"
						[filter]="true"
					>
					</customan-dual-list>
				</div>
			</form>
		</div>
	</div>
</div>
