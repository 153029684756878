import { CLienteAPi } from "./cliente-api.model";

//get
export class peticion_TiposEntrada_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    id: string;
    Recinto?: string;
    TallerActividad?: string;
    GrupoTipoEntrada: string;
    TipoMotivo: string;
    ControlAcceso: string;
    ALaVenta: string;
    Filtro: string;
}

//insUpd
export class peticion_Insercion_Upd_TiposEntrada {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    EntradaId: string;
    Nombre: string;
    Descripcion: string;
    PrecioUnitario: string;
    DivisaId: string;
    ALaVenta: string;
    FormatoId: string;
    TextoPantalla: string;
    CondicionesVenta: string;
    TextoImpresion: string;
    TextoDisplay: string;
    CodigoBarras: string;
    IVA: string;
    BaseImponible: string;
    TipoAbreviado: string;
    MaximoPorDia: string;
    GrupoId: string;
    ImprimirTicket: string;
    ControlarAcceso: string;
    CodigoTipoControl: string;
    TeclaRapida: string;
    GrupoMotivoId: string;
    ImpresoraSecundaria: string;
    TicketVendido: string;
    ImprimirPrecio: string;
    TipoButaca: string;
    CuentaVisitante: string;
    CaracteristicaId: string;
    ArgumentosCaracteristica: string;
    TipoButacaId: string;
    BarCodeInternet: string;
    RecintosAsociados: string;
    ActividadesAsociadas: string;

    // Nuevos campos
    TipoProducto: string;
    GrupoAsociado: string;
    TipoVisita: string;
    TipoNivel: string;
    Categoria: string;
    TEDDatos: string;
    PosicionEnBotonera: string;
    ConfiguracionAdicional: string;
    CentrosIds: string;
    CentroCosteId: string;
    CanalId: string;
    Funcion: string;
}

//del
export class peticion_Del_TiposEntrada {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    entradaId: string;
}

//GetBotonera
export class peticion_Get_Botonera {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Max: string;
}