import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_TiposControl_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
}

//insUpd
export class peticion_Insercion_Upd_TiposControl {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    CodigoTipoControl: string;
    Nombre: string;
    Descripcion: string;
    CompHoraria: string;
    PermisoSinFecha: string;
    CompDiaria: string;
    CompIntervalo: string;
    CompDiaSemana: string;
    CompPasoPrevio: string;
    CompCalendario: string;
    CompCalendHorario: string;
    DenegarAcceso: string;
    DiasIntervalo: string;
    AccesoLunes: string;
    AccesoMartes: string;
    AccesoMiercoles: string;
    AccesoJueves: string;
    AccesoViernes: string;
    AccesoSabado: string;
    AccesoDomingo: string;
    PorDefecto: string;
    NivelEntrada: string;
    NumPasosPrevios: string;
    NumPasosPreviosDiarios: string;
    DiasIntervaloDesde: string;
    Funcion: string;
}

//del
export class peticion_Del_TiposControl {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}