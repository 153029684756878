<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
	<app-buttons
		(save)="submit()"
		(close)="destroy()"
		(discard)="discard()"
		[buttonsWrap]="'panel'"
		[modificar]="modificar"
	></app-buttons>

	<div class="divForm">
		<form [formGroup]="editUserForm" (onSubmit)="submit()">
			<div class="form-group">
				<mat-form-field appearance="outline">
					<mat-label>{{ translation.Nombre }}</mat-label>
					<input matInput formControlName="Nombre" />
					<mat-error *ngIf="validateFields('Nombre')">
						{{ getErrorMessage("Nombre") }}
					</mat-error>
				</mat-form-field>
				<!-- <mat-form-field appearance="outline">
          <mat-label>Clau</mat-label>
          <input matInput formControlName="Clave">
          <mat-error *ngIf="validateFields('Clave')">
            {{getErrorMessage("Clave")}}
          </mat-error>
        </mat-form-field> -->
				<mat-form-field appearance="outline">
					<mat-label>{{ translation.Empleado }}</mat-label>
					<input matInput formControlName="Empleado" />
					<mat-error *ngIf="validateFields('Empleado')">
						{{ getErrorMessage("Empleado") }}
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<div class="half">
					<app-input-filter
						*ngIf="user && userTypes && flagInput"
						formControlText="Tipo"
						[label]="translation.Tipo"
						[placeHolderTranslation]="translation.Tipo"
						[list]="userTypes"
						[disabled]="userTypes.length < 1"
						textShow="Nombre"
						dataInput="pkId"
						required="true"
						[data]="user.Tipo"
						(close)="closeInputFilter($event)"
					></app-input-filter>
				</div>
				<!-- <mat-form-field appearance="outline">
        <mat-label>Tipus</mat-label>
        <mat-select formControlName="Tipo">
          <mat-option *ngFor="let userType of userTypes" [value]="userType.pkId">
            {{userType.Nombre}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="validateFields('Tipo')">
          {{getErrorMessage("Tipo")}}
        </mat-error>
      </mat-form-field> -->

				<mat-form-field appearance="outline">
					<mat-label>{{ 'IdPersonal' | translateV2 }}</mat-label>
					<input matInput formControlName="Personal" />
					<mat-error *ngIf="validateFields('Personal')">
						{{ getErrorMessage("Personal") }}
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group row">
				<mat-form-field>
					<mat-chip-list #chipList>
						<mat-chip
							[color]="item.invalid ? 'warn' : ''"
							selected
							*ngFor="let item of emailList; let i = index"
							[selectable]="true"
							[removable]="removable"
							(removed)="removeEmail(item)"
							required
							name="chips"
						>
							{{ item }}
							<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
						</mat-chip>
						<input
							[placeholder]="translation.Email"
							[matChipInputFor]="chipList"
							[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							[matChipInputAddOnBlur]="true"
							(matChipInputTokenEnd)="add($event)"
						/>
					</mat-chip-list>
				</mat-form-field>
			</div>
			<div class="form-group">
				<!--       <div class="form-group divButtons">
      -->

				<mat-label
					>{{ 'Conectado' | translateV2 }}
					<mat-slide-toggle
						name="Conectado"
						formControlName="Conectado"
					></mat-slide-toggle>
				</mat-label>

				<mat-label
					>{{ translation.Baja }}
					<mat-slide-toggle
						matInput
						name="Baja"
						formControlName="Baja"
					></mat-slide-toggle>
				</mat-label>

				<!--       </div>
      --></div>

			<div style="margin: 20px 5px 0 5px; text-align: center">
				<mat-label>{{ translation.Centros }}</mat-label>

				<customan-dual-list
					[source]="centrosSinAsignar"
					[(destination)]="centrosAsignados"
					key="pkId"
					display="Nombre"
					[format]="format"
					[filter]="true"
				>
				</customan-dual-list>
			</div>
		</form>
	</div>
</div>
