import {CLienteAPi} from "./cliente-api.model";

//getIdiomas
export class peticion_Idiomas_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Codigo: string;
    Activo: string;
}

//insUpdIdiomas 
export class peticion_Insercion_Upd_Idiomas {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    CodIdioma: string;
    Nombre: string;
    Activo: string;
    funcion: string;
}

//delIdiomas
export class peticion_Del_Idiomas {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}