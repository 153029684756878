<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
	<app-buttons
		(save)="submit()"
		(close)="destroy()"
		(discard)="discard()"
		[buttonsWrap]="'panel'"
		[modificar]="modificar"
	></app-buttons>
	<div class="editor-registro">
		<div class="divForm">
			<form [formGroup]="editRegistroForm" (onSubmit)="submit()">
				<div class="form-group">
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Nombre }}</mat-label>
						<input matInput formControlName="Nombre" />
						<mat-error *ngIf="validateFields('Nombre')">
							{{ getErrorMessage("Nombre") }}
						</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>{{ translation.Descripcion }}</mat-label>
						<input matInput formControlName="Descripcion" />
						<mat-error *ngIf="validateFields('Descripcion')">
							{{ getErrorMessage("Descripcion") }}
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.TipoDescuento }}</mat-label>
						<mat-select
							placeholder="{{ translation.TipoDescuento }}"
							formControlName="TipoDescuento"
							name="TipoDescuento"
						>
							<mat-option value="DT">{{ translation.Porcentaje }}</mat-option>
							<mat-option value="PR">{{ translation.Importe }}</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>{{ translation.ImporteDescuento }}</mat-label>
						<input
							[disabled]="this.editRegistroForm.value.TipoDescuento !== '' ? false : true"
							matInput
							formControlName="ImporteDescuento"
							appInputDecimal
							placeholder="00.00"
						/>
						<span class="prefix-input" matSuffix>
							{{
								this.editRegistroForm.value.TipoDescuento === 'DT' ? "%" : simbolo
							}}
						</span>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.PlataformasDeVenta }}</mat-label>
						<mat-select
							multiple
							placeholder="{{ translation.PlataformasDeVenta }}"
							formControlName="tipoControlPlataforma"
							(selectionChange)="actualizarPlataformasSeleccionadas()"
						>
							<mat-option value="1">Punto Venta Kore</mat-option>
							<mat-option value="2">Internet</mat-option>
							<mat-option value="3">Administración</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field class="input-select" appearance="outline">
						<mat-label>{{ translation.Dias }}</mat-label>
						<mat-select
							placeholder="{{ translation.Dias }}"
							multiple
							formControlName="diasSemana"
							(selectionChange)="actualizarDiasSeleccionados()"
						>
							<mat-option value="1">{{ translation.Lunes }}</mat-option>
							<mat-option value="2">{{ translation.Martes }}</mat-option>
							<mat-option value="3">{{ translation.Miercoles }}</mat-option>
							<mat-option value="4">{{ translation.Jueves }}</mat-option>
							<mat-option value="5">{{ translation.Viernes }}</mat-option>
							<mat-option value="6">{{ translation.Sabado }}</mat-option>
							<mat-option value="0">{{ translation.Domingo }}</mat-option>
							<p class="px-4 py-4">* Para seleccionar toda la semana, hay que dejar todos los días sin marcar.</p>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="form-group">
					<mat-label
						>{{ translation.Habilitado }}
						<mat-slide-toggle
							name="Habilitado"
							formControlName="Habilitado"
						></mat-slide-toggle>
					</mat-label>
				</div>

				<div style="margin: 20px 5px 30px 5px; text-align: center">
					<mat-label>{{ translation.Clientes }}</mat-label>

					<customan-dual-list
						[source]="clientesSinAsignar"
						[(destination)]="clientesAsignados"
						key="Id"
						display="Nombre"
						[format]="format"
						[filter]="true"
					>
					</customan-dual-list>
				</div>

				<div style="margin: 20px 5px 30px 5px; text-align: center">
					<mat-label>{{ translation.Tarifas }}</mat-label>

					<customan-dual-list
						[source]="tarifasSinAsignar"
						[(destination)]="tarifasAsignadas"
						key="Id"
						display="Nombre"
						[format]="format"
						[filter]="true"
						[withPagination]="false"
					>
					</customan-dual-list>
				</div>

				<div style="margin: 20px 5px 0 5px; text-align: center">
					<mat-label>{{ translation.Centros }}</mat-label>

					<customan-dual-list
						[source]="centrosSinAsignar"
						[(destination)]="centrosAsignados"
						key="pkId"
						display="Nombre"
						[format]="format"
						[filter]="true"
					>
					</customan-dual-list>
				</div>
			</form>
		</div>
	</div>
</div>
