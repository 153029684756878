import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpParams,
	HttpRequest,
	HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { MicroserviceService } from "@sharedV11/services/microservices/microservice.service";
import { MicroserviceTokenResponse } from "@sharedV11/interfaces/microservice/microservice.interface";
import { AuthenticationService } from "@sharedV11/services/authentication/authentication.service";

@Injectable()
export class RequestMicroserviceInterceptor implements HttpInterceptor {
	routesToIgnore = [];

	constructor(
		private tokenService: ApiTokenService,
		private microservice: MicroserviceService,
		private authService: AuthenticationService,
	) {
		const apiURL = this.tokenService.getConfig("API_MICROSERVICIOS");
		this.routesToIgnore = [`${apiURL}/Token`, `${apiURL}/Refresh`];
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		const apiURL = this.tokenService.getConfig("API_MICROSERVICIOS");
		if (req.url.includes(apiURL)) {
			if (this.routesToIgnore.includes(req.url)) {
				return next.handle(req);
			}
			// Obtenemos el token
			// Importante: modificamos de forma inmutable, haciendo el clonado de la petición
			const modReq = this.addCommonData(req);
			// Pasamos al siguiente interceptor de la cadena la petición modificada
			//return next.handle(modReq);
			return next.handle(modReq).pipe(
				catchError((error: HttpErrorResponse) => {
					if (error.status === 401) {
						const microserviceData: MicroserviceTokenResponse = JSON.parse(localStorage.getItem("microserviceData")) || "";
						this.microservice.refreshToken(microserviceData)
							.subscribe(
								(data) => {
									this.microservice.loginMicroservice(data);

									const modReq = this.addCommonData(req);
									return next.handle(modReq);
								},
								(error) => {
									this.authService.logout();
								}
							)
							
					}
					return throwError(error);
				})
			);
		} else {
			return next.handle(req);
		}
	}

	addCommonData(req: HttpRequest<any>): HttpRequest<any> {
		const conexionId = localStorage.getItem("conexionIACPOS") || "";
		const token = localStorage.getItem("tokenMicroservice") || "";

		if (req.method === "GET" || req.method === "DELETE") {


			let keysParams = req.params.keys();
			console.log({
				keysParams,
			});

			let newParams = new HttpParams();

			if (keysParams.length > 0) {
				keysParams.forEach((key) => {
					newParams = newParams.set(key, req.params.get(key));
				});
			}

			newParams = newParams.set("ConexionId", conexionId);

			console.log({
				newParams,
			})

			const modReq = req.clone({
				setHeaders: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},

				//Agregar el ConexionId a los parámetros de la petición
				params: newParams,
			});
			return modReq;
		}

		if (req.body) {
			let body = req.body;

			body["ConexionId"] = conexionId;

			const modReq = req.clone({
				setHeaders: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: body,
			});

			return modReq;
		}

		return req;
	}
}
