<div class="modal-header">
  <h4 class="modal-title" id="myLargeModalLabel">Emails</h4>
  <button
    [mat-dialog-close]="{ action: 1, data: myGroup.value.ListEmails }"
    type="button"
    class="close"
    data-dismiss="modal"
    aria-hidden="true"
  >
    ×
  </button>
</div>
<div [formGroup]="myGroup" class="col-sm-12 col-md-10">
  <h5 class="h5">Añadir o quitar los emails</h5>
  <textarea
    class="form-control"
    formControlName="ListEmails"
    placeholder="Lista de eMails"
  ></textarea>
</div>
<div class="modal-footer">
  <button
    [mat-dialog-close]="{ action: 1, data: myGroup.value.ListEmails }"
    type="button"
    style="margin: 5px"
    class="btn btn-secondary"
    data-dismiss="modal"
  >
    Cancelar
  </button>
  <button
    [mat-dialog-close]="{ action: 2, data: myGroup.value.ListEmails }"
    type="button"
    style="margin: 5px"
    class="btn btn-secondary"
  >
    No enviar
  </button>
  <button
    [mat-dialog-close]="{ action: 3, data: myGroup.value.ListEmails }"
    type="button"
    style="margin: 5px"
    class="btn btn-primary"
  >
    Modificar y enviar
  </button>
  <button
    [mat-dialog-close]="{ action: 4, data: myGroup.value.ListEmails }"
    type="button"
    style="margin: 5px"
    class="btn btn-primary"
  >
    Enviar
  </button>
</div>
