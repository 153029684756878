import { CLienteAPi } from "@sharedV11/classes/userRequest/client_api";

export class peticion_ItemMotGrupo_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Descripcion: string;
}

//insUpd
export class peticion_Insercion_Upd_ItemMotGrupo {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string
    Nombre: string
    Descripcion: string
    Seleccionable: string
    GrupoId: string

    funcion: string
}

//del
export class peticion_Del_ItemMotGrupo {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
}