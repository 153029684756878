import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Boton } from '../../classes/botones/boton';
import data from 'src/app/shared/json/botones.json';
@Injectable({
  providedIn: 'root'
})
export class BotonService {
  buttons: object;

  private botonesSubject: BehaviorSubject<any>;
  public botones$: Observable<any>;

  constructor() {
    this.botonesSubject = new BehaviorSubject<any>([]);
    this.botones$ = this.botonesSubject.asObservable();


    let l_button: Boton = { "buttonsWrap": null,"layout":null, "content": null }
    this.buttons = new BehaviorSubject(l_button);
  }
  get botonesValue() {
    return this.botonesSubject.value;
  }

  public setBotones(botones) {  
    this.botonesSubject.next(botones);
  }
  findTable(name) {
    return data.botones.find(data => data.buttonsWrap == name);
  }


}










