import { Deserializable } from "@sharedV11/classes/deserializable";

export class Contacto_Respuesta implements Deserializable {
  DatosResult?: DatosResult_Contacto;
  Mensajes: Mensaje[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Contacto implements Deserializable {

  Nombre: string;
  PrimerApellido: string;
  SegundoApellido: string;
  Email: string;
  Localidad: string;
  Provincia: string;

  constructor() {
    this.Nombre = '',
    this.PrimerApellido = '';
    this.SegundoApellido = '';
    this.Email = '';
    this.Localidad = '';
    this.Provincia = '';
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_Contacto implements Deserializable {
  lista: Contacto[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

//Botonera
export class Botonera {
  TeclaRapida: string;
  NombreTipo: string;
  idEntrada: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_Botonera {
  Lista: Botonera[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class RespuestaBotonera {
  DatosResult: DatosResult_Botonera;
  Mensajes: Mensaje[];
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

