import { Injectable } from '@angular/core';
import { CLienteAPi } from '../_modelos/cliente-api.model';
import { peticion_Usuario } from '../_modelos/peticion-usuario.model';
import { Permiso } from '../_modelos/usuario-Respuesta.model';


@Injectable({
  providedIn: 'root'
})
export class Globales {
  clienteapi: CLienteAPi = { Id: '1', Token: 'aaa', EnlaceMenu: 'a' };
  iniciarLogPass: string = 'CUSTO$A$SZ45$A$MMAN$A$23g!.';

  //en la llamada, si tiene permisos para mas de una conexion, se piden en desplegable antes del login
  //De datos Indicio una vez seleccionado el proyecto.
  idiomaxDefecto = 'es-ES';
  divisapordefectoSistema = '2';
  idAplicacion: number = 19; //19 = CUSTOMMAN
  maxBotonesRapidosTPV = 50; //72
  //Constantes Identificadas
  Permisos: Array<Permiso> = [];
  arr_nivlelesEntrada: Array<any> = [
    { id: '1', value: 'NORMAL' },
    { id: '2', value: 'REDUCIDA' },
    { id: '3', value: 'GRATUITA' },
    { id: '4', value: 'NORMAL IDA Y VUELTA' },
    { id: '5', value: 'REDUCIDA IDA Y VUELTA' },
    { id: '6', value: 'GRATUITA IDA Y VUELTA' }
  ];

  diasSemana: Array<any> = [
    { id: '1', value: 'Lunes' },
    { id: '2', value: 'Martes' },
    { id: '3', value: 'Miércoles' },
    { id: '4', value: 'Jueves' },
    { id: '5', value: 'Viernes' },
    { id: '6', value: 'Sábado' },
    { id: '7', value: 'Domingo' }
  ];

  tiposAforo: Array<any> = [
    { id: 'SF', value: 'Sesiones Fijas' },
    { id: 'SI', value: 'Sesiones Irregulares' },
    { id: 'SD', value: 'Sesiones Diarias' },
    { id: 'PI', value: 'Sesiones por Intervalo' },
    { id: 'SP', value: 'Sesiones Privadas' }
  ];

  plataformasVenta: Array<any> = [
    { id: '1', value: 'Internet' },
    { id: '2', value: 'Punto Venta KORE' }
  ]; //, { id: '3', value: "Taquilla Automática" }

  tipoProductoAsociadoTarifa: Array<any> = [{ id: '1', value: 'Entrada' }]; // , { id: '2', value: "Promoción" }, { id: '3', value: "Abonado" }
  impresorasSeleccionables: Array<any> = [
    { id: '0', value: 'Primaria' },
    { id: '1', value: 'Secundaria' },
    { id: '2', value: 'Ambas' }
  ];

  /*
  translation;
  t = new Translator;
  tt = this.t.GetTranslations().then(data => {
    this.translation = data;
  });

  */
  //Funciones Globales

  //Pasar un Valor string a boolean
  aBooleano(valor) {
    if (typeof valor === 'string') {
      if (
        valor.toLowerCase().trim() === 'false' ||
        valor.toLowerCase().trim() === '0' ||
        valor.toLowerCase().trim() === ''
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (typeof valor === 'boolean') {
        return valor;
      } else {
        if (typeof valor === 'number') {
          if (valor > 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }
  }

  //Pasar un Valor string a Bit (0/1)
  aBit(valor) {
    if (typeof valor === 'string') {
      if (
        valor.toLowerCase().trim() === 'false' ||
        valor.toLowerCase().trim() === '0' ||
        valor.toLowerCase().trim() === ''
      ) {
        return 0;
      } else {
        return 1;
      }
    } else {
      if (typeof valor === 'boolean') {
        if (valor) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (typeof valor === 'number') {
          if (valor > 0) {
            return 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    }
  }
  anadirCeroHoraLeft(hora: string, largo?: number): string {
    let respuesta: string = '';
    if (largo == null) largo = 8;
    while (hora.length + respuesta.length < largo) {
      respuesta = respuesta + '0';
    }
    return respuesta + hora;
  }
  truncarDecimales(number) {
    return Math[number < 0 ? 'ceil' : 'floor'](number);
  }

  ComaDecimalxPunto(numero: string): string {
    if (numero == null || numero == '') numero = '0';
    return numero.replace(',', '.');
  }
  PuntoDecimalxComa(numero: string): string {
    if (numero == null || numero == '') numero = '0';
    return numero.replace('.', ',');
  }
}
//TODO llamar Api Bridge
