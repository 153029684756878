import { CLienteAPi } from "../client_api";

//InsUpdUsuarios
export class peticion_Insercion_Upd_Usuarios {
    ConexionIacpos: number;//?
    clienteAPI: CLienteAPi;//?
    pkId: string;
    Personal: string;
    Nombre: string;
    Clave: string;
    Tipo: string;
    Baja: string;
    Conectado: string;
    Empleado: string;
    Funcion: string;//?
}