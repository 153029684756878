import {Deserializable} from "./deserializable.model";

export class TipoNivel_Respuesta implements Deserializable {
    DatosResult?: DatosResult_TipoNivel;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoNivel implements Deserializable {
  Id: string;
  Nombre: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_TipoNivel implements Deserializable {
  DatosListas: TipoNivel[];
  ListadoTiposNiveles: any;

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

