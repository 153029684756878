export enum TipoDescuento {
  PORCENTAJE = 'DT',
  IMPORTE = 'PR'
}


export interface ClienteUI {
  informacion_personal: {
    tipo_documento: string,
    num_documento: string,
    nombre: string,
    imprimir: boolean;
    nombre_comercial: string,
    primer_apellido: string,
    segundo_apellido: string,
  };

  informacion_contacto: {
    email: string,
    email_2: string,
    prefijo_tlf: string,
    tlf: string,
    tlf_2: string,
    domicilio: string,
    localidad: string,
    codigo_postal: string,
    provincia: string,
    pais: string,
  };

  detalles_cuenta: {
    forma_pago: number,
    grupo_cliente: number,
    zona_comercial: number,
    tipo_cliente: number
  };

  venta_taquilla: {
    fecha_de_alta: string;
    descuento: boolean;
    tipo_descuento: TipoDescuento;
    importe_descuento: number;
    redondear_precio: boolean;
    ticket_sin_descuento: boolean;
  };

  venta_internet?:  {
    fecha_de_alta: string,
    usuario: string,
    descuento: boolean,
    tipo_descuento: TipoDescuento,
    importe_descuento: number,
    redondear_precio: boolean,
    ticket_sin_descuento: boolean,
    activar_baja: boolean
  };

  informacion_facturacion: {
    datos_facturacion: {
      tipo_documento: string,
      num_documento: string,
      razon_social: string,
      nombre: string
      primer_apellido: string
      segundo_apellido: string,
      domicilio: string,
      localidad: string,
      provincia: string,
      codigo_postal: string,
      pais: string,
    },
    datos_pago: {
      persona: string,
      direccion: string,
      lugar: string,
      dias: string
    }
  };

  debaja: boolean;
  codigo_cliente: string;
}

export interface Cliente {
  DatosComunes: null | {
    CodigoCliente: string;
    TipoDocumento: string;
    CIFoDNI: string;
    ImprimirEnTicket: boolean;
    NombreCliente: string;
    PrimerApellido: string;
    SegundoApellido: string;
    PrefijoInternacional: string;
    Telefono: string;
    Movil: string;
    Domicilio: string;
    Localidad: string;
    Provincia: string;
    CP: string;
    Pais: string;
    PersonaCobro: string;
    DireccionCobro: string;
    LugarCobro: string;
    DiaPago: string;
  };

  Cliente: null | {
    TipoPagoId: number;
    TipoClienteId: number;
    NombreComercial: string;
    EMail: string;
    EMail2: string;
    Telefono2: string;
    CodigoGrupos: number;
    CodigoZonaComercial: number;
    DeBaja: boolean;
    FechaBaja: string;
    FechaAlta: string;
    TieneDescuento: boolean;
    TipoDescuento: TipoDescuento;
    CantidadDescuento: number;
    RedondeoPrecios: boolean;
    TicketSinDescuento: boolean;
    CentrosIds?: string;
    GruposTarifas?: string;
  };

  ClienteAdicional: null | {
    TipoDocumento: string;
    NumeroDocumento: string;
    RazonSocial: string;
    NombreClienteFactura: string;
    PrimerApellido: string;
    SegundoApellido: string;
    Domicilio: string;
    Localidad: string;
    Provincia: string;
    CodigoPostal: string;
    Pais: string;
  };

  ClienteInternet: null | {
    DeBaja: boolean;
    FechaBaja: string;
    FechaAlta: string;
    Usuario: string;
    TieneDescuento: boolean;
    TipoDescuento: TipoDescuento;
    CantidadDescuento: number;
    RedondeoPrecios: boolean;
    TicketSinDescuento: boolean;
  };
}
