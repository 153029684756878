import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserType } from "src/app/shared/classes/userType/user_type";
import { Translator } from "src/app/shared/services/translator/translator.service";
import { Boton } from "../../classes/botones/boton";
import { BotonService } from "../../services/boton/boton.service";
import { AlertComponent } from "../alert/alert.component";

@Component({
  selector: "app-buttons",
  templateUrl: "./buttons.component.html",
  styleUrls: ["./buttons.component.scss"],
})
export class ButtonsComponent implements OnInit {
  private ngUnsubscribe = new Subject();

  componentRef: ComponentRef<AlertComponent>;
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() discard = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() desactivar = new EventEmitter();
  @Input() debaja: boolean;
  @Input() buttonsWrap: string;
  @Input() tableName: string;
  @Input() modificar: boolean = true;
  @Input() addbut: boolean = true;

  buttons: object;

  dataSource: MatTableDataSource<UserType>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  translation: any;

  usuarios;

  boton: Boton;
  content: any[] = [];
  layout;
  customanpanel: any;
  eltope: number;

  constructor(
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private botonService: BotonService,
    private panelService: PanelService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngAfterViewInit() {
    //this.initializeForms()
    this.cdRef.detectChanges();
    this.discardValueChanged();

    if (document.getElementById("Cerrar"))
      document.getElementById("Cerrar").focus();
  }

  ngOnInit(): void {
    this.eltope = document.getElementById("eltope").scrollTop;

    this.initializeTranslate();

    this.cdRef.detectChanges();

    this.findButton();

    this.botonService.botones$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((b) => {
        if (b) {
          if (b.buttonsWrap === this.buttonsWrap) {
            this.content = b.content;
          }
        }
      });
  }
  async initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }
  findButton() {
    this.boton = this.botonService.findTable(this.buttonsWrap);
    this.botonService.setBotones(this.boton);
    if (this.boton) {
      this.content = this.boton.content;
      this.layout = this.boton.layout;
    }
  }
  closepanel() {
    this.customanpanel = undefined;

    this.closePanelChangeCss();
  }

  closePanelChangeCss() {
    let classElem;

    classElem = document.getElementsByClassName("component");
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = "block";
    }
  }
  destroy() {
    //this.appStatus.paneldoc_close.emit();
    this.close.emit(null);
  }

  discardValueChanged() {
    this.panelService.discardChanges$.subscribe((value) => {
      if (this.content[0] && this.content[0][0] != undefined) {
        this.content[0].map((c) => {
          if (c.buttonTranslate == "Descartar") c.disabled = value;
        });
      }
    });
  }

  saveRow() {
    this.save.emit();
  }
  adios() {}
  discardButton() {
    this.discard.emit();
  }

  openPanel() {
    this.open.emit();
  }
  desactivarFunction() {
    this.desactivar.emit();
  }

  funcion1(nombre) {
    switch (nombre) {
      case "save":
        this.saveRow();
        break;
      case "destroy":
        this.destroy();
        break;
      case "discard":
        this.discardButton();
        break;
      case "open":
        this.openPanel();
        break;
      case "desactivar":
        this.desactivarFunction();
        break;
      case "adios":
        this.adios();
        break;
    }

    document.getElementById("eltope").scrollTop = this.eltope;
  }
}
