import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiTokenService } from 'src/app/shared/services/api/token/api-token.service';
import { GlobalService } from '../global/global.service';
import { Localizaciones, Paises, GrupoTipoEntrada, TipoControl, TipoEntrada , GrupoTipoBono } from '@sharedV11/classes/core/api-maestras.model';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};



@Injectable({providedIn: 'root'})


export class ApiMaestrasService {

  constructor(
    private globales: GlobalService,
    private http: HttpClient,
    private tokenService: ApiTokenService
  ) { }

  getLocalizaciones(): Observable<Localizaciones[]> {
    const peticion = {
      DispositivoId: '0'
    };

    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanLugares', peticion, httpOptions).pipe(
      map((data) => {
        if (data.DatosResult) {
          return data.DatosResult;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      })
    );
  }

  getPaises(): Observable<Paises[]> {
    const peticion = {};
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Configuracion/GetPaises', peticion).pipe(
      map((data) => {
        if (data.DatosPaises) {
          return data.DatosPaises;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      })
    );
  }

  getGruposTiposEntrada(): Observable<GrupoTipoEntrada[]> {
    const peticion = { NombreGrupo: '', chDescripcion: '' };
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoEntrada', peticion).pipe(
      map((data) => {
        if (data.DatosResult) {
          return data.DatosResult.customanGrupoTipoEntrada;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      })
    );
  }

  getTiposControl(): Observable<TipoControl[]> {
    const peticion = { Nombre: '' };

    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTiposControl', peticion).pipe(
      map((data) => {
        if (data.DatosResult) {
          return data.DatosResult.customanTiposControl;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      })
    );
  }

  getTipoEntrada(): Observable<TipoEntrada[]> {
    const peticion = {};
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetTipoEntrada', peticion).pipe(
      map((data) => {
        if (data.DatosResult) {
          return data.DatosResult.Lista;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      })
    );
  }

  getGrupoTipoBono(): Observable<GrupoTipoBono[]> {
    const peticion = { NombreGrupo: '', chDescripcion: '' };
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoBono', peticion).pipe(
      map((data) => {
        if (data.DatosResult) {
          return data.DatosResult.customanGrupoTipoBono;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      })
    );
  }
}
