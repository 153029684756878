<!-- TODO YS 20221220 es una barra de precios que necesita una libreria- mirar si se puede usar mejor material -->
<div> 
  <!-- <div class="text_precio">
    <span>Precio: </span>
    <span class="precio_min">min</span>
  </div> -->
<!--   <nouislider
    #slider
    [config]="someKeyboardConfig"
    (ngModelChange)="onChange($event)"
  >
  </nouislider> -->

<!--   <mat-slider  #slider min="0" max="100000" step="1000" showTickMarks discrete [displayWith]="formatLabel">
    <input matSliderThumb>
  </mat-slider> -->

  <!-- <span class="precio_max">max</span> -->
</div>
