import { Deserializable } from "../../deserializable";

export class GrupoTiposEntrada implements Deserializable {
    pkId:string;
    NombreGrupo:string;
    Descripcion:string;
    ConMotivo:string;
    CentrosIds: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}