import { Injectable } from "@angular/core";
import esES from "../../languages/es-ES.json";
import caES from "../../languages/ca-ES.json";
import ptPT from "../../languages/pt-PT.json";
import frFR from "../../languages/fr-FR.json";
import enGB from "../../languages/en-GB.json";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

type typeTranslate = "UPPER" | "LOWER" | "CAPITALIZE";

@Injectable({
  providedIn: "root",
})
export class Translator {

  data: any;

  public _locale: string;

  public _langDefault:string;

  public filename:string;

  proyecto: string;

  languageSubject: BehaviorSubject<any>;
  language$: Observable<any>;

  constructor(private http: HttpClient) {
    this.proyecto = environment['chClaveEmpresa'];
    this.inicitializeSubjectsAndObservable();
  }

  inicitializeSubjectsAndObservable() {
    this.languageSubject = new BehaviorSubject<any>({});
    this.language$ = this.languageSubject.asObservable();
  }

  getLanguageValue() {
    return this.languageSubject.value;
  }

  setLanguageValue(value) {
    this.languageSubject.next(value);
  }


  setLocale(value: string) {
    this._locale = value;
    //this.calendarService.setMeses(value);
  }

  setLangDefault(value:string){
    this._langDefault = value;
  }

  getLocale(): string {
    return this._locale || this._langDefault || 'es-ES';
  }

  getFilename() {
    return this.filename;
  }

  setFilename(value:string) {
    this.filename=value;
  }

  GetTranslationsDeprecated() {
    const language = localStorage.getItem("Language");

    switch (language) {
      case "es-ES":
        this.data = esES;
        return esES;
      case "ca":
        this.data = caES;
        return caES;
      case "fr-FR":
        this.data = frFR;
        return frFR;
      case "pt-PT":
        this.data = ptPT;
        return ptPT;
      case "en-GB":
      case "en":
        this.data = enGB;
        return enGB;
      default:
        localStorage.setItem("Language", "es-ES");
        this.data = esES;
        return esES;
    }
  }

  setData(data: any) {
    this.data = data;
  }

  GetTranslations() {
    console.log({
      data: this.data
    })
    return this.data;
  }

  use(lang_: string): Promise<{}> {
    let lang = lang_;
		if (lang === "en") {
			lang = "en-GB";
		}

    if (lang === 'ca') {
      lang = 'ca-ES';
    }

		return new Promise<{}>((resolve) => {
			this.fileLangExists(lang).subscribe((existsLangPath) => {
				if (!existsLangPath) {
					lang = this._langDefault || "es-ES";
				}
				this.setLocale(lang);
				const langPath = `./assets/configurations_old/languages/${
					lang || this._langDefault || "es-ES"
				}.json`;
				this.http.get<{}>(langPath).subscribe(
					(translation) => {
						this.data = Object.assign({}, translation || {});
						resolve(this.data);
					},
					() => {
						this.data = {};
						resolve(this.data);
					}
				);
			});
		});
	}

	fileLangExists(lang: string) {
		const url = `./assets/configurations_old/languages/${lang}.json`;
		return this.fileExists(url);
	}

	fileExists(url: string): Observable<boolean> {
		return this.http.get(url).pipe(
			map((response) => {
				return true;
			}),
			catchError((error) => {
				return of(false);
			})
		);
	}

  translateV2(key: string, typeTransform: typeTranslate = 'UPPER', data?: any) {
    const objectTranslate = data ? data : this.data;
    const value: string = objectTranslate[key] || key;

    switch (typeTransform) {
      case 'UPPER':
        return value.toUpperCase();
      case 'LOWER':
        return value.toLowerCase();
      case 'CAPITALIZE':
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
      default:
        return value;
    }
  }
}
