<div class="container col">
  <div class="menu-acciones-metodo">
    
    <button class="btn btn-primary btn-magento-atras" (click)="editando=false;" [hidden]="!editando">&nbsp;&nbsp;{{translation.Atras}}</button>
    <!--
    <button *ngIf="permisobtnDuplicar && model.pkId!=null && editando" class="btn btn-warning btn-magento-gris" (click)="duplicarRegistro()">{{translation.DuplicarRegistro}}</button>
    -->
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirUsuario}}</button>
  </div>
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li *ngIf="(filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() !='')"> {{translation.Nombre}}:
          <span>{{filtrosactivos.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"></i></li>
        <li *ngIf="(filtrosactivos.TipoUsuario != null)"> {{translation.Tipo}}:
          <span>{{filtrosactivos.TipoUsuario.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('tipoControl')"></i></li>
        <li
          *ngIf="((filtrosactivos.personaldesde != null || filtrosactivos.personalhasta != null) && (filtrosactivos.personaldesde.trim() !='' ||filtrosactivos.personalhasta.trim() !='' ))">
          {{translation.Personal}}: <span>{{filtrosactivos.personaldesde}} {{translation.A}}
            {{filtrosactivos.personalhasta}} </span>
          &nbsp;<i class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('personal')"></i></li>
        <li *ngIf="(filtrosactivos.Baja != null && filtrosactivos.Baja.trim() !='')"> {{translation.Baja}}:
          <span>{{filtrosactivos.Baja}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Baja')"></i></li>
        <li *ngIf="(filtrosactivos.Conectado != null && filtrosactivos.Conectado.trim() !='')">
          {{translation.Conectado}}: <span>{{filtrosactivos.Conectado}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Conectado')"></i></li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" (click)="MostrarFiltros()"
        [class.filtro-activado]="panelFiltrosvisible"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.pkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.Nombre)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.Nombre}}</mat-label>
              <input matInput placeholder="{{translation.FiltroDeNombre}}" formControlName="nombre"
                [value]="filtrosactivosModelo.Nombre">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.TipoUsuario)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TipoUsuario}}" formControlName="tipoControl" name="tipoUsuario"
                [value]="filtrosactivosModelo.TipoUsuario" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoUsuario of tiposUsuario" [value]="tipoUsuario">
                  {{tipoUsuario.Nombre}}
                </mat-option>
              </mat-select>
              <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Personal)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.Personal}}</b> &nbsp; <i>{{translation.Desde}}</i></mat-label>
              <input matInput placeholder="{{translation.Desde}}" formControlName="personaldesde"
                [value]="filtrosactivosModelo.personaldesde">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.Personal}}</b> &nbsp; <i>{{translation.Hasta}}</i></mat-label>
              <input matInput placeholder="{{translation.Hasta}}" formControlName="personalhasta"
                [value]="filtrosactivosModelo.personalhasta">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Conectado)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Conectado}}" formControlName="conectado" name="tipoConectado"
                [value]="filtrosactivosModelo.Conectado">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Baja)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Baja}}" formControlName="baja" name="tipoConectado"
                [value]="filtrosactivosModelo.Baja">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>

    <!--Tabla-->
    <div class="mat-elevation-z8 overflow-table">

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" class="magento-paginator">
      </mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">

              <button matToolTip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)=duplicar(row) style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>

              <button *ngIf="permisobtnEliminar" matToolTip="Borrar Registro" class="button-icon"
                (click)="borrar(row.pkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>

            </div>
          </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Nombre}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Nombre}} </td>
        </ng-container>

        <!-- Clave Column -->
        <ng-container matColumnDef="Clave">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Clave}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Clave}} </td>
        </ng-container>

        <!-- tipo Column -->
        <ng-container matColumnDef="Tipo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Tipo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.tipoUsuario.Nombre}} </td>
        </ng-container>

        <!-- Clave Column -->
        <ng-container matColumnDef="Personal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Personal}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Personal}} </td>
        </ng-container>

        <!-- tipo Column -->
        <ng-container matColumnDef="Empleado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Empleado}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Empleado}} </td>
        </ng-container>

        <!-- conectado Column -->
        <ng-container matColumnDef="Conectado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Conectado}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Conectado}} </td>
        </ng-container>

        <!-- baja Column -->
        <ng-container matColumnDef="Baja">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.DeBaja}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Baja}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="editar(row)">
        </tr>
      </table>

    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>

  <div [hidden]="!editando" class="editor-registro">
      <div class="tituloSeccion" (click)="mostrar()" id="titulo">Información personal</div>
    <form [formGroup]="editorRegistroForm" id="formulario" (click)="validacion()">
      <div class=grupo-editor-campo-registro>
        <label>{{translation.Nombre}}</label>
        <div class="separadorLateral">
          <input formControlName="nombre" class="editor-campo-registro form-control"
          [(ngModel)]="model.Nombre" [ngClass]="{ 'is-invalid': f.nombre.errors }" placeholder="Campo obligatorio" >
          <div *ngIf="f.nombre.errors" class="invalid-feedback">
          <div *ngIf="f.nombre.errors.required">{{translation.SeRequiereNombre}}</div>
          </div>
       </div>
      </div>
      <div class=grupo-editor-campo-registro>
        <label>{{translation.Clave}}</label>
        <div class="separadorLateral">
          <input formControlName="clave" class="editor-campo-registro form-control"
            [(ngModel)]="model.Clave" [ngClass]="{ 'is-invalid': f.clave.errors }" placeholder="Mínimo 6 caracteres">
          <div *ngIf="f.clave.errors" class="invalid-feedback">
            <div *ngIf="f.clave.errors.required">{{translation.SeRequiereUnaClave}}</div>
            <div *ngIf="f.clave.errors.minlength">{{translation.LaClaveAlMenosDebeDeTenerSeisCaracteres}}</div>
          </div>
        </div>
      </div>
      <div class="grupo-editor-campo-registro tipoUsuario">
        <label>{{translation.Tipo}}</label>
        <!--<input formControlName="tipo" class = "editor-campo-registro form-control" [(ngModel)]="model.Tipo"  [ngClass]="{ 'is-invalid': f.tipo.errors }">-->
        <div class="separadorLateral">
          <mat-form-field class="input-select editor-campo-registro">
            <mat-select placeholder="{{translation.TipoUsuario}}" [formControl]="tipoControl" name="tipoUsuario"
              [value]="model.tipoUsuario" required>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let tipoUsuario of tiposUsuario" [value]="tipoUsuario">
                {{tipoUsuario.Nombre}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="tipoControl.hasError('required')">{{translation.PorFavorSeleccioneUnTipoDeUsuario}}
            </mat-error>
            <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class=grupo-editor-campo-registro>
        <label>{{translation.IdPersonal}}</label>
        <div class="separadorLateral">
          <input formControlName="personal"
          class="editor-campo-registro form-control" [(ngModel)]="model.Personal">
        </div>
      </div>
      <div class=grupo-editor-campo-registro>
        <label>{{translation.Empleado}}</label>
        <div class="separadorLateral">
          <input formControlName="empleado" empleado
            class="editor-campo-registro form-control" [(ngModel)]="model.Empleado">
          </div>
      </div>
      <div class="selectores">
        <div class=grupo-editor-campo-registro>
          <label>{{translation.Conectado}}</label>
          <label class="switch">
            <input formControlName="conectado" type="checkbox"
            class="editor-campo-registro-check form-check-input form-control" [(ngModel)]="model.Conectado">
            <span class="slider round"></span>
          </label>
        </div>
        <div class=grupo-editor-campo-registro>
          <label>{{translation.Baja}}</label>
          <label class="switch">
            <input formControlName="baja" type="checkbox"
            class="editor-campo-registro-check form-check-input form-control" [(ngModel)]="model.Baja">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
    </form>
<div class="botones">
  <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="!editorRegistroForm.valid"
  [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>

  <button *ngIf="permisobtnEliminar && model.pkId!=null && editando" class="btn btn-danger btn-magento-gris"
  (click)="pregborrarRegistro()">{{translation.Borrar}}</button>
</div>

  </div>
</div>
