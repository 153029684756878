import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ApiTokenService } from 'src/app/shared/services/api/token/api-token.service';
import { PeticionClienteLista } from '@sharedV11/classes/userRequest/clientes/api-clientes.model';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Cliente } from '@sharedV11/classes/clients/client/client.interface';
import { PeticionAltaBajaCliente } from '@sharedV11/classes/clients/client/api-clientes.model';


@Injectable({ providedIn: 'root' })

export class ApiClientesService {

  constructor(private http?: HttpClient, private globales?: GlobalService, private tokenService?: ApiTokenService) { }

  getClientes(pagination, ordenation, filters): Observable<any> {
    const peticion = new PeticionClienteLista(pagination)
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/BuscadorClienteAgrupado', peticion).pipe(
      map(data => {
        if (data.ResultadoBuscadorClientes) {
          return data;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  getClientDetail(PkId: string): Observable<any> {
    let peticion: any = {};
    peticion.CodigoCliente = PkId;
    peticion.Usuario = '';

    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanClienteIndividual', peticion)
      .pipe(
        map(data => {
          if (data.DatosResult) {
            return data.DatosResult;
          } else {
            throw new Error(data.Mensajes[0].DescripcionMensaje);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  insUpdCliente(info: Cliente, codigoCliente?: string): Observable<any> {
    // Montamos la peticion insercion/Update
    const peticion: any = info;

    if (codigoCliente != null && codigoCliente !== "0") {
      peticion.DatosComunes.CodigoCliente = codigoCliente;
      peticion.RegistrarModificar = 1;
    } else {
      peticion.RegistrarModificar = 0;
    }

    peticion.Magento = true;

    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanCliente', peticion)
      .pipe(
        map(data => {
          if (data.DatosResult && data.DatosResult.Avisos) {
            return data.DatosResult;
          } else {
            throw new Error(data.Mensajes[0].DescripcionMensaje);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  insUpdClienteNew(body: any): Observable<any> {
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanCliente', body)
      .pipe(
        map(data => {
          if (data.DatosResult && data.DatosResult.Avisos) {
            return data.DatosResult;
          } else {
            throw new Error(data.Mensajes[0].DescripcionMensaje);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  insUpdContacto(body: any) {
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'InsUpdContacto', body)
      .pipe(
        map(data => {
          if (data.DatosResult && data.DatosResult.Avisos) {
            return data.DatosResult;
          } else {
            if (data.Mensajes[0])
              throw new Error(data.Mensajes[0].DescripcionMensaje);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delContacto(id: any) {
    let body = {
      "idContacto": id
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanContacto', body)
      .pipe(
        map(data => {
          if (data.DatosResult && data.DatosResult.Avisos) {
            return data.DatosResult;
          } else {
            throw new Error(data.Mensajes[0].DescripcionMensaje);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  delCliente(PkId: number, email: string): Observable<any> {
    const peticion = {
      CodigoCliente: PkId,
      Usuario: '',
      Email: email,
      Magento: true
    };
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanCliente', peticion).pipe(
      map(data => {
        if (data.Mensajes && data.Mensajes[0].CodigoMensaje === null) {
          return data.Mensajes[0].DescripcionMensaje;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  altaBajaCliente(PkId: string, fecha_baja?: string): Observable<any> {
    const peticion: PeticionAltaBajaCliente = {
      CodigoCliente: PkId.toString(),
      Usuario: '',
      CodigoAltaBaja: fecha_baja ? '1' : '0',
      Magento: true
    };

    if (fecha_baja) { peticion.FechaDebaja = fecha_baja; }

    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActivarDarDeBajaCustomanCliente', peticion).pipe(
      map(data => {
        if (data.DatosResult) {
          return data.DatosResult;
        } else {
          throw new Error(data.Mensajes[0].DescripcionMensaje);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
}
