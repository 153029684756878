import {CLienteAPi} from "./cliente-api.model";

//getTiposCliente
export class peticion_Lugares_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
}

//insUpdTiposCliente 
export class peticion_Insercion_Upd_Lugares {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    Nombre: string;
    Descripcion: string;
    Funcion: string;
}

//delTiposCliente
export class peticion_Del_Lugares {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
}