<div class="container col">
  <div class="menu-acciones-metodo">
    <button
      class="btn btn-primary btn-magento-atras"
      (click)="editando = false"
      [hidden]="!editando"
    >
      <i class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{ translation.Atras }}
    </button>
    <button
      *ngIf="permisobtnEliminar && model.PkId != null && editando"
      class="btn btn-danger btn-magento-gris"
      (click)="pregborrarRegistro()"
    >
      {{ translation.Borrar }}
    </button>
    <button
      *ngIf="permisobtnDuplicar && model.PkId != null && editando"
      class="btn btn-warning btn-magento-gris"
      (click)="duplicarRegistro()"
    >
      {{ translation.DuplicarRegistro }}
    </button>
    <button
      class="btn btn-success btn-magento-naranja"
      (click)="guardar()"
      [disabled]="!editorRegistroForm.valid"
      [hidden]="!permisobtnGuardar || !editando"
    >
      {{ translation.Guardar }}
    </button>
    <button
      class="btn btn-warning btn-magento-naranja"
      (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando"
    >
      {{ translation.AnadirGrupoTipoBono }}
    </button>
  </div>
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div
      *ngIf="hayFiltros(filtrosactivos)"
      class="customan-panel-filtros-activos"
    >
      {{ translation.FiltrosActivos }}:
      <ul class="custom-list-horizontal">
        <li
          *ngIf="
            filtrosactivos.textoGeneral != null &&
            filtrosactivos.textoGeneral.trim() != ''
          "
        >
          {{ translation.PalabraClave }}:
          <span>{{ filtrosactivos.textoGeneral }}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('General')"
          ></i>
        </li>
        <li
          *ngIf="
            filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() != ''
          "
        >
          {{ translation.Nombre }}:
          <span>{{ filtrosactivos.Nombre }}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"
          ></i>
        </li>
        <li
          *ngIf="
            filtrosactivos.Descripcion != null &&
            filtrosactivos.Descripcion.trim() != ''
          "
        >
          {{ translation.Descripcion }}:
          <span>{{ filtrosactivos.Descripcion }}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Descripcion')"
          ></i>
        </li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button
        class="btn btn-warning btn-magento-gris-claro"
        [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"
      >
        <i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{ translation.Filtros }}
      </button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button
        class="btn btn-primary btn-magento-atras"
        (click)="MostrarColumnas()"
      >
        <i class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{
          translation.Columnas
        }}&nbsp;&nbsp;<i
          class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible"
          [class.fa-caret-down]="!panelColumnasvisible"
        ></i>
      </button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{ columnas.selectedOptions.selected.length }} {{ translation.De }}
      {{ displayedColumns.length }} {{ translation.Visibles }}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list
          #columnas
          (selectionChange)="
            visibilidadColumna($event, columnas.selectedOptions.selected)
          "
          class="columnas-tabla-listado"
          [formControl]="formControlListaColumnas"
        >
          <mat-list-option
            class="columnas-listado-elemento"
            checkboxPosition="before"
            *ngFor="let columna of displayedColumns"
            [value]="columna"
          >
            {{ columna }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div
      *ngIf="panelSelectorFiltrosvisible"
      class="customan-panel-columnas-tabla"
    >
      {{ columnas.selectedOptions.selected.length }} {{ translation.De }}
      {{ displayedFilters.length }} {{ translation.Visibles }}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list
          #columnas
          (selectionChange)="
            visibilidadFiltro($event, columnas.selectedOptions.selected)
          "
          class="columnas-tabla-listado"
          [formControl]="formControlListaFiltros"
        >
          <mat-list-option
            class="columnas-listado-elemento"
            checkboxPosition="before"
            *ngFor="let filtro of displayedFilters"
            [value]="filtro"
          >
            {{ filtro }}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div
            *ngIf="filtersToDisplay.includes(translation.Nombre)"
            class="grupo-filtros-campo-registro"
          >
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Nombre }}</mat-label>
              <input
                matInput
                placeholder="{{ translation.FiltroDeNombre }}"
                formControlName="nombre"
                [value]="filtrosactivosModelo.Nombre"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="filtersToDisplay.includes(translation.Descripcion)"
            class="grupo-filtros-campo-registro"
          >
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Descripcion }}</mat-label>
              <input
                matInput
                placeholder="{{ translation.FiltroDeDescripcion }}"
                formControlName="descripcion"
                [value]="filtrosactivosModelo.Descripcion"
              />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button
          class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()"
        >
          {{ translation.AplicarFiltros }}
        </button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input
          matInput
          placeholder="{{ translation.BuscarPorPalabraClave }}"
          (keydown.enter)="applyFilter($any($event.target).value)"
          [value]="filtrosactivosModelo.textoGeneral"
        />
        <i
          (click)="
            applyFilter($any($event.target).parentNode.children[0].value)
          "
          class="fa fa-search fa-lg"
        ></i>
      </mat-form-field>
    </div>
    <!--Tabla-->

    <div class="mat-elevation-z8">
      <mat-paginator
        #paginatorPrincipal
        [pageSizeOptions]="[10, 25, 100]"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{ translation.Acciones }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            (click)="$event.stopPropagation()"
            style="cursor: default"
          >
            <div class="action-container">
              <button
                customanTooltip="Duplicar Registro"
                *ngIf="permisobtnDuplicar"
                class="button-icon"
                (click)="duplicar(row)"
                style="width: 34px; height: 34px"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
              <button
                *ngIf="permisobtnEliminar"
                customanTooltip="Borrar Registro"
                class="button-icon"
                (click)="borrar(row.PkId)"
                style="width: 34px; height: 34px"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <!-- Nombre Column -->
        <ng-container matColumnDef="NombreGrupo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ translation.Nombre }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.NombreGrupo }}</td>
        </ng-container>

        <!-- Clave Column -->
        <ng-container matColumnDef="Descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ translation.Descripcion }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.Descripcion }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsToDisplay"
          (click)="editar(row)"
        ></tr>
      </table>
      <!--  <mat-paginator
        (page)="paginatorPrincipal.pageIndex = $event.pageIndex; paginatorPrincipal._emitPageEvent($event.pageIndex)"
        [pageSize]="paginatorPrincipal.pageSize" [pageIndex]="paginatorPrincipal.pageIndex"
        [length]="paginatorPrincipal.length" [pageSizeOptions]="paginatorPrincipal.pageSizeOptions"
        [showFirstLastButtons]="true">
      </mat-paginator> -->
    </div>

    <span _ngcontent-c0="" class="div-separador"></span>
  </div>

  <div [hidden]="!editando" class="editor-registro">
    <form [formGroup]="editorRegistroForm">
      <div class="grupo-editor-campo-registro">
        <label>{{ translation.Nombre }}</label
        ><input
          formControlName="nombre"
          class="editor-campo-registro form-control"
          [(ngModel)]="model.NombreGrupo"
          [ngClass]="{ 'is-invalid': f.nombre.errors }"
        />
        <div *ngIf="f.nombre.errors" class="invalid-feedback">
          <div *ngIf="f.nombre.errors.required">
            {{ translation.SeRequiereNombreDeGrupo }}
          </div>
        </div>
      </div>

      <div class="grupo-editor-campo-registro">
        <label>{{ translation.Descripcion }}</label
        ><input
          formControlName="descripcion"
          class="editor-campo-registro form-control"
          [(ngModel)]="model.Descripcion"
        />
      </div>
    </form>
  </div>
</div>
