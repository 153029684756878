import {CLienteAPi} from "./cliente-api.model";
import {IdiomaGR, IdiomaconURL} from "./grupos-recintos-Respuesta.model"

//getGruposInternet
export class peticion_GruposInternet_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Descripcion: string;
    TipoReserva: string;
}

//insUpdGruposInternet /TODO
export class peticion_Insercion_Upd_GruposInternet {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    pkId: string;
    Nombre: string;
    Descripcion: string;
    chCodigo: string;
    Minimo: string;
    Maximo: string;
    Orden: string;
    TipoReservaId: string;
    ValidarGratuitas: string;
    Idioma:string;
    Idiomas: IdiomaconURL[];
    Funcion: string;
}

//delGruposInternet //TODO
export class peticion_Del_GruposInternet {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}