import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IReglaNegocio, IUReglaNegocioAPI, StringNumber } from "@sharedV11/classes/EntradasPromociones/ReglasNegocio/reglas-negocio";
import { IGrupoTarifa, IUGrupoTarifaAPI, Mode } from "@sharedV11/classes/tarifas/grupostarifas";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { filters, pagination } from "@sharedV11/services/interfaces/paginator.interface";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, filter, map } from "rxjs/operators";
import { ListRequestBody } from "./codigo-promocionale-bones.interface";
import {  BodyInsertCodigoPromocionalBines, BodyUpdateCodigoPromocionalBines, ResponseGetCodigoPromocionalBines } from "@sharedV11/interfaces/microservice/CodigoPromocionalBines/index.interface";


const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
	}),
};

@Injectable({
	providedIn: "root",
})
export class CodigoPromocionalBinesService {
  conexionIACPOS: number = parseInt(localStorage.getItem("conexionIACPOS"));

  codigosPromocionales = [];

	private extractData(res: Response) {
		let body = res;
		return body || {};
	}

	constructor(
		private http: HttpClient,
		private tokenService: ApiTokenService,
		private global: GlobalService,
		private servicioAPI: ConectarApiService,
		private httpErrorService: HttpErrorsService
	) {}

	cargarDatos (): Promise<boolean> {

		const promise = new Promise<boolean>((resolve, reject) => {

			const observableListCodigosPromocionales = this.listCodigosPromocionales();

			forkJoin([
        observableListCodigosPromocionales
			]).subscribe(
				([
					dataCodigosPromocionales
				]) => {

					this.codigosPromocionales = dataCodigosPromocionales?.DatosResult?.ListaCodigoPromocional ?? [];

					resolve(true);
				},
				error => {
					this.httpErrorService.identificarErrores(error);
					reject(error); // Rechazamos la promesa en caso de error
				}
			);
		});
		
		return promise;
	}

  listCodigosPromocionales(): Observable<any> {
		let peticion: any = {};
		peticion.clienteAPI = this.global.clienteapi; // TODO
		const response = this.http
			.post<any>(
				this.tokenService.getConfig("API_URL") +
					"Customan/ObtenerCustomanCodigoPromocional",
				JSON.stringify(peticion),
				httpOptions
			)
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);
		return response;
	}

	listCodigosPromocionalesBines(pagination?: pagination, filters?: filters): Observable <any> {
		let peticion: ListRequestBody = {
			orderby: "",
			ordertype: "",
			page: 1,
			pageelements: 100,
		};

      if (pagination) {
        peticion = {
					...peticion,
					...pagination,
					pageelements: pagination.pagelements || peticion.pageelements || 100
				}
      }

			const URL = `${this.tokenService.getConfig('API_MICROSERVICIOS')}/api/DescuentosEspeciales/CodigoPromocionalBines`;

			let params = new HttpParams();

			for (const key in peticion) {
				if (peticion.hasOwnProperty(key)) {
					params = params.set(key, peticion[key]);
				}
			}

			if (filters.length > 0) {
				for (const filter of filters) {
					params = params.set('name', filter.name);
					params = params.set('value', filter.value);
				}
			}

      const response = this.http.get<any>(
				URL,
				{
					params
				}
			).pipe(map(data => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
      return response;
	}
	
	findCodigoPromocionalBinByPkId(pkId: string): Observable <ResponseGetCodigoPromocionalBines> {
		const URL = `${this.tokenService.getConfig('API_MICROSERVICIOS')}/api/DescuentosEspeciales/CodigoPromocionalBines`;

		let params = new HttpParams();

		params = params.set("name", "pkId");
		params = params.set("value", pkId);

		const response = this.http.get<any>(
			URL,
			{
				params
			}
		).pipe(map(data => {
				return data;
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		);
		return response;
	}

	insertCodigoPromocionalBin(codigoPromocionalBin: BodyInsertCodigoPromocionalBines): Observable<any> {
		//Montamos la peticion insercion/Update
		
		const URL = `${this.tokenService.getConfig('API_MICROSERVICIOS')}/api/DescuentosEspeciales/CodigoPromocionalBines`;

		const response =  this.http
			.post<any>(
				URL,
				codigoPromocionalBin,
				httpOptions
			)
			.pipe(
				map(data => data),
				catchError((error: HttpErrorResponse) => {
					console.log({
						errorResponse: error
					})
          return throwError(error);
        })
			);

		return response;
	}

	updateCodigoPromocionalBin(codigoPromocionalBin: BodyUpdateCodigoPromocionalBines): Observable<any> {
		//Montamos la peticion insercion/Update
		const URL = `${this.tokenService.getConfig('API_MICROSERVICIOS')}/api/DescuentosEspeciales/CodigoPromocionalBines`;

		const response =  this.http
			.put<any>(
				URL,
				codigoPromocionalBin,
				httpOptions
			)
			.pipe(
				map(data => data),
				catchError((error: HttpErrorResponse) => {
					console.log({
						errorResponse: error
					})
					return throwError(error);
				})
			);
		
		return response;
	}


	deleteCodigoPromocionalBin(pkId: string): Observable<any> {

		if (!pkId) {
			return of(null);
		}
		
		let params = new HttpParams();

		params = params.set("pkId", pkId);

		const URL = `${this.tokenService.getConfig('API_MICROSERVICIOS')}/api/DescuentosEspeciales/CodigoPromocionalBines`;
		// Enviamos la peticion
		const response = this.http.delete<any>(
			URL,
			{
				params
			}
		).pipe(
			map(data => {
				return data
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(error);
			})
		)

		return response;
	}

	private handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
