import { TiendaProductosData } from "src/app/components/tienda/productos/productosData";
import { Deserializable } from "../deserializable";

export class TiendaProductosToShow implements Deserializable, TiendaProductosData {
    pkId: string;
    Nombre: string;
    Descripcion: string;
    CodigoBarras: string;
    TextoEnPantalla: string;
    AlaVenta: string;
    GrupoIdFilter: string;
    iCategoriaIdFilter: string;
    SubCategoria: string;
    Proveedor: string;
    CentrosIds: string;
    iTipoProductoIdFilter: string;
    iGrupoAsociadoIdFilter: string;
    TipoVisitaFilter: string;
    iTipoNivelIdFilter: string;
    TipoPublicoFilter: string;
    TipoVisitante: string;
    Niveles: string;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

	assign(param,data){
		this[param] = data
	}
	
}