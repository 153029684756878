import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class EditInternetGroupService {
  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService
  ) {}

  findIternetGroupByPkId(
    pkId: string,
    ClaveEmpresa: string,
    ProyectoOnLine: string,
    ConexionEntorno: string
  ): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    peticion.ClaveEmpresa = ClaveEmpresa;
    peticion.ProyectoOnLine = ProyectoOnLine;
    peticion.ConexionEntorno = ConexionEntorno;
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ObtenerGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  findTipoSesionPkId(
    pkId: string,
    ClaveEmpresa: string,
    ConexionEntorno: string
  ): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = pkId;
    peticion.ClaveEmpresa = ClaveEmpresa;
    peticion.ConexionEntorno = ConexionEntorno;
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") + "Customan/ObtenerTipoSesion",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  /* 
  findUserByPkIdLocal(pkId:string): Observable<any> {
    return of(data["default"])
  } */

  insGruposInternet(internetgroup, mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = internetgroup;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarCustomanGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("InsertarCustomanGruposInternet"))
      );
  }
  updGruposInternet(internetgroup, mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = internetgroup;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/ActualizarCustomanGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map(this.extractData),
        catchError(this.handleError<any>("ActualizarCustomanGruposInternet"))
      );
  }
  insUpdGruposInternet(internetgroup, mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = internetgroup;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    const response = this.http
      .post<any>(
        this.tokenService.getConfig("API_URL") +
          "Customan/InsertarActualizarCustomanGruposInternet",
        JSON.stringify(peticion),
        httpOptions
      )
      .pipe(
        map((data) => {
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
}
