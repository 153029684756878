import { Component, Inject, OnInit, HostBinding, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { first } from 'rxjs/operators';

import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Idiomas_Respuesta, Idiomas } from '../../_modelos/Idiomas-Respuesta.model';
import { IdiomaGR, IdiomaconURL } from '../../_modelos/grupos-recintos-Respuesta.model';
import { GruposInternet_Respuesta, GruposInternet } from '../../_modelos/grupos-internet-Respuesta.model';
import { tipos_reserva_Respuesta, TipoReserva } from '../../_modelos/tipos-reserva-Respuesta.model';
import { DatosResult_EnlaceMenu, EnlaceMenu } from '../../_modelos/enlaces-menu-Respuesta.model';

import { ConectarAPIService } from '../../_API/conectar-api.service';
//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { AlertaService } from '../../shared/_alerta/alerta.service';
import { AutentificacionService } from '../../_otrosproveedores/autentificacion.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { DialogoPopUp } from '../../shared/_alerta/popup.service';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Globales } from '@clasesV6/globales';
import { peticion_Insercion_Upd_EnlacesMenu } from '@modelosV6/peticion-enlaces-menu.model';
import { Translator } from 'src/app/shared/services/translator/translator.service';


export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class ColumnaIdiomas {
  textoHeader: string;
  CodIdioma: string;
}

export class traspasomodelo {
  pkId: string;
  Nombre: string;
  Descripcion: string;
  chCodigo: string;
  Minimo: string;
  Maximo: string;
  Orden: string;
  TipoReservaId: string;
  ValidarGratuitas: boolean;
  EnlaceMenuId: string;
  Idioma: string;
  Idiomas: IdiomaconURL[];

  deserialize: null;
}

export class filtrosActivos {
  Nombre: string;
  ValidaGratuita: string;
  CodigoTipoReserva: string;
  EnlaceMenu: string;
  textoGeneral: string;
}

@Component({
  selector: 'app-grupos-internet',
  templateUrl: './grupos-internet.component.html',
  styleUrls: ['./grupos-internet.component.css']
})
export class GruposInternetComponent implements OnInit {
  displayedColumns: string[] = ['Acciones', 'Nombre', 'Descripcion', 'Minimo', 'Maximo', 'Orden',
    'TReserva', 'ValidarGratuitas', 'EMenu'];
  columnsToDisplay: string[] = ['Acciones', 'Nombre', 'Descripcion', 'Minimo', 'Maximo', 'Orden', 'Idioma',
    'TReserva', 'ValidarGratuitas','EMenu'];
  displayedFilters: string[] = ['Nombre', 'ValidaGratuitas', 'TipoDeReserva', 'EnlacesMenu'];
  filtersToDisplay: string[] = this.displayedFilters.slice();

  dataSource: MatTableDataSource<GruposInternet>;
  seleccion = new SelectionModel<GruposInternet>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  usuarios;
  respuestaAPI: GruposInternet_Respuesta;
  editando: boolean = false;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  formControlListaColumnas: FormControl;
  editorListaIdiomas: Array<IdiomaconURL>;
  //--
  tiposIdiomasAPI: Idiomas_Respuesta;
  tiposIdiomas: Array<Idiomas>;
  tipoControl = new FormControl('', [Validators.required]);
  tipoControlIdiomas = new FormControl('', [Validators.required]);
  //---
  tiposEnlaceMenuAPI: DatosResult_EnlaceMenu;
  tiposEnlaceMenu: Array<EnlaceMenu>;
  //---
  tiposTipoReservaAPI: tipos_reserva_Respuesta;
  tiposTipoReserva: Array<TipoReserva>;
  //--
  tipodidiomacontrol = new FormControl('');
  tipodereservacontrol = new FormControl('');
  enlacemenucontrol = new FormControl('');
  //--
  tiposGrupoInternetAPI: GruposInternet_Respuesta;
  tiposGrupoInternet: Array<GruposInternet>;
  //--
  columnasIdiomas: Array<ColumnaIdiomas>;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = true;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  accionselecionada: string = null;
  panelFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos;
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  //compruebaPorDefectovalor:boolean = true;
  EMInicial: string = "";
  translation: any;
  panelSelectorFiltrosvisible: boolean = false;
  formControlListaFiltros: FormControl;
  //
  constructor(private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private cdRef:ChangeDetectorRef,
    private translator: Translator) {

      this.editorRegistroForm = new FormGroup({});
      this.editorFiltrosForm = new FormGroup({});

  }

  @HostBinding('class.col') true;

  
  ngAfterViewInit(){
    //this.initializeForms()
    this.cdRef.detectChanges();

  }
  async ngOnInit() {
    this.translation = this.translator.GetTranslations();
    this.displayedFilters = [this.translation.Nombre, this.translation.ValidaGratuitas, this.translation.TipoDeReserva, this.translation.EnlacesMenu];
    this.filtersToDisplay = this.displayedFilters.slice();
    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(

      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      res => {
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[this._ruta.snapshot.pathFromRoot.length - 1].url.forEach(elemento => {
          ruta = ruta + elemento.path + "/";
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(ruta, 'VER');
        if (!this.permisoFormulario) { this.router.navigate(['/portada']) };
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(ruta, 'NUEVO');
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(ruta, 'MODIFICAR');
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(ruta, 'ELIMINAR');
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(ruta, 'DUPLICAR');
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({ accion: "borrar", texto: "Borrar", funcion: "BorrarSeleccionados" });
        }
      }
    );
    //Seguimos
    //this.cargarDatos();
    this.resetearFiltrosActivos();

/*
    this.editorRegistroForm = new FormGroup({
      nombre: new FormControl(  ['', Validators.required] ),
      descripcion: new FormControl(  [''] ),
      chCodigo: new FormControl(  [''] ),
      maximo: new FormControl(  ['', Validators.pattern('^-?[0-9]*$')] ),
      minimo: new FormControl(  ['', Validators.pattern('^-?[0-9]*$')] ),
      orden: new FormControl(  ['', Validators.pattern('^[0-9]*$')] ),
      validargratuita: new FormControl(  [''] )
    });
*/
    
    this.editorRegistroForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      descripcion: [''],
      chCodigo: [''],
      maximo: ['', Validators.pattern('^-?[0-9]*$')],
      minimo: ['', Validators.pattern('^-?[0-9]*$')],
      orden: ['', Validators.pattern('^[0-9]*$')],
      validargratuita: [''],
    });
    

    /*
    this.editorFiltrosForm = new FormGroup({
      nombre: new FormControl( [''] ),
      validargratuita: new FormControl( [''] ),
      tipoControl: new FormControl( [''] ),
      tipoControlEM: new FormControl( [''] )
    });
    */
    
    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [''],
      validargratuita: [''],
      tipoControl: [''],
      tipoControlEM: ['']
    });
    
   
    this.formControlListaColumnas = new FormControl(this.displayedColumns);

  }

  // un getter para facilitar el acceso a los campos del form
  get f() { return this.editorRegistroForm.controls; } //Editor
  get fFiltro() { return this.editorFiltrosForm.controls; } //Filtros

  model = new traspasomodelo();

  AnadirRegistro() {
    this.model = new traspasomodelo();
    //Ponemos los idiomas a reset
    this.editorListaIdiomas = new Array<IdiomaconURL>();
    this.tiposIdiomas.forEach(idioma => {
      this.editorListaIdiomas.push(<IdiomaconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null });
    });
    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.ValidarGratuitas = false;
    this.EMInicial = "";

  }

  editar(item) {

    let objtraspaso = new traspasomodelo();
    //this.compruebaPorDefectovalor = this.compruebaPorDefecto(item.PorDefecto);
    objtraspaso.pkId = item.pkId;
    objtraspaso.Nombre = item.Nombre;
    objtraspaso.Descripcion = item.Descripcion;
    objtraspaso.chCodigo = item.chCodigo;
    objtraspaso.Minimo = item.Minimo;
    objtraspaso.Maximo = item.Maximo;
    objtraspaso.Orden = item.Orden;
    objtraspaso.TipoReservaId = item.TipoReservaId;
    objtraspaso.Idioma = item.Idioma;
    objtraspaso.ValidarGratuitas = this.globales.aBooleano(item.ValidarGratuitas);
    //EnlaceMenu
    objtraspaso.EnlaceMenuId = this.traeEnlacesMenuxGInternetID(item.pkId, true);
    this.EMInicial = objtraspaso.EnlaceMenuId == null ? "" : objtraspaso.EnlaceMenuId;
    //IDIOMAS
    this.editorListaIdiomas = new Array<IdiomaconURL>();
    this.tiposIdiomas.forEach(idioma => {
      let idiomafiltrado = item.Idiomas.filter(x => x.CodIdioma == idioma.CodIdioma);
      if (idiomafiltrado.length > 0) {
        this.editorListaIdiomas.push(<IdiomaconURL>{ CodIdioma: idioma.CodIdioma, Nombre: idiomafiltrado[0].Nombre, Descripcion: idiomafiltrado[0].Descripcion, });
      }
      else {
        this.editorListaIdiomas.push(<IdiomaconURL>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null });
      }
    });

    this.model = objtraspaso;
    //Cargamos entradas especificas de Taller y de Recinto
    //Last Touches ;)
    this.editando = true;
    this.alertaServicio.limpiar();
    this.cdRef.detectChanges();

  }

  guardar() {
    //TODO, de momento no se guarda hay que cambiar todos los modelos y lo que se pasa
    //y el procedimiento (no es motivo grupo) WOIT
    let insertando = this.model.pkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el Pkid no sea null (estariamos insertando)
      let objtraspaso = new traspasomodelo;
      if (this.model.pkId != null) {
        objtraspaso = this.dataSource.data.map<traspasomodelo>(function (item) {
          return {
            pkId: item.pkId,
            Nombre: item.Nombre,
            Descripcion: item.Descripcion,
            chCodigo: item.chCodigo,
            Minimo: item.Minimo,
            Maximo: item.Maximo,
            Orden: item.Orden,
            Idioma: item.Idioma,
            TipoReservaId: item.TipoReservaId,
            ValidarGratuitas: globales.aBooleano(item.ValidarGratuitas),
            EnlaceMenuId: "",
            Idiomas: null,
            deserialize: null
          }
        }).find(item => item.pkId == this.model.pkId);
      }

      objtraspaso.Nombre = this.model.Nombre;
      objtraspaso.Descripcion = this.model.Descripcion;
      objtraspaso.chCodigo = this.model.chCodigo;
      objtraspaso.Minimo = this.model.Minimo;
      objtraspaso.Maximo = this.model.Maximo;
      objtraspaso.Orden = this.model.Orden;
      objtraspaso.TipoReservaId = this.model.TipoReservaId;
      objtraspaso.ValidarGratuitas = this.model.ValidarGratuitas;
      objtraspaso.EnlaceMenuId = this.model.EnlaceMenuId;
      objtraspaso.Idioma = this.model.Idioma;
      objtraspaso.Idiomas = this.editorListaIdiomas;

      //Salvar a API
      this.servicioAPI.insUpdGruposInternet(
        this.model.pkId,
        objtraspaso.Nombre,
        objtraspaso.Descripcion,
        objtraspaso.chCodigo,
        objtraspaso.Minimo,
        objtraspaso.Maximo,
        objtraspaso.Orden,
        objtraspaso.TipoReservaId,
        globales.aBit(objtraspaso.ValidarGratuitas).toString(),
        objtraspaso.EnlaceMenuId,
        objtraspaso.Idioma,
        objtraspaso.Idiomas)
        .pipe(first())
        .subscribe(
          data => {
            this.respuestaAPI = new GruposInternet_Respuesta().deserialize(data);
            if (this.respuestaAPI.DatosResult != null) {
              //Vamos a meter los Enlaces Menu.
              if (this.EMInicial == "") {
                this.guardarEMconGI(this.model.EnlaceMenuId, this.respuestaAPI.DatosResult.toString(), true);
              }
              else {
                if (this.EMInicial != this.model.EnlaceMenuId) {
                  //Quitamos el anterior
                  this.guardarEMconGI(this.EMInicial, this.respuestaAPI.DatosResult.toString(), false);
                  //Metemos el actual
                  this.guardarEMconGI(this.model.EnlaceMenuId, this.respuestaAPI.DatosResult.toString(), true);
                }
              }
              this.alertaServicio.success("Datos Guardados Correctamente")
              if (insertando) {

                this.cargarDatosAsync();
              }
              else {
                this.cargarDatosAsync();
              }
              this.editando = false;
            }
            else {
              this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              this.editando = false;
            }

          },
          error => {
            this.alertaServicio.error(error);
            this.editando = false;
          }
        );

    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.pkId != null) {//Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo = this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog()
    }
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (this.seleccion.selected == null || this.seleccion.selected.length == 0) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo = this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
      }
      else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo = this.translation.VaAProcederAlBorradoDe + this.seleccion.selected.length.toString() + this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }

  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(this.columnsToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex(x => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
      }
    }
  }

  traeNombreReservaxID(id) {
    if (id != null && this.tiposTipoReserva != null && this.tiposTipoReserva.length > 0) {
      let respuesta = this.tiposTipoReserva.filter(x => x.pkId == id)
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
    }else{
      return null
    }
  }


  traeIdiomaID(id) {
    if (id != null && this.tiposIdiomas != null && this.tiposIdiomas.length > 0) {
      let respuesta = this.tiposIdiomas.filter(x => x.PkId == id && x.Activo == "1")
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].CodIdioma;
    }else{
      return null
    }
  }

  //En realidad ya tenemos guardado GI, no controlamos el error en la actualizacion de EM
  ///tipo: true añadir el GI/ false quitar el GI
  guardarEMconGI(enlace: string, grupo: string, tipo: boolean): void {
    //Solo va a devolver uno [0]
    let enlacemenutemporal: EnlaceMenu = this.tiposEnlaceMenu.filter(x => x.pkid.toString().trim() == enlace)[0];
    let gruposinternet = enlacemenutemporal.GruposInternetAsociados.split(',');
    let GIAsociados: string = "";
    let posicionGI = gruposinternet.indexOf(grupo);
    if (posicionGI < 0) {
      if (tipo) { gruposinternet.push(grupo); }
    }
    else {
      if (!tipo) {
        gruposinternet.splice(posicionGI, 1);
      }
    }
    GIAsociados = gruposinternet.join();
    enlacemenutemporal.GruposInternetAsociados = GIAsociados;
    //Insertamos EM en API
    this.servicioAPI.insUpdEnlacesMenu(this.enlacemenu2peticionInsUpdEnlacesMenu(enlacemenutemporal))
      .pipe(first())
      .subscribe(
        data => {
          this.respuestaAPI = new GruposInternet_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            //this.alertaServicio.success("Datos Guardados Correctamente")
            //this.cargarDatosAsync();
            //this.editando = false;
          }
          else {
            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
            //this.editando = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          //this.editando = false;
        }
      );
  }

  enlacemenu2peticionInsUpdEnlacesMenu(enlace: EnlaceMenu): peticion_Insercion_Upd_EnlacesMenu {
    return {
      conexionId: null,
      clienteAPI: null,

      pkid: enlace.pkid,
      chNombre: enlace.chNombre,
      chDescripcion: enlace.chDescripcion,
      iSinFecha: enlace.iSinFecha,
      iDiasValidezSinFecha: enlace.iDiasValidezSinFecha,
      chCalendario: enlace.chCalendario,
      iAudioguia: enlace.iAudioguia,
      iVerCodigosPromocionales: enlace.iVerCodigosPromocionales,
      iActivo: enlace.iActivo,
      iVisible: enlace.iVisible,
      dtTiempoVenta: enlace.dtTiempoVenta,
      dtFechaMinVenta: enlace.dtFechaMinVenta,
      dtTiempoReservar: enlace.dtTiempoReservar,
      dtFechaMinReserva: enlace.dtFechaMinReserva,
      iNivel: enlace.iNivel,
      iPadre: enlace.iPadre,
      iOrden: enlace.iOrden,
      chSeleccionProductos: enlace.chSeleccionProductos,
      chBotones: enlace.chBotones,
      iTipoEnlace: enlace.iTipoEnlace,
      chTablaTarifas: enlace.chTablaTarifas,
      iMaximoGratuitas: enlace.iMaximoGratuitas,
      iValidarGratuitas: enlace.iValidarGratuitas,
      iDiasCalendario: enlace.iDiasCalendario,
      iTiempoVentaMin: enlace.iTiempoVentaMin,
      chTiempoVentaMinTipo: enlace.chTiempoVentaMinTipo,
      iTiempoVentaMax: enlace.iTiempoVentaMax,
      chTiempoVentaMaxTipo: enlace.chTiempoVentaMaxTipo,
      iTiempoReservaMin: enlace.iTiempoReservaMin,
      chTiempoReservaMinTipo: enlace.chTiempoReservaMinTipo,
      iTiempoReservaMax: enlace.iTiempoReservaMax,
      chTiempoReservaMaxTipo: enlace.chTiempoReservaMaxTipo,
      dtSinFechaHasta: enlace.dtSinFechaHasta,
      iCheckAcepto: enlace.iCheckAcepto,
      iLogueo: enlace.iLogueo,
      iTodasMisReservas: enlace.iTodasMisReservas,
      iTodasMisCompras: enlace.iTodasMisCompras,
      iNoMostrarPrecioGratuitas: enlace.iNoMostrarPrecioGratuitas,
      iEsCliente: enlace.iEsCliente,
      iAsignarAgrupadas: enlace.iAsignarAgrupadas,
      iTotalizarAgrupadas: enlace.iTotalizarAgrupadas,
      iSolicitaVerificarRegistro: enlace.iSolicitaVerificarRegistro,
      iSolicitaFactura: enlace.iSolicitaFactura,
      chTiposClientes: enlace.chTiposClientes,
      iRecorrido: enlace.iRecorrido,
      GruposInternetAsociados: enlace.GruposInternetAsociados,
      DatosEMListados: enlace.DatosEMListados,
      CaracteristicasAdicionales: enlace.CaracteristicasAdicionales
    };
  }
  //true = devuelve el ID, false, null, ... = devuelve nombre
  traeEnlacesMenuxGInternetID(id, tipo?: boolean) {
    if (id && this.tiposEnlaceMenu && this.tiposEnlaceMenu.length > 0) {
      /* const respuesta = this.tiposEnlaceMenu.filter(x => x.GruposInternetAsociados.split(',').indexOf(id.trim()) >= 0); */
      let respuesta: any;
      for (let x = 0; this.tiposEnlaceMenu.length > x; x++) {
        if (this.tiposEnlaceMenu[x].GruposInternetAsociados) {
          respuesta = this.tiposEnlaceMenu[x].GruposInternetAsociados.split(',').indexOf(id.trim());
          if (respuesta) {
            break;
          }
        }
      }

      if (tipo != true) {

        return respuesta == null || respuesta.length == 0 ? null : respuesta[0].chNombre;
      } else {
        return respuesta == null || respuesta.length == 0 ? null : respuesta[0].pkid.toString().trim();
      }
    }
  }

  traeEnlacesMenuxID(id) {
    if (id != null && this.tiposEnlaceMenu != null && this.tiposEnlaceMenu.length > 0) {
      let respuesta = this.tiposEnlaceMenu.filter(x => x.pkid == id)
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].chNombre;
    }else{
      return null
    }
  }
  cambiaNombre(evento, idioma) //idioma
  {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Nombre = evento;
  }
  cambiaDescripcion(evento, idioma) //Idioma
  {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Descripcion = evento;
  }

  formateaIdioma(idioma: Array<IdiomaGR>, columna: ColumnaIdiomas) {
    try {
      return columna.textoHeader.trim().toLowerCase() == 'nombre' ? idioma.filter(x => x.CodIdioma == columna.CodIdioma)[0].Nombre : idioma.filter(x => x.CodIdioma == columna.CodIdioma)[0].Descripcion;
    }
    catch (e) {
      return null
    }

  }

  compruebadrops(): boolean {
    if (this.model.TipoReservaId == null || this.model.TipoReservaId.trim() == "" ||
      this.model.EnlaceMenuId == null || this.model.EnlaceMenuId.trim() == "") { return false; } else { return this.editorRegistroForm.valid; }
  }
  // compruebaPorDefecto(valor:string)
  // {
  //   if(!(valor.trim().toLowerCase() == "true" || valor.trim().toLowerCase() == "1")){
  //   return this.dataSource != null && this.dataSource.data != null && this.dataSource.data.length != 0 &&
  //   this.dataSource.data.some(x => x.PorDefecto.trim() == "1" || x.PorDefecto.toLocaleLowerCase().trim()  == "true")}
  //   else {return false;}
  // }


  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    await this.cargarDatosAsync().then(
      respuesta => {
        let siono = new Boolean(respuesta);
        if (siono) {
          let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales
          if (filtro != null) {

            if (filtro.Nombre != null && filtro.Nombre.trim() != "") {
              datostemporales = datostemporales.filter(x => x.Nombre.indexOf(filtro.Nombre.trim()) >= 0);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
            }
            this.fFiltro["nombre"].setValue(filtro.Nombre.trim());

            if (filtro.ValidaGratuita != null && filtro.ValidaGratuita.trim() != "") {
              datostemporales = datostemporales.filter(x => x.ValidarGratuitas.trim().toLowerCase() == (filtro.ValidaGratuita.trim().toLowerCase() == "si" ? "true" : "false"));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.ValidaGratuita = filtro.ValidaGratuita.trim();
            }
            this.fFiltro["validargratuita"].setValue(filtro.ValidaGratuita.trim());

            if (filtro.CodigoTipoReserva != null && filtro.CodigoTipoReserva != "") {
              datostemporales = datostemporales.filter(x => x.TipoReservaId == filtro.CodigoTipoReserva);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.CodigoTipoReserva = filtro.CodigoTipoReserva;
            }
            this.fFiltro["tipoControl"].setValue(filtro.CodigoTipoReserva);

            if (filtro.EnlaceMenu != null && filtro.EnlaceMenu.trim() != "") {
              datostemporales = datostemporales.filter(x => this.traeEnlacesMenuxGInternetID(x.pkId, true) == filtro.EnlaceMenu);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.EnlaceMenu = filtro.EnlaceMenu;
            }
            this.fFiltro["tipoControlEM"].setValue(filtro.EnlaceMenu);
          }
          else {
            if (this.fFiltro["nombre"].value != null && this.fFiltro["nombre"].value.trim() != "") {
              datostemporales = datostemporales.filter(x => x.Nombre.indexOf(this.fFiltro["nombre"].value.trim()) >= 0);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = this.fFiltro["nombre"].value;
            }
            if (this.fFiltro["validargratuita"].value != null && this.fFiltro["validargratuita"].value.trim() != "") {
              datostemporales = datostemporales.filter(x => x.ValidarGratuitas.trim().toLowerCase() == (this.fFiltro["validargratuita"].value.trim().toLowerCase() == "si" ? "true" : "false"));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.ValidaGratuita = this.fFiltro["validargratuita"].value;
            }
            if (this.fFiltro["tipoControl"].value != null && this.fFiltro["tipoControl"].value != "") {
              datostemporales = datostemporales.filter(x => x.TipoReservaId == this.fFiltro["tipoControl"].value);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.CodigoTipoReserva = this.fFiltro["tipoControl"].value;
              //Reseteamos Filtros que sean Combos
              this.fFiltro["tipoControl"].reset();
            }

            if (this.fFiltro["tipoControlEM"].value != null && this.fFiltro["tipoControlEM"].value != "") {
              datostemporales = datostemporales.filter(x => this.traeEnlacesMenuxGInternetID(x.pkId, true) == this.fFiltro["tipoControlEM"].value);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.EnlaceMenu = this.fFiltro["tipoControlEM"].value;
              //Reseteamos Filtros que sean Combos
              this.fFiltro["tipoControlEM"].reset();
            }
          }

          this.dataSource.data = datostemporales;

          if (this.filtrosactivosModelo.textoGeneral != null && this.filtrosactivosModelo.textoGeneral.trim() != "") {
            this.dataSource.filter = this.filtrosactivosModelo.textoGeneral.trim().toLowerCase();
          }
          else {
            //Solo borramos el input text, el filtro se actualiza Ok
            //this.filtrogeneral.nativeElement.value = '';
          }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        }
        else {
          //No hemos podido cargar los datos
        }

      },
      error => {
        //No hemos podido cargar los datos
      });
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre = filtro == null || filtro == "Nombre" ? "" : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.ValidaGratuita = filtro == null || filtro == "ValidaGratuita" ? "" : this.filtrosactivosModelo.ValidaGratuita;
    this.filtrosactivosModelo.CodigoTipoReserva = filtro == null || filtro == "TipoReserva" ? null : this.filtrosactivosModelo.CodigoTipoReserva;
    this.filtrosactivosModelo.EnlaceMenu = filtro == null || filtro == "EnlaceMenu" ? null : this.filtrosactivosModelo.EnlaceMenu;
    this.filtrosactivosModelo.textoGeneral = filtro == null || filtro == "General" ? "" : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
    }

  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if ((filtros.Nombre != null && filtros.Nombre.trim() != "") || (filtros.ValidaGratuita != null && filtros.ValidaGratuita.trim() != "") ||
      filtros.CodigoTipoReserva != null || filtros.EnlaceMenu != null ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != "")) return true; else return false;
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.seleccion.clear() :
      this.dataSource.data.forEach(row => this.seleccion.select(row));
  }
  ///Validador


  ///Funcionalidades

  cargarDatos() {
    //Cargamos Datos Asincronamente

  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    var tiposUsuariotmp;
    await this.servicioAPI.getIdiomasAsync(null, null, '1')
      .then(
        data => {
          this.tiposIdiomasAPI = new Idiomas_Respuesta().deserialize(data);
          if (this.tiposIdiomasAPI.DatosResult != null) {
            this.tiposIdiomas = this.tiposIdiomasAPI.DatosResult.ListaIdiomas;
          }
          else {
            this.alertaServicio.error(this.tiposIdiomasAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getTiposReservaAsync()
      .then(
        data => {
          this.tiposTipoReservaAPI = new tipos_reserva_Respuesta().deserialize(data);
          if (this.tiposTipoReservaAPI.DatosResult != null) {
            this.tiposTipoReserva = this.tiposTipoReservaAPI.DatosResult.listaTipoReserva;

          }
          else {
            this.tiposTipoReserva = [];
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getEnlacesMenuAsync()
      .then(
        data => {
          this.tiposEnlaceMenuAPI = new DatosResult_EnlaceMenu().deserialize(data);
          if (this.tiposEnlaceMenuAPI.LstEnlacesMenu != null) {
            this.tiposEnlaceMenu = this.tiposEnlaceMenuAPI.LstEnlacesMenu;
          }
          else {
            this.tiposEnlaceMenu = [];
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getGruposInternetAsync()
      .then(
        data => {
          this.tiposGrupoInternetAPI = new GruposInternet_Respuesta().deserialize(data);
          if (this.tiposGrupoInternetAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.tiposGrupoInternetAPI.DatosResult.listaGrupoInternet);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            resultado = true;
            //Idiomas
            this.columnasIdiomas = new Array<ColumnaIdiomas>();
            this.displayedColumns = ['Acciones', 'Nombre', 'Descripcion', 'Minimo', 'Maximo', 'Orden',
              'TReserva', 'ValidarGratuitas', 'EMenu'];
            this.columnsToDisplay = ['Acciones', 'Nombre', 'Descripcion', 'Minimo', 'Maximo', 'Orden',
              'TReserva', 'ValidarGratuitas','EMenu'];
            //No sabemos si todos los elementos tiene todos los idiomas, ni siquiera si los comparten, nos vemos obligados a recorrerlos
            //   para saber las columnas que necesitamos
            this.tiposGrupoInternetAPI.DatosResult.listaGrupoInternet.forEach(elementoGR => {
              if (elementoGR.Idiomas !== null) {
                elementoGR.Idiomas.forEach(elementoI => {
                  if (!(this.columnasIdiomas.filter(x => x.CodIdioma == elementoI.CodIdioma).length > 0)) {
                    this.columnasIdiomas.push(<ColumnaIdiomas>({ textoHeader: 'Nombre', CodIdioma: elementoI.CodIdioma }));
                    this.displayedColumns.push(elementoI.CodIdioma + '_Nombre');
                    this.columnsToDisplay.push(elementoI.CodIdioma + '_Nombre');
                    this.columnasIdiomas.push(<ColumnaIdiomas>({ textoHeader: 'Descripcion', CodIdioma: elementoI.CodIdioma }));
                    this.displayedColumns.push(elementoI.CodIdioma + '_Descripcion');
                    //No metemos la descripcion en las columnas a mostrar
                  }
                });
              }
            });
            //this.columnsToDisplay = this.displayedColumns.slice();
            //F Idiomas
          }
          else {
            this.alertaServicio.error(this.tiposGrupoInternetAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );

    return resultado;
  }

  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): GruposInternet {
    let respuesta = new GruposInternet;
    // respuesta.Activo = item.Activo?"True":"False";
    // respuesta.Nombre = item.Nombre;
    // respuesta.subGrupoRecinto = item.subGrupoRecinto?"True":"False";
    // respuesta.PkId = item.PkId;
    // respuesta.GrupoRecintosId = item.GrupoRecintosId;
    // respuesta.ControlarAforo = item.ControlarAforo?"True":"False";
    // respuesta.ControlarAforoReserva = item.ControlarAforoReserva?"True":"False";
    // respuesta.TipoAforo = item.TipoAforo;
    // respuesta.AforoMaximo = item.AforoMaximo;
    // respuesta.LimiteAforo = item.LimiteAforo;
    // respuesta.HoraInicio = item.HoraInicio;
    // respuesta.HoraFin = item.HoraFin;
    // respuesta.PorDefecto = item.PorDefecto?"True":"False";
    // respuesta.MinimoAforo = item.MinimoAforo;
    // respuesta.Taller = item.Taller?"True":"False";
    // respuesta.Butacas = item.Butacas?"True":"False";
    // respuesta.MostrarInternet = item.MostrarInternet?"True":"False";
    // respuesta.MaximoInternet = item.MaximoInternet;
    // respuesta.GrupoActividadId = item.GrupoActividadId;
    // respuesta.CodigoTipoControl = item.CodigoTipoControl;
    // respuesta.Secciones = item.Secciones;
    // respuesta.Shape  = item.Shape;
    // respuesta.Coords = item.Coords;
    // respuesta.OrdenFilas = item.OrdenFilas;
    // respuesta.OrdenButacas = item.OrdenButacas;
    // respuesta.Numeracion = item.Numeracion;
    // respuesta.NumSesion = item.NumSesion?"True":"False";
    // respuesta.TiempoExtra = item.TiempoExtra;

    // respuesta.Idiomas = this.editorListaIdiomas;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: this.ko_dialogo }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI.delGruposInternet(
          this.model.pkId)
          .pipe(first())
          .subscribe(
            data => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente")
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== this.model.pkId);
              }
              else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => { this.alertaServicio.error(error); }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: this.ko_dialogo }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        var resultado: string = "";
        this.seleccion.selected.forEach(
          element => {
            //Recorremos los seleccionados y vamos borrando
            this.servicioAPI.delGruposInternetAsync(
              element.pkId)
              .then(
                data => {
                  //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
                  if (this.respuestaAPI.DatosResult != null) {
                    this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== element.pkId);
                  }
                  else {
                    resultado += this.respuestaAPI.Mensajes[0].DescripcionMensaje + " <BR />";
                  }
                },
                error => { resultado += error + " <BR />"; }
              );
          });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        }
        else {
          this.alertaServicio.success("Datos Borrados Correctamente");
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.alertaServicio.limpiar();
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translation.BorrarCliente, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        this.servicioAPI.delGruposInternet(
          pkId)
          .pipe(first())
          .subscribe(
            data => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente")
                this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== pkId);
              }
              else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => { this.alertaServicio.error(error); }
          );
        this.editando = false;
      }
    });
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(this.filtersToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex(x => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
      }
    }
  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

}

