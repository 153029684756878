import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { name, version } from "package.json";
import { filter, switchMap } from "rxjs/operators";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { LayoutService } from "../../services/layouts/layout.service";
import { Translator } from "../../services/translator/translator.service";

@Component({
  selector: "app-home-layout",
  templateUrl: "./home-layout.component.html",
  styleUrls: ["./home-layout.component.scss"],
})
export class HomeLayoutComponent implements OnInit {
  mobileQuery: MediaQueryList;
  media: MediaMatcher;
  private _mobileQueryListener: () => void;
  changeDetectorRef: ChangeDetectorRef;

  opened: boolean = true;
  sectionTitle: string = "";
  currentYear: Date;
  translation: any;
  public version: string = version;
  public appname: string = name;

  @ViewChild("sidenav") mysidenav: MatSidenav;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private autentificador: AuthenticationService,
    private _ruta: ActivatedRoute,
    private translator: Translator,
    private _layoutservices: LayoutService
  ) {
    this.changeDetectorRef = changeDetectorRef;
    this.media = media;
    this.currentYear = new Date();
  }

  initConfig() {
    if (this._ruta.firstChild)
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          switchMap(() => this._ruta.firstChild.data)
        )
        .subscribe((data) => {
          if (data && data && data.SectionTitle) {
            this.sectionTitle = this.translation[data.SectionTitle];
          }
        });
  }

  async initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  ngOnInit() {
    this.initializeTranslate();
    this.initConfig();
    this.mobileQuery = this.media.matchMedia("(max-width: 600px)");
    this._mobileQueryListener = () => {
      if (this.mobileQuery.matches) {
        this._layoutservices.sidebar_opened.emit(false);
        this.mysidenav.close();
        this.opened = false;
      } else {
        this._layoutservices.sidebar_opened.emit(true);
        this.mysidenav.open();
        this.opened = true;
      }
      this.changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(() => this._ruta.firstChild.data)
      )
      .subscribe((data) => {
        if (data && data && data.SectionTitle) {
          this.sectionTitle = this.translation[data.SectionTitle];
        }
      });
  }

  menutoogle() {
    if (this.mysidenav.opened) {
      this._layoutservices.sidebar_opened.emit(false);
      this.mysidenav.close();
      this.opened = false;
    } else {
      this._layoutservices.sidebar_opened.emit(true);
      this.mysidenav.open();
      this.opened = true;
    }
  }
  sidenavClose() {
    this.mysidenav.close();
    this.opened = false;
    this._layoutservices.sidebar_opened.emit(false);
  }
  logout() {
    this.autentificador.logout();
  }

  toTop() {
    document.getElementById("eltope").scrollTop = 0;
  }
}
