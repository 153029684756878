import { Injectable } from '@angular/core';
//import { AppConfiguration } from 'src/app/app.configuration';
import { ApiTokenService } from 'src/app/shared/services/api/token/api-token.service';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Actividad } from '@sharedV11/classes/mapas/actividad.model';
import moment from 'moment';
import { catchError, map } from 'rxjs/operators';
import { GlobalService } from '../global/global.service';
import { IdiomaGR } from '@sharedV11/classes/tarifaResponse/idiomaGR';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class EspectaculosService {

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private espectaculoSeleccionadoSubject: BehaviorSubject<any>;
  public espectaculoSeleccionado: Observable<any>;
  public sesionSeleccionada = {
    espectaculoId : 0,
    sesionId: 0,
    fechaSesion : "",
    horaInicioSesion : "",
    GrupoRecinto : "",
    chPathMapaSVG : ""

  };



  private espectaculosSubject: BehaviorSubject<any>;
  public espectaculos$: Observable<any>;

  constructor(private config: ApiTokenService, private http: HttpClient, private tokenService: ApiTokenService,  private global:GlobalService) {
    this.espectaculoSeleccionadoSubject = new BehaviorSubject(undefined);
    this.espectaculoSeleccionado = this.espectaculoSeleccionadoSubject.asObservable();

    this.espectaculosSubject = new BehaviorSubject([]);
    this.espectaculos$ = this.espectaculosSubject.asObservable();
  }

  public get espectaculoSeleccionadoValue(): Actividad {
    return this.espectaculoSeleccionadoSubject.value;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  
  setEspectaculoSeleccionadoValue(espectaculo) {
    this.espectaculoSeleccionadoSubject.next(espectaculo);
  }



  findEspectaculoByPkId(pkId:string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = ""; 
    peticion.Descripcion = ""; 
    peticion.Orden = ""; 
    //peticion.pkId =pkId;
    let filters = [{ name: "pkId", value: pkId, translation: "", db: "" }];
    peticion.filters = filters;
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetEspectaculos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }


  insEspectaculo(espectaculo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = espectaculo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsUpdEspectaculos', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarCustomanUsuarios '))
    );
  }
  updEspectaculo(espectaculo,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = espectaculo;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsUpdEspectaculos', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('ActualizarCustomanUsuarios '))
    );
  }


  getEspectaculos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination
      peticion.filters = filters
    }

    //Enviamos la peticion
    let respuesta = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetEspectaculos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
  }


  getSesionesEspectaculos(pagination?, filters?): Observable<any> {
    // Montamos la peticion Listado
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO
    if (pagination) {
      peticion.paginator = pagination
      peticion.filters = filters
    }
    peticion.Activa = "2";
    //Enviamos la peticion
    let respuesta = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'VentaTaquilla/GetSesiones', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return respuesta;
  }

  findSesionByPkId(pkId:string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = ""; 
    peticion.Descripcion = ""; 
    peticion.Orden = ""; 
    peticion.Activa = "2";
    //peticion.pkId =pkId;
    let filters = [{ name: "pkId", value: pkId, translation: "", db: "" }];
    peticion.filters = filters;
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'VentaTaquilla/GetSesiones', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }


  //updSesionButaca
  //insSesionButaca

  insSesionButaca(session,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = session;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.dtFechaInicio = session.dtFecha;
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/CrearSesiones', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarSesionButaca '))
    );
  }

  updSesionButaca(session,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = session;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.dtFechaInicio = session.dtFecha;
    peticion.Funcion = mode;
    //Enviamos la peticion
    let actdesact = "";
    
    if (peticion.Activa==true) 
      actdesact="ActivarSesiones" 
    else 
      actdesact ="DesactivarSesiones";

    return this.http.post<any>(this.tokenService.getConfig('API_URL') + `Customan/${actdesact}`, JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('ActualizarSesionButaca '))
    );
  }


  getSesionesFechasDisponibles(
    espectaculoId = 1,
    grupoRecintoId = 1,
    fechaDesde,
    fechaHasta
  ) {
    const conexionId = JSON.parse(localStorage.getItem('conexionId'));
    const body = {
      ConexionIacpos: conexionId,
      Idioma: 'es',
      EspectaculoId: espectaculoId,
      GrupoRecintoId: grupoRecintoId,
      FechaDesde: fechaDesde,
      FechaHasta: fechaHasta,
      GroupbyDias: 'HORAS',
      GruposInternet: 'TODOS',
      EnlaceMenuId: 'TODOS',
      Plataforma: '2'
    };
    return this.http.post(
      `${this.config.getConfig('API_URL')}ButacasDisponibleFechas`,
      body
    );
  }

  public get espectaculos() {
    return this.espectaculosSubject.value;
  }

  public setEspectaculos(espectaculos) {
    this.espectaculosSubject.next(espectaculos);
  }

  public pushEspectaculo(espectaculo) {
    if (this.comprobarSiExisteEspectaculo(espectaculo)) {
      return;
    }
    this.espectaculos.push(espectaculo);
    this.setEspectaculos(this.espectaculos);
  }

  public popEspectaculo() {
    this.setEspectaculos(this.espectaculos.pop());
  }

  deleteEspectaculo(espectaculo) {
    this.setEspectaculos(
      this.espectaculos.filter(
        e => e.EspectaculosId !== espectaculo.EspectaculosId
      )
    );
  }

  deleteAllEspectaculos() {
    this.setEspectaculos([]);
  }

  comprobarSiExisteEspectaculo(espectaculo: any) {
    return this.espectaculos.find(
      e =>
        e.EspectaculosId === espectaculo.EspectaculosId &&
        e.fecha === espectaculo.fecha &&
        e.hora === espectaculo.hora
    );
  }

  removeEspectaculo(espectaculo) {
    const nuevaLista = this.espectaculos.filter(
      esp =>
        esp.EspectaculosId !== espectaculo.EspectaculosId ||
        esp.fecha !== espectaculo.fecha ||
        esp.hora !== espectaculo.hora
    );
    this.setEspectaculos(nuevaLista);
  }
}
