import {Deserializable} from "./deserializable.model";

export class grupo_tipo_bono_Respuesta implements Deserializable {
    DatosResult?: DatosResult_grupo_tipo_bono;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class GrupoTipoBono implements Deserializable {
    PkId: string;
    NombreGrupo: string;
    Descripcion: string;
    chCodigo: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_grupo_tipo_bono implements Deserializable {
    customanGrupoTipoBono: GrupoTipoBono[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
