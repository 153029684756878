import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { ProfileSaveComponent } from "./profile-save/profile-save.component";

@Component({
  selector: "app-user-profile-v11",
  templateUrl: "./user-profile-v11.component.html",
  styleUrls: ["./user-profile-v11.component.scss"],
})
export class UserProfileV11Component extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = ProfileSaveComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
