import {
  Component,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  OnInit,
  OnDestroy
} from '@angular/core';
import { EntradasService } from '../../servicios/entradas.service';
import { InfoMapasService } from '../../servicios/info-mapas.service';

@Component({
  selector: 'iacpos-slider-precios',
  templateUrl: './slider-precios.component.html',
  styleUrls: ['./slider-precios.component.css']
})
export class SliderPreciosComponent implements AfterViewInit, OnDestroy {
  min = 0;
  max = 91;
  sliderRange = [0, 20];
  @ViewChild('slider', { read: ElementRef, static: false }) slider: ElementRef;

  someKeyboardConfig: any = {
    connect: true,
    start: [this.min, this.max],
    tooltips: [true, true],
    range: {
      min: this.min,
      max: this.max
    },
    step: 1,
    behaviour: 'drag'
  };

  constructor(
    private entradaService: EntradasService,
    private infoMapasService: InfoMapasService
  ) {}

  onChange(changes) {
    this.entradaService.mapaFiltradoPorPrecio(
      this.infoMapasService.recinto,
      this.sliderRange[0],
      this.sliderRange[1]
    );
  }
  ngOnInit(){
    this.sliderRange = [this.entradaService.minFiltro, this.entradaService.maxFiltro];
    this.min = this.entradaService.minFiltro;
    this.max = this.entradaService.maxFiltro;
  }
  ngAfterViewInit() {
    this.func();
  }

  func() {
    if ( this.slider) {
    const connect = this.slider.nativeElement.querySelectorAll('.noUi-connect');
    const classes = ['c-1-color', 'c-2-color', 'c-3-color'];
      for (let i = 0; i < connect.length; i++) {
        connect[i].classList.add(classes[i]);
      }
    }
  }

  ngOnDestroy() {
    this.entradaService.filtroPreparado.next(false);
  }
}
