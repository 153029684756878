<div
  *ngIf="mensaje"
  class="alert"
  [ngClass]="{
    alerta: mensaje,
    'alert-success': mensaje.type === 'success',
    'alert-danger': mensaje.type === 'error'
  }"
>
  {{ mensaje.text }}
</div>
