import {Deserializable} from "./deserializable.model";

export class GruposTipoBono_Respuesta implements Deserializable {
    DatosResult?: DatosResult_GruposTipoBono;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class GruposTipoBono implements Deserializable {
  Id: string;
  Nombre: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_GruposTipoBono implements Deserializable {
  DatosListas: GruposTipoBono[];
  customanGrupoTipoBono: any;

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

