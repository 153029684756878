import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
//import { AuthenticationService } from '@sharedV11/services/authentication/authentication.service';
import { Translator } from "@sharedV11/services/translator/translator.service";

@Injectable({
	providedIn: "root",
})
export class SnackbarInfoService {
	translation;
	constructor(
		private snackBar: MatSnackBar, //private autentificador: AuthenticationService,
		private translator: Translator
	) {
		this.initializeTranslate();
	}

  async initializeTranslate() {

    this.translator.dataLanguage$.subscribe((data) => {
      if (data) {
        this.translation = this.translator.GetTranslations();
      }
    })
  }

	openSnackBar(
		message: string,
		action: string,
		color: string,
		codigo?: any,
		durationToast = 2500
	) {
		if (codigo && codigo != "") {
			var cod = parseInt(codigo);
			//if (cod==-2) this.autentificador.logout();
			if (isNaN(cod)) {
				message = this.translation[codigo];
			} else {
				message = this.translation["CodErr_" + cod];
			}
			//if (!message) message = "Error desconocido";
		} else {
			message = message;
		}
		if (message)
			this.snackBar.open(message, action, {
				duration: durationToast,
				panelClass: [color],
				horizontalPosition: "center",
				verticalPosition: "top",
			});
	}
}
