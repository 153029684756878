import { IdiomaGR } from "../tarifaResponse/idiomaGR";
import { tarifaPromocion } from "../tarifaResponse/tarifaPromocion";

export class traspasomodelo {
  pkId: string;

  Internet: boolean;
  Taquilla: boolean;
  TaqAutomatica: boolean;
  AlaVenta: boolean;
  iTipoEntradaId: string;
  iTipoPromocionId: string;
  FecIniVisita: string;
  FecFinVisita: string;
  NoFecFinVisita: string;
  PrecioPVP: number;
  PorcIva: string;
  Comision: number;
  PorIvaComision: string;
  iDivisaId: string;
  iTipoAbonadoId: string;
  Agrupar: boolean;
  Orden: string;
  Minimo: string;
  Maximo: string;
  iGrupoInternetId: string;
  FecCompraDesde: string;
  FecCompraHasta: string;
  HoraIni: string;
  HoraFin: string;
  iTipoIvaId: string;
  iTipoIvaComisionId: string;
  BaseImponible: any;
  ComisionBI: any;
  DesglosarComision: boolean;
  Lunes: boolean;
  Martes: boolean;
  Miercoles: boolean;
  Jueves: boolean;
  Viernes: boolean;
  Sabado: boolean;
  Domingo: boolean;
  Idiomas: IdiomaGR[];
  TarifaPromocion: tarifaPromocion[];
  chPrefijo: string; //Prefijo Especial

  TipoProductoAsociado: string;
  AsumeIncrementoIVA: boolean;



  deserialize: null
  tipoControlDias: any;
}