import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { SuperClassComponent } from '@sharedV11/super-class-edit/super-class/super-class.component';
import { EditReglasDeNegocioComponent } from './edit-reglas-de-negocio/edit-reglas-de-negocio.component';

@Component({
  selector: 'app-reglas-de-negocio',
  templateUrl: './reglas-de-negocio.component.html',
  styleUrls: ['./reglas-de-negocio.component.scss']
})
export class ReglasDeNegocioComponent extends SuperClassComponent {

  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(
      panelService,
      cdRef
    );
  }


  openPanel() {
    this.customanpanel = EditReglasDeNegocioComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }

}
