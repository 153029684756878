import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_CalendarioTipoIVA_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    id: string;
}

//insUpd
export class peticion_Insercion_Upd_CalendarioTipoIVA {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    TipoIvaId: string;
    Iva: string;
    FechaDesde: string;
    FechaHasta: string;

    Funcion: string;
}

//del
export class peticion_Del_CalendarioTipoIVA {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    entradaId: string;
}

