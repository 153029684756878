import { Pipe, PipeTransform } from '@angular/core';
import { Translator } from './services/translator/translator.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  data: any = {};

  constructor(private translate: Translator) {
    this.loadJson();
  }

  async loadJson() {
    let data = this.translate.GetTranslations();
    this.data = data;
  }

  transform(key: any): any {
    let data_ = this.data;
    return data_[key] || key;
  }

}
