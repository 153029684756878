import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IReglaNegocio, StringNumber } from '@sharedV11/classes/EntradasPromociones/ReglasNegocio/reglas-negocio';
import { IGrupoTarifa, Mode } from '@sharedV11/classes/tarifas/grupostarifas';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { ReglasNegocioService } from '@sharedV11/services/components/entradas-y-promociones/reglas-negocio/reglas-negocio.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { isEqual } from '@sharedV11/utils/lodash';
import { DualListComponent } from 'angular-dual-listbox';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-reglas-de-negocio',
  templateUrl: './edit-reglas-de-negocio.component.html',
  styleUrls: ['./edit-reglas-de-negocio.component.scss']
})

export class EditReglasDeNegocioComponent implements OnInit {
	loading = true;
	private ngUnsubscribe = new Subject();
	@Input() pkId: string;
	@Input() mode: Mode;
	@Input() modificar: boolean;

	translation: any;

	editRegistroForm: FormGroup;

	ReglaNegocio: any;

	//tarifas dual list
	tarifasAsignadas = [];
	tarifasSinAsignar = [];

	// Clientes dual list
	clientesAsignados = [];
	clientesSinAsignar = [];

	//selects
	PVKSelected: boolean;
	IntSelected: boolean;
	AdminSelected: boolean;


	//Dynamic select
	asignadoIdSelectValues = [];
	aplicadoIdSelectValues = [];

	//centros
	centrosSinAsignar = [];
	centrosAsignados = [];
	tipoCentros: any[] = [];


	//Dual List Config
	format = {
		add: "Añadir",
		remove: "Borrar",
		all: "Todos",
		none: "Ninguno",
		direction: DualListComponent.LTR,
		draggable: true,
		locale: "es",
	};

	constructor(
		private panelService: PanelService,
		private fb: FormBuilder,
		private translator: Translator,
		private httpErrorService: HttpErrorsService,
		private snackvar_service: SnackbarInfoService,
		private gsCentros: GlobalServicCentros,
		private servicioAPI: ConectarApiService,
		private reglasNegocioService: ReglasNegocioService,
		public globales: GlobalService,
	) {}

	async ngOnInit(){
		try {
			this.initializeTranslate();
			this.initializeFormWithoutData();
			await this.reglasNegocioService.cargarDatos(),
			await this.loadCentros(),
			this.manageForm(this.pkId, this.mode);

		} catch (err) {
			console.error(err);
		}
	}


	async loadCentros() {
		await this.gsCentros.getCentros();
	}

	get procesosVenta() {
		return [...this.reglasNegocioService.procesosVenta];
	}

	get espectaculos() {
		return [...this.reglasNegocioService.espectaculos];
	}


	async initializeTranslate() {

		this.translator.dataLanguage$.subscribe((data) => {
			if (data) {
				this.translation = this.translator.GetTranslations();
				this.format = {
					add: this.translation.Anadir,
					remove: this.translation.Borrar,
					all: this.translation.Todos,
					none: this.translation.Ninguno,
					direction: DualListComponent.LTR,
					draggable: true,
					locale: "es",
				};
			}
		});
		
	}

	initializeFormWithoutData() {
		this.editRegistroForm = this.fb.group({
			pkId: [""],
			tipoControlPlataforma: ["", [Validators.required]],
			Activa: [false, Validators.required],
			NombreTabla: ["", Validators.required],
			PkIdTabla: ["", Validators.required],
			NombreTablaEntrada: ["", Validators.required],
			iTablaEntradaId: ["", Validators.required],
			TipoPeriodo: ["", Validators.required],
			FechaVisibleInicio: ["", Validators.required],
			FechaVisibleFin: ["", Validators.required],
			Cantidad: [0, Validators.required],
			ProcesoVentaId: [""],
			TablaValidar: [true],
			TipoDocumento: [["*"], Validators.required],
			CantidadPor: ["visita", Validators.required],
			ValidarEspectaculo: [false],
			
			EspectaculoId: [""],
			TodasLasFechas: [false],
			FechaSesion: [""],
			HoraSesion: [""],

			NominarEntradasPromo: [false],
			NominarEntradas: [false]
		});
	}

	manageForm(pkId, mode) {
		if (mode == "I") {
			this.ReglaNegocio = this.editRegistroForm.value;

			//centros
			this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
			this.centrosAsignados = [];

			this.loading = false;
			this.valuedChanged();
		}
		if (pkId != null) {
			this.findReglaNegocio(pkId);
		}
	}

	findReglaNegocio(pkId: StringNumber) {
		this.reglasNegocioService
			.findReglaNegocioByPkId(pkId)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(ReglaNegocioAPI) => {
					let reglaNegocio: IReglaNegocio = ReglaNegocioAPI["DatosResult"]["Lista"][0];

					if (reglaNegocio != null) {
						this.changeValueForm(reglaNegocio);
						this.ReglaNegocio = this.editRegistroForm.value;
						this.ReglaNegocio.CentrosIds = reglaNegocio.CentrosIds;

						this.valuedChanged();

						//centros
						this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
						this.centrosAsignados = this.gsCentros.daTiposCentros(this.ReglaNegocio);

						this.loading = false;
					}
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	actualizarPlataformasSeleccionadas() {
		// 1 - Punto Venta Kore
		// 2 - Internet
		// 3 - Administración

		this.PVKSelected = this.editRegistroForm.controls[
			"tipoControlPlataforma"
		].value.includes("1")
			? true
			: false;

		this.IntSelected = this.editRegistroForm.controls[
			"tipoControlPlataforma"
		].value.includes("2")
			? true
			: false;

		this.AdminSelected = this.editRegistroForm.controls[
			"tipoControlPlataforma"
		].value.includes("3")
			? true
			: false;
	}

	actualizarAsignadoIdSelectValues() {

		this.editRegistroForm.get('PkIdTabla').setValue("");

		const asignedTable = this.editRegistroForm.controls['NombreTabla'].value;

		switch(asignedTable) {
			case 'tbClientes':
					this.asignadoIdSelectValues = [...this.reglasNegocioService.clientes];
				break;

			case 'tbGruposClientes':
					this.asignadoIdSelectValues = [...this.reglasNegocioService.gruposClientes];
				break;
			
			case 'tbTiposClientes':
					this.asignadoIdSelectValues = [...this.reglasNegocioService.tiposClientes];
				break;
		}
	}

	actualizarAplicadoIdSelectValues() {

		this.editRegistroForm.get('iTablaEntradaId').setValue("");

		const asignedTable = this.editRegistroForm.controls['NombreTablaEntrada'].value;

		switch(asignedTable) {
			case 'tbTiposEntrada':
					this.aplicadoIdSelectValues = [...this.reglasNegocioService.entradas];
				break;

			case 'tbGruposTiposEntrada':
					this.aplicadoIdSelectValues = [...this.reglasNegocioService.gruposEntradas];
				break;
			
			case 'tbTipoBono':
					this.aplicadoIdSelectValues = [...this.reglasNegocioService.tiposBonos];
				break;
			
			case 'tbGrupoTipoBono':
					this.aplicadoIdSelectValues = [...this.reglasNegocioService.gruposTiposBonos];
				break;
		}
	
	}

	clearInputAndValidation(formControlName = '') {
		this.editRegistroForm.get(formControlName).clearValidators();
		this.editRegistroForm.get(formControlName).setValue("");
		this.editRegistroForm.get(formControlName).updateValueAndValidity();
	}

	onChangeValidarEspectaculo() {

		const validarEspectaculoValue = this.editRegistroForm.get('ValidarEspectaculo').value;

		if (validarEspectaculoValue) {
			this.editRegistroForm.get('EspectaculoId').setValidators([Validators.required])
		} else {
			this.clearInputAndValidation('EspectaculoId');
			this.clearInputAndValidation('FechaSesion');
			this.clearInputAndValidation('HoraSesion');
		}
	}

	onChangeTodasLasFechasToggle() {
		const todasLasFechasValue = this.editRegistroForm.get('TodasLasFechas').value;
		const validarEspectaculoValue = this.editRegistroForm.get('ValidarEspectaculo').value;

		if (todasLasFechasValue) {
			this.clearInputAndValidation('FechaSesion');
			this.clearInputAndValidation('HoraSesion');
		} else if (validarEspectaculoValue){
			this.editRegistroForm.get('FechaSesion').setValidators([Validators.required]);
			this.editRegistroForm.get('HoraSesion').setValidators([Validators.required]);
		}
	}

	setDate(event, formControl): void {
    this.editRegistroForm.controls[formControl].setValue(
      moment(event.value).format("YYYY/MM/DD 0:00:00")
    );
  }

	onChangeHour() {
		console.log('hour::', this.editRegistroForm.get('HoraSesion').value)
	}

	aFechaddmmyyyy(fecha: string): Date {
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      if (parts[0] != "Invalid date") {
        return new Date(
          parseInt(parts[2].substring(0, 4)),
          parseInt(parts[1]) - 1,
          parseInt(parts[0])
        );
      } else {
        return null;
      }
    }
  }

	getFechaInicio(): Date {
    return this.aFechaddmmyyyy(
      moment(this.editRegistroForm.controls["FechaVisibleInicio"].value).format(
        "DD/MM/YYYY 0:00:00"
      )
    );
  }

	getFechaInicioString(): string {
		return moment(this.editRegistroForm.controls["FechaVisibleInicio"].value).format(
			"DD/MM/YYYY 0:00:00"
		);
	}

	getFechaFin(): Date {
    return this.aFechaddmmyyyy(
      moment(this.editRegistroForm.controls["FechaVisibleFin"].value).format(
        "DD/MM/YYYY 0:00:00"
      )
    );
  }

	getFechaFinString(): string {
		return moment(this.editRegistroForm.controls["FechaVisibleFin"].value).format(
			"DD/MM/YYYY 0:00:00"
		);
	}

	getFechaSesion(): Date {
		return this.aFechaddmmyyyy(
			moment(this.editRegistroForm.controls["FechaSesion"].value).format(
				"DD/MM/YYYY 0:00:00"
			)
		);
	}

	getFechaSesionString(): string {
		return moment(this.editRegistroForm.controls["FechaSesion"].value).format(
			"DD/MM/YYYY"
		);
	}

	changeValueForm(ReglaNegocio: IReglaNegocio) {

		const isCheckValidarEspectaculo = ReglaNegocio.EspectaculoId !== '' && Number(ReglaNegocio.EspectaculoId) > 0;
		

		this.editRegistroForm.get("pkId").setValue(ReglaNegocio.pkId);

		this.editRegistroForm
			.get("Activa")
			.setValue(Number(ReglaNegocio.Activa) === 1 ? true : false);
		
		this.editRegistroForm.get('NombreTabla').setValue(ReglaNegocio.NombreTabla);
		this.actualizarAsignadoIdSelectValues();
		this.editRegistroForm.get('PkIdTabla').setValue(ReglaNegocio.PkIdTabla);
		this.editRegistroForm.get('NombreTablaEntrada').setValue(ReglaNegocio.NombreTablaEntrada);
		this.actualizarAplicadoIdSelectValues();
		this.editRegistroForm.get('iTablaEntradaId').setValue(ReglaNegocio.iTablaEntradaId);
		this.editRegistroForm.get('TipoPeriodo').setValue(ReglaNegocio.TipoPeriodo);
		this.editRegistroForm.get('FechaVisibleInicio').setValue(this.globales.aFecha(ReglaNegocio.FechaVisibleInicio));
		this.editRegistroForm.get('FechaVisibleFin').setValue(this.globales.aFecha(ReglaNegocio.FechaVisibleFin));
		this.editRegistroForm.get('Cantidad').setValue(ReglaNegocio.Cantidad);
		this.editRegistroForm.get('ProcesoVentaId').setValue(ReglaNegocio.ProcesoVentaId);
		this.editRegistroForm.get('CantidadPor').setValue(ReglaNegocio.CantidadPor);
		this.editRegistroForm.get('ValidarEspectaculo').setValue(isCheckValidarEspectaculo);
		this.onChangeValidarEspectaculo();
		this.onChangeTodasLasFechasToggle();
		this.editRegistroForm.get('EspectaculoId').setValue(isCheckValidarEspectaculo ? ReglaNegocio.EspectaculoId : '');

		this.editRegistroForm
		.get('NominarEntradasPromo')
		.setValue(Number(ReglaNegocio.NominarEntradasPromo) === 1 ? true : false)

		this.editRegistroForm
		.get('NominarEntradas')
		.setValue(Number(ReglaNegocio.NominarEntradas) === 1 ? true : false)

		this.editRegistroForm
		.get("TablaValidar")
		.setValue(Number(ReglaNegocio.TablaValidar) === 1 ? true : false);


		const platformsSelected = [];
		const tiposDocumentosSelected = ReglaNegocio.TipoDocumento.split(',');

		if (Number(ReglaNegocio.Taquilla) === 1 ) {
			platformsSelected.push("1");
		}

		if (Number(ReglaNegocio.Internet) === 1 ) {
			platformsSelected.push("2");
		}

		if (Number(ReglaNegocio.IntAdmon) === 1 ) {
			platformsSelected.push("3");
		}


		this.editRegistroForm.get("tipoControlPlataforma").setValue(platformsSelected);
		this.editRegistroForm.get('TipoDocumento').setValue(tiposDocumentosSelected);

		this.actualizarPlataformasSeleccionadas();
	}

	valuedChanged() {
		this.editRegistroForm.valueChanges.subscribe((value) => {
			if (this.ReglaNegocio != null) {

				this.panelService.setDiscardChanges(false);

				const keysForm = Object.keys(value);

				const valuesDismatch = [];

				for (const keyForm of keysForm) {
					const valueForm = value[keyForm];
					const valueObjectEdit = this.ReglaNegocio[keyForm];

					if (
						!isEqual(valueForm, valueObjectEdit)
					) {
						valuesDismatch.push(1);
					}
				}

				if (valuesDismatch.length === 0) {
					this.panelService.setDiscardChanges(true);
				}
			}
		});
	}

	discard() {
		this.changeValueForm(this.ReglaNegocio);
	}

	manageApi(reglaNegocio: IReglaNegocio, mode: Mode) {
		this.loading = true;

		reglaNegocio.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(
			this.centrosAsignados
		);

		switch (mode) {
			case "I":
				//insert
				reglaNegocio.pkId = "0";
				this.InsUpReglaNegocio(reglaNegocio, "I");
				break;
			case "U":
				//update
				this.InsUpReglaNegocio(reglaNegocio, "U");

				break;
			case "D":
				//duplicate
				reglaNegocio.pkId = "0";
				this.InsUpReglaNegocio(reglaNegocio, "I");

				break;

			default:
				break;
		}
	}


	InsUpReglaNegocio(reglaNegocio: IReglaNegocio, mode: Mode) {
		this.reglasNegocioService
			.insUpReglaNegocio(reglaNegocio, mode)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				(response) => {
					this.nextWindow(response);
				},
				(error) => {
					this.httpErrorService.identificarErrores(error);
				}
			);
	}

	destroy() {
		this.panelService.setDiscardChanges(true);
		this.panelService.setClosePanel(null);
	}

	submit() {
		this.editRegistroForm.markAllAsTouched();

		const arrTipoDocumento: string[] = this.editRegistroForm.get('TipoDocumento').value;
		const isCheckValidarEspectaculo: Boolean = this.editRegistroForm.get('ValidarEspectaculo').value;
		const isCheckTodasLasFechas: Boolean = this.editRegistroForm.get('TodasLasFechas').value;
		const HoraSesionValue: string = this.editRegistroForm.get('HoraSesion').value;
		const FechaSesionValue: string = this.getFechaSesionString();

		const fechaSesionFormat = `${FechaSesionValue} ${HoraSesionValue}:00`;

		const fechaSesionToAPI = isCheckTodasLasFechas ? 'ALL' : fechaSesionFormat;

		let reglaNegocio: IReglaNegocio = {
			pkId: this.editRegistroForm.get("pkId").value,

			Taquilla: this.PVKSelected === true ? 1 : 0,

			Internet: this.IntSelected === true ? 1 : 0,

			IntAdmon: this.IntSelected === true ? 1 : 0,

			Activa: this.editRegistroForm.get("Activa").value === true ? 1 : 0,

			ValidarIdentificacion: this.editRegistroForm.get("Activa").value === true ? 1 : 0,

			NombreTabla: this.editRegistroForm.get("NombreTabla").value,

			PkIdTabla: this.editRegistroForm.get("PkIdTabla").value,

			NombreTablaEntrada: this.editRegistroForm.get("NombreTablaEntrada").value,

			iTablaEntradaId: this.editRegistroForm.get("iTablaEntradaId").value,

			TipoPeriodo: this.editRegistroForm.get("TipoPeriodo").value,

			FechaVisibleInicio: this.getFechaInicioString(),

			FechaVisibleFin: this.getFechaFinString(),

			Cantidad: this.editRegistroForm.get("Cantidad").value,

			ProcesoVentaId: this.editRegistroForm.get("ProcesoVentaId").value,

			TablaValidar: this.editRegistroForm.get("TablaValidar").value === true ? 1 : 0,

			TipoDocumento: arrTipoDocumento.length > 0 ? arrTipoDocumento.join(',') : '',

			CantidadPor: this.editRegistroForm.get('CantidadPor').value,

			EspectaculoId: isCheckValidarEspectaculo ? this.editRegistroForm.get('EspectaculoId').value : '',

			FechaSesion: isCheckValidarEspectaculo ? fechaSesionToAPI : '',

			NominarEntradasPromo: this.editRegistroForm.get("NominarEntradasPromo").value === true ? 1 : 0,

			NominarEntradas: this.editRegistroForm.get("NominarEntradas").value === true ? 1 : 0,

			Funcion: 'I',

			CentrosIds: '',
			
		}

		if (this.editRegistroForm.valid) {
			this.manageApi(reglaNegocio, this.mode);
		} else {
			console.log('errors', this.editRegistroForm.errors)
		}
	}

	getErrorMessage(field: string) {
		let fc_pv = this.editRegistroForm.get(field);
		if (fc_pv) {
			if (fc_pv.errors) {
				if (fc_pv.errors.required) {
					return this.translation["CampoRequerido"];
				} else if (field === "Empleado") {
					if (fc_pv.hasError("pattern")) {
						return this.translation["SoloNumeros"];
					}
				} else if (field === "Nombre") {
					if (fc_pv.hasError("pattern")) {
						return this.translation["SoloLetras"];
					}
				}
			}
		}

		return "Error";
	}

	nextWindow(response: any) {
		this.save();
		this.destroy();
		if (response.Mensajes.length > 0) {
			console.table(response.Mensajes);
			response.Mensajes.forEach((element) => {
				this.snackvar_service.openSnackBar(
					element.DescripcionMensaje,
					"",
					"red-snackbar",
					element.CodigoMensaje,
					4000
				);
			});
		} else {
			this.snackvar_service.openSnackBar(
				this.translation["MensajeSuccessSnackBar"],
				"",
				"green-snackbar"
			);
		}
	}

	save() {
		this.loading = false;
		this.panelService.setSavePanel(null);
	}

	validateFields(field: string) {
		return this.isValidAnyField(field);
	}

	isValidAnyField(field: string) {
		let fc_pv = this.editRegistroForm.controls;
		return fc_pv[field].errors != null || fc_pv[field].invalid ? true : false;
	}
}
