import {CLienteAPi} from "./cliente-api.model";


//get
export class peticion_grupos_actividad_taller_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Descripcion: string;
}

//insUpd
export class peticion_Insercion_Upd_grupos_actividad_taller {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    Nombre: string;
    Descripcion: string;
    funcion: string
}

//del
export class peticion_Del_grupos_actividad_taller {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}