import { Deserializable } from "@sharedV11/classes/deserializable";


export class usuario_Respuesta implements Deserializable {
    DatosResult?: DatosResult;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class Permiso implements Deserializable {
    AccionId: number;
    NombreAccion: string;
    CodigoAccion: number;
    ModuloId: number;
    CodigoModulo: number;
    NombreModulo: string;
    NombreModuloIdioma:string;
    IconModulo: string;
    Ventana: string;
    ModuloIdPadre: number;
    NombreApp: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult implements Deserializable {
    NombreUsuario: string;
    TipoUsuarioId: number;
    DeBaja: string;
    Conectado: string;
    CodEmpleado: string;
    Id: number;
    Nombre: string;
    Descripcion: string;
    Privilegios: string;
    Permisos: Permiso[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}