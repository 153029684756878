import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog_Data } from 'src/app/shared/classes/dialog/dialog_data';
/*
@Injectable({
  providedIn: 'root'
})*/
@Component({
  selector: 'dialogo-popUp',
  templateUrl: './dialogo-popup-layout.html'
})
export class PopupService {

  constructor(
    public dialogRef: MatDialogRef<PopupService>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog_Data) {}

  onNoClick(): void {
    this.dialogRef.close("NO");
  }

  onSiClick(): void {
    this.dialogRef.close("SI");
  }
}
