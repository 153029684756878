import { Filter } from "@sharedV6/filtros-genericos/filter.model";
import { Paginator } from "./paginator/paginator";
import { CLienteAPi } from "./userRequest/client_api";


export class peticion_Generica  {
  
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    GrupoInternet: string;
    pkId: string;
    Nombre: string;
    paginator:Paginator;
    filters:Filter[];
    Usuario: string;
    Password:string;
    iEspectaculoId:string;
    dtFechaInicio:string;
    dtHoraInicio:string;

    constructor(paginator?,filters?) {
      this.paginator =paginator
      this.filters = filters;
    }
    
  }