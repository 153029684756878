
import {CLienteAPi} from "./cliente-api.model";

export class peticion_Usuario {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    NombreUsuario: string;
    ClaveUsuario: string;
    IdAplicacion: string;
    AplicacionCod?: string;

}
//getUsuarios
export class peticion_Usuarios_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    NombreUsuario: string;
    iTipoUsuario: number;
}

//InsUpdUsuarios
export class peticion_Insercion_Upd_Usuarios {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    Personal: string;
    Nombre: string;
    Clave: string;
    Tipo: string;
    Baja: string;
    Conectado: string;
    Empleado: string;
    Funcion: string;
}

//delUsuarios
export class peticion_Usuarios_Borrar {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}