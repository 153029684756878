<span
  class="toolbar-bread"
  [ngClass]="!opened ? 'toolbar-bread-off' : 'toolbar-bread-on'"
>
  <nav aria-label="breadcrumb" role="navigation">
    <ol class="breadcrumb">
      <!--li class="breadcrumb-item"><a href="index.html">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Tarifas</li-->
      <li
        class="breadcrumb-item"
        [ngClass]="i == breadlength - 1 ? 'active' : ''"
        *ngFor="let bread of breadarray; let i = index"
      >
        <span
          *ngIf="i < breadlength - 1"
          (click)="clickCrumb(bread.urlcrumb)"
          [ngClass]="bread.urlcrumb ? 'breadClick' : ''"
        >
          {{ bread.crumb }}
        </span>
        <span *ngIf="i == breadlength - 1">
          {{ bread.crumb }}
        </span>
      </li>
    </ol>
  </nav>
</span>
