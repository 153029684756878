import { Deserializable } from '@sharedV11/classes/deserializable';

export class Items_Motivos_Group_Respuesta implements Deserializable {
    DatosResult?: DatosResult_items_motivos_group;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export class ItemsMotivosGroup implements Deserializable {
    pkId: string;
    Nombre: string;
    Descripcion: string;
    Seleccionable: string;
    GrupoId: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export class DatosResult_items_motivos_group implements Deserializable {
    customanItemsMotivosGroup: ItemsMotivosGroup[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
export class Mensaje implements Deserializable {
    CodigoMensaje: string;
    DescripcionMensaje: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}