import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { ButacasService } from './butacas.service';

@Injectable({
  providedIn: 'root'
})
export class InfoMapasService {
  altoAncho = new Subject();
  boxWidth = 720;
  boxHeight = 500;
  selectionRect: any;
  container: any;
  lastMapPressed: string;
  // lastEvent: any;
  recinto: any;

  lastEvent = {
    x: 0,
    y: 0,
    k: 1
  };

  rectTranslation = {
    x: 0,
    y: 0,
    k: 1
  };

  saltCoord = {
    x: 0,
    y: 0
  };

  constructor(private http: HttpClient, private config: ButacasService) {}

  getSVG(nombreRecinto: string): any {
    return this.http.get(
      `assets/${this.config.getConfig('chClaveEmpresa')}/recintos/${nombreRecinto}.svg`,
      { responseType: 'svg' as 'json' }
    );
  }

  getButacas(nombreRecinto: string): any {
    return this.http.get(
      `assets/${this.config.getConfig('chClaveEmpresa')}/recintos/${nombreRecinto}.json`
    );
  }

  button(b: any) {
    const move = 200;
    switch (b) {
      case 'menosc':
        if (this.rectTranslation.k > 0.9) {
          this.rectTranslation.k -= 0.3;
          this.updateMap();
        }
        break;
      case 'arriba':
        this.rectTranslation.y += move;
        this.updateMap();
        break;
      case 'derecha':
        this.rectTranslation.x -= move;
        this.updateMap();
        break;
      case 'izquierda':
        this.rectTranslation.x += move;
        this.updateMap();
        break;
      case 'abajo':
        this.rectTranslation.y -= move;
        this.updateMap();
        break;
      case 'masc':
        this.rectTranslation.k += 1;
        this.updateMap();

        break;
    }
  }

  updateMap() {
    if (this.selectionRect) {
      const transformed = {
        k: 1 / this.rectTranslation.k,
        x: this.rectTranslation.x - this.rectTranslation.x * 2,
        y: this.rectTranslation.y - this.rectTranslation.y * 2
      };
      const zoom = d3
        .zoom()
        .scaleExtent([0, 1])
        .on('zoom', () => {
          if (!this.lastMapPressed) {
            this.lastMapPressed = 'map';
          }
          if (this.lastMapPressed !== 'map') {
            this.saltCoord.x = this.rectTranslation.x;
            this.saltCoord.y = this.rectTranslation.y;
            this.lastMapPressed = 'map';
          }

          transformed.x = transformed.x < 0 ? 0 : transformed.x;
          transformed.y = transformed.y < 0 ? 0 : transformed.y;

          const x = 1 / transformed.k;
          const Xmax = 752036600 + (-1365.99 - 752036600) / (1 + Math.pow(x / 550485, 1.000008));
          const Ymax = 292530100 + (-704.6834 - 292530100) / (1 + Math.pow(x / 415070.3, 1.00001));

          transformed.x = transformed.x > Xmax ? Xmax : transformed.x;
          transformed.y = transformed.y > Ymax ? Ymax : transformed.y;

          this.selectionRect.attr(
            'transform',
            ' scale(' +
              transformed.k +
              ') translate(' +
              transformed.x +
              ', ' +
              (transformed.y - 280) +
              ')'
          );
          this.container.node().__zoom.k = transformed.k;
          this.container.node().__zoom.x = transformed.x;
          this.container.node().__zoom.y = transformed.y;
        });
      this.selectionRect.call(zoom.transform);
      this.lastEvent.k = 1 / transformed.k;
      this.lastEvent.x = -transformed.x;
      this.lastEvent.y = -transformed.y;
      this.recinto.attr('transform', this.lastEvent);
    }
  }
}
