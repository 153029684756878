import { CLienteAPi } from "../userRequest/client_api";


export class peticion_TiposReserva_Lista  {
  
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    Nombre: string;
    Descripcion: string;
    IntervaloControl: string;
    LimiteIntervalo: string;
    deBaja: string;
    ControlHorario: string;
    TiempoLimite: string;
    MostrarHorario: string;
    ControlarAforo: string;
    IncluirLLegada: string;
    Internet: string;
    MaximoInternet: string;
    MinimoInternet: string;
    dePago: string;
  
  }