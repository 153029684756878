import {Deserializable} from "./deserializable.model";

export class Sesion_Respuesta implements Deserializable {
    DatosResult?: DatosResult_Sesion;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class Sesion implements Deserializable {

  pkId: string;
  Recinto: string;
  TipoSesion: string;
  Fecha: string;
  Descripcion: string;
  HoraInicio: string;
  HoraFin: string;
  Reservadas: string;
  Vendidas: string;
  Creado: string;
  Activa: string;
  ControlaHorario: string;
  LimiteAforo: string;
  Didactica: string;
  MaximoAforo: string;
  MaximoInternet: string;
  VendidasInternet: string;
  SesionBloqueada: string;
  NumSesion: string;
  AforoInicial: string;
  TotalRegistros: string;
    
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_Sesion implements Deserializable {
  Lista: Sesion[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}