import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_TipoButaca_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    id: string;
    
}

//insUpd TODO (en un futuro muy lejano)
export class peticion_Insercion_Upd_TipoButaca {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    // pkId: string;
    // Nombre: string;
    // Descripcion: string;

    Funcion: string;
}

//del TODO
export class peticion_Del_TipoButaca {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    // PkId: string;
}

