<div class="container">
  <!--Spinner-->
  <div class="loading-shade" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults" color="warn"></mat-spinner>
  </div>

  <!-------------------------------------->
  <!-------------------------------------->

  <div class="menu-acciones-metodo">
    <div class="row flex justify-content-between" [hidden]="!editando">
      <div style="margin-left: 15px">
        <button class="btn btn-success btn-magento-naranja" (click)="newGuardar()"
          [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
        <!-- <button class="btn btn-success btn-magento-naranja" (click)="newGuardar()"
          [disabled]="!editorRegistroForm.valid"
          [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button> -->
        <button class="btn btn-warning btn-magento-gris-claro" (click)="editando=false;" [hidden]="!editando">
          {{translation.Descartar}}</button>
      </div>
      <button class="btn" (click)="editando=false;" [matTooltip]="translation.Cerrar">
        <mat-icon style="font-size: 32px;">close</mat-icon>
      </button>
    </div>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirTarifa}}</button>
  </div>


  <!--<div class="menu-acciones-metodo">
    <button class="btn btn-primary btn-magento-atras" (click)="editando=false;" [hidden]="!editando"><i
        class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{translation.Atras}}</button>
    <button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris"
      (click)="pregborrarRegistro()">{{translation.Borrar}}</button>
    <button *ngIf="permisobtnDuplicar && model.PkId!=null && editando" class="btn btn-warning btn-magento-gris"
      (click)="duplicarRegistro()">{{translation.DuplicarRegistro}}</button>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirVigencia}}</button>
  </div>-->
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li
          *ngIf="((filtrosactivos.desdeC != null || filtrosactivos.hastaC != null) && (filtrosactivos.desdeC.trim() !='' ||filtrosactivos.hastaC.trim() !='' ))">
          {{translation.FechaC}}: <span>{{filtrosactivos.desdeC}} a {{filtrosactivos.hastaC}} </span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('fechaC')"></i></li>
        <li
          *ngIf="((filtrosactivos.desdeV != null || filtrosactivos.hastaV != null) && (filtrosactivos.desdeV.trim() !='' ||filtrosactivos.hastaV.trim() !='' ))">
          {{translation.FechaV}}: <span>{{filtrosactivos.desdeV}} a {{filtrosactivos.hastaV}} </span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('fechaV')"></i></li>
        <li *ngIf="(filtrosactivos.Taquilla != null && filtrosactivos.Taquilla.trim() !='')">
          {{translation.Taquilla}}:
          <span>{{filtrosactivos.Taquilla}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Taquilla')"></i>
        </li>
        <li *ngIf="(filtrosactivos.Internet != null && filtrosactivos.Internet.trim() !='')">
          {{translation.Internet}}:
          <span>{{filtrosactivos.Internet}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Internet')"></i>
        </li>
        <!-- <li *ngIf="(filtrosactivos.TAuto != null && filtrosactivos.TAuto.trim() !='')"> {{translation.TAuto}}:
          <span>{{filtrosactivos.Automatica}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Automatica')"></i>
        </li> -->
        <li *ngIf="(filtrosactivos.AlaVenta != null && filtrosactivos.AlaVenta.trim() !='')">
          {{translation.ALaVenta}}:
          <span>{{filtrosactivos.AlaVenta}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('AlaVenta')"></i>
        </li>
        <li *ngIf="(filtrosactivos.TEntradaId != null && filtrosactivos.TEntradaId.trim() !='')">
          {{translation.Entrada}}: <span>{{traeNombreEntradaxID(filtrosactivos.TEntradaId)}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('TEntrada')"></i></li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <!--#columnas-->
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>

      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.FechaCompra)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.FechaCompra}}</b> &nbsp; <i>{{translation.Desde}}</i>
              </mat-label>
              <input matInput [matDatepicker]="UCdesde" placeholder="{{translation.Desde}}" formControlName="UCdesde"
                [value]="aFecha(filtrosactivosModelo.desdeC)" >
              <mat-datepicker-toggle matSuffix [for]="UCdesde"></mat-datepicker-toggle>
              <mat-datepicker #UCdesde disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.FechaCompra}}</b> &nbsp; <i>{{translation.Hasta}}</i>
              </mat-label>
              <input matInput [matDatepicker]="UChasta" placeholder="{{translation.Hasta}}" formControlName="UChasta"
                [value]="aFecha(filtrosactivosModelo.hastaC)" >
              <mat-datepicker-toggle matSuffix [for]="UChasta"></mat-datepicker-toggle>
              <mat-datepicker #UChasta disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.FechaVisita)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.FechaVisita}}</b> &nbsp; <i>{{translation.Desde}}</i>
              </mat-label>
              <input matInput [matDatepicker]="UVdesde" placeholder="{{translation.Desde}}" formControlName="UVdesde"
                [value]="aFecha(filtrosactivosModelo.desdeV)">
              <mat-datepicker-toggle matSuffix [for]="UVdesde"></mat-datepicker-toggle>
              <mat-datepicker #UVdesde disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.FechaVisita}}</b> &nbsp; <i>{{translation.Hasta}}</i>
              </mat-label>
              <input matInput [matDatepicker]="UVhasta" placeholder="{{translation.Hasta}}" formControlName="UVhasta"
                [value]="aFecha(filtrosactivosModelo.hastaV)" >
              <mat-datepicker-toggle matSuffix [for]="UVhasta"></mat-datepicker-toggle>
              <mat-datepicker #UVhasta disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Taquilla)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Taquilla}}" formControlName="taquilla" name="tipoTaquilla"
                [value]="filtrosactivosModelo.Taquilla">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Internet)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Internet}}" formControlName="internet" name="tipoInternet"
                [(value)]="filtrosactivosModelo.Internet">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.TaquillaAutomatica)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TaquillaAutomatica}}" formControlName="automatica"
                name="tipoAutomatica" [(value)]="filtrosactivosModelo.Automatica">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ALaVenta)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ALaVenta}}" formControlName="alaventa" name="tipoAlaVenta"
                [(value)]="filtrosactivosModelo.AlaVenta">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.TipoDeEntrada)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TipoDeEntrada}}" formControlName="tipoTipoEntrada"
                [(value)]="filtrosactivosModelo.TEntradaId" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos of tiposEntradas" [value]="tipoGrupos.EntradaId">
                  {{tipoGrupos.Nombre}}
                </mat-option>
              </mat-select>
              <!-- <mat-hint>{{tipoControl.value?.Descripcion}}</mat-hint> -->
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->
    <div class="mat-elevation-z8 overflow-table">
      <mat-paginator [length]="resultsLength" [showFirstLastButtons]="true" [pageSizeOptions]="[10, 25, 100]"
        class="magento-paginator" #paginatorPrincipal></mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort #sortPrincipal="matSort" style="overflow-x: scroll">
        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">

              <button customanTooltip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)="duplicar(row)" style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>

              <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Registro" class="button-icon"
                (click)="borrar(row.PkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>

            </div>
          </td>
        </ng-container>
        <!-- Nombre Column -->
        <!-- <ng-container matColumnDef="Nombre">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                  <td mat-cell *matCellDef="let row"> {{row.DescripcionEntTPromTAbo}} </td>
                </ng-container> -->

        <!-- Taquilla Column -->
        <ng-container matColumnDef="Internet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Internet}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Internet}} </td>
        </ng-container>

        <!-- Internet Column -->
        <ng-container matColumnDef="Taquilla">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Taquilla}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Taquilla}} </td>
        </ng-container>

        <!-- Automatica Column -->
        <ng-container matColumnDef="Automatica">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TAutomatica}} </th>
          <td mat-cell *matCellDef="let row"> {{row.TaqAutomatica}} </td>
        </ng-container>

        <!-- tipo Column -->
        <ng-container matColumnDef="Entrada">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Producto}} </th>
          <td mat-cell *matCellDef="let row"> {{row.DescripcionEntTPromTAbo}} </td>
        </ng-container>

        <!-- alaVenta Column -->
        <ng-container matColumnDef="alaVenta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ALaVenta}} </th>
          <td mat-cell *matCellDef="let row"> {{row.AlaVenta}} </td>
        </ng-container>

        <!-- Dias Semana Column -->
        <ng-container matColumnDef="Semana">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.DiasSemana}} </th>
          <td mat-cell *matCellDef="let row">{{row.Lunes == '1'?'lun':''}}
            {{row.Martes == '1'?' mar':''}}
            {{row.Miercoles == '1'?' mier':''}}
            {{row.Jueves == '1'?'jue':''}} {{row.Viernes
            == '1'?' vie':''}} {{row.Sabado == '1'?' sab':''}}
            {{row.Domingo == '1'?' dom':''}}
          </td>
        </ng-container>

        <!-- FecIniVisita Column -->
        <ng-container matColumnDef="FecIniVisita">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.FechaInVisita}} </th>
          <td mat-cell *matCellDef="let row"> {{quitahoras(row.FecIniVisita)}} </td>
        </ng-container>

        <!-- FecFinVisita Column -->
        <ng-container matColumnDef="FecFinVisita">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.FechaFinVisita}} </th>
          <td mat-cell *matCellDef="let row"> {{quitahoras(row.FecFinVisita)}} </td>
        </ng-container>

        <!-- FecCompraDesde Column -->
        <ng-container matColumnDef="FecCompraDesde">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.FechaCompraDesde}} </th>
          <td mat-cell *matCellDef="let row"> {{quitahoras(row.FecCompraDesde)}} </td>
        </ng-container>

        <!-- FecCompraHasta Column -->
        <ng-container matColumnDef="FecCompraHasta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.FechaCompraHasta}} </th>
          <td mat-cell *matCellDef="let row"> {{quitahoras(row.FecCompraHasta)}} </td>
        </ng-container>

        <!-- PVP Column -->
        <ng-container matColumnDef="PrecioPVP">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.PVP}} </th>
          <td mat-cell *matCellDef="let row"> {{row.PrecioPVP}} {{simbolo}} </td>
        </ng-container>

        <!-- Columnas de Idiomas -->
        <ng-container [matColumnDef]="columna.CodIdioma + '_' + columna.textoHeader"
          *ngFor="let columna of columnasIdiomas">
          <th mat-header-cell *matHeaderCellDef> {{columna.CodIdioma + ' ' + columna.textoHeader}} </th>
          <td mat-cell *matCellDef="let row"> {{formateaIdioma(row.Idiomas, columna)}} </td>
          <!-- {{element[columna]}}-->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="newEditar(row)">
        </tr>
      </table>

     <!--  <mat-paginator
        (page)="paginatorPrincipal.pageIndex = $event.pageIndex; paginatorPrincipal._emitPageEvent($event.pageIndex)"
        [pageSize]="paginatorPrincipal.pageSize" [pageIndex]="paginatorPrincipal.pageIndex"
        [length]="paginatorPrincipal.length" [pageSizeOptions]="paginatorPrincipal.pageSizeOptions"
        [showFirstLastButtons]="true">
      </mat-paginator> -->
    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>

  <div [hidden]="!editando" class="editor-registro">

    <!-- Nueva Maquetacion -->
    <!------------------------------->
    <!------------------------------->
    <!------------------------------->
    <!------------------------------->
    <!--------------here----------------->
    <!------------------------------->
    <!------------------------------->
    <!------------------------------->
    <div class="main-container" [hidden]="!editando">
      <div>
        <div class="min-height-200px">
          <!-- horizontal Basic Forms Start -->
          <div>
            <form [formGroup]="editorRegistroForm">

              <div class="form-group row">
                <div class="col-sm-12">
                  <mat-slide-toggle color="primary" formControlName="activa" id="activaCheck4">
                    {{translation.bALaVenta}}</mat-slide-toggle>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.PlataformasDeVenta}}</mat-label>
                    <mat-select multiple placeholder="{{translation.PlataformasDeVenta}}"
                      formControlName="tipoControlPlataforma" (selectionChange)="actualizarPlataformasSeleccionadas()">
                      <mat-option value="1">Punto Venta Kore</mat-option>
                      <mat-option *ngIf="TieneInternet === '1'" value="2" [disabled]="disableInternet">Internet
                      </mat-option>
                      <mat-option *ngIf="TieneTaquillaAutomatica === '1'" value="3">Taquilla
                        Automática</mat-option>
                      <mat-option *ngIf="TieneAdmon === '1'" value="4" [disabled]="disableAdministracion">Administración
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoDeProducto}}</mat-label>
                    <mat-select placeholder="{{translation.TipoDeProducto}}"
                      formControlName="tipoControlproductoAsociado"
                      (selectionChange)="actualizarProductoSeleccionado()">
                      <mat-option *ngIf="tipoProductoEntrada === true" value="1">Entrada</mat-option>
                      <mat-option *ngIf="tipoProductoPaquete === true" value="2">Paquete</mat-option>
                      <mat-option *ngIf="tipoProductoAbonado === true" value="3">Abonado</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <!-- TIPO ENTRADA -->
              <div class="form-group row" *ngIf="platSelected === '1'">
                <div class="col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoDeEntrada}}</mat-label>
                    <input type="text" placeholder="{{translation.TipoDeEntrada}}" matInput
                      formControlName="tipoControlproductoAsociadoEntrada" [matAutocomplete]="entrada">
                    <mat-autocomplete #entrada="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let entrada of filteredEntradas | async" [value]="entrada">
                        {{entrada.TextoPantalla}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>

              <!-- 
              <div class="form-group row" *ngIf="platSelected === '1'">
                <div class="col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoDeEntrada}}</mat-label>
                    <mat-select placeholder="{{translation.TipoDeEntrada}}"
                      formControlName="tipoControlproductoAsociadoEntrada"
                      (selectionChange)="actualizarProductoEntrada($event.value)">
                        <mat-option *ngFor="let entrada of ProductosEntrada" [value]="entrada.EntradaId">
                          {{entrada.TextoPantalla}}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
               -->
              <!-- TIPO PAQUETE -->
              <div class="form-group row" *ngIf="platSelected === '2'">
                <div class="col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoDePaquete}}</mat-label>
                    <input type="text" placeholder="{{translation.TipoDePaquete}}" matInput
                      formControlName="tipoControlproductoAsociadoPaquete" [matAutocomplete]="paquete">
                    <mat-autocomplete #paquete="matAutocomplete" [displayWith]="displayFnProducto"
                      (optionSelected)="actualizarProductoPaquete($event)">
                      <mat-option *ngFor="let paquete of filteredProductos | async" [value]="paquete">
                        {{paquete.chTextoPantalla}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <!--
              <div class="form-group row" *ngIf="platSelected === '2'">
                <div class="col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoDePaquete}}</mat-label>
                    <mat-select placeholder="{{translation.TipoDePaquete}}"
                      formControlName="tipoControlproductoAsociadoPaquete"
                      (selectionChange)="actualizarProductoPaquete($event.value)">
                      <mat-option *ngFor="let paquete of ProductosPaquete" [value]="paquete.pkId">
                        {{paquete.chTextoPantalla}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div> -->

              <div class="form-group row" *ngIf="platSelected === '3'">
                <div class="col-sm-12">
                  <label class="col-sm-12 col-md-2 col-form-label">{{translation.TipoDeAbonado}}</label>
                  <div class="col-sm-12 col-md-10">
                    <mat-select formControlName="tipoControlproductoAsociadoAbono"
                     
                      class="custom-select2 select col-12 form-control" style="width: 100%;">
                      <mat-option *ngFor="let abono of ProductosAbono" [value]="abono.pkId">
                        {{abono.Nombre}}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-select formControlName="tipoControlproductoAsociadoAbono"
                      (selectionChange)="actualizarProductoAbonado($event.value)"
                      class="custom-select2 select col-12 form-control" style="width: 100%;">
                      <mat-option *ngFor="let abono of ProductosAbono" [value]="abono.pkId">
                        {{abono.Nombre}}
                      </mat-option>
                    </mat-select> -->
                  </div>
                </div>
              </div>

              <!--  <div class="form-group row" *ngIf="PVKSelected || TaqAutSelected">
                <div class="col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Prefijo}}</mat-label>
                    <label>
                      <input matInput type="text" placeholder="{{translation.Prefijo}}" formControlName="chPrefijo"
                        [(ngModel)]="model.chPrefijo">
                    </label>
                  </mat-form-field>
                </div>
              </div> -->

              <div class="form-group row" *ngIf="IntSelected || AdminSelected">
                <div class="col-sm-12">
                  <!-- TIPO ENTRADA -->
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.GrupoInternet}}</mat-label>
                    <input type="text" placeholder="{{translation.GrupoInternet}}" matInput
                      formControlName="tipoGrupoInternet" [matAutocomplete]="internet">
                    <mat-autocomplete #internet="matAutocomplete" [displayWith]="displayFnInternet">
                      <mat-option *ngFor="let tipoGruposGi of filteredInternet | async" [value]="tipoGruposGi">
                        {{tipoGruposGi.Nombre}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!-- <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.GrupoInternet}}</mat-label>
                    <mat-select formControlName="tipoGrupoInternet" [(ngModel)]="model.iGrupoInternetId">
                      <mat-option [value]="0">--</mat-option>
                      <mat-option *ngFor="let tipoGruposGi of tiposGInternet" [value]="tipoGruposGi.pkId">
                        {{tipoGruposGi.Nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                </div>
              </div>

              <div class="form-group row" *ngIf="IntSelected || AdminSelected">
                <div class="col-sm-12">
                  <mat-slide-toggle color="primary" formControlName="agrupada" id="tarifaAgrupadaCheck4">
                    {{translation.TarifaAgrupada}}</mat-slide-toggle>
                </div>
              </div>

              <!------------------------CONDICIONAL MOSTRAR IDIOMAS------------------------>
              <div *ngFor="let idioma of editorListaIdiomas">
                <div class="form-group row">
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.NombreInternet}} ({{idioma.CodIdioma}})</mat-label>
                      <label>
                        <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                          [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)"
                          formControlName="{{'nombre' + idioma.CodIdioma}}">
                      </label>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
                      <label>
                        <input matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                          [value]="idioma.Descripcion" formControlName="{{'descripcion' + idioma.CodIdioma}}"
                          (change)="cambiaDescripcion($any($event.target).value, idioma.CodIdioma)">
                      </label>
                    </mat-form-field>
                  </div>
                </div>
              </div>


              <!--------------------------------------------->
              <!-----------------fechas---------------------->
              <!--------------------------------------------->
              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">{{translation.PeriodoDeVisita}}</h4>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <mat-slide-toggle color="primary" (change)="sinFechaFin()" formControlName="sinFechaFin"
                    id="sinFechaFin">
                    {{translation.SinFechaFin}}</mat-slide-toggle>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.FechaDesde}}</mat-label>
                    <input matInput formControlName="fechaDesdeVisita" [matDatepicker]="dpdesdevisita"
                      placeholder="{{translation.FechaDesde}}" [min]="fechaFV_minDesde" [max]="fechaFV_maxDesde"
                      (dateChange)="cambiaFVdesde($event)" [value]="getFechaFVInicio()">
                    <mat-datepicker-toggle matSuffix [for]="dpdesdevisita"></mat-datepicker-toggle>
                    <mat-datepicker #dpdesdevisita></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.FechaHasta}}</mat-label>
                    <input matInput formControlName="fechaHastaVisita" [matDatepicker]="dphastavisita"
                      placeholder="{{translation.FechaHasta}}" [min]="fechaFV_minHasta"
                      (dateChange)="cambiaFVhasta($event)" [value]="getFechaFVFin()"
                      [disabled]="this.editorRegistroForm.get('sinFechaFin').value">
                    <mat-datepicker-toggle matSuffix [for]="dphastavisita"></mat-datepicker-toggle>
                    <mat-datepicker #dphastavisita></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>


              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">{{translation.PeriodoDeCompra}}</h4>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.FechaDesde}}</mat-label>
                    <input matInput formControlName="fechaDesdeCompra" [matDatepicker]="dpdesdecompra"
                      placeholder="{{translation.PeriodoDeCompra}}" [min]="fechaFV_minDesde" [max]="fechaPC_maxHasta"
                      (dateChange)="cambiaFCdesde($event)" [value]="getFechaFCInicio()">
                    <mat-datepicker-toggle matSuffix [for]="dpdesdecompra"></mat-datepicker-toggle>
                    <mat-datepicker #dpdesdecompra></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.FechaHasta}}</mat-label>
                    <input matInput formControlName="fechaHastaCompra" [matDatepicker]="dphastacompra"
                      placeholder="{{translation.FechaHasta}}" [min]="fecha_minHasta"
                      (dateChange)="cambiaFChasta($event)" [value]="getFechaFCFin()">
                    <mat-datepicker-toggle matSuffix [for]="dphastacompra"></mat-datepicker-toggle>
                    <mat-datepicker #dphastacompra></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <!------------FIN FECHAS--------------->

              <div class="clearfix">
                <div class="pull-left">
                  <h4 class="text-blue h4">{{translation.HoraDeVisita}}</h4>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraInicio}}test</mat-label>
                    <input matInput formControlName="horarioDeVisitaInicio" type="time" [(ngModel)]="model.HoraIni"
                      step="2" placeholder="{{translation.SeleccionaHoraInicio}}" [value]="getHoraInicio()">
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraFin}} test</mat-label>
                    <input matInput formControlName="horarioDeVisitaFin" type="time" [(ngModel)]="model.HoraFin"
                      step="2" placeholder="{{translation.SeleccionaHoraFin}}" [value]="getHoraFin()">
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-12">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.DiasConDisponibilidad}}</mat-label>
                    <mat-select placeholder="{{translation.DiasEnLosQueEstaraDisponible}}"
                      formControlName="tipoControlDias" name="tipoDias" multiple [compareWith]="comparaObjetosSelect"
                      (selectionChange)="CambioDiasSemanaMultiple($event)">
                      <mat-option value="1">{{translation.Lunes}}</mat-option>
                      <mat-option value="2">{{translation.Martes}}</mat-option>
                      <mat-option value="3">{{translation.Miercoles}}</mat-option>
                      <mat-option value="4">{{translation.Jueves}}</mat-option>
                      <mat-option value="5">{{translation.Viernes}}</mat-option>
                      <mat-option value="6">{{translation.Sabado}}</mat-option>
                      <mat-option value="7">{{translation.Domingo}}</mat-option>
                    </mat-select>
                    <mat-hint>
                      {{translation.ParaSeleccionarTodaLaSemanaHayQueDejarTodosLosDiasSinMarcar}}
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>

              <!------------------------------------------------------------------------>
              <!------------------------------------------------------------------------>
              <!------------------------------------------------------------------------>
              <!-------------------------CAMPOS INDIVIDUALES---------------------------->
              <!------------------------------------------------------------------------>
              <!------------------------------------------------------------------------>
              <!------------------------MOSTRAR TODOS----------------------------------->
              <!--<div class="form-group row" *ngIf="PVKSelected || TaqAutSelected">-->
              <div class="form-group row" *ngIf="platSelected !== '2'">
                <div class="col-sm-12 col-md-4">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoIVA}}</mat-label>
                    <mat-select formControlName="tipoIVA" (selectionChange)="cambiapvp('pvp')">
                      <mat-option value="0">{{translation.ExentoONA}}</mat-option>
                      <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.id">
                        {{tipoGruposIC.Iva}}%
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.PVP}}</mat-label>
                    <input matInput (change)="cambiapvp('pvp', $event)" formControlName="pvp" type="number"
                      class="example-right-align" placeholder="{{translation.PVP}}" [(ngModel)]="model.PrecioPVP">
                    <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
                    <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
                    <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
                    <div *ngIf="f.pvp.errors" class="invalid-feedback">
                      <mat-error *ngIf="f.pvp.errors.pattern">{{translation.ValorNumerico}}</mat-error>
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.BaseImponible}}</mat-label>
                    <input matInput (change)="cambiabaseImp('bimp', $event)" formControlName="bimp" type="number"
                      class="example-right-align" placeholder="{{translation.BaseImponible}}"
                      [(ngModel)]="model.BaseImponible">
                    <span matSuffix>{{simbolo}}</span>
                    <div *ngIf="f.bimp.errors" class="invalid-feedback">
                      <mat-error *ngIf="f.bimp.errors.pattern">{{translation.ValorNumerico}}</mat-error>
                    </div>
                  </mat-form-field>
                </div>
              </div>
              <!------------------------------------------------------------------------->

              <div class="form-group row">
                <div class="col-sm-12">
                  <mat-slide-toggle color="primary" formControlName="desglosarGastosGestion" id="gastosDeGestionCheck4">
                    {{translation.GastosDeGestion}}</mat-slide-toggle>
                </div>
              </div>

              <!---------------------------SEGUNDA FILA--------------------------------------------->
              <div class="form-group row" *ngIf="platSelected !== '2'">
                <div class="col-sm-12 col-md-4">
                  <mat-form-field class="input-select" appearance="outline">
                    <mat-label>{{translation.TipoIVAComision}}</mat-label>
                    <mat-select formControlName="tipoControlTIVAComision" (selectionChange)="cambiapvp('pvpc')"
                      [(ngModel)]="model.iTipoIvaComisionId">
                      <mat-option value="0">{{translation.ExentoONA}}</mat-option>
                      <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.id">
                        {{tipoGruposIC.Iva}}%
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.PVPComision}}</mat-label>
                    <input matInput (change)="cambiapvp('pvpc', $event)" formControlName="pvpc" type="number"
                      class="example-right-align" placeholder="{{translation.BaseImponible}}"
                      [(ngModel)]="model.Comision" value="">
                    <span matSuffix>{{simbolo}}</span>
                    <div *ngIf="f.pvpc.errors" class="invalid-feedback">
                      <mat-error *ngIf="f.pvpc.errors.pattern">{{translation.ValorNumerico}}</mat-error>
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.BaseImponibleComision}}</mat-label>
                    <input matInput (change)="cambiabaseImp('bimpc', $event)" formControlName="bimpc" value=""
                      class="example-right-align" type="number" placeholder="{{translation.BaseImponibleComision}}"
                      [(ngModel)]="model.ComisionBI">
                    <span matSuffix>{{simbolo}}</span>
                    <div *ngIf="f.bimpc.errors" class="invalid-feedback">
                      <mat-error *ngIf="f.bimpc.errors.pattern">{{translation.ValorNumerico}}</mat-error>
                    </div>
                  </mat-form-field>
                </div>
              </div>
              <!---------------------------------------------------------------------->
              <!---------------------------------------------------------------------->
              <!------------------------MOSTRAR SOLO PVP------------------------------>
              <!---------------------------------------------------------------------->
              <!---------------------------------------------------------------------->
              <div class="form-group row" *ngIf="platSelected === '2' && (PVKSelected || TaqAutSelected)">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.PVP}}</mat-label>
                    <input matInput (change)="cambiapvpTabla('pvp')" formControlName="pvp" type="number"
                      class="example-right-align" placeholder="{{translation.PVP}}" [(ngModel)]="model.PrecioPVP">
                    <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
                    <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
                    <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
                    <div *ngIf="f.pvp.errors" class="invalid-feedback">
                      <mat-error *ngIf="f.pvp.errors.pattern">{{translation.ValorNumerico}}</mat-error>
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.PVPComision}}</mat-label>
                    <input matInput (change)="cambiapvpTabla('pvpc')" formControlName="pvpc" type="number"
                      class="example-right-align" placeholder="{{translation.PVP}}" [(ngModel)]="model.Comision">
                    <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
                    <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
                    <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
                    <div *ngIf="f.pvpc.errors" class="invalid-feedback">
                      <mat-error *ngIf="f.pvpc.errors.pattern">{{translation.ValorNumerico}}</mat-error>
                    </div>
                  </mat-form-field>
                </div>
              </div>
              <!-----------------------FIN CAMPOS SOLO PVP------------------------------>

              <div class="form-group row">
                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Minimo}}</mat-label>
                    <label>
                      <input matInput type="number" value="" [(ngModel)]="model.Minimo" class="example-right-align"
                        placeholder="{{translation.Minimo}}" formControlName="minimo">
                    </label>
                    <div  class="invalid-feedback">
                    <!-- <div *ngIf="f.minimo.errors" class="invalid-feedback"> -->
                      <mat-error >{{translation.ValorNumerico}}</mat-error>
                      <!-- <mat-error *ngIf="f.minimo.pattern">{{translation.ValorNumerico}}</mat-error> -->
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Maximo}}</mat-label>
                    <label>
                      <input matInput type="number" value="" (change)="minimoValidator()" [(ngModel)]="model.Maximo"
                        class="example-right-align" placeholder="{{translation.Maximo}}" formControlName="maximo">
                    </label>
                    <div *ngIf="f.maximo.errors" class="invalid-feedback">
                    <!-- <div *ngIf="f.maximo.errors" class="invalid-feedback"> -->
                      <mat-error >{{translation.ValorNumerico}}</mat-error>
                      <!--<mat-error *ngIf="f.maximo.pattern">{{translation.ValorNumerico}}</mat-error>-->
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Orden}}</mat-label>
                    <label>
                      <input matInput value="" (change)="minimoValidator()" [(ngModel)]="model.Orden"
                        class="example-right-align" formControlName="orden" type="number"
                        placeholder="{{translation.Orden}}">
                    </label>
                    <div *ngIf="f.orden.errors" class="invalid-feedback">
                      <mat-error >{{translation.ValorNumerico}}</mat-error>
<!--                       <mat-error *ngIf="f.orden.pattern">{{translation.ValorNumerico}}</mat-error>
 -->                    </div>
                  </mat-form-field>
                </div>
              </div>
              <!------------------------------>

              <!-- tabla ripeada TODO::: a la espera de tener un componente unico para unificar todas las tablas -->
              <!--<div class="pd-20 card-box mb-30" *ngIf="platSelected === '2'">
                <div class="clearfix mb-20">
                  <div class="pull-left">
                    <h4 class="text-blue h4">Tarifas Promoción Entrada</h4>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Nombre Entrada</th>
                      <th scope="col">Porcentaje</th>
                      <th scope="col">Tipo IVA</th>
                      <th scope="col">PVP</th>
                      <th scope="col">Base Imponible</th>
                      <th scope="col">Tipo IVA Comisión</th>
                      <th scope="col">PVP Comisión</th>
                      <th scope="col">Base Imponible Comisión</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Descripción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of TarifaPromocion">
                      <td>{{item.NomTipoEntrada}}</td>

                      <td>
                        {{item.Porcentaje}}%
                      </td>

                      <td>
                        <select class="custom-select col-12"
                          (input)="actualizarTarifaPromocion(item.Pkid, 'TipoIVA', $event.target.value)">
                          <option value="0">{{translation.ExentoONA}}</option>
                          <option *ngFor="let tipoGruposIC of tiposIVA" [value]="tipoGruposIC.PkId">
                            {{tipoGruposIC.Nombre}}
                          </option>
                        </select>
                      </td>

                      <td>
                        <input type="text" value="{{item.Precio}}" class="form-control table-size1"
                          (input)="actualizarTarifaPromocion(item.Pkid, 'Precio', $event.target.value)">
                      </td>

                      <td>
                        <input type="text" value="{{item.BaseImponible}}" class="form-control table-size1"
                          (input)="actualizarTarifaPromocion(item.Pkid, 'BaseImponible', $event.target.value)">
                      </td>

                      <td>
                        <select class="custom-select col-12"
                          (input)="actualizarTarifaPromocion(item.Pkid, 'TipoIVAComision', $event.target.value)">
                          <option value="0">{{translation.ExentoONA}}</option>
                          <option *ngFor="let tipoGruposIC of tiposIVA" [value]="tipoGruposIC.PkId">
                            {{tipoGruposIC.Nombre}}
                          </option>
                        </select>
                      </td>

                      <td>
                        <input type="text" value="{{item.Comision}}" class="form-control table-size1"
                          (input)="actualizarTarifaPromocion(item.Pkid, 'Comision', $event.target.value)">
                      </td>

                      <td>
                        <input type="text" value="{{item.ComisionBI}}" class="form-control table-size1"
                          (input)="actualizarTarifaPromocion(item.Pkid, 'ComisionBI', $event.target.value)">
                      </td>

                      <td>
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'NombreESES', $event.target.value)"
                          type="text" value="{{item.Idiomas.NombreESES}}" placeholder="es-ES"
                          class="form-control table-size">
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'NombreCAES', $event.target.value)"
                          type="text" value="{{item.Idiomas.NombreCAES}}" placeholder="ca-ES"
                          class="form-control table-size">
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'NombreDEDE', $event.target.value)"
                          type="text" value="{{item.Idiomas.NombreDEDE}}" placeholder="de-DE"
                          class="form-control table-size">
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'NombreENGB', $event.target.value)"
                          type="text" value="{{item.Idiomas.NombreENGB}}" placeholder="en-GB"
                          class="form-control table-size">
                      </td>

                      <td>
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'DescripcionESES', $event.target.value)"
                          type="text" value="{{item.Idiomas.DescripcionESES}}" placeholder="es-ES"
                          class="form-control table-size">
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'DescripcionCAES', $event.target.value)"
                          type="text" value="{{item.Idiomas.DescripcionCAES}}" placeholder="ca-ES"
                          class="form-control table-size">
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'DescripcionDEDE', $event.target.value)"
                          type="text" value="{{item.Idiomas.DescripcionDEDE}}" placeholder="de-DE"
                          class="form-control table-size">
                        <input (input)="actualizarIdiomaPromocion(item.Pkid, 'DescripcionENGB', $event.target.value)"
                          type="text" value="{{item.Idiomas.DescripcionENGB}}" placeholder="en-GB"
                          class="form-control table-size">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>-->
              <!-- fin tabla ripeada -->


              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!-------------------------------NUEVA TABLA----------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <div class="pull-left" *ngIf="TarifaPromocion && platSelected === '2'">
                <h4 class="text-blue h4">Tarifas Promoción Entrada</h4>
              </div>
              <br>
              <br>
              <div class="mat-elevation-z8 overflow-table" *ngIf="TarifaPromocion && platSelected === '2'">
                <table mat-table *ngIf="platSelected === '2'" [dataSource]="TarifaPromocion" multiTemplateDataRows>
                  <ng-container matColumnDef="{{column}}" *ngFor="let column of columnasTabla; let i = index"
                    [stickyEnd]="i === columnasTabla.length - 1 || i === 0">
                    <th mat-header-cell *matHeaderCellDef> {{column}} </th>
                    <td mat-cell *matCellDef="let element">
                      <p *ngIf="i === 0">{{element.NomTipoEntrada}}</p>

                      <!--BASES IMPONIBLES-->
                      <mat-form-field
                        *ngIf="i !== columnasTabla.length - 1 && i !== 0 && i !== 1 && i !== 2 && i !== 3 && i !== 6 && i !== 4 && i !== 7"
                        appearance="outline" style="width: 100px; margin: 0 10px" (click)="closeExpanded()">
                        <label>
                          <input matInput type="number" placeholder="{{column}}"
                            (change)="revesBaseImponibleDesdeTabla(i === 5 ? 'baseImponible' : 'baseImponibleComision', element)"
                            class="example-right-align" formControlName="{{columnasVanila[i] + element.Pkid}}">
                        </label>
                        <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
                        <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
                        <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
                      </mat-form-field>

                      <!--Porcentaje-->
                      <p *ngIf="i === 1 && element.rPorcentaje">{{element.rPorcentaje}}%</p>
                      <p *ngIf="i === 1 && element.Porcentaje">{{element.Porcentaje}}%</p>

                      <!--Cantidad ....REEMPLAZAR!!-->
                      <p *ngIf="i === 2 && element.iNumEntradas">{{element.iNumEntradas}}</p>
                      <p *ngIf="i === 2 && element.NumEntradas">{{element.NumEntradas}}</p>

                      <!--Tipo iva-->
                      <mat-form-field class="input-select" appearance="outline" *ngIf="i === 3"
                        (click)="closeExpanded()">
                        <mat-label>{{translation.TipoIVA}}</mat-label>
                        <mat-select (click)="closeExpanded()" formControlName="{{'tipoIva' + element.Pkid}}"
                          (selectionChange)="cambiapvpDesdeTabla('pvp')">
                          <mat-option value="0">{{translation.ExentoONA}}</mat-option>
                          <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.id">
                            {{tipoGruposIC.Iva}}%
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <!---PVPs-->
                      <mat-form-field *ngIf="i === 4 || i === 7" appearance="outline"
                        style="width: 100px; margin: 0 10px" (click)="closeExpanded()">
                        <label>
                          <input matInput type="number" placeholder="{{column}}" class="example-right-align"
                            (change)="cambiapvpDesdeTabla(i === 4 ? 'pvp' : 'pvpc')"
                            formControlName="{{(i === 4 ? 'pvp' : 'pvpComision') + element.Pkid}}">
                        </label>
                        <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
                        <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
                        <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
                      </mat-form-field>

                      <!--Tipo iva COMISION-->
                      <mat-form-field class="input-select" appearance="outline" *ngIf="i === 6">
                        <mat-label>{{translation.TipoIVAComision}}</mat-label>
                        <mat-select (click)="closeExpanded()" formControlName="{{'tipoIvaComision' + element.Pkid}}"
                          (selectionChange)="cambiapvpDesdeTabla('pvpc')">
                          <mat-option value="0">{{translation.ExentoONA}}</mat-option>
                          <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.id">
                            {{tipoGruposIC.Iva}}%
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <!--ICONO-->
                      <div *ngIf="i === columnasTabla.length - 1">
                        <button mat-icon-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>


                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnasTabla.length">
                      <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div *ngFor="let idioma of editorListaIdiomas" class="form-group row">
                          <div class="col-sm-12 col-md-6">
                            <mat-form-field appearance="outline" style="width: 100%">
                              <mat-label>Nombre {{idioma.CodIdioma}}</mat-label>
                              <label>
                                <input matInput formControlName="{{'nombre' + idioma.CodIdioma + element.Pkid}}"
                                  type="text" placeholder="{{'nombre' + idioma.CodIdioma}}" />
                              </label>
                            </mat-form-field>
                          </div>
                          <div class="col-sm-12 col-md-6">
                            <mat-form-field appearance="outline" style="width: 100%">
                              <mat-label>descripcion {{idioma.CodIdioma}}</mat-label>
                              <label>
                                <input matInput formControlName="{{'descripcion' + idioma.CodIdioma + element.Pkid}}"
                                  type="text" placeholder="{{'descripcion' + idioma.CodIdioma}}" />
                              </label>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnasTabla"></tr>
                  <tr mat-row *matRowDef="let element; columns: columnasTabla" class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                  </tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
              </div>
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <!----------------------------------------------------------------------------------->
              <br>
              <button class="btn btn-success btn-magento-naranja" (click)="newGuardar()"
                [disabled]="!editorRegistroForm.valid"
                [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>

            </form>
            <div class="collapse collapse-box" id="horizontal-basic-form1">
              <div class="code-box">
                <div class="clearfix">
                  <a href="javascript:;" class="btn btn-primary btn-sm code-copy pull-left"
                    data-clipboard-target="#horizontal-basic"><i class="fa fa-clipboard"></i> Copy
                    Code</a>
                  <a href="#horizontal-basic-form1" class="btn btn-primary btn-sm pull-right" rel="content-y"
                    data-toggle="collapse" role="button"><i class="fa fa-eye-slash"></i> Hide Code</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>