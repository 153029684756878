import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_Festivos_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    Fecha: string;
}

//insUpd
export class peticion_Insercion_Upd_Festivos {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    Fecha: string;
    Motivo: string;
    Venta: string;
    Reserva: string;
    HoraInicio1Venta: string;
    HoraFin1Venta: string;
    HoraInicio2Venta: string;
    HoraFin2Venta: string;
    HoraInicio1Reserva: string;
    HoraFin1Reserva: string;
    HoraInicio2Reserva: string;
    HoraFin2Reserva: string;
    Funcion: string;
}

//del
export class peticion_Del_Festivos {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    Motivo: string;
}