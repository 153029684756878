import {
  Component,
  Inject,
  OnInit,
  HostBinding,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { SelectionModel } from "@angular/cdk/collections";
import { DatePipe } from "@angular/common";

import { first } from "rxjs/operators";

import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";

import {
  TiposControl_Respuesta,
  TipoControl,
  DatosResult_TiposControl,
} from "../../_modelos/tipos-control-Respuesta.model";

import { ConectarAPIService } from "../../_API/conectar-api.service";
//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { AlertaService } from "../../shared/_alerta/alerta.service";
import { AutentificacionService } from "../../_otrosproveedores/autentificacion.service";
import { Router, ActivatedRoute } from "@angular/router";
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { DialogoPopUp } from "../../shared/_alerta/popup.service";
import { Globales } from "src/app/V6/_clases/globales";
import {
  CalendariosTiposControl_Respuesta,
  CalendariosTipoControl,
  DatosResult_CalendariosTiposControl,
} from "src/app/shared/classes/maestros/calendarios/CalendariosTiposControl_Respuesta";
import { Translator } from "src/app/shared/services/translator/translator.service";

export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class traspasomodelo {
  PkId: string;
  CodigoTipoControl: string;
  Nombre: string;
  Descripcion: string;
  CompHoraria: boolean;
  PermisoSinFecha: boolean;
  CompDiaria: boolean;
  CompIntervalo: boolean;
  CompDiaSemana: boolean;
  CompPasoPrevio: boolean;
  CompCalendario: boolean;
  NumPasosPrevios: string;
  CompCalendHorario: boolean;
  DenegarAcceso: boolean;
  DiasIntervalo: string;
  AccesoLunes: boolean;
  AccesoMartes: boolean;
  AccesoMiercoles: boolean;
  AccesoJueves: boolean;
  AccesoViernes: boolean;
  AccesoSabado: boolean;
  AccesoDomingo: boolean;
  PorDefecto: boolean;
  NivelEntrada: string;
  NumPasosPreviosDiarios: string;
  DiasIntervaloDesde: string;
  deserialize: null;
}

export class filtrosActivos {
  Nombre: string;
  Descripcion: string;
  codigodesde: string;
  codigohasta: string;
  comphoraria: string;
  compsinfecha: string;
  compdiaria: string;
  compdiasemana: string;
  compintervalo: string;
  comppasoprevio: string;
  compcalendario: string;
  compcalendariohora: string;
  compdenegaracceso: string;
  diasintervalodesde: string;
  diasintervalohasta: string;
  diasintervalodesdedesde: string;
  diasintervalodesdehasta: string;
  lunes: string;
  martes: string;
  miercoles: string;
  jueves: string;
  viernes: string;
  sabado: string;
  domingo: string;
  nivelentrada: string;
  pasopreviodesde: string;
  pasopreviohasta: string;
  pasopreviodiadesde: string;
  pasopreviodiahasta: string;
  textoGeneral: string;
}

@Component({
  selector: "app-tipos-control",
  templateUrl: "./tipos-control.component.html",
  styleUrls: ["./tipos-control.component.css"],
})
export class TiposControlComponent implements OnInit {
  displayedColumns: string[] = [
    "Acciones",
    "Codigo",
    "Nombre",
    "Descripcion",
    "PorDefecto",
    "Comprobaciones",
    "Intervalo",
    "Semana",
    "NivelEntrada",
    "NumPasosPrevios",
    "NumPasosPreviosDiarios",
    "IntervaloDesde",
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  displayedFilters: string[] = [
    "Nombre",
    "Descripcion",
    "Codigo",
    "ComprobacionHoraria",
    "ComprobacionSinFecha",
    "ComprobacionDiaria",
    "ComprobacionDiaSemana",
    "ComprovacionIntervalo",
    "ComprovacionPasoPrevio",
    "ComprobacionCalendario",
    "ComprobacionCalendarioHorario",
    "ComprobacionDenegarAcceso",
    "DiasIntervalo",
    "DiasIntervaloDesde",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo",
    "NivelEntrada",
    "PasosPrevios",
    "PasosPreviosDiario",
  ];
  filtersToDisplay: string[] = this.displayedFilters.slice();

  dataSource: MatTableDataSource<TipoControl>;
  seleccion = new SelectionModel<TipoControl>(true, []);
  //
  dataSourceCalendarioHorario: MatTableDataSource<CalendariosTipoControl>;
  columnasCalendarioHorario: string[] = ["HoraInicio", "HoraFin", "Accion"];
  //
  dataSourceCalendario: MatTableDataSource<CalendariosTipoControl>;
  columnasCalendario: string[] = ["FechaInicio", "FechaFin", "Accion"];
  //
  @ViewChild("paginatorPrincipal") paginator: MatPaginator;
  @ViewChild("sortPrincipal") sort: MatSort;
  //
  @ViewChild("paginatorCalendario") paginatorCalendario: MatPaginator;
  @ViewChild("sortCalendario") sortCalendario: MatSort;
  @ViewChild("paginatorCalendarioHorario")
  paginatorCalendarioHorario: MatPaginator;
  @ViewChild("sortCalendarioHorario") sortCalendarioHorario: MatSort;

  respuestaAPI: TiposControl_Respuesta;
  respuestaAPICalendario: CalendariosTiposControl_Respuesta;
  editando: boolean = false;
  editandoCalendarios: boolean = false;
  objedicionCalendario: CalendariosTipoControl = new CalendariosTipoControl();
  editandoCalendariosHorario: boolean = false;
  objedicionCalendarioHorario: CalendariosTipoControl =
    new CalendariosTipoControl();
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  tipoPosicion = new FormControl("", [Validators.required]);
  accionselecionada: string = null;
  panelFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos();
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  arr_comprobaciones: Array<any> = [
    { id: "CompHoraria", value: "Hora" },
    { id: "PermisoSinFecha", value: "Sin Fecha" },
    { id: "CompDiaria", value: "Diaria" },
    { id: "CompDiaSemana", value: "Dia Semana" },
    { id: "CompIntervalo", value: "Intervalo" },
    { id: "CompPasoPrevio", value: "Paso Previo" },
    { id: "CompCalendario", value: "Calendario" },
    { id: "CompCalendHorario", value: "Calendario Horario" },
    { id: "DenegarAcceso", value: "Sin Acceso" },
  ];
  arr_comprobaciones_seleccionadas: Array<any> = [];
  arr_dias_semana_seleccionadas: Array<any> = [];
  panelCalendarioHorarioDisabled = true;
  panelCalendarioHorarioAbierto = false;
  panelCalendarioDisabled = true;
  panelCalendarioAbierto = true;
  fecha_minDesde = new Date();
  fecha_minHasta = new Date();
  fecha_maxDesde: Date;
  fecha_maxHasta: Date;
  compruebaPorDefectovalor: boolean = true;

  translation;
  panelSelectorFiltrosvisible: boolean = false;
  formControlListaFiltros: FormControl;

  constructor(
    private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private translator: Translator
  ) {}

  @HostBinding("class.col") true;
  model = new traspasomodelo();

  async ngOnInit() {
    this.translation = this.translator.GetTranslations();
    this.displayedFilters = [
      this.translation.Nombre,
      this.translation.Descripcion,
      this.translation.Codigo,
      this.translation.ComprobacionHoraria,
      this.translation.ComprobacionSinFecha,
      this.translation.ComprobacionDiaria,
      this.translation.ComprobacionDiaSemana,
      this.translation.ComprobacionIntervalo,
      this.translation.ComprobacionPasoPrevio,
      this.translation.ComprobacionCalendario,
      this.translation.ComprobacionCalendarioHorario,
      this.translation.ComprobacionDenegarAcceso,
      this.translation.DiasIntervalo,
      this.translation.DiasIntervaloDesde,
      this.translation.Lunes,
      this.translation.Martes,
      this.translation.Miercoles,
      this.translation.Jueves,
      this.translation.Viernes,
      this.translation.Sabado,
      this.translation.Domingo,
      this.translation.NivelEntrada,
      this.translation.PasosPrevios,
      this.translation.PasosPreviosDiario,
    ];
    this.filtersToDisplay = this.displayedFilters.slice();

    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(
      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      (res) => {
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[
          this._ruta.snapshot.pathFromRoot.length - 1
        ].url.forEach((elemento) => {
          ruta = ruta + elemento.path + "/";
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(
          ruta,
          "VER"
        );
        if (!this.permisoFormulario) {
          this.router.navigate(["/portada"]);
        }
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(
          ruta,
          "NUEVO"
        );
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(
          ruta,
          "MODIFICAR"
        );
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(
          ruta,
          "ELIMINAR"
        );
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(
          ruta,
          "DUPLICAR"
        );
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({
            accion: "borrar",
            texto: this.translation.Borrar,
            funcion: "BorrarSeleccionados",
          });
        }
      }
    );
    //Seguimos
    this.cargarDatos();
    this.resetearFiltrosActivos();

    this.editorRegistroForm = this.formBuilder.group({
      pordefecto: [""],
      codigo: ["", [Validators.required], , this.estaUsado.bind(this)],
      nombre: ["", Validators.required],
      descripcion: [""],
      nivelentrada: [""],
      tipoControlComprobacion: [""],
      tipoControlDias: [""],
      diasintervalo: ["", Validators.maxLength(3)],
      numpasosprevios: ["", Validators.maxLength(3)],
      numpasospreviosdiarios: ["", Validators.maxLength(3)],
    });

    //TODO  Validators
    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [""],
      descripcion: [""],
      codigodesde: [""],
      codigohasta: [""],
      comphoraria: [""],
      compsinfecha: [""],
      compdiaria: [""],
      compdiasemana: [""],
      compintervalo: [""],
      comppasoprevio: [""],
      compcalendario: [""],
      compcalendariohora: [""],
      compdenegaracceso: [""],
      diasintervalodesde: [""],
      diasintervalohasta: [""],
      diasintervalodesdedesde: [""],
      diasintervalodesdehasta: [""],
      nivelentrada: [""],
      lunes: [""],
      martes: [""],
      miercoles: [""],
      jueves: [""],
      viernes: [""],
      sabado: [""],
      domingo: [""],
      pasopreviodesde: [""],
      pasopreviohasta: [""],
      pasopreviodiadesde: [""],
      pasopreviodiahasta: [""],
    });
  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get fFiltro() {
    return this.editorFiltrosForm.controls;
  } //Filtros

  AnadirRegistro() {
    this.limpiarmodelos();
    this.compruebaPorDefectovalor = this.compruebaPorDefecto("false");
    this.model = new traspasomodelo();
    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.CompHoraria = false;
    this.model.PermisoSinFecha = false;
    this.model.CompDiaria = false;
    this.model.CompDiaSemana = false;
    this.model.CompIntervalo = false;
    this.model.CompPasoPrevio = false;
    this.model.CompCalendario = false;
    this.model.CompCalendHorario = false;
    this.model.DenegarAcceso = false;
    this.model.AccesoLunes = false;
    this.model.AccesoMartes = false;
    this.model.AccesoMiercoles = false;
    this.model.AccesoJueves = false;
    this.model.AccesoViernes = false;
    this.model.AccesoSabado = false;
    this.model.AccesoDomingo = false;
    //Ponemos pasos previos con valor por defecto = 1
    this.model.NumPasosPrevios = "1";
    this.model.NumPasosPreviosDiarios = "1";
  }

  editar(item) {
    this.limpiarmodelos();
    this.compruebaPorDefectovalor = this.compruebaPorDefecto(item.PorDefecto);
    let objtraspaso = new traspasomodelo();
    objtraspaso.PkId = item.PkId;
    objtraspaso.Nombre = item.Nombre;
    objtraspaso.Descripcion = item.Descripcion;
    objtraspaso.CodigoTipoControl = item.CodigoTipoControl;
    objtraspaso.CompHoraria = this.globales.aBooleano(item.CompHoraria);
    objtraspaso.PermisoSinFecha = this.globales.aBooleano(item.PermisoSinFecha);
    objtraspaso.CompDiaria = this.globales.aBooleano(item.CompDiaria);
    objtraspaso.CompIntervalo = this.globales.aBooleano(item.CompIntervalo);
    objtraspaso.CompDiaSemana = this.globales.aBooleano(item.CompDiaSemana);
    objtraspaso.CompPasoPrevio = this.globales.aBooleano(item.CompPasoPrevio);
    objtraspaso.CompCalendario = this.globales.aBooleano(item.CompCalendario);
    objtraspaso.CompCalendHorario = this.globales.aBooleano(
      item.CompCalendHorario
    );
    objtraspaso.DenegarAcceso = this.globales.aBooleano(item.DenegarAcceso);
    objtraspaso.NumPasosPrevios = item.NumPasosPrevios;
    objtraspaso.DiasIntervalo = item.DiasIntervalo;
    objtraspaso.AccesoLunes = this.globales.aBooleano(item.AccesoLunes);
    objtraspaso.AccesoMartes = this.globales.aBooleano(item.AccesoMartes);
    objtraspaso.AccesoMiercoles = this.globales.aBooleano(item.AccesoMiercoles);
    objtraspaso.AccesoJueves = this.globales.aBooleano(item.AccesoJueves);
    objtraspaso.AccesoViernes = this.globales.aBooleano(item.AccesoViernes);
    objtraspaso.AccesoSabado = this.globales.aBooleano(item.AccesoSabado);
    objtraspaso.AccesoDomingo = this.globales.aBooleano(item.AccesoDomingo);
    objtraspaso.PorDefecto = this.globales.aBooleano(item.PorDefecto);
    objtraspaso.NivelEntrada = item.NivelEntrada;
    objtraspaso.NumPasosPreviosDiarios = item.NumPasosPreviosDiarios;
    objtraspaso.DiasIntervaloDesde = item.DiasIntervaloDesde;
    //Metemos los selectmultiple
    this.arr_comprobaciones_seleccionadas = [];
    if (objtraspaso.CompHoraria) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "CompHoraria",
        value: "Hora",
      });
    }
    if (objtraspaso.PermisoSinFecha) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "PermisoSinFecha",
        value: "Sin Fecha",
      });
    }
    if (objtraspaso.CompDiaria) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "CompDiaria",
        value: "Diaria",
      });
    }
    if (objtraspaso.CompDiaSemana) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "CompDiaSemana",
        value: "Dia Semana",
      });
    }
    if (objtraspaso.CompIntervalo) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "CompIntervalo",
        value: "Intervalo",
      });
    }
    if (objtraspaso.CompPasoPrevio) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "CompPasoPrevio",
        value: "Paso Previo",
      });
    }
    if (objtraspaso.CompCalendario) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "CompCalendario",
        value: "Calendario",
      });
    }
    if (objtraspaso.CompCalendHorario) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "CompCalendHorario",
        value: "Calendario Horario",
      });
    }
    if (objtraspaso.DenegarAcceso) {
      this.arr_comprobaciones_seleccionadas.push({
        id: "DenegarAcceso",
        value: "Sin Acceso",
      });
    }
    this.f["tipoControlComprobacion"].setValue(
      this.arr_comprobaciones_seleccionadas
    );
    this.EstadosControlesxComprobacionMultiple(
      this.arr_comprobaciones_seleccionadas
    );
    //Dias
    this.arr_dias_semana_seleccionadas = [];
    if (objtraspaso.AccesoLunes) {
      this.arr_dias_semana_seleccionadas.push({ id: "1", value: "Lunes" });
    }
    if (objtraspaso.AccesoMartes) {
      this.arr_dias_semana_seleccionadas.push({ id: "2", value: "Martes" });
    }
    if (objtraspaso.AccesoMiercoles) {
      this.arr_dias_semana_seleccionadas.push({ id: "3", value: "Miércoles" });
    }
    if (objtraspaso.AccesoJueves) {
      this.arr_dias_semana_seleccionadas.push({ id: "4", value: "Jueves" });
    }
    if (objtraspaso.AccesoViernes) {
      this.arr_dias_semana_seleccionadas.push({ id: "5", value: "Viernes" });
    }
    if (objtraspaso.AccesoSabado) {
      this.arr_dias_semana_seleccionadas.push({ id: "6", value: "Sábado" });
    }
    if (objtraspaso.AccesoDomingo) {
      this.arr_dias_semana_seleccionadas.push({ id: "7", value: "Domingo" });
    }
    this.f["tipoControlDias"].setValue(this.arr_dias_semana_seleccionadas);
    //F select multiple
    this.model = objtraspaso;
    this.cargarDatosCalendariosAsync(item.CodigoTipoControl);
    this.editando = true;
    this.alertaServicio.limpiar();
  }

  guardar() {
    let insertando = this.model.PkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el Pkid no sea null (estariamos insertando)
      let objtraspaso = new traspasomodelo();
      if (this.model.PkId != null) {
        objtraspaso = this.dataSource.data
          .map<traspasomodelo>(function (item) {
            return {
              PkId: item.pkId,
              Nombre: item.Nombre,
              Descripcion: item.Descripcion,
              CodigoTipoControl: item.CodigoTipoControl,
              CompHoraria: globales.aBooleano(item.CompHoraria),
              PermisoSinFecha: globales.aBooleano(item.PermisoSinFecha),
              CompDiaria: globales.aBooleano(item.CompDiaria),
              CompIntervalo: globales.aBooleano(item.CompIntervalo),
              CompDiaSemana: globales.aBooleano(item.CompDiaSemana),
              CompPasoPrevio: globales.aBooleano(item.CompPasoPrevio),
              CompCalendario: globales.aBooleano(item.CompCalendario),
              CompCalendHorario: globales.aBooleano(item.CompCalendHorario),
              DenegarAcceso: globales.aBooleano(item.DenegarAcceso),
              NumPasosPrevios: item.NumPasosPrevios,
              DiasIntervalo: item.DiasIntervalo,
              AccesoLunes: globales.aBooleano(item.AccesoLunes),
              AccesoMartes: globales.aBooleano(item.AccesoMartes),
              AccesoMiercoles: globales.aBooleano(item.AccesoMiercoles),
              AccesoJueves: globales.aBooleano(item.AccesoJueves),
              AccesoViernes: globales.aBooleano(item.AccesoViernes),
              AccesoSabado: globales.aBooleano(item.AccesoSabado),
              AccesoDomingo: globales.aBooleano(item.AccesoDomingo),
              PorDefecto: globales.aBooleano(item.PorDefecto),
              NivelEntrada: item.NivelEntrada,
              NumPasosPreviosDiarios: item.NumPasosPreviosDiarios,
              DiasIntervaloDesde: item.DiasIntervaloDesde,
              deserialize: null,
            };
          })
          .find((item) => item.PkId == this.model.PkId);
      }
      objtraspaso.Nombre = this.model.Nombre;
      objtraspaso.Descripcion = this.model.Descripcion;
      objtraspaso.CodigoTipoControl = this.model.CodigoTipoControl;
      //Comprobaciones
      this.calculaModeloComprobaciones();
      objtraspaso.CompHoraria = this.model.CompHoraria;
      objtraspaso.PermisoSinFecha = this.model.PermisoSinFecha;
      objtraspaso.CompDiaria = this.model.CompDiaria;
      objtraspaso.CompIntervalo = this.model.CompIntervalo;
      objtraspaso.CompDiaSemana = this.model.CompDiaSemana;
      objtraspaso.CompPasoPrevio = this.model.CompPasoPrevio;
      objtraspaso.CompCalendario = this.model.CompCalendario;
      objtraspaso.CompCalendHorario = this.model.CompCalendHorario;
      objtraspaso.DenegarAcceso = this.model.DenegarAcceso;
      //F
      objtraspaso.NumPasosPrevios = this.model.NumPasosPrevios;
      objtraspaso.DiasIntervalo = this.model.DiasIntervalo;
      //Dias Semana
      objtraspaso.AccesoLunes = this.model.AccesoLunes;
      objtraspaso.AccesoMartes = this.model.AccesoMartes;
      objtraspaso.AccesoMiercoles = this.model.AccesoMiercoles;
      objtraspaso.AccesoJueves = this.model.AccesoJueves;
      objtraspaso.AccesoViernes = this.model.AccesoViernes;
      objtraspaso.AccesoSabado = this.model.AccesoSabado;
      objtraspaso.AccesoDomingo = this.model.AccesoDomingo;
      //F
      objtraspaso.PorDefecto = this.model.PorDefecto;
      objtraspaso.NivelEntrada = this.model.NivelEntrada;
      objtraspaso.NumPasosPreviosDiarios = this.model.NumPasosPreviosDiarios;
      objtraspaso.DiasIntervaloDesde = this.model.DiasIntervaloDesde;
      //Salvar a API
      this.servicioAPI
        .insUpdTiposControl(
          this.model.PkId,
          this.model.CodigoTipoControl,
          this.model.Nombre,
          this.model.Descripcion,
          this.globales.aBit(this.model.CompHoraria).toString(),
          this.globales.aBit(this.model.PermisoSinFecha).toString(),
          this.globales.aBit(this.model.CompDiaria).toString(),
          this.globales.aBit(this.model.CompIntervalo).toString(),
          this.globales.aBit(this.model.CompDiaSemana).toString(),
          this.globales.aBit(this.model.CompPasoPrevio).toString(),
          this.globales.aBit(this.model.CompCalendario).toString(),
          this.globales.aBit(this.model.CompCalendHorario).toString(),
          this.globales.aBit(this.model.DenegarAcceso).toString(),
          this.model.DiasIntervalo,
          this.globales.aBit(this.model.AccesoLunes).toString(),
          this.globales.aBit(this.model.AccesoMartes).toString(),
          this.globales.aBit(this.model.AccesoMiercoles).toString(),
          this.globales.aBit(this.model.AccesoJueves).toString(),
          this.globales.aBit(this.model.AccesoViernes).toString(),
          this.globales.aBit(this.model.AccesoSabado).toString(),
          this.globales.aBit(this.model.AccesoDomingo).toString(),
          this.globales.aBit(this.model.PorDefecto).toString(),
          this.model.NivelEntrada,
          this.model.NumPasosPrevios,
          this.model.NumPasosPreviosDiarios,
          this.model.DiasIntervaloDesde
        )
        .pipe(first())
        .subscribe(
          (data) => {
            this.respuestaAPI = new TiposControl_Respuesta().deserialize(data);
            if (this.respuestaAPI.DatosResult != null) {
              this.alertaServicio.success("Datos Guardados Correctamente");
              let max = Math.max.apply(
                Math,
                this.dataSource.data.map(function (item) {
                  return item.pkId;
                })
              );
              //Hacemos un push al data para que se muestre
              if (insertando) {
                this.model.PkId = "" + (max + 1);
                let pusheddata = this.dataSource.data;
                pusheddata.push(this.modeloaclaseAPI(this.model));
                this.dataSource.data = pusheddata;
              } else {
                this.cargarDatos();
              }
              //Ahora guardamos los calendarios
              //Borramos Todos los anteriores
              this.servicioAPI
                .delCalendariosTiposControl(null, this.model.CodigoTipoControl)
                .pipe(first())
                .subscribe(
                  (data) => {
                    this.respuestaAPICalendario =
                      new CalendariosTiposControl_Respuesta().deserialize(data);
                    if (this.respuestaAPI.DatosResult != null) {
                      //this.alertaServicio.success("Datos Borrados Correctamente")
                      //Ahora insertamos los calendarios Primero Horario y luego Fecha
                      if (
                        this.model.CompCalendario &&
                        this.dataSourceCalendario != null &&
                        this.dataSourceCalendario.data != null &&
                        this.dataSourceCalendario.data.length > 0
                      ) {
                        this.dataSourceCalendario.data.forEach((element) => {
                          this.servicioAPI
                            .insUpdCalendariosTiposControlAsync(
                              "0",
                              this.model.PkId,
                              this.model.CodigoTipoControl,
                              this.model.Nombre,
                              element.FechaAcceso + " 00:00:00",
                              element.FechaAccesoFin + " 23:59:00"
                            )
                            .then(
                              (data) => {
                                this.respuestaAPICalendario =
                                  new CalendariosTiposControl_Respuesta().deserialize(
                                    data
                                  );
                                if (
                                  this.respuestaAPICalendario.DatosResult !=
                                  null
                                ) {
                                  //Ok
                                } else {
                                  this.alertaServicio.error(
                                    this.respuestaAPI.Mensajes[0]
                                      .DescripcionMensaje
                                  );
                                  this.editando = false;
                                }
                              },
                              (error) => {
                                this.alertaServicio.error(error);
                                this.editando = false;
                              }
                            );
                        });
                      }
                      if (
                        this.model.CompCalendHorario &&
                        this.dataSourceCalendarioHorario != null &&
                        this.dataSourceCalendarioHorario.data != null &&
                        this.dataSourceCalendarioHorario.data.length > 0
                      ) {
                        this.dataSourceCalendarioHorario.data.forEach(
                          (element) => {
                            this.servicioAPI
                              .insUpdCalendariosTiposControlAsync(
                                "0",
                                this.model.PkId,
                                this.model.CodigoTipoControl,
                                this.model.Nombre,
                                "01/01/1900 " + element.FechaAcceso + ":00",
                                "01/01/1900 " + element.FechaAccesoFin + ":00"
                              )
                              .then(
                                (data) => {
                                  this.respuestaAPICalendario =
                                    new CalendariosTiposControl_Respuesta().deserialize(
                                      data
                                    );
                                  if (
                                    this.respuestaAPICalendario.DatosResult !=
                                    null
                                  ) {
                                    //Ok
                                  } else {
                                    this.alertaServicio.error(
                                      this.respuestaAPI.Mensajes[0]
                                        .DescripcionMensaje
                                    );
                                    this.editando = false;
                                  }
                                },
                                (error) => {
                                  this.alertaServicio.error(error);
                                  this.editando = false;
                                }
                              );
                          }
                        );
                      }
                      this.alertaServicio.success(
                        "Datos Guardados Correctamente"
                      );
                      this.cargarDatos();
                    } else {
                      this.alertaServicio.error(
                        this.respuestaAPI.Mensajes[0].DescripcionMensaje
                      );
                    }
                  },
                  (error) => {
                    this.alertaServicio.error(error);
                  }
                );

              this.editando = false;
            } else {
              this.alertaServicio.error(
                this.respuestaAPI.Mensajes[0].DescripcionMensaje
              );
              this.editando = false;
            }
          },
          (error) => {
            this.alertaServicio.error(error);
            this.editando = false;
          }
        );
    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.PkId != null) {
      //Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo =
        this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (
        this.seleccion.selected == null ||
        this.seleccion.selected.length == 0
      ) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo =
          this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
      } else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo =
          this.translation.VaAProcederAlBorradoDe +
          this.seleccion.selected.length.toString() +
          this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    //Cambiar el boton
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(
            this.columnsToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex((x) => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
      }
    }
  }

  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    await this.cargarDatosAsync().then(
      (respuesta) => {
        let siono = new Boolean(respuesta);
        if (siono) {
          let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales
          if (filtro != null) {
            if (filtro.Nombre != null && filtro.Nombre.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.Nombre.indexOf(filtro.Nombre.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
            }
            this.fFiltro["nombre"].setValue(filtro.Nombre.trim());

            if (filtro.Descripcion != null && filtro.Descripcion.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) => x.Descripcion.indexOf(filtro.Descripcion.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Descripcion = filtro.Descripcion.trim();
            }
            this.fFiltro["descripcion"].setValue(filtro.Nombre.trim());

            if (
              filtro.codigodesde != null &&
              filtro.codigohasta != null &&
              (filtro.codigodesde.trim() != "" ||
                filtro.codigohasta.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CodigoTipoControl >=
                    (filtro.codigodesde.trim() == ""
                      ? -9999999999
                      : filtro.codigodesde.trim()) &&
                  x.CodigoTipoControl <=
                    (filtro.codigohasta.trim() == ""
                      ? 9999999999
                      : filtro.codigohasta.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.codigodesde = filtro.codigodesde.trim();
              this.filtrosactivosModelo.codigohasta = filtro.codigohasta.trim();
            }
            this.fFiltro["codigodesde"].setValue(filtro.codigodesde.trim());
            this.fFiltro["codigohasta"].setValue(filtro.codigohasta.trim());

            if (filtro.comphoraria != null && filtro.comphoraria.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompHoraria.trim().toLowerCase() ==
                  (filtro.comphoraria.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.comphoraria = filtro.comphoraria.trim();
            }
            this.fFiltro["comphoraria"].setValue(filtro.comphoraria.trim());

            if (
              filtro.compsinfecha != null &&
              filtro.compsinfecha.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.PermisoSinFecha.trim().toLowerCase() ==
                  (filtro.compsinfecha.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compsinfecha =
                filtro.compsinfecha.trim();
            }
            this.fFiltro["compsinfecha"].setValue(filtro.compsinfecha.trim());

            if (filtro.compdiaria != null && filtro.compdiaria.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompDiaria.trim().toLowerCase() ==
                  (filtro.compdiaria.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compdiaria = filtro.compdiaria.trim();
            }
            this.fFiltro["compdiaria"].setValue(filtro.compdiaria.trim());

            if (
              filtro.compdiasemana != null &&
              filtro.compdiasemana.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompDiaSemana.trim().toLowerCase() ==
                  (filtro.compdiasemana.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compdiasemana =
                filtro.compdiasemana.trim();
            }
            this.fFiltro["compdiasemana"].setValue(filtro.compdiasemana.trim());

            if (
              filtro.compintervalo != null &&
              filtro.compintervalo.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompIntervalo.trim().toLowerCase() ==
                  (filtro.compintervalo.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compintervalo =
                filtro.compintervalo.trim();
            }
            this.fFiltro["compintervalo"].setValue(filtro.compintervalo.trim());

            if (
              filtro.comppasoprevio != null &&
              filtro.comppasoprevio.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompPasoPrevio.trim().toLowerCase() ==
                  (filtro.comppasoprevio.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.comppasoprevio =
                filtro.comppasoprevio.trim();
            }
            this.fFiltro["comppasoprevio"].setValue(
              filtro.comppasoprevio.trim()
            );

            if (
              filtro.compcalendario != null &&
              filtro.compcalendario.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompCalendario.trim().toLowerCase() ==
                  (filtro.compcalendario.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compcalendario =
                filtro.compcalendario.trim();
            }
            this.fFiltro["compcalendario"].setValue(
              filtro.compcalendario.trim()
            );

            if (
              filtro.compcalendariohora != null &&
              filtro.compcalendariohora.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompCalendHorario.trim().toLowerCase() ==
                  (filtro.compcalendariohora.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compcalendariohora =
                filtro.compcalendariohora.trim();
            }
            this.fFiltro["compcalendariohora"].setValue(
              filtro.compcalendariohora.trim()
            );

            if (
              filtro.compdenegaracceso != null &&
              filtro.compdenegaracceso.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.DenegarAcceso.trim().toLowerCase() ==
                  (filtro.compdenegaracceso.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compdenegaracceso =
                filtro.compdenegaracceso.trim();
            }
            this.fFiltro["compdenegaracceso"].setValue(
              filtro.compdenegaracceso.trim()
            );

            if (
              filtro.diasintervalodesde != null &&
              filtro.diasintervalohasta != null &&
              (filtro.diasintervalodesde.trim() != "" ||
                filtro.diasintervalohasta.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.DiasIntervalo >=
                    (filtro.diasintervalodesde.trim() == ""
                      ? -9999999999
                      : filtro.diasintervalodesde.trim()) &&
                  x.DiasIntervalo <=
                    (filtro.diasintervalohasta.trim() == ""
                      ? 9999999999
                      : filtro.diasintervalohasta.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.diasintervalodesde =
                filtro.diasintervalodesde.trim();
              this.filtrosactivosModelo.diasintervalohasta =
                filtro.diasintervalohasta.trim();
            }
            this.fFiltro["diasintervalodesde"].setValue(
              filtro.diasintervalodesde.trim()
            );
            this.fFiltro["diasintervalohasta"].setValue(
              filtro.diasintervalohasta.trim()
            );

            if (
              filtro.diasintervalodesdedesde != null &&
              filtro.diasintervalodesdehasta != null &&
              (filtro.diasintervalodesdedesde.trim() != "" ||
                filtro.diasintervalodesdehasta.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.DiasIntervaloDesde >=
                    (filtro.diasintervalodesdedesde.trim() == ""
                      ? -9999999999
                      : filtro.diasintervalodesdedesde.trim()) &&
                  x.DiasIntervaloDesde <=
                    (filtro.diasintervalodesdehasta.trim() == ""
                      ? 9999999999
                      : filtro.diasintervalodesdehasta.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.diasintervalodesdedesde =
                filtro.diasintervalodesdedesde.trim();
              this.filtrosactivosModelo.diasintervalodesdehasta =
                filtro.diasintervalodesdehasta.trim();
            }
            this.fFiltro["diasintervalodesdedesde"].setValue(
              filtro.diasintervalodesdedesde.trim()
            );
            this.fFiltro["diasintervalodesdehasta"].setValue(
              filtro.diasintervalodesdehasta.trim()
            );

            if (filtro.lunes != null && filtro.lunes.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoLunes.trim().toLowerCase() ==
                  (filtro.lunes.trim().toLowerCase() == "si" ? "true" : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.lunes = filtro.lunes.trim();
            }
            this.fFiltro["lunes"].setValue(filtro.lunes.trim());

            if (filtro.martes != null && filtro.martes.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoMartes.trim().toLowerCase() ==
                  (filtro.martes.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.martes = filtro.martes.trim();
            }
            this.fFiltro["martes"].setValue(filtro.martes.trim());

            if (filtro.miercoles != null && filtro.miercoles.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoMiercoles.trim().toLowerCase() ==
                  (filtro.miercoles.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.miercoles = filtro.miercoles.trim();
            }
            this.fFiltro["miercoles"].setValue(filtro.miercoles.trim());

            if (filtro.jueves != null && filtro.jueves.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoJueves.trim().toLowerCase() ==
                  (filtro.jueves.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.jueves = filtro.jueves.trim();
            }
            this.fFiltro["jueves"].setValue(filtro.jueves.trim());

            if (filtro.viernes != null && filtro.viernes.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoViernes.trim().toLowerCase() ==
                  (filtro.viernes.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.viernes = filtro.viernes.trim();
            }
            this.fFiltro["viernes"].setValue(filtro.viernes.trim());

            if (filtro.sabado != null && filtro.sabado.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoSabado.trim().toLowerCase() ==
                  (filtro.sabado.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.sabado = filtro.sabado.trim();
            }
            this.fFiltro["sabado"].setValue(filtro.sabado.trim());

            if (filtro.domingo != null && filtro.domingo.trim() != "") {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoDomingo.trim().toLowerCase() ==
                  (filtro.domingo.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.domingo = filtro.domingo.trim();
            }
            this.fFiltro["domingo"].setValue(filtro.domingo.trim());

            if (
              filtro.nivelentrada != null &&
              filtro.nivelentrada.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.NivelEntrada.trim().toLowerCase() ==
                  filtro.nivelentrada.trim().toLowerCase()
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.nivelentrada =
                filtro.nivelentrada.trim();
            }
            this.fFiltro["nivelentrada"].setValue(filtro.nivelentrada.trim());

            if (
              filtro.pasopreviodesde != null &&
              filtro.pasopreviohasta != null &&
              (filtro.pasopreviodesde.trim() != "" ||
                filtro.pasopreviohasta.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.NumPasosPrevios >=
                    (filtro.pasopreviodesde.trim() == ""
                      ? -9999999999
                      : filtro.pasopreviodesde.trim()) &&
                  x.NumPasosPrevios <=
                    (filtro.pasopreviohasta.trim() == ""
                      ? 9999999999
                      : filtro.pasopreviohasta.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.pasopreviodesde =
                filtro.pasopreviodesde.trim();
              this.filtrosactivosModelo.pasopreviohasta =
                filtro.pasopreviohasta.trim();
            }
            this.fFiltro["pasopreviodesde"].setValue(
              filtro.pasopreviodesde.trim()
            );
            this.fFiltro["pasopreviohasta"].setValue(
              filtro.pasopreviohasta.trim()
            );

            if (
              filtro.pasopreviodiadesde != null &&
              filtro.pasopreviodiahasta != null &&
              (filtro.pasopreviodiadesde.trim() != "" ||
                filtro.pasopreviodiahasta.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.NumPasosPreviosDiarios >=
                    (filtro.pasopreviodiadesde.trim() == ""
                      ? -9999999999
                      : filtro.pasopreviodiadesde.trim()) &&
                  x.NumPasosPreviosDiarios <=
                    (filtro.pasopreviodiahasta.trim() == ""
                      ? 9999999999
                      : filtro.pasopreviodiahasta.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.pasopreviodiadesde =
                filtro.pasopreviodiadesde.trim();
              this.filtrosactivosModelo.pasopreviodiahasta =
                filtro.pasopreviodiahasta.trim();
            }
            this.fFiltro["pasopreviodiadesde"].setValue(
              filtro.pasopreviodiadesde.trim()
            );
            this.fFiltro["pasopreviodiahasta"].setValue(
              filtro.pasopreviodiahasta.trim()
            );
          } else {
            if (
              this.fFiltro["nombre"].value != null &&
              this.fFiltro["nombre"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Nombre.indexOf(this.fFiltro["nombre"].value.trim()) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = this.fFiltro["nombre"].value;
            }

            if (
              this.fFiltro["descripcion"].value != null &&
              this.fFiltro["descripcion"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.Descripcion.trim()
                    .toLowerCase()
                    .indexOf(
                      this.fFiltro["descripcion"].value.trim().toLowerCase()
                    ) >= 0
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Descripcion =
                this.fFiltro["descripcion"].value;
            }

            if (
              this.fFiltro["codigodesde"].value != null &&
              this.fFiltro["codigohasta"].value != null &&
              (this.fFiltro["codigodesde"].value.trim() != "" ||
                this.fFiltro["codigohasta"].value.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CodigoTipoControl >=
                    (this.fFiltro["codigodesde"].value.trim() == ""
                      ? -9999999999
                      : this.fFiltro["codigodesde"].value.trim()) &&
                  x.CodigoTipoControl <=
                    (this.fFiltro["codigohasta"].value.trim() == ""
                      ? 9999999999
                      : this.fFiltro["codigohasta"].value.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.codigodesde =
                this.fFiltro["codigodesde"].value.trim();
              this.filtrosactivosModelo.codigohasta =
                this.fFiltro["codigohasta"].value.trim();
            }

            if (
              this.fFiltro["comphoraria"].value != null &&
              this.fFiltro["comphoraria"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompHoraria.trim().toLowerCase() ==
                  (this.fFiltro["comphoraria"].value.trim().toLowerCase() ==
                  "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.comphoraria =
                this.fFiltro["comphoraria"].value;
            }

            if (
              this.fFiltro["compsinfecha"].value != null &&
              this.fFiltro["compsinfecha"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.PermisoSinFecha.trim().toLowerCase() ==
                  (this.fFiltro["compsinfecha"].value.trim().toLowerCase() ==
                  "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compsinfecha =
                this.fFiltro["compsinfecha"].value;
            }

            if (
              this.fFiltro["compdiaria"].value != null &&
              this.fFiltro["compdiaria"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompDiaria.trim().toLowerCase() ==
                  (this.fFiltro["compdiaria"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compdiaria =
                this.fFiltro["compdiaria"].value;
            }

            if (
              this.fFiltro["compdiasemana"].value != null &&
              this.fFiltro["compdiasemana"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompDiaSemana.trim().toLowerCase() ==
                  (this.fFiltro["compdiasemana"].value.trim().toLowerCase() ==
                  "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compdiasemana =
                this.fFiltro["compdiasemana"].value;
            }

            if (
              this.fFiltro["compintervalo"].value != null &&
              this.fFiltro["compintervalo"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompIntervalo.trim().toLowerCase() ==
                  (this.fFiltro["compintervalo"].value.trim().toLowerCase() ==
                  "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compintervalo =
                this.fFiltro["compintervalo"].value;
            }

            if (
              this.fFiltro["comppasoprevio"].value != null &&
              this.fFiltro["comppasoprevio"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompPasoPrevio.trim().toLowerCase() ==
                  (this.fFiltro["comppasoprevio"].value.trim().toLowerCase() ==
                  "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.comppasoprevio =
                this.fFiltro["comppasoprevio"].value;
            }

            if (
              this.fFiltro["compcalendario"].value != null &&
              this.fFiltro["compcalendario"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompCalendario.trim().toLowerCase() ==
                  (this.fFiltro["compcalendario"].value.trim().toLowerCase() ==
                  "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compcalendario =
                this.fFiltro["compcalendario"].value;
            }

            if (
              this.fFiltro["compcalendariohora"].value != null &&
              this.fFiltro["compcalendariohora"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.CompCalendHorario.trim().toLowerCase() ==
                  (this.fFiltro["compcalendariohora"].value
                    .trim()
                    .toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compcalendariohora =
                this.fFiltro["compcalendariohora"].value;
            }

            if (
              this.fFiltro["compdenegaracceso"].value != null &&
              this.fFiltro["compdenegaracceso"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.DenegarAcceso.trim().toLowerCase() ==
                  (this.fFiltro["compdenegaracceso"].value
                    .trim()
                    .toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.compdenegaracceso =
                this.fFiltro["compdenegaracceso"].value;
            }

            if (
              this.fFiltro["diasintervalodesde"].value != null &&
              this.fFiltro["diasintervalohasta"].value != null &&
              (this.fFiltro["diasintervalodesde"].value.trim() != "" ||
                this.fFiltro["diasintervalohasta"].value.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.DiasIntervalo >=
                    (this.fFiltro["diasintervalodesde"].value.trim() == ""
                      ? -9999999999
                      : this.fFiltro["diasintervalodesde"].value.trim()) &&
                  x.DiasIntervalo <=
                    (this.fFiltro["diasintervalohasta"].value.trim() == ""
                      ? 9999999999
                      : this.fFiltro["diasintervalohasta"].value.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.diasintervalodesde =
                this.fFiltro["diasintervalodesde"].value.trim();
              this.filtrosactivosModelo.diasintervalohasta =
                this.fFiltro["diasintervalohasta"].value.trim();
            }

            if (
              this.fFiltro["diasintervalodesdedesde"].value != null &&
              this.fFiltro["diasintervalodesdehasta"].value != null &&
              (this.fFiltro["diasintervalodesdedesde"].value.trim() != "" ||
                this.fFiltro["diasintervalodesdehasta"].value.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.DiasIntervaloDesde >=
                    (this.fFiltro["diasintervalodesdedesde"].value.trim() == ""
                      ? -9999999999
                      : this.fFiltro["diasintervalodesdedesde"].value.trim()) &&
                  x.DiasIntervaloDesde <=
                    (this.fFiltro["diasintervalodesdehasta"].value.trim() == ""
                      ? 9999999999
                      : this.fFiltro["diasintervalodesdehasta"].value.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.diasintervalodesdedesde =
                this.fFiltro["diasintervalodesdedesde"].value.trim();
              this.filtrosactivosModelo.diasintervalodesdehasta =
                this.fFiltro["diasintervalodesdehasta"].value.trim();
            }

            if (
              this.fFiltro["lunes"].value != null &&
              this.fFiltro["lunes"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoLunes.trim().toLowerCase() ==
                  (this.fFiltro["lunes"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.lunes = this.fFiltro["lunes"].value;
            }

            if (
              this.fFiltro["martes"].value != null &&
              this.fFiltro["martes"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoMartes.trim().toLowerCase() ==
                  (this.fFiltro["martes"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.martes = this.fFiltro["martes"].value;
            }

            if (
              this.fFiltro["miercoles"].value != null &&
              this.fFiltro["miercoles"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoMiercoles.trim().toLowerCase() ==
                  (this.fFiltro["miercoles"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.miercoles =
                this.fFiltro["miercoles"].value;
            }

            if (
              this.fFiltro["jueves"].value != null &&
              this.fFiltro["jueves"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoJueves.trim().toLowerCase() ==
                  (this.fFiltro["jueves"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.jueves = this.fFiltro["jueves"].value;
            }

            if (
              this.fFiltro["viernes"].value != null &&
              this.fFiltro["viernes"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoViernes.trim().toLowerCase() ==
                  (this.fFiltro["viernes"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.viernes = this.fFiltro["viernes"].value;
            }

            if (
              this.fFiltro["sabado"].value != null &&
              this.fFiltro["sabado"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoSabado.trim().toLowerCase() ==
                  (this.fFiltro["sabado"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.sabado = this.fFiltro["sabado"].value;
            }

            if (
              this.fFiltro["domingo"].value != null &&
              this.fFiltro["domingo"].value.trim() != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.AccesoDomingo.trim().toLowerCase() ==
                  (this.fFiltro["domingo"].value.trim().toLowerCase() == "si"
                    ? "true"
                    : "false")
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.domingo = this.fFiltro["domingo"].value;
            }

            if (
              this.fFiltro["nivelentrada"].value != null &&
              this.fFiltro["nivelentrada"].value != ""
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.NivelEntrada == this.fFiltro["nivelentrada"].value.trim()
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.nivelentrada =
                this.fFiltro["nivelentrada"].value;
            }

            if (
              this.fFiltro["pasopreviodesde"].value != null &&
              this.fFiltro["pasopreviohasta"].value != null &&
              (this.fFiltro["pasopreviodesde"].value.trim() != "" ||
                this.fFiltro["pasopreviohasta"].value.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.NumPasosPrevios >=
                    (this.fFiltro["pasopreviodesde"].value.trim() == ""
                      ? "01/01/1970"
                      : this.fFiltro["pasopreviodesde"].value.trim()) &&
                  x.NumPasosPrevios <=
                    (this.fFiltro["pasopreviohasta"].value.trim() == ""
                      ? "31/12/2999"
                      : this.fFiltro["pasopreviohasta"].value.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.pasopreviodesde =
                this.fFiltro["pasopreviodesde"].value.trim();
              this.filtrosactivosModelo.pasopreviohasta =
                this.fFiltro["pasopreviohasta"].value.trim();
            }

            if (
              this.fFiltro["pasopreviodiadesde"].value != null &&
              this.fFiltro["pasopreviodiahasta"].value != null &&
              (this.fFiltro["pasopreviodiadesde"].value.trim() != "" ||
                this.fFiltro["pasopreviodiahasta"].value.trim() != "")
            ) {
              datostemporales = datostemporales.filter(
                (x) =>
                  x.NumPasosPreviosDiarios >=
                    (this.fFiltro["pasopreviodiadesde"].value.trim() == ""
                      ? "01/01/1970"
                      : this.fFiltro["pasopreviodiadesde"].value.trim()) &&
                  x.NumPasosPreviosDiarios <=
                    (this.fFiltro["pasopreviodiahasta"].value.trim() == ""
                      ? "31/12/2999"
                      : this.fFiltro["pasopreviodiahasta"].value.trim())
              );
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.pasopreviodiadesde =
                this.fFiltro["pasopreviodiadesde"].value.trim();
              this.filtrosactivosModelo.pasopreviodiahasta =
                this.fFiltro["pasopreviodiahasta"].value.trim();
            }
          }

          this.dataSource.data = datostemporales;

          if (
            this.filtrosactivosModelo.textoGeneral != null &&
            this.filtrosactivosModelo.textoGeneral.trim() != ""
          ) {
            this.dataSource.filter = this.filtrosactivosModelo.textoGeneral
              .trim()
              .toLowerCase();
          } else {
            //Solo borramos el input text, el filtro se actualiza Ok
            //this.filtrogeneral.nativeElement.value = '';
          }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);

          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          //No hemos podido cargar los datos
        }
      },
      (error) => {
        //No hemos podido cargar los datos
      }
    );
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre =
      filtro == null || filtro == "Nombre"
        ? ""
        : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.Descripcion =
      filtro == null || filtro == "Descripcion"
        ? ""
        : this.filtrosactivosModelo.Descripcion;
    this.filtrosactivosModelo.codigodesde =
      filtro == null || filtro == "codigo"
        ? ""
        : this.filtrosactivosModelo.codigodesde;
    this.filtrosactivosModelo.codigohasta =
      filtro == null || filtro == "codigo"
        ? ""
        : this.filtrosactivosModelo.codigohasta;
    this.filtrosactivosModelo.comphoraria =
      filtro == null || filtro == "comphoraria"
        ? ""
        : this.filtrosactivosModelo.comphoraria;
    this.filtrosactivosModelo.compsinfecha =
      filtro == null || filtro == "compsinfecha"
        ? ""
        : this.filtrosactivosModelo.compsinfecha;
    this.filtrosactivosModelo.compdiaria =
      filtro == null || filtro == "compdiaria"
        ? ""
        : this.filtrosactivosModelo.compdiaria;
    this.filtrosactivosModelo.compdiasemana =
      filtro == null || filtro == "compdiasemana"
        ? ""
        : this.filtrosactivosModelo.compdiasemana;
    this.filtrosactivosModelo.compintervalo =
      filtro == null || filtro == "compintervalo"
        ? ""
        : this.filtrosactivosModelo.compintervalo;
    this.filtrosactivosModelo.comppasoprevio =
      filtro == null || filtro == "comppasoprevio"
        ? ""
        : this.filtrosactivosModelo.comppasoprevio;
    this.filtrosactivosModelo.compcalendario =
      filtro == null || filtro == "compcalendario"
        ? ""
        : this.filtrosactivosModelo.compcalendario;
    this.filtrosactivosModelo.compcalendariohora =
      filtro == null || filtro == "compcalendariohora"
        ? ""
        : this.filtrosactivosModelo.compcalendariohora;
    this.filtrosactivosModelo.compdenegaracceso =
      filtro == null || filtro == "compdenegaracceso"
        ? ""
        : this.filtrosactivosModelo.compdenegaracceso;
    this.filtrosactivosModelo.diasintervalodesde =
      filtro == null || filtro == "diasintervalo"
        ? ""
        : this.filtrosactivosModelo.diasintervalodesde;
    this.filtrosactivosModelo.diasintervalohasta =
      filtro == null || filtro == "diasintervalo"
        ? ""
        : this.filtrosactivosModelo.diasintervalohasta;
    this.filtrosactivosModelo.diasintervalodesdedesde =
      filtro == null || filtro == "diasintervalodesde"
        ? ""
        : this.filtrosactivosModelo.diasintervalodesdedesde;
    this.filtrosactivosModelo.diasintervalodesdehasta =
      filtro == null || filtro == "diasintervalodesde"
        ? ""
        : this.filtrosactivosModelo.diasintervalodesdehasta;
    this.filtrosactivosModelo.lunes =
      filtro == null || filtro == "lunes"
        ? ""
        : this.filtrosactivosModelo.lunes;
    this.filtrosactivosModelo.martes =
      filtro == null || filtro == "martes"
        ? ""
        : this.filtrosactivosModelo.martes;
    this.filtrosactivosModelo.miercoles =
      filtro == null || filtro == "miercoles"
        ? ""
        : this.filtrosactivosModelo.miercoles;
    this.filtrosactivosModelo.jueves =
      filtro == null || filtro == "jueves"
        ? ""
        : this.filtrosactivosModelo.jueves;
    this.filtrosactivosModelo.viernes =
      filtro == null || filtro == "viernes"
        ? ""
        : this.filtrosactivosModelo.viernes;
    this.filtrosactivosModelo.sabado =
      filtro == null || filtro == "sabado"
        ? ""
        : this.filtrosactivosModelo.sabado;
    this.filtrosactivosModelo.domingo =
      filtro == null || filtro == "domingo"
        ? ""
        : this.filtrosactivosModelo.domingo;
    this.filtrosactivosModelo.nivelentrada =
      filtro == null || filtro == "nivelentrada"
        ? ""
        : this.filtrosactivosModelo.nivelentrada;
    this.filtrosactivosModelo.pasopreviodesde =
      filtro == null || filtro == "pasoprevio"
        ? ""
        : this.filtrosactivosModelo.pasopreviodesde;
    this.filtrosactivosModelo.pasopreviohasta =
      filtro == null || filtro == "pasoprevio"
        ? ""
        : this.filtrosactivosModelo.pasopreviohasta;
    this.filtrosactivosModelo.pasopreviodiadesde =
      filtro == null || filtro == "pasopreviodia"
        ? ""
        : this.filtrosactivosModelo.pasopreviodiadesde;
    this.filtrosactivosModelo.pasopreviodiahasta =
      filtro == null || filtro == "pasopreviodia"
        ? ""
        : this.filtrosactivosModelo.pasopreviodiahasta;
    this.filtrosactivosModelo.textoGeneral =
      filtro == null || filtro == "General"
        ? ""
        : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
    }
  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if (
      (filtros.Nombre != null && filtros.Nombre.trim() != "") ||
      (filtros.Descripcion != null && filtros.Descripcion.trim() != "") ||
      (filtros.codigodesde != null && filtros.codigodesde.trim() != "") ||
      (filtros.codigohasta != null && filtros.codigohasta.trim() != "") ||
      (filtros.comphoraria != null && filtros.comphoraria.trim() != "") ||
      (filtros.compsinfecha != null && filtros.compsinfecha.trim() != "") ||
      (filtros.compdiaria != null && filtros.compdiaria.trim() != "") ||
      (filtros.compdiasemana != null && filtros.compdiasemana.trim() != "") ||
      (filtros.compintervalo != null && filtros.compintervalo.trim() != "") ||
      (filtros.comppasoprevio != null && filtros.comppasoprevio.trim() != "") ||
      (filtros.compcalendario != null && filtros.compcalendario.trim() != "") ||
      (filtros.compcalendariohora != null &&
        filtros.compcalendariohora.trim() != "") ||
      (filtros.compdenegaracceso != null &&
        filtros.compdenegaracceso.trim() != "") ||
      (filtros.diasintervalodesde != null &&
        filtros.diasintervalodesde.trim() != "") ||
      (filtros.diasintervalohasta != null &&
        filtros.diasintervalohasta.trim() != "") ||
      (filtros.diasintervalodesdedesde != null &&
        filtros.diasintervalodesdedesde.trim() != "") ||
      (filtros.diasintervalodesdehasta != null &&
        filtros.diasintervalodesdehasta.trim() != "") ||
      (filtros.lunes != null && filtros.lunes.trim() != "") ||
      (filtros.martes != null && filtros.martes.trim() != "") ||
      (filtros.miercoles != null && filtros.miercoles.trim() != "") ||
      (filtros.jueves != null && filtros.jueves.trim() != "") ||
      (filtros.viernes != null && filtros.viernes.trim() != "") ||
      (filtros.sabado != null && filtros.sabado.trim() != "") ||
      (filtros.domingo != null && filtros.domingo.trim() != "") ||
      (filtros.nivelentrada != null && filtros.nivelentrada.trim() != "") ||
      (filtros.pasopreviodesde != null &&
        filtros.pasopreviodesde.trim() != "") ||
      (filtros.pasopreviohasta != null &&
        filtros.pasopreviohasta.trim() != "") ||
      (filtros.pasopreviodiadesde != null &&
        filtros.pasopreviodiadesde.trim() != "") ||
      (filtros.pasopreviodiahasta != null &&
        filtros.pasopreviodiahasta.trim() != "") ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != "")
    )
      return true;
    else return false;
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2
      ? o1.id === o2.id || o1 === o2.id || o1.id === o2
      : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.seleccion.clear()
      : this.dataSource.data.forEach((row) => this.seleccion.select(row));
  }
  ///Validador
  CambioComprobacionMultiple(evento) {
    if (evento.value != null && evento.value.length > 0) {
      this.EstadosControlesxComprobacionMultiple(evento.value);
    }
  }

  EstadosControlesxComprobacionMultiple(lista) {
    //(des)activar el diasemana
    if (
      lista.findIndex((x) => x == "CompDiaSemana" || x.id == "CompDiaSemana") >=
      0
    ) {
      this.f["tipoControlDias"].enable();
    } else {
      this.f["tipoControlDias"].disable();
    }
    //(des)activar el Intervalo
    if (
      lista.findIndex((x) => x == "CompIntervalo" || x.id == "CompIntervalo") >=
      0
    ) {
      this.f["diasintervalo"].enable();
    } else {
      this.f["diasintervalo"].disable();
    }
    //(des)activar el Intervalo
    if (
      lista.findIndex(
        (x) => x == "CompPasoPrevio" || x.id == "CompPasoPrevio"
      ) >= 0
    ) {
      this.f["numpasosprevios"].enable();
      this.f["numpasospreviosdiarios"].enable();
    } else {
      this.f["numpasosprevios"].disable();
      this.f["numpasospreviosdiarios"].disable();
    }
    //Acordeones
    //(des)activar el Calendario
    if (
      lista.findIndex(
        (x) => x == "CompCalendario" || x.id == "CompCalendario"
      ) >= 0
    ) {
      //this.f["panelCalendario"].enable();
      this.panelCalendarioDisabled = false;
      this.panelCalendarioAbierto = true;
    } else {
      //this.f["panelCalendario"].disable();
      this.panelCalendarioAbierto = false;
      this.panelCalendarioDisabled = true;
    }
    //(des)activar el Calendario Horario
    if (
      lista.findIndex(
        (x) => x == "CompCalendHorario" || x.id == "CompCalendHorario"
      ) >= 0
    ) {
      //this.f["panelCalendarioHorario"].enable();
      this.panelCalendarioHorarioDisabled = false;
      this.panelCalendarioHorarioAbierto = true;
    } else {
      //this.f["panelCalendarioHorario"].disable();
      this.panelCalendarioHorarioAbierto = false;
      this.panelCalendarioHorarioDisabled = true;
    }
  }

  CambioDiasSemanaMultiple(evento) {
    //En principio no lo vamos a usar
  }
  ///Funcionalidades

  cargarDatos() {
    //Cargamos Datos
    this.servicioAPI
      .getTiposControl()
      .pipe(first())
      .subscribe(
        (data) => {
          this.respuestaAPI = new TiposControl_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(
              this.respuestaAPI.DatosResult.customanTiposControl
            );
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          } else {
            this.alertaServicio.error(
              this.respuestaAPI.Mensajes[0].DescripcionMensaje
            );
          }
        },
        (error) => {
          this.alertaServicio.error(error);
        }
      );
  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    await this.servicioAPI.getTiposControlAsync().then(
      (data) => {
        this.respuestaAPI = new TiposControl_Respuesta().deserialize(data);
        if (this.respuestaAPI.DatosResult != null) {
          this.dataSource = new MatTableDataSource(
            this.respuestaAPI.DatosResult.customanTiposControl
          );
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.alertaServicio.error(
            this.respuestaAPI.Mensajes[0].DescripcionMensaje
          );
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );
    return resultado;
  }

  async cargarDatosCalendariosAsync(codigoTC?: string): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    await this.servicioAPI.getCalendariosTiposControlAsync(null, codigoTC).then(
      (data) => {
        this.respuestaAPICalendario =
          new CalendariosTiposControl_Respuesta().deserialize(data);
        if (this.respuestaAPICalendario.DatosResult != null) {
          this.dataSourceCalendario = new MatTableDataSource(
            this.respuestaAPICalendario.DatosResult.customanCalendariosTiposControl
              .filter(
                (x) => x.FechaAcceso.substring(0, 10).trim() != "01/01/1900"
              )
              .map(function (x) {
                return {
                  pkId: x.pkId,
                  TipoControlId: x.TipoControlId,
                  CodigoTipoControl: x.CodigoTipoControl,
                  NombreTipoControl: x.NombreTipoControl,
                  FechaAcceso: x.FechaAcceso.trim().substring(0, 10),
                  FechaAccesoFin: x.FechaAccesoFin.trim().substring(0, 10),
                  deserialize: null,
                };
              })
          );
          this.dataSourceCalendarioHorario = new MatTableDataSource(
            this.respuestaAPICalendario.DatosResult.customanCalendariosTiposControl
              .filter(
                (x) => x.FechaAcceso.substring(0, 10).trim() == "01/01/1900"
              )
              .map(function (x) {
                return {
                  pkId: x.pkId,
                  TipoControlId: x.TipoControlId,
                  CodigoTipoControl: x.CodigoTipoControl,
                  NombreTipoControl: x.NombreTipoControl,
                  FechaAcceso: x.FechaAcceso.trim().substring(10, 20).trim(),
                  FechaAccesoFin: x.FechaAccesoFin.trim()
                    .substring(10, 20)
                    .trim(),
                  deserialize: null,
                };
              })
          );
          this.dataSourceCalendario.paginator = this.paginatorCalendario;
          this.dataSourceCalendario.sort = this.sortCalendario;
          this.dataSourceCalendarioHorario.paginator =
            this.paginatorCalendarioHorario;
          this.dataSourceCalendarioHorario.sort = this.sortCalendarioHorario;
        } else {
          //Si no hay calendarios no es un error asignamos un array vacio
          this.dataSourceCalendario = new MatTableDataSource([]);
          this.dataSourceCalendarioHorario = new MatTableDataSource([]);
          this.dataSourceCalendario.paginator = this.paginatorCalendario;
          this.dataSourceCalendario.sort = this.sortCalendario;
          this.dataSourceCalendarioHorario.paginator =
            this.paginatorCalendarioHorario;
          this.dataSourceCalendarioHorario.sort = this.sortCalendarioHorario;
          //this.alertaServicio.error(this.respuestaAPICalendario.Mensajes[0].DescripcionMensaje);
        }
      },
      (error) => {
        this.alertaServicio.error(error);
        resultado = false;
      }
    );
    return resultado;
  }

  anadirCalendario() {
    this.objedicionCalendario = new CalendariosTipoControl();
    this.objedicionCalendario.CodigoTipoControl = this.model.CodigoTipoControl;
    this.editandoCalendarios = true;
  }

  editarCalendario(fila) {
    //TODO
  }

  guardarCalendarioRango() {
    //metemos el rango en el datasource
    let max = 0;
    if (this.dataSourceCalendario.data.length > 0) {
      max = Math.max.apply(
        Math,
        this.dataSourceCalendario.data.map(function (item) {
          return item.pkId;
        })
      );
    }
    //Hacemos un push al data para que se muestre
    this.objedicionCalendario.pkId = "" + (max + 1);
    let pusheddata = this.dataSourceCalendario.data;
    pusheddata.push(this.objedicionCalendario);
    this.dataSourceCalendario.data = pusheddata;
    this.dataSourceCalendario.paginator = this.paginatorCalendario;
    this.dataSourceCalendario.sort = this.sortCalendario;
    //Limpiamos
    let temporaldesde = this.objedicionCalendario.FechaAcceso;
    let temporalhasta = this.objedicionCalendario.FechaAccesoFin;
    this.objedicionCalendario = new CalendariosTipoControl();
    this.objedicionCalendario.FechaAcceso = temporaldesde;
    this.objedicionCalendario.FechaAccesoFin = temporalhasta;
    //¿quitamos el editando? En principio No
  }

  cambiadesde(evento) {
    this.fecha_minHasta = evento.value;
    this.objedicionCalendario.FechaAcceso = new DatePipe("es")
      .transform(evento.value, "dd/MM/yyyy")
      .trim();
  }
  cambiahasta(evento) {
    this.fecha_maxDesde = evento.value;
    this.objedicionCalendario.FechaAccesoFin = new DatePipe("es")
      .transform(evento.value, "dd/MM/yyyy")
      .trim();
  }

  borrarCalendario(fila) {
    this.dataSourceCalendario.data = this.dataSourceCalendario.data.filter(
      (item) => item.pkId !== fila.pkId
    );
  }

  anadirCalendarioHorario() {
    this.objedicionCalendarioHorario = new CalendariosTipoControl();
    this.objedicionCalendarioHorario.CodigoTipoControl =
      this.model.CodigoTipoControl;
    this.editandoCalendariosHorario = true;
  }

  editarCalendarioHorario(fila) {
    //TODO
  }

  guardarCalendarioRangoHoras() {
    //metemos el rango en el datasource
    let max = 0;
    if (this.dataSourceCalendarioHorario.data.length > 0) {
      max = Math.max.apply(
        Math,
        this.dataSourceCalendarioHorario.data.map(function (item) {
          return item.pkId;
        })
      );
    }
    //Hacemos un push al data para que se muestre
    this.objedicionCalendarioHorario.pkId = "" + (max + 1);
    let pusheddata = this.dataSourceCalendarioHorario.data;
    pusheddata.push(this.objedicionCalendarioHorario);
    this.dataSourceCalendarioHorario.data = pusheddata;
    this.dataSourceCalendarioHorario.paginator = this.paginatorCalendario;
    this.dataSourceCalendarioHorario.sort = this.sortCalendario;
    //Limpiamos
    let temporaldesde = this.objedicionCalendarioHorario.FechaAcceso;
    let temporalhasta = this.objedicionCalendarioHorario.FechaAccesoFin;
    this.objedicionCalendarioHorario = new CalendariosTipoControl();
    this.objedicionCalendarioHorario.FechaAcceso = temporaldesde;
    this.objedicionCalendarioHorario.FechaAccesoFin = temporalhasta;
    //¿quitamos el editando? En principio No
  }

  cambiaHoradesde(evento) {
    this.objedicionCalendarioHorario.FechaAcceso = evento.target.value + ":00";
  }
  cambiaHorahasta(evento) {
    this.objedicionCalendarioHorario.FechaAccesoFin =
      evento.target.value + ":00";
  }

  borrarCalendarioHorario(fila) {
    this.dataSourceCalendarioHorario.data =
      this.dataSourceCalendarioHorario.data.filter(
        (item) => item.pkId !== fila.pkId
      );
  }

  calculaModeloComprobaciones() {
    let lista = this.f["tipoControlComprobacion"].value;
    if (
      lista.findIndex((x) => x == "CompDiaSemana" || x.id == "CompDiaSemana") >=
      0
    ) {
      this.model.CompDiaSemana = true;
      this.calculaModeloDiasSemana(false);
    } else {
      this.model.CompDiaSemana = false;
      this.calculaModeloDiasSemana(true);
    }
    if (
      lista.findIndex((x) => x == "CompHoraria" || x.id == "CompHoraria") >= 0
    ) {
      this.model.CompHoraria = true;
    } else {
      this.model.CompHoraria = false;
    }
    if (
      lista.findIndex(
        (x) => x == "PermisoSinFecha" || x.id == "PermisoSinFecha"
      ) >= 0
    ) {
      this.model.PermisoSinFecha = true;
    } else {
      this.model.PermisoSinFecha = false;
    }
    if (
      lista.findIndex((x) => x == "CompDiaria" || x.id == "CompDiaria") >= 0
    ) {
      this.model.CompDiaria = true;
    } else {
      this.model.CompDiaria = false;
    }
    if (
      lista.findIndex((x) => x == "CompIntervalo" || x.id == "CompIntervalo") >=
      0
    ) {
      this.model.CompIntervalo = true;
    } else {
      this.model.CompIntervalo = false;
    }
    if (
      lista.findIndex(
        (x) => x == "CompPasoPrevio" || x.id == "CompPasoPrevio"
      ) >= 0
    ) {
      this.model.CompPasoPrevio = true;
    } else {
      this.model.CompPasoPrevio = false;
    }
    if (
      lista.findIndex(
        (x) => x == "CompCalendario" || x.id == "CompCalendario"
      ) >= 0
    ) {
      this.model.CompCalendario = true;
    } else {
      this.model.CompCalendario = false;
    }
    if (
      lista.findIndex(
        (x) => x == "CompCalendHorario" || x.id == "CompCalendHorario"
      ) >= 0
    ) {
      this.model.CompCalendHorario = true;
    } else {
      this.model.CompCalendHorario = false;
    }
    if (
      lista.findIndex((x) => x == "DenegarAcceso" || x.id == "DenegarAcceso") >=
      0
    ) {
      this.model.DenegarAcceso = true;
    } else {
      this.model.DenegarAcceso = false;
    }
  }

  calculaModeloDiasSemana(negar: boolean) {
    let lista = this.f["tipoControlDias"].value;
    if (negar) {
      this.model.AccesoLunes =
        this.model.AccesoMartes =
        this.model.AccesoMiercoles =
        this.model.AccesoJueves =
        this.model.AccesoViernes =
        this.model.AccesoSabado =
        this.model.AccesoDomingo =
          false;
    } else {
      if (lista.findIndex((x) => x == "1" || x.id == "1") >= 0) {
        this.model.AccesoLunes = true;
      } else {
        this.model.AccesoLunes = false;
      }
      if (lista.findIndex((x) => x == "2" || x.id == "2") >= 0) {
        this.model.AccesoMartes = true;
      } else {
        this.model.AccesoMartes = false;
      }
      if (lista.findIndex((x) => x == "3" || x.id == "3") >= 0) {
        this.model.AccesoMiercoles = true;
      } else {
        this.model.AccesoMiercoles = false;
      }
      if (lista.findIndex((x) => x == "4" || x.id == "4") >= 0) {
        this.model.AccesoJueves = true;
      } else {
        this.model.AccesoJueves = false;
      }
      if (lista.findIndex((x) => x == "5" || x.id == "5") >= 0) {
        this.model.AccesoViernes = true;
      } else {
        this.model.AccesoViernes = false;
      }
      if (lista.findIndex((x) => x == "6" || x.id == "6") >= 0) {
        this.model.AccesoSabado = true;
      } else {
        this.model.AccesoSabado = false;
      }
      if (lista.findIndex((x) => x == "7" || x.id == "7") >= 0) {
        this.model.AccesoDomingo = true;
      } else {
        this.model.AccesoDomingo = false;
      }
    }
  }

  compruebaPorDefecto(valor: string) {
    if (valor.trim().toLowerCase() != "true") {
      return (
        this.dataSource != null &&
        this.dataSource.data != null &&
        this.dataSource.data.length != 0 &&
        this.dataSource.data.some(
          (x) =>
            x.PorDefecto.trim() == "1" ||
            x.PorDefecto.toLocaleLowerCase().trim() == "true"
        )
      );
    } else {
      return false;
    }
  }
  checkCodigoNoUsado(codigoID: string) {
    if (
      this.dataSource == null ||
      this.dataSource.data == null ||
      this.dataSource.data.length == 0
    ) {
      return [{ codigoNOUsado: true }];
    } else {
      return [
        {
          codigoNOUsado:
            this.dataSource.data.filter(
              (x) => x.CodigoTipoControl.trim() == codigoID.trim()
            ) == null,
        },
      ];
    }
  }
  estaUsado() {
    return (control: AbstractControl) => {
      return this.checkCodigoNoUsado(control.value).map((res) => {
        return res.codigoNOUsado ? null : { codigoUsado: true };
      });
    };
  }

  limpiarmodelos() {
    if (this.dataSourceCalendarioHorario) {
      this.dataSourceCalendarioHorario.data = [];
    }
    if (this.dataSourceCalendario) {
      this.dataSourceCalendario.data = [];
    }
    this.objedicionCalendario = new CalendariosTipoControl();
    this.objedicionCalendarioHorario = new CalendariosTipoControl();
  }

  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): TipoControl {
    let respuesta = new TipoControl();
    respuesta.pkId = item.PkId;
    respuesta.CodigoTipoControl = item.CodigoTipoControl;
    respuesta.Nombre = item.Nombre;
    respuesta.Descripcion = item.Descripcion;
    respuesta.CompHoraria = item.CompHoraria ? "true" : "false";
    respuesta.PermisoSinFecha = item.PermisoSinFecha ? "true" : "false";
    respuesta.CompDiaria = item.CompDiaria ? "true" : "false";
    respuesta.CompIntervalo = item.CompIntervalo ? "true" : "false";
    respuesta.CompDiaSemana = item.CompDiaSemana ? "true" : "false";
    respuesta.CompPasoPrevio = item.CompPasoPrevio ? "true" : "false";
    respuesta.CompCalendario = item.CompCalendario ? "true" : "false";
    respuesta.CompCalendHorario = item.CompCalendHorario ? "true" : "false";
    respuesta.DenegarAcceso = item.DenegarAcceso ? "true" : "false";
    respuesta.DiasIntervalo = item.DiasIntervalo;
    respuesta.AccesoLunes = item.AccesoLunes ? "true" : "false";
    respuesta.AccesoMartes = item.AccesoMartes ? "true" : "false";
    respuesta.AccesoMiercoles = item.AccesoMiercoles ? "true" : "false";
    respuesta.AccesoJueves = item.AccesoJueves ? "true" : "false";
    respuesta.AccesoViernes = item.AccesoViernes ? "true" : "false";
    respuesta.AccesoSabado = item.AccesoSabado ? "true" : "false";
    respuesta.AccesoDomingo = item.AccesoDomingo ? "true" : "false";
    respuesta.NivelEntrada = item.NivelEntrada;
    respuesta.NumPasosPrevios = item.NumPasosPrevios;
    respuesta.NumPasosPreviosDiarios = item.NumPasosPreviosDiarios;
    respuesta.DiasIntervaloDesde = item.DiasIntervaloDesde;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI
          .delTiposControl(this.model.PkId)
          .pipe(first())
          .subscribe(
            (data) => {
              this.respuestaAPI = new TiposControl_Respuesta().deserialize(
                data
              );
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente");
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== this.model.PkId
                );
              } else {
                this.alertaServicio.error(
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje
                );
              }
            },
            (error) => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        var resultado: string = "";
        this.seleccion.selected.forEach((element) => {
          //Recorremos los seleccionados y vamos borrando
          this.servicioAPI.delTiposControlAsync(element.pkId).then(
            (data) => {
              this.respuestaAPI = new TiposControl_Respuesta().deserialize(
                data
              );
              if (this.respuestaAPI.DatosResult != null) {
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== element.pkId
                );
              } else {
                resultado +=
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje + " <BR />";
              }
            },
            (error) => {
              resultado += error + " <BR />";
            }
          );
        });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        } else {
          this.alertaServicio.success("Datos Borrados Correctamente");
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.alertaServicio.limpiar();
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: "250px",
      data: {
        titulo: this.translation.BorrarCliente,
        texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas,
        ok: this.translation.Confirmar,
        ko: this.translation.Cancelar,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === "SI") {
        this.servicioAPI
          .delTiposControl(pkId)
          .pipe(first())
          .subscribe(
            (data) => {
              this.respuestaAPI = new TiposControl_Respuesta().deserialize(
                data
              );
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente");
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(
                  (item) => item.pkId !== pkId
                );
              } else {
                this.alertaServicio.error(
                  this.respuestaAPI.Mensajes[0].DescripcionMensaje
                );
              }
            },
            (error) => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      }
    });
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(
            this.filtersToDisplay.findIndex((x) => x === evento.option.value),
            1
          );
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex((x) => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
      }
    }
  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }
}
