import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditGrupoRecintosComponent } from "./edit-grupo-recintos/edit-grupo-recintos.component";

@Component({
  selector: "app-grupo-recintos",
  templateUrl: "./grupo-recintos.component.html",
  styleUrls: ["./grupo-recintos.component.scss"],
})
export class GrupoRecintosComponent extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditGrupoRecintosComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
