import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-super-class-edit',
  templateUrl: './super-class-edit.component.html',
  styleUrls: ['./super-class-edit.component.scss']
})
export class SuperClassEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
