<div class="container col">
  <div class="menu-acciones-metodo">
    <button class="btn btn-primary btn-magento-atras" (click)="editando=false;" [hidden]="!editando"><i
        class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{translation.Atras}}</button>
    <button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris"
      (click)="pregborrarRegistro()">{{translation.Borrar}}</button>
    <button *ngIf="permisobtnDuplicar && model.PkId!=null && editando" class="btn btn-warning btn-magento-gris"
      (click)="duplicarRegistro()">{{translation.DuplicarRegistro}}</button>
    <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="!editorRegistroForm.valid"
      [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirTipoDeControl}}</button>
  </div>
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li *ngIf="(filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() !='')">
          {{translation.Nombre}}: <span>{{filtrosactivos.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"></i></li>
        <li *ngIf="(filtrosactivos.Descripcion != null && filtrosactivos.Nombre.trim() !='')">
          {{translation.Descripcion}}: <span>{{filtrosactivos.Descripcion}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('Descripcion')"></i></li>
        <li
          *ngIf="((filtrosactivos.codigodesde != null || filtrosactivos.codigohasta != null) && (filtrosactivos.codigodesde.trim() !='' ||filtrosactivos.codigohasta.trim() !='' ))">
          {{translation.Codigo}}: <span>{{filtrosactivos.codigodesde}} a {{filtrosactivos.codigohasta}}
          </span> &nbsp;<i class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('codigo')"></i>
        </li>
        <li *ngIf="(filtrosactivos.comphoraria != null && filtrosactivos.comphoraria.trim() !='')">
          {{translation.Comphora}}: <span>{{filtrosactivos.comphoraria}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('comphoraria')"></i></li>
        <li *ngIf="(filtrosactivos.compsinfecha != null && filtrosactivos.compsinfecha.trim() !='')">
          {{translation.SinFecha}}: <span>{{filtrosactivos.compsinfecha}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('compsinfecha')"></i></li>
        <li *ngIf="(filtrosactivos.compdiaria != null && filtrosactivos.compdiaria.trim() !='')">
          {{translation.diaria}}: <span>{{filtrosactivos.compdiaria}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('compdiaria')"></i></li>
        <li *ngIf="(filtrosactivos.compdiasemana != null && filtrosactivos.compdiasemana.trim() !='')">
          {{translation.DiaSemana}}: <span>{{filtrosactivos.compdiasemana}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('compdiasemana')"></i></li>
        <li *ngIf="(filtrosactivos.compintervalo != null && filtrosactivos.compintervalo.trim() !='')">
          {{translation.Intervalo}}: <span>{{filtrosactivos.compintervalo}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('compintervalo')"></i></li>
        <li *ngIf="(filtrosactivos.comppasoprevio != null && filtrosactivos.comppasoprevio.trim() !='')">
          {{translation.PasoPrevio}}: <span>{{filtrosactivos.comppasoprevio}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('comppasoprevio')"></i></li>
        <li *ngIf="(filtrosactivos.compcalendario != null && filtrosactivos.compcalendario.trim() !='')">
          {{translation.Calendario}}: <span>{{filtrosactivos.compcalendario}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('compcalendario')"></i></li>
        <li *ngIf="(filtrosactivos.compcalendariohora != null && filtrosactivos.compcalendariohora.trim() !='')">
          {{translation.CalHora}}: <span>{{filtrosactivos.compcalendariohora}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('compcalendariohora')"></i>
        </li>
        <li *ngIf="(filtrosactivos.compdenegaracceso != null && filtrosactivos.compdenegaracceso.trim() !='')">
          {{translation.NoAcceso}}: <span>{{filtrosactivos.compdenegaracceso}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('compdenegaracceso')"></i></li>
        <li
          *ngIf="((filtrosactivos.diasintervalodesde != null || filtrosactivos.diasintervalohasta != null) && (filtrosactivos.diasintervalodesde.trim() !='' ||filtrosactivos.diasintervalohasta.trim() !='' ))">
          {{translation.Intervalo}}: <span>{{filtrosactivos.diasintervalodesde}} {{translation.A}}
            {{filtrosactivos.diasintervalohasta}} </span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('diasintervalo')"></i></li>
        <li
          *ngIf="((filtrosactivos.diasintervalodesdedesde != null || filtrosactivos.diasintervalodesdehasta != null) && (filtrosactivos.diasintervalodesdedesde.trim() !='' ||filtrosactivos.diasintervalodesdehasta.trim() !='' ))">
          IntervaloD: <span>{{filtrosactivos.diasintervalodesdedesde}} {{translation.A}}
            {{filtrosactivos.diasintervalodesdehasta}} </span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('diasintervalodesde')"></i></li>
        <li *ngIf="(filtrosactivos.lunes != null && filtrosactivos.lunes.trim() !='')"> {{translation.Lunes}}:
          <span>{{filtrosactivos.lunes}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('lunes')"></i></li>
        <li *ngIf="(filtrosactivos.martes != null && filtrosactivos.martes.trim() !='')">
          {{translation.Martes}}: <span>{{filtrosactivos.martes}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('martes')"></i></li>
        <li *ngIf="(filtrosactivos.miercoles != null && filtrosactivos.miercoles.trim() !='')">
          {{translation.Miercoles}}: <span>{{filtrosactivos.miercoles}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('miercoles')"></i></li>
        <li *ngIf="(filtrosactivos.jueves != null && filtrosactivos.jueves.trim() !='')">
          {{translation.Jueves}}: <span>{{filtrosactivos.jueves}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('jueves')"></i></li>
        <li *ngIf="(filtrosactivos.viernes != null && filtrosactivos.viernes.trim() !='')">
          {{translation.Viernes}}: <span>{{filtrosactivos.viernes}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('viernes')"></i></li>
        <li *ngIf="(filtrosactivos.sabado != null && filtrosactivos.sabado.trim() !='')">
          {{translation.Sabado}}: <span>{{filtrosactivos.sabado}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('sabado')"></i></li>
        <li *ngIf="(filtrosactivos.domingo != null && filtrosactivos.domingo.trim() !='')">
          {{translation.Domingo}}: <span>{{filtrosactivos.domingo}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('domingo')"></i></li>
        <li *ngIf="(filtrosactivos.nivelentrada != null && filtrosactivos.nivelentrada.trim() !='')">
          {{translation.NivEntr}}: <span>{{filtrosactivos.nivelentrada}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('nivelentrada')"></i></li>
        <li
          *ngIf="((filtrosactivos.pasopreviodesde != null || filtrosactivos.pasopreviohasta != null) && (filtrosactivos.pasopreviodesde.trim() !='' ||filtrosactivos.pasopreviohasta.trim() !='' ))">
          {{translation.PasoPrevio}}: <span>{{filtrosactivos.pasopreviodesde}} {{translation.A}}
            {{filtrosactivos.pasopreviohasta}} </span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('pasoprevio')"></i></li>
        <li
          *ngIf="((filtrosactivos.pasopreviodiadesde != null || filtrosactivos.pasopreviodiahasta != null) && (filtrosactivos.pasopreviodiadesde.trim() !='' ||filtrosactivos.pasopreviodiahasta.trim() !='' ))">
          {{translation.PasoPrevioD}}: <span>{{filtrosactivos.pasopreviodiadesde}} {{translation.A}}
            {{filtrosactivos.pasopreviodiahasta}} </span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('pasopreviodia')"></i></li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna" selected="true">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>

      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.Nombre)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.Nombre}}</mat-label>
              <input matInput placeholder="{{translation.FiltroDeNombre}}" formControlName="nombre"
                (value)="filtrosactivosModelo.Nombre">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Descripcion)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.Descripcion}}</mat-label>
              <input matInput placeholder="{{translation.FiltroDeDescripcion}}" formControlName="descripcion"
                (value)="filtrosactivosModelo.Descripcion">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Codigo)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.Codigo}}</b> &nbsp; <i>{{translation.Desde}}</i></mat-label>
              <input matInput placeholder="{{translation.Desde}}" formControlName="codigodesde"
                (value)="filtrosactivosModelo.codigodesde">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.Codigo}}</b> &nbsp; <i>{{translation.Hasta}}</i></mat-label>
              <input matInput placeholder="{{translation.Hasta}}" formControlName="codigohasta"
                (value)="filtrosactivosModelo.codigohasta">
            </mat-form-field>
          </div>
          <!--Comprobaciones-->
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionHoraria)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionHoraria}}" formControlName="comphoraria"
                name="tipoCompHoraria" (value)="filtrosactivosModelo.comphoraria">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionSinFecha)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionSinFecha}}" formControlName="compsinfecha"
                name="tipoCompSinFecha" (value)="filtrosactivosModelo.compsinfecha">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionDiaria)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionDiaria}}" formControlName="compdiaria"
                name="tipoCompDiaria" (value)="filtrosactivosModelo.compdiaria">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionDiaSemana)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionDiaSemana}}" formControlName="compdiasemana"
                name="tipoCompDiaSemana" (value)="filtrosactivosModelo.compdiasemana">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionIntervalo)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionIntervalo}}" formControlName="compintervalo"
                name="tipoCompIntervalo" (value)="filtrosactivosModelo.compintervalo">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionPasoPrevio)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionPasoPrevio}}" formControlName="comppasoprevio"
                name="tipoCompPasoPrevio" (value)="filtrosactivosModelo.comppasoprevio">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionCalendario)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionCalendario}}" formControlName="compcalendario"
                name="tipoCompCalendario" (value)="filtrosactivosModelo.compcalendario">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionCalendarioHorario)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionCalendarioHorario}}"
                formControlName="compcalendariohora" name="tipoCompCalendarioHora"
                (value)="filtrosactivosModelo.compcalendariohora">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.ComprobacionDenegarAcceso)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.ComprobacionDenegarAcceso}}" formControlName="compdenegaracceso"
                name="tipoDenegarAcceso" (value)="filtrosactivosModelo.compdenegaracceso">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--F Comprobaciones-->
          <div *ngIf="filtersToDisplay.includes(translation.DiasIntervalo)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.DiasIntervalo}}</b> &nbsp; <i>{{translation.Desde}}</i>
              </mat-label>
              <input matInput placeholder="{{translation.Desde}}" formControlName="diasintervalodesde"
                (value)="filtrosactivosModelo.diasintervalodesde">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.DiasIntervalo}}</b> &nbsp; <i>{{translation.Hasta}}</i>
              </mat-label>
              <input matInput placeholder="{{translation.Hasta}}" formControlName="diasintervalohasta"
                (value)="filtrosactivosModelo.diasintervalohasta">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.DiasIntervaloDesde)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.DiasIntervalo}} {{translation.Desde}}</b> &nbsp;
                <i>{{translation.Desde}}</i></mat-label>
              <input matInput placeholder="{{translation.Desde}}" formControlName="diasintervalodesdedesde"
                (value)="filtrosactivosModelo.diasintervalodesdedesde">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.DiasIntervalo}} {{translation.Desde}}</b> &nbsp;
                <i>{{translation.Hasta}}</i></mat-label>
              <input matInput placeholder="{{translation.Hasta}}" formControlName="diasintervalodesdehasta"
                (value)="filtrosactivosModelo.diasintervalodesdehasta">
            </mat-form-field>
          </div>
          <!--Dias de la semana-->
          <div *ngIf="filtersToDisplay.includes(translation.Lunes)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Acceso}} {{translation.Lunes}}" formControlName="lunes"
                name="tipoLunes" (value)="filtrosactivosModelo.lunes">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Martes)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Acceso}} {{translation.Martes}}" formControlName="martes"
                name="tipoMartes" (value)="filtrosactivosModelo.martes">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Miercoles)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Acceso}} {{translation.Miercoles}}" formControlName="miercoles"
                name="tipoMiercoles" (value)="filtrosactivosModelo.miercoles">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Jueves)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Acceso}} {{translation.Jueves}}" formControlName="jueves"
                name="tipoJueves" (value)="filtrosactivosModelo.jueves">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Viernes)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Acceso}} {{translation.Viernes}}" formControlName="viernes"
                name="tipoViernes" (value)="filtrosactivosModelo.viernes">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Sabado)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Acceso}} {{translation.Sabado}}" formControlName="sabado"
                name="tipoSabado" (value)="filtrosactivosModelo.sabado">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Domingo)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Acceso}} {{translation.Domingo}}" formControlName="domingo"
                name="tipoDomingo" (value)="filtrosactivosModelo.domingo">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!--Fin Dias Semana-->
          <div *ngIf="filtersToDisplay.includes(translation.NivelEntrada)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.NivelEntrada}}" formControlName="nivelentrada"
                name="tipoNivEntrada" (value)="filtrosactivosModelo.nivelentrada">
                <mat-option> --- --- </mat-option>
                <mat-option *ngFor="let tiponivelentrada of globales.arr_nivlelesEntrada" [value]="tiponivelentrada.id">
                  {{tiponivelentrada.value | translateV2 }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.PasosPrevios)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.PasosPrevios}}</b> &nbsp; <i>{{translation.Desde}}</i>
              </mat-label>
              <input matInput placeholder="{{translation.Desde}}" formControlName="pasopreviodesde"
                (value)="filtrosactivosModelo.pasopreviodesde">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.PasosPrevios}}</b> &nbsp; <i>{{translation.Hasta}}</i>
              </mat-label>
              <input matInput placeholder="{{translation.Hasta}}" formControlName="pasopreviohasta"
                (value)="filtrosactivosModelo.pasopreviohasta">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.PasosPreviosDiario)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.PasosPreviosDiario}}</b> &nbsp; <i>{{translation.Desde}}</i>
              </mat-label>
              <input matInput placeholder="{{translation.Desde}}" formControlName="pasopreviodiadesde"
                (value)="filtrosactivosModelo.pasopreviodiadesde">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label><b>{{translation.PasosPreviosDiario}}</b> &nbsp; <i>{{translation.Hasta}}</i>
              </mat-label>
              <input matInput placeholder="{{translation.Hasta}}" formControlName="pasopreviodiahasta"
                (value)="filtrosactivosModelo.pasopreviodiahasta">
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" (value)="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->

    <div class="mat-elevation-z8">


      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" #paginatorPrincipal>
      </mat-paginator>
      <table mat-table [dataSource]="dataSource" matSort #sortPrincipal="matSort">

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">
              <button matToolTip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)=duplicar(row) style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button *ngIf="permisobtnEliminar" matToolTip="Borrar Registro" class="button-icon"
                (click)="borrar(row.PkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <!-- Codigo Column -->
        <ng-container matColumnDef="Codigo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Codigo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.CodigoTipoControl}} </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Nombre}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Nombre}} </td>
        </ng-container>

        <!-- Descripcion Column -->
        <ng-container matColumnDef="Descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Descripcion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Descripcion}} </td>
        </ng-container>

        <!-- PorDefecto Column -->
        <ng-container matColumnDef="PorDefecto">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.PorDefecto}} </th>
          <td mat-cell *matCellDef="let row"> {{row.PorDefecto}} </td>
        </ng-container>

        <!-- Comprobaciones Column -->
        <ng-container matColumnDef="Comprobaciones">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Comprobaciones}} </th>
          <td mat-cell *matCellDef="let row">
            {{row.CompHoraria.trim().toLowerCase() == 'true'?'hora':''}}{{row.PermisoSinFecha.trim().toLowerCase() == 'true'?' sinfech':''}}{{row.CompDiaria.trim().toLowerCase() == 'true'?' diaria':''}}
            {{row.CompDiaSemana.trim().toLowerCase() == 'true'?'diaSemana':''}}{{row.CompIntervalo.trim().toLowerCase() == 'true'?' intervalo':''}}{{row.CompPasoPrevio.trim().toLowerCase() == 'true'?' pasoprevio':''}}
            {{row.CompCalendario.trim().toLowerCase() == 'true'?'calend':''}}{{row.CompCalendHorario.trim().toLowerCase() == 'true'?' calHorario':''}}{{row.DenegarAcceso.trim().toLowerCase() == 'true'?' denegar':''}}
          </td>
        </ng-container>

        <!-- Intervalo Column -->
        <ng-container matColumnDef="Intervalo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Intervalo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.DiasIntervalo}} </td>
        </ng-container>

        <!-- Dias Semana Column -->
        <ng-container matColumnDef="Semana">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.DiasSemana}} </th>
          <td mat-cell *matCellDef="let row">
            {{row.AccesoLunes.trim().toLowerCase() == 'true'?'lun':''}}{{row.AccesoMartes.trim().toLowerCase() == 'true'?' mar':''}}{{row.AccesoMiercoles.trim().toLowerCase() == 'true'?' mier':''}}
            {{row.AccesoJueves.trim().toLowerCase() == 'true'?'jue':''}}{{row.AccesoViernes.trim().toLowerCase() == 'true'?' vie':''}}{{row.AccesoSabado.trim().toLowerCase() == 'true'?' sab':''}}{{row.AccesoDomingo.trim().toLowerCase() == 'true'?' dom':''}}
          </td>
        </ng-container>

        <!-- NivelEntrada Column -->
        <ng-container matColumnDef="NivelEntrada">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.NivelEntrada}} </th>
          <td mat-cell *matCellDef="let row"> {{row.NivelEntrada}} </td>
        </ng-container>

        <!-- NumPasosPrevios Column -->
        <ng-container matColumnDef="NumPasosPrevios">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.PasosPrevios}} </th>
          <td mat-cell *matCellDef="let row"> {{row.NumPasosPrevios}} </td>
        </ng-container>

        <!-- NumPasosPrevios Column -->
        <ng-container matColumnDef="NumPasosPreviosDiarios">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.PasosPreviosDiarios}} </th>
          <td mat-cell *matCellDef="let row"> {{row.NumPasosPreviosDiarios}} </td>
        </ng-container>

        <!-- IntervaloD Column -->
        <ng-container matColumnDef="IntervaloDesde">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.IntervaloDesde}} </th>
          <td mat-cell *matCellDef="let row"> {{row.DiasIntervaloDesde}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="editar(row)">
        </tr>
      </table>

    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>

  <div [hidden]="!editando" class="editor-registro">
    <form [formGroup]="editorRegistroForm">

      <div class="grupo-editor-campo-registro">
        <label>{{translation.PorDefecto}}</label><input formControlName="pordefecto" type="checkbox"
          class="editor-campo-registro-check form-check-input form-control" [(ngModel)]="model.PorDefecto"
          [attr.disabled]="compruebaPorDefectovalor?'':null">
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Codigo}}</label><input formControlName="codigo" class="editor-campo-registro form-control"
          [(ngModel)]="model.CodigoTipoControl" [ngClass]="{ 'is-invalid': f.codigo.errors }">
        <div *ngIf="f.codigo.errors" class="invalid-feedback">
          <div *ngIf="f.codigo.errors.required">{{translation.SeRequiereUnCodigo}}</div>
          <div *ngIf="f.codigo.errors.codigoUsado">{{translation.CodigoYaUsado}}</div>
        </div>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Nombre}}</label><input formControlName="nombre" class="editor-campo-registro form-control"
          [(ngModel)]="model.Nombre" [ngClass]="{ 'is-invalid': f.nombre.errors }">
        <div *ngIf="f.nombre.errors" class="invalid-feedback">
          <div *ngIf="f.nombre.errors.required">{{translation.SeRequiereNombre}}</div>
        </div>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Descripcion}}</label><input formControlName="descripcion"
          class="editor-campo-registro form-control" [(ngModel)]="model.Descripcion">
      </div>

      <div class="grupo-editor-campo-registro">
        <label>{{translation.NivelEntrada}}</label>
        <mat-form-field class="input-select">
          <mat-select placeholder="{{translation.NivelEntrada}}" formControlName="nivelentrada" name="tipoNivEntrada"
            (value)="model.NivelEntrada">
            <mat-option> --- --- </mat-option>
            <mat-option *ngFor="let tiponivelentrada of globales.arr_nivlelesEntrada" [value]="tiponivelentrada.id">
              {{tiponivelentrada.value | translateV2}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Comprobaciones}}</label>
        <mat-form-field class="input-select">
          <mat-select placeholder="{{translation.SeleccioneComprobaciones}}" formControlName="tipoControlComprobacion"
            name="tipoComprobacion" multiple [compareWith]="comparaObjetosSelect"
            (selectionChange)="CambioComprobacionMultiple($event)">
            <!-- (value)="model.tipoUsuario" -->
            <!-- <mat-select-trigger>
                    {{tipoControlComprobacion.value ? tipoControlComprobacion.value[0] : ''}}
                    <span *ngIf="tipoControlComprobacion.value?.length > 1" class="example-additional-selection">
                        (+{{tipoControlComprobacion.value.length - 1}} {{tipoControlComprobacion.value?.length === 2 ? 'otro' : 'otros'}})
                    </span>
                </mat-select-trigger> -->
            <mat-option *ngFor="let comprobacion of arr_comprobaciones" [value]="comprobacion.id">
              {{comprobacion.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.DiasConAcceso}}</label>
        <mat-form-field class="input-select">
          <mat-select placeholder="{{translation.DiasConAcceso}}" formControlName="tipoControlDias" name="tipoDias"
            multiple [compareWith]="comparaObjetosSelect" (selectionChange)="CambioDiasSemanaMultiple($event)">
            <!-- (value)="model.tipoUsuario" -->
            <!-- <mat-select-trigger>
                    {{tipoControlDias.value ? tipoControlDias.value[0] : ''}}
                    <span *ngIf="tipoControlDias.value?.length > 1" class="example-additional-selection">
                        (+{{tipoControlDias.value.length - 1}} {{tipoControlDias.value?.length === 2 ? 'otro' : 'otros'}})
                    </span>
                </mat-select-trigger> -->
            <mat-option *ngFor="let dia of globales.diasSemana" [value]="dia.id">
              {{dia.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.Intervalo}}</label><input formControlName="diasintervalo"
          class="editor-campo-registro form-control" [(ngModel)]="model.DiasIntervalo"
          [ngClass]="{ 'is-invalid': f.diasintervalo.errors }">
        <div *ngIf="f.diasintervalo.errors" class="invalid-feedback">
          <div *ngIf="f.diasintervalo.errors.maxLength">{{translation.TresDigitos}}</div>
        </div>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.PasosPreviosTotales}}</label><input formControlName="numpasosprevios"
          class="editor-campo-registro form-control" [(ngModel)]="model.NumPasosPrevios"
          [ngClass]="{ 'is-invalid': f.numpasosprevios.errors }">
        <div *ngIf="f.numpasosprevios.errors" class="invalid-feedback">
          <div *ngIf="f.numpasosprevios.errors.maxLength">{{translation.TresDigitos}}</div>
        </div>
      </div>

      <div class=grupo-editor-campo-registro>
        <label>{{translation.PasosPreviosDiarios}}</label><input formControlName="numpasospreviosdiarios"
          class="editor-campo-registro form-control" [(ngModel)]="model.NumPasosPreviosDiarios"
          [ngClass]="{ 'is-invalid': f.numpasospreviosdiarios.errors }">
        <div *ngIf="f.numpasospreviosdiarios.errors" class="invalid-feedback">
          <div *ngIf="f.numpasospreviosdiarios.errors.maxLength">{{translation.TresDigitos}}</div>
        </div>
      </div>
      <mat-expansion-panel [(expanded)]="panelCalendarioHorarioAbierto" [disabled]="panelCalendarioHorarioDisabled"
        #scrollAcordeonCalendarioHorario>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <label>{{translation.CalendarioHorario}}</label>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="mat-elevation-z8">
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" #paginatorCalendarioHorario>
          </mat-paginator>
          <table mat-table [dataSource]="dataSourceCalendarioHorario" matSort #sortCalendarioHorario="matSort">


            <!-- Codigo Column -->
            <ng-container matColumnDef="HoraInicio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.HoraInicio}} </th>
              <td mat-cell *matCellDef="let row"> {{row.FechaAcceso}} </td>
            </ng-container>

            <!-- Nombre Column -->
            <ng-container matColumnDef="HoraFin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.HoraFin}} </th>
              <td mat-cell *matCellDef="let row"> {{row.FechaAccesoFin}} </td>
            </ng-container>

            <!-- Accion Column -->
            <ng-container matColumnDef="Accion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Accion}} </th>
              <td mat-cell *matCellDef="let row"><a [routerLink]=""
                  (click)="$event.stopPropagation();borrarCalendarioHorario(row);">{{translation.Borrar}}</a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnasCalendarioHorario"></tr>
            <tr mat-row *matRowDef="let row; columns: columnasCalendarioHorario;"
              (click)="editarCalendarioHorario(row)">
            </tr>
          </table>
          <div class="calendarioInput" *ngIf="editandoCalendariosHorario">
            <mat-form-field>
              <input matInput type="time" placeholder="{{translation.SeleccionaHoraDesde}}"
                (change)="cambiaHoradesde($event)">
              <mat-hint>{{translation.HoraDesde}}</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="time" placeholder="{{translation.SeleccionaHoraHasta}}"
                (change)="cambiaHorahasta($event)">
              <mat-hint>{{translation.HoraHasta}}</mat-hint>
            </mat-form-field>
          </div>
          <div class="menu-acciones-metodo">
            <button *ngIf="!editandoCalendariosHorario" class="btn btn-warning btn-magento-gris"
              (click)="anadirCalendarioHorario()">{{translation.AnadirRangoHorario}}</button>
            <button class="btn btn-success btn-magento-naranja" (click)="guardarCalendarioRangoHoras()"
              [hidden]="!editandoCalendariosHorario"
              [disabled]="!objedicionCalendarioHorario.FechaAccesoFin || !objedicionCalendarioHorario.FechaAccesoFin">{{translation.AnadirHorasSeleccionadas}}</button>
          </div>
        </div>

      </mat-expansion-panel>
      <mat-expansion-panel [(expanded)]="panelCalendarioAbierto" [disabled]="panelCalendarioDisabled"
        #scrollAcordeonCalendario>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <label>{{translation.Calendario}}</label>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mat-elevation-z8">
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [showFirstLastButtons]="true" #paginatorCalendario>
          </mat-paginator>
          <table mat-table [dataSource]="dataSourceCalendario" matSort #sortCalendario="matSort">


            <!-- Fcha Inicio Column -->
            <ng-container matColumnDef="FechaInicio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.FechaInicio}} </th>
              <td mat-cell *matCellDef="let row"> {{row.FechaAcceso}} </td>
            </ng-container>

            <!-- Fecha Fin  Column -->
            <ng-container matColumnDef="FechaFin">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.FechaFin}} </th>
              <td mat-cell *matCellDef="let row"> {{row.FechaAccesoFin}} </td>
            </ng-container>

            <!-- Accion Column -->
            <ng-container matColumnDef="Accion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Accion}} </th>
              <td mat-cell *matCellDef="let row"><a [routerLink]=""
                  (click)="$event.stopPropagation();borrarCalendario(row);">{{translation.Borrar}}</a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnasCalendario"></tr>
            <tr mat-row *matRowDef="let row; columns: columnasCalendario;" (click)="editarCalendario(row)">
            </tr>
          </table>
          <div class="calendarioInput" *ngIf="editandoCalendarios">
            <mat-form-field>
              <input matInput [matDatepicker]="dpdesde" placeholder="{{translation.FechaDesde}}" [min]="fecha_minDesde"
                [max]="fecha_maxDesde" (dateChange)="cambiadesde($event)">
              <mat-datepicker-toggle matSuffix [for]="dpdesde"></mat-datepicker-toggle>
              <mat-datepicker #dpdesde></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput [matDatepicker]="dphasta" placeholder="{{translation.FechaHasta}}" [min]="fecha_minHasta"
                [max]="fecha_maxHasta" (dateChange)="cambiahasta($event)">
              <mat-datepicker-toggle matSuffix [for]="dphasta"></mat-datepicker-toggle>
              <mat-datepicker #dphasta></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="menu-acciones-metodo">
            <button *ngIf="!editandoCalendarios" class="btn btn-warning btn-magento-gris"
              (click)="anadirCalendario()">{{translation.AnadirRangoFechas}}</button>
            <button class="btn btn-success btn-magento-naranja" (click)="guardarCalendarioRango()"
              [hidden]="!editandoCalendarios"
              [disabled]="!objedicionCalendario.FechaAccesoFin || !objedicionCalendario.FechaAccesoFin">{{translation.AnadirFechasSeleccionadas}}</button>
          </div>
        </div>
      </mat-expansion-panel>
    </form>
  </div>
</div>