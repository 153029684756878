import {Deserializable} from "./deserializable.model";

export class GruposAsociados_Respuesta implements Deserializable {
    DatosResult?: DatosResult_GruposAsociados;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class GruposAsociados implements Deserializable {
  Id: string;
  Nombre: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_GruposAsociados implements Deserializable {
  DatosListas: GruposAsociados[]; 
  ListadoGruposAsociados;

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

