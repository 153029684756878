import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-guardar-popup",
  templateUrl: "./guardar-popup.component.html",
  styleUrls: ["./guardar-popup.component.css"],
})
export class GuardarPopupComponent {
  myGroup;

  constructor(
    public dialogRef: MatDialogRef<GuardarPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      mensaje: string;
      name: string;
      eMails: string;
    }
  ) {
    this.myGroup = new FormGroup({
      ListEmails: new FormControl(data.eMails),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
