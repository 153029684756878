import { CLienteAPi } from "../client_api";

//getTiposCliente
export class peticion_FormasPago_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Denominacion: string;
    Descripcion: string;
}

//insUpdTiposCliente 
export class peticion_Insercion_Upd_FormasPago {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    Denominacion: string;
    Descripcion: string;
    SiNumVencimiento: string;
    siDiasPrimerVto: string;
    rDescProntoPago: string;
    rAumentoRecargo: string;
    siVencimiento: string;
    iCodigoFormaPago: string;
    chFOP: string;
    chFOP1: string;
    iFacturar: string;
    Funcion: string;
}

//delTiposCliente
export class peticion_Del_FormasPago {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
}