import { Message } from "@sharedV11/classes/message/message";
import { DatosResultGrupoTarifa } from "@sharedV11/classes/tarifas/grupostarifas";

export interface TiendaProductosData {
    pkId: string;
    Nombre: string;
    Descripcion: string;
    CodigoBarras: string;
    TextoEnPantalla: string;
    AlaVenta: string;
    GrupoIdFilter: string;
    iCategoriaIdFilter: string;
    SubCategoria: string;
    Proveedor: string;
    CentrosIds: string;
    iTipoProductoIdFilter: string;
    iGrupoAsociadoIdFilter: string;
    TipoVisitaFilter: string;
    iTipoNivelIdFilter: string;
    TipoPublicoFilter: string;
    TipoVisitante: string;
    Niveles: string;
}

export interface TiendaProductosDataResponse {
    DatosResult?: {
        Lista: TiendaProductosData[];
        TotalResultados?: number;
    }
	Mensajes: Message[];
}

export interface GrupoTipoEntrada {
    pkId: string;
    NombreGrupo: string;
}

export interface Categoria {
    pkId: string;
    chNombre: string;
}

export interface SubCategoria {
    pkId: string;
    chNombre: string;
}

export interface Proveedor {
    pkId: string;
    chNombre: string;
}

export interface Centros {
    pkId: string;
    CentrosIds: string;
}

export interface TipoProductoDescripcion {
    pkId: string;
    Descripcion: string;
}

export interface TipoProductoNombre {
    pkId: string;
    Nombre: string;
}

export const productosData: TiendaProductosDataResponse = {
    DatosResult: {
        Lista: [
            {
                pkId: '1',
                Nombre: 'Test',
                Descripcion: 'Some descr',
                CodigoBarras: 'Codigo de barras',
                TextoEnPantalla: 'Texto en Pantalla',
                AlaVenta: '0',
                GrupoIdFilter: "1",
                iCategoriaIdFilter: "1",
                SubCategoria: "1",
                Proveedor: "1",
                CentrosIds: "1",
                iTipoProductoIdFilter: "1",
                iGrupoAsociadoIdFilter: "1",
                TipoVisitaFilter: "1",
                iTipoNivelIdFilter: "1",
                TipoPublicoFilter: "Públic Grup",
                TipoVisitante: "1",
                Niveles: "1",
            },
            {
                pkId: '2',
                Nombre: 'Test',
                Descripcion: 'Some descr',
                CodigoBarras: 'Codigo de barras',
                TextoEnPantalla: 'Texto en Pantalla',
                AlaVenta: '0',
                GrupoIdFilter: "1",
                iCategoriaIdFilter: "1",
                SubCategoria: "1",
                Proveedor: "2",
                CentrosIds: "1",
                iTipoProductoIdFilter: "1",
                iGrupoAsociadoIdFilter: "1",
                TipoVisitaFilter: "1",
                iTipoNivelIdFilter: "2",
                TipoPublicoFilter: "Públic Grup agència",
                TipoVisitante: "1",
                Niveles: "2",
            },
            {
                pkId: '3',
                Nombre: 'Test',
                Descripcion: 'Some descr',
                CodigoBarras: 'Codigo de barras',
                TextoEnPantalla: 'Texto en Pantalla',
                AlaVenta: '0',
                GrupoIdFilter: "1",
                iCategoriaIdFilter: "1",
                SubCategoria: "1",
                Proveedor: "1",
                CentrosIds: "1",
                iTipoProductoIdFilter: "1",
                iGrupoAsociadoIdFilter: "1",
                TipoVisitaFilter: "1",
                iTipoNivelIdFilter: "3",
                TipoPublicoFilter: "Públic Grup congressos",
                TipoVisitante: "3",
                Niveles: "3",
            },
        ],

        TotalResultados: 0
    },

    Mensajes: [
        {
            CodigoMensaje: 'test',
            DescripcionMensaje: 'test',
            deserialize: function() { return this }
        }
    ]
}