import { Component, ElementRef, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ModalService } from '../servicios/modal.service';
import { EntradasService } from '../servicios/entradas.service';
import { Entrada } from '../modelos/entrada';
import { Router } from '@angular/router';
import { Butaca } from '../modelos/butaca.model';
import { InfoMapasService } from '../servicios/info-mapas.service';
import { ButacasService } from '../servicios/butacas.service';

@Component({
  selector: 'iacpos-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: string;
  private element: any;
  tiposEntradas: any;
  butaca: any;
  claveEmpresa: string;
  ruta: string;

  constructor(
    public router: Router,
    private modalService: ModalService,
    private el: ElementRef,
    private entradasService: EntradasService,
    private butacasService: ButacasService,
    private infoMapasService: InfoMapasService,
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    this.ruta = this.router.url;
    const modal = this;
    this.tiposEntradas = [];
    this.entradasService.butacaMarcada.subscribe(butaca => {
      this.butaca = butaca;
    });

    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', function (e: any) {
      if (e.target.className === 'iacpos-modal') {
        modal.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
    this.claveEmpresa = this.butacasService.getConfig('chClaveEmpresa');
  }

  ngAfterViewInit(): void {
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('selector');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('selector');
  }

  selectTipo(butaca: any, index): void {
    if (+butaca.Habilitado !== 0) {
      let cantidad = 0;
      if (this.modalService.butacas) {
        this.modalService.butacas.forEach((b: Butaca) => {
          const entrada = new Entrada();
          entrada.Id = +this.entradasService.butacasMap[b.idRecintoButaca];
          entrada.Nombre = butaca.NombreEntrada;
          entrada.Precio = butaca.PrecioInternet;


          this.butacasService.addProduct(butaca)   //TODO YS 20221220  PENDIENTE DE AÑADIR LA ENTRADA AL CARRO
        });
        this.modalService.butacas = undefined;
      } else {
        this.butaca.tipoSeleccionado = this.butaca.tarifas[index];
        this.butaca.fecha = this.butacasService.fechaSeleccionadaValue;
        this.butaca.hora = this.butacasService.horaSeleccionadaValue;
        // this.entradasService.entradasSeleccionadas.subscribe(entradas => {
        let modficacionReserva = false;

        if (this.entradasService.entradasSeleccionadasValue.carroReservaInicial) {
          modficacionReserva = this.entradasService.entradasSeleccionadasValue.carroReservaInicial.some(
            e => +e.PKIDRB === +this.butaca.PKIDRB
          );
        }

        cantidad = this.entradasService.entradasSeleccionadasValue.filter(
          e =>
            e.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId &&
            e.fecha.format('DD/MM/YYYY') ===
            this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
            e.hora.format('HH:mm') === this.butacasService.horaSeleccionadaValue.format('HH:mm') &&
            +e.tipoSeleccionado.IdTarifa === +this.butaca.tipoSeleccionado.IdTarifa
        ).length;

        if (this.butaca.tipoSeleccionado.Maximo && cantidad < +this.butaca.tipoSeleccionado.Maximo) {

          if (!modficacionReserva) {
            this.entradasService
              .httpPostMarcarDesmarcarButaca(this.butaca, true)
              .subscribe((res: any) => {
                if (res && res.DatosResult && res.DatosResult.IdRB &&
                  res.DatosResult.IdRB.includes(
                    Number.parseInt(this.butaca.info.infoGeneral.recintoButacaId, 10)
                  )
                ) {
                  this.updateDatosButacaMarcada();

                  this.modalService.close('selector');
                } else {
                  this.entradasService.getEstadosButacas(
                    this.infoMapasService.recinto,
                    this.entradasService.nombreRecinto
                  );
                  alert(this.butacasService.getTranslate().data.ERR_BUTACA_NO_DISPONIBLE);
                  this.modalService.close('selector');
                }
              });

          } else {
            this.updateDatosButacaMarcada();

            this.modalService.close('selector');
          }
        } else {
          alert(this.butacasService.getTranslate().data.ERR_SUPERADO_MAXIMO_TARIFA);
        }
      }
      // });
    }
  }

  private updateDatosButacaMarcada() {
    const espectaculo = {
      ...this.butacasService.espectaculoValue,
      fecha: this.butacasService.fechaSeleccionadaValue,
      hora: this.butacasService.horaSeleccionadaValue
    };
    //TODO YS 20221215  Comentamos servicios o componentes que no existen
    // this.cesta.pushEspectaculo(espectaculo);
    const datosExtra = {
      idEspectaculoAsociado: this.butacasService.espectaculoValue.EspectaculosId,
      // fecha: this.ventaService.fechaSeleccionadaValue,
      // hora: this.ventaService.horaSeleccionadaValue
    };

    const copyButaca = { ...this.butaca };
    // delete copyButaca.tarifas;
    delete copyButaca.info.tiposDeEntrada;

    this.entradasService.pushButaca({ ...copyButaca, ...datosExtra });
    // this.butaca.DOMElement.style('fill', 'blue');
    const butacaDom = document.querySelector('circle[_PKIDRB=\'' + this.butaca.PKIDRB + '\']');
    butacaDom.setAttribute('style', butacaDom.getAttribute('style') + 'fill-opacity: 0.2');
    butacaDom.classList.add('marcada');
    this.butacasService.addProduct({ ...copyButaca, ...datosExtra });
  }

  selectTipo2(tipoSeleccionado: any): void {
    if (this.modalService.butacas) {
      this.modalService.butacas.forEach((b: Butaca) => {
        const entrada = new Entrada();
        entrada.Id = +this.entradasService.butacasMap[b.idRecintoButaca];
        entrada.Nombre = tipoSeleccionado.NombreEntrada;
        entrada.Precio = tipoSeleccionado.PrecioInternet;
        //this.ventaButacasService.setCarritoValue(entrada);
      });
      this.modalService.butacas = [];
    } else {
      this.butaca.tipoSeleccionado = tipoSeleccionado;
      if (this.entradasService.removeButaca(this.butaca)) {
        this.entradasService.pushButaca(this.butaca);
        this.butacasService.addProduct(this.butaca);
      }
    }
    this.modalService.close('selector');
  }
}
