import { Deserializable } from "../deserializable";
import { CLienteAPi } from "../userRequest/client_api";

export class Request_GrupoRecintos_list {
  ConexionIacpos: number;
  clienteAPI: CLienteAPi;
  Nombre: string;
  pkId: string;
  Descripcion: string;
}

export class Request_GrupoRecintos_ins_upd {
  ConexionIacpos: number;
  clienteAPI: CLienteAPi;
  pkId: string;
  Funcion: string;
  Nombre: string;
  Activo: string;
  CentroId: string;
  subGrupo: string;
  Idiomas: any[];
  //Idiomas: IdiomaGR[];
}

export class GruposRecintos_Respuesta implements Deserializable {
  DatosResult?: DatosResult_gruposrecintos;
  Mensajes: Mensaje[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class GruposRecintos implements Deserializable {
  pkId: string;
  Nombre: string;
  Activo: string;
  CentroId: string;
  subGrupo: string;
  Idiomas: IdiomaGR[];
  CentrosIds: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
//^
//v
export class IdiomaGR implements Deserializable {
  CodIdioma: string;
  Nombre: string;
  Descripcion: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class IdiomaconURL extends IdiomaGR implements Deserializable {
  URL: string;
  //chCodigo: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
export class DatosResult_gruposrecintos implements Deserializable {
  ListaGrupoRecintos: GruposRecintos[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class peticion_Del_GrupoRecintos {
  clienteAPI: CLienteAPi;
  pkId: string;
  ConexionIacpos: number;
}