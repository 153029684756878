<!--Tabla-->
<div class="component">
  <alert></alert>
  <div id="div-table" *ngIf="table != undefined && showTable == true">
    <mat-paginator
      [length]="paginatorLength"
      [pageSize]="paginatorSize"
      [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]="true"
    ></mat-paginator>
    <mat-progress-bar
      *ngIf="!tableLoaded"
      mode="indeterminate"
    ></mat-progress-bar>
    <table
      [ngClass]="{ zonaoculta: tableLoaded === false }"
      mat-table
      [dataSource]="dataSource"
      matSort
    >
      <ng-container *ngFor="let item of table.columns">
        <ng-container
          *ngIf="item.columnActive == item.name || tableLoaded"
          matColumnDef="{{ item.name }}"
        >
          <ng-container *ngIf="item.name == 'Actions'">
            <th mat-header-cell *matHeaderCellDef>
              {{ translation[item.labelTranslate] }}
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="action-container">
                <ng-container
                  *ngFor="let item_button of item.filterOptions.actions"
                >
                  <button
                    *ngIf="permisos(item_button.action)"
                    (click)="actionManage(item_button.action, row)"
                    #tooltip="matTooltip"
                    [matTooltip]="item_button.matTooltip"
                    [matTooltipPosition]="'above'"
                    class="button-icon"
                    style="width: 34px; height: 34px"
                  >
                    <mat-icon class="icon-actions-tables ennegro">{{
                      item_button.icon
                    }}</mat-icon>
                  </button>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="item.name != 'Actions'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ translation[item.labelTranslate] }}
            </th>
            <td mat-cell *matCellDef="let row" (click)="editRow(row)">
              {{ row[item.name] }}
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        [ngClass]="{ highlight: selectedRowIndex == row.pkId }"
      ></tr>
    </table>
  </div>
</div>
<!--Panel-->
<app-panelcustoman
  *ngIf="customanpanel !== undefined"
  [ConfigPanel]="customanpanel"
  [pkId]="customanPkId"
  [mode]="customanMode"
  [modificar]="permisos('modificar')"
>
</app-panelcustoman>
