import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IReglaNegocio, IUReglaNegocioAPI, StringNumber } from "@sharedV11/classes/EntradasPromociones/ReglasNegocio/reglas-negocio";
import { IGrupoTarifa, IUGrupoTarifaAPI, Mode } from "@sharedV11/classes/tarifas/grupostarifas";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";
import { GlobalService } from "@sharedV11/services/global/global.service";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";


const httpOptions = {
	headers: new HttpHeaders({
		"Content-Type": "application/json",
	}),
};

@Injectable({
	providedIn: "root",
})
export class ReglasNegocioService {
	gruposTarifas = [];

  conexionIACPOS: number = parseInt(localStorage.getItem("conexionIACPOS"));

	clientes = [];
  gruposClientes = [];
  tiposClientes = [];

	entradas = [];
	gruposEntradas = [];
	tiposBonos = [];
	gruposTiposBonos = [];


	procesosVenta = [];

	espectaculos = [];

	private extractData(res: Response) {
		let body = res;
		return body || {};
	}

	constructor(
		private http: HttpClient,
		private tokenService: ApiTokenService,
		private global: GlobalService,
		private servicioAPI: ConectarApiService,
		private httpErrorService: HttpErrorsService
	) {}

	setGruposTarifas(gruposTarifas: any[]): void {
		this.gruposTarifas = gruposTarifas;
	} 

	cargarDatos (): Promise<boolean> {

		const promise = new Promise<boolean>((resolve, reject) => {

			const observableClientes = this.servicioAPI.traerClientes();
      const observableGruposClientes = this.servicioAPI.traerGruposClientes();
      const observableTiposClientes = this.servicioAPI.traerTiposClientes();

			const observableEntradas = this.servicioAPI.traerEntradas();
			const observableGruposEntradas = this.servicioAPI.traerGruposEntradas();
			const observableTiposBonos = this.servicioAPI.traerTiposBonos();
			const observableGruposTiposBonos = this.servicioAPI.traerGruposTipoBono();

			const observableProcesosVenta = this.servicioAPI.traerProcesosVenta();

			const observableEspectaculos = this.servicioAPI.traerEspectaculos();

			forkJoin([
				observableClientes,
				observableGruposClientes,
				observableTiposClientes,

				observableEntradas,
				observableGruposEntradas,
				observableTiposBonos,
				observableGruposTiposBonos,

				observableProcesosVenta,

				observableEspectaculos
			]).subscribe(
				([
					dataClientes,
					dataGruposClientes,
					dataTiposClientes,

					dataEntradas,
					dataGruposEntradas,
					dataTiposBonos,
					dataGruposTiposBonos,

					dataProcesosVenta,

					dataEspectaculos,
				]) => {

					this.clientes = dataClientes.DatosListas ?? [];
          this.gruposClientes = dataGruposClientes.DatosListas ?? [];
          this.tiposClientes = dataTiposClientes.DatosListas ?? [];

					this.entradas = dataEntradas.DatosListas ?? [];
					this.gruposEntradas = dataGruposEntradas.DatosListas ?? [];
					this.tiposBonos = dataTiposBonos.DatosListas ?? [];
					this.gruposTiposBonos = dataGruposTiposBonos.DatosListas ?? [];

					this.procesosVenta = dataProcesosVenta.DatosListas ?? [];

					this.espectaculos = dataEspectaculos.DatosListas ?? [];

					resolve(true);
				},
				error => {
					this.httpErrorService.identificarErrores(error);
					reject(error); // Rechazamos la promesa en caso de error
				}
			);
		});
		
		return promise;
	}

	cargarListaGruposTarifas (): Promise<boolean> {

		const promise = new Promise<boolean>((resolve, reject) => {
			this.servicioAPI.traerGruposTarifas().subscribe(
				(data) => {
					this.gruposTarifas = data.DatosListas;
					resolve(true);
				},
				error => {
					this.httpErrorService.identificarErrores(error);
					reject(error);
				}
			)
		})

		return promise;
	}

	getGruposTarifasNombres(gruposTarifasPkIds = ''): string {
		if (!gruposTarifasPkIds) return '';

		const arrGruposTarifasPkIds = gruposTarifasPkIds.split(',');

		const gruposTarifasNombres = this.gruposTarifas.filter(
			grupoTarifa => arrGruposTarifasPkIds.includes(grupoTarifa.Id)
		)
			.map(grupoTarifa => grupoTarifa.Nombre)
			.join(',');

		return gruposTarifasNombres;
	}

	filtrarGruposTarifas() : any[] {
		return [...this.gruposTarifas]
	}

	getGruposTarifasAsignadasJoin (gruposTarifasAsignados = []): string {
		const gruposTarifasPkids = gruposTarifasAsignados.map((grupoTarifa) => grupoTarifa.Id);

    return gruposTarifasPkids.join(",");
	}

	getArrGruposTarifasAsignadas(stringIds = ''): any[] {
		if (!stringIds) return [];

		const arrIds = stringIds.split(',');

		const gruposTarifasAsignadas = this.gruposTarifas.filter(grupoTarifa => arrIds.includes(grupoTarifa.Id));

		return gruposTarifasAsignadas;
	}

  getTarifasAsignadasJoin(tarifasAsignadas = []): string {
    const tarifasPkids = tarifasAsignadas.map((tarifa) => tarifa.Id);

    return tarifasPkids.join(",");
  }


  getClientesAsignadosJoin(clientesAsignados = []): string {
    const clientesPkids = clientesAsignados.map((cliente) => cliente.Id);

    return clientesPkids.join(",");
  }

	getArrClientesAsignados(stringIds = ''): any[] {
		if (!stringIds) return [];

		const arrIds = stringIds.split(',');

		const clientesAsignados = this.clientes.filter(cliente => arrIds.includes(cliente.Id));

		return clientesAsignados;
	}

	filtrarClientes(clientesAsignadosIds = []): any[] {

    if (clientesAsignadosIds.length === 0) return [...this.clientes]

    const clientesAsignadosPkIds = clientesAsignadosIds;

    const clientesFiltrados = this.clientes.filter(client => !clientesAsignadosPkIds.includes(client.Id))

    return clientesFiltrados;
	}

	findReglaNegocioByPkId(pkId: StringNumber): Observable<any> {
		let peticion: any = {};
		peticion.clienteAPI = this.global.clienteapi; //TODO
		peticion.pkId = pkId;
    
		const response = this.http
			.post<any>(
				this.tokenService.getConfig("API_URL") + "Customan/ObtenerReglasNegocioTipoEntrada",
				JSON.stringify(peticion),
				httpOptions
			)
			.pipe(
				map((data) => {
					return data;
				}),
				catchError((error: HttpErrorResponse) => {
					return throwError(error);
				})
			);
		return response;
	}

	insUpReglaNegocio(reglaNegocio: IReglaNegocio, mode: Mode): Observable<any> {
		//Montamos la peticion insercion/Update
		let peticion: IUReglaNegocioAPI = reglaNegocio;
		peticion.clienteAPI = this.global.clienteapi; //TODO
		peticion.Funcion = mode;
		//Enviamos la peticion
		return this.http
			.post<any>(
				this.tokenService.getConfig("API_URL") +
					"Customan/InsertarActualizarReglasNegocioTipoEntrada",
				JSON.stringify(peticion),
				httpOptions
			)
			.pipe(
				map(this.extractData),
				catchError(this.handleError<any>("InsertarActualizarReglasNegocioTipoEntrada"))
			);
	}

	private handleError<T>(operation = "operation", result?: T) {
		return (error: any): Observable<T> => {
			// TODO: send the error to remote logging infrastructure
			console.error(error); // log to console instead

			// TODO: better job of transforming error for user consumption

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}
}
