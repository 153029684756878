import { CLienteAPi } from "@sharedV11/classes/userRequest/client_api";

//getGruposTiposBono
export class peticion_Grupo_Tipo_Bono_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    NombreGrupo: string;
    chDescripcion: string;
}

//insUpdGruposTiposBono
export class peticion_Insercion_Upd_GrupoTipoBono {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
    NombreGrupo: string;
    Descripcion: string;
    Funcion: string;
}

//delUsuarios
export class peticion_Del_GrupoTipoBono {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    pkId: string;
}
