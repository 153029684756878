import { Deserializable } from "../../deserializable";

export class Customan_usuario implements Deserializable {
    pkId: string;
    Personal: string;
    Nombre: string;
    Clave: string;
    Tipo: string;
    Baja: string;
    Conectado: string;
    Empleado: string;

deserialize(input: any): this {
Object.assign(this, input);
return this;
}
}