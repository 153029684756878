<h1 mat-dialog-title>{{data.titulo}}</h1>
<div mat-dialog-content>
  <p>{{data.texto}}</p>
  <!-- <mat-form-field>
    <input matInput [(ngModel)]="data.eleccion">
  </mat-form-field> -->
</div>
<div mat-dialog-actions class="btn-group pull-right" role="group">
  <!-- <button mat-button [mat-dialog-close]="Ko" class="btn btn-danger">{{data.ko}}</button> -->
  <button mat-button *ngIf="data.ko!=null" (click)="onNoClick()" class="btn btn-danger">{{data.ko}}</button>
  <!-- <button mat-button [mat-dialog-close]="Ok" cdkFocusInitial class="btn btn-success">{{data.ok}}</button> -->
  <button mat-button *ngIf="data.ok!=null" (click)="onSiClick()" class="btn btn-success" cdkFocusInitial>{{data.ok}}</button>
</div>
