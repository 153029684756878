import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { SuperClassComponent } from '@sharedV11/super-class-edit/super-class/super-class.component';
import { EditProductoComponent } from './edit-producto/edit-producto.component';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent extends SuperClassComponent {

  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditProductoComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }

}
