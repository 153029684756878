import { CLienteAPi } from "./cliente-api.model";

//get
export class peticion_TiposPromocion_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    id: string;
    Recinto?: string;
    TallerActividad?: string;
    GrupoTipoPromocion: string;
    TipoMotivo: string;
    ControlAcceso: string;
    ALaVenta: string;
    Filtro: string;
}

//insUpd
export class peticion_Insercion_Upd_TiposPromocion {
    pkId: string;
    chCodigo: string;
    chNombrePromo: string;
    chDescripcionNombre: string;
    chTextoPantalla: string;
    chTextoImpresion: string;
    chTextoDisplay: string;
    chTipoValidez: string;
    iValidez: string;
    iGrupoId: string;
    iTipoProductoId: string;
    iGrupoAsociadoId: string;
    iTipoVisitaId: string;
    iTipoNivelId: string;
    iCategoriaId: string;
    bALaVenta: string;
    bImprimirPromo: string;
    bImprimirPrecio: string;
    chTeclaRapida: string;
    iTEDatosId: string;
    datosPromocionEntradas;

    Funcion: string;
}

//del
export class peticion_Del_TiposPromocion {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    entradaId: string;
}