import {
  ChangeDetectorRef,
  Component,
  Input,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Cliente } from "@sharedV11/classes/clients/client/client.interface";
import { ClienteMapper } from "@sharedV11/classes/clients/client/client.mapper";
import { Contacto } from "@sharedV11/classes/clients/client/contacto.model";
import {
  TipoEntrada,
  TiposEntrada_Respuesta,
} from "@sharedV11/classes/clients/client/tipos-entrada-Respuesta.model";
import {
  APP_FORMATOS_FECHA,
  AppDateAdapter,
} from "@sharedV11/classes/dataAdapter/date-format.adapter";
import { CifNieValidator } from "@sharedV11/core/cif-validator";
import { AlertService } from "@sharedV11/services/alerts/alert/alert.service";
import { HttpErrorsService } from "@sharedV11/services/alerts/errors/http-errors.service";
import { SnackbarInfoService } from "@sharedV11/services/alerts/snackbar-info/snackbar-info.service";
import { ConectarApiService } from "@sharedV11/services/api/connection/conectar-api.service";
import { ApiClientesService } from "@sharedV11/services/components/client/api-clientes.service";
import { ClientService } from "@sharedV11/services/components/client/client.service";
import { EditClientService } from "@sharedV11/services/components/edit_client/edit-client.service";
import { MaestrasService } from "@sharedV11/services/core/maestras.service";
import { GlobalServicCentros } from "@sharedV11/services/global/global.service centros";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { DualListComponent } from "angular-dual-listbox";
import moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Translator } from "src/app/shared/services/translator/translator.service";
import { ActivarClienteComponent } from "../activar-cliente/activar-cliente.component";
import { GruposTarifasService } from "@sharedV11/services/components/grupos-tarifas/grupos-tarifas.service";

//import { detectChanges } from '@angular/core/src/render3';

interface ValueSelectorField {
  name: string;
  id: string;
}

export class clientePlantilla {
  Id: string = "";
  Nombre: string = "";
  PrimerApellido: string = "";
  SegundoApellido: string = "";
  Email: string = "";
  Localidad: string = "";
  Provincia: string = "";
}

@Component({
  selector: "edit-client",
  templateUrl: "./edit-client.component.html",
  styleUrls: ["./edit-client.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_FORMATOS_FECHA,
    },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class EditClientComponent implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();
  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;
  editorRegistroForm: FormGroup;
  client: Cliente;

  format = {
    add: "Añadir",
    remove: "Borrar",
    all: "Todos",
    none: "Ninguno",
    direction: DualListComponent.LTR,
    draggable: true,
    locale: "es",
  };

  activado: boolean;
  permision = {};

  idCliente: number;

  formaPago: ValueSelectorField[];
  pais: ValueSelectorField[];
  tipoCliente: ValueSelectorField[];
  zonaComercial: ValueSelectorField[];
  grupoCliente: ValueSelectorField[];

  translation;

  dataSourceContactos: MatTableDataSource<Contacto>;
  columnsToDisplayContacto: string[];
  listaContactos: Contacto[];
  ContactosFormGroup: any[];

  tiposTipoEntradaTallerAsignadas: Array<TipoEntrada> = [];
  tiposTipoEntradaTallerSinAsignar: Array<TipoEntrada> = [];
  tiposTiposEntradaAPI: TiposEntrada_Respuesta;
  tiposTipoEntrada: Array<TipoEntrada>;

  tiposTipoPromocionTallerAsignadas: Array<any> = [];
  tiposTipoPromocionRecintoSinAsignar: Array<any> = [];
  tiposTiposPromocionAPI: any;
  tiposTipoPromocion: Array<any>;

  tiposTipoAbonadoTallerAsignados: Array<any> = [];
  tiposTipoAbonadoRecintoSinAsignar: Array<any> = [];
  tiposTiposAbonadoAPI: any;
  tiposTipoAbonado: Array<any>;

  dataSourceCliente: MatTableDataSource<clientePlantilla>;
  listaClientes: clientePlantilla[];
  clientePlantilla: clientePlantilla;
  clienteFormGroup: FormArray = this.formBuilder.array([]);
  tablavalida: boolean;
  editando: boolean = false;
  idClienteEnEdicion: string;
  @ViewChild(MatTable) entradastable: MatTable<any>;
  columnsToDisplayTarifaEntrada: string[];
  listaIdiomas: any;
  listaPaises: any[];
  listaFormasPago: any;
  listaGruposClientes: any;
  listaZonaComercial: any;
  listaTiposClientes: any;
  RegistrarModificar: string;
  listaCanales: any;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = true;
  permisobtnGuardar: boolean = true;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  VentaInternet: boolean = false;
  estadebaja: string;
  url: any;
  duplicando: boolean;
  detalleCliente;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros

  //grupos tarifas
	gruposTarifasSinAsignar = [];
	gruposTarifasAsignados = [];

  constructor(
    private servicioAPI: ConectarApiService,
    private clientesAPI: ApiClientesService,
    private clientService: ClientService,
    private maestras: MaestrasService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private alertaServicio: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private translator: Translator,
    private _cds: ChangeDetectorRef,
    private panelService: PanelService,
    private editClientService: EditClientService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros,
    private gruposTarifasService: GruposTarifasService
  ) {
    this.url = this.route.url;
  }

  async ngOnInit() {
    this.loadCentros();
    this.translation = this.translator.GetTranslations();
    this.format = {
      add: this.translation.Anadir,
      remove: this.translation.Borrar,
      all: this.translation.Todos,
      none: this.translation.Ninguno,
      direction: DualListComponent.LTR,
      draggable: true,
      locale: "es",
    };

    this.tablavalida = true;
    this.clientePlantilla = {
      Id: "",
      Nombre: "",
      PrimerApellido: "",
      SegundoApellido: "",
      Email: "",
      Localidad: "",
      Provincia: "",
    };
    this.checkPermission();

    this.columnsToDisplayContacto = [
      "Acciones",
      "Nombre",
      "PrimerApellido",
      "SegundoApellido",
      "Email",
      "Localidad",
      "Provincia",
    ];

    this.generateFormGroup();
    this.loadData();
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  manageForm(pkId, mode) {
    //this.changeStatusClientCode(true)

    (<HTMLInputElement>document.getElementById("codigoCliente")).readOnly =
      false;
    if (mode == "I") {
      this.client = this.editorRegistroForm.value;
      this.loading = false;

      //centros dualist
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();

      //grupos tarifas dualist
      this.gruposTarifasSinAsignar = this.gruposTarifasService.filtrarGruposTarifas();
      this.gruposTarifasAsignados = [];

      this.editorRegistroForm.controls["bDeBajaInternet"].setValue(true);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      let todaystr = yyyy + "/" + mm + "/" + dd;
      this.editorRegistroForm.controls["FechaDeBajaInternet"].setValue(
        todaystr
      );

      this.valuedChanged();

      //this.changeStatusClientCode(false)
    } else if (mode == "U") {
      (<HTMLInputElement>document.getElementById("codigoCliente")).readOnly =
        true;
    }
    if (pkId != null) {
      this.findClient(pkId);
    }
  }

  changeStatusClientCode(flag) {
    if (flag) this.f["Codigo"].enable();
    else this.f["Codigo"].disable();
  }

  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(null);
  }
  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }
  discard() {
    //this.changeStatusClientCode(true)
    this.changeValueFormReset(this.client);
    //this.changeStatusClientCode(false)
  }
  submit() {
    let flagValidContacts = true;
    this.editorRegistroForm.markAllAsTouched();
    this.clienteFormGroup.markAllAsTouched();
    // this.clienteFormGroup.controls[0].markAllAsTouched();
    this.clienteFormGroup.controls.map((contact) => {
      contact.markAllAsTouched();
      if (!contact.valid) {
        flagValidContacts = false;
      }
    });

    //this.manageApi(this.mode);
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editorRegistroForm.valid && flagValidContacts) {
      this.manageApi(this.mode);
    } else {
      if (!this.editorRegistroForm.controls["FormaDePago"].valid)
        this.snackvar_service.openSnackBar(
          this.translation.especificar_forma_pago,
          "",
          "green-snackbar"
        );
      if (!this.editorRegistroForm.controls["TipoCliente"].valid)
        this.snackvar_service.openSnackBar(
          this.translation.especificar_tipo_cliente,
          "",
          "green-snackbar"
        );
    }
  }

  manageApi(mode) {
    //this.insertDataInObj();
    //this.changeStatusClientCode(true)
    this.loading = true;
    switch (mode) {
      case "I":
        //insert
        //this.f["Codigo"].setValue("");
        this.RegistrarModificar = "0";
        this.newGuardar();
        break;
      case "U":
        this.RegistrarModificar = "1";
        //update
        this.newGuardar();

        break;
      case "D":
        //duplicate
        this.RegistrarModificar = "0";
        // this.f["Codigo"].setValue("");
        this.newGuardar();

        break;

      default:
        break;
    }
    //this.changeStatusClientCode(false)
  }

  insUpdUsuario(client) {
    this.editClientService
      .insUpdClienteNew(client)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          this.save();
          this.destroy();
        },
        (error) => {
          this.httpErrorService.identificarErrores(error);
        }
      );
  }

  findClient(pkId) {
    this.clientesAPI.getClientDetail(pkId).subscribe(
      (res) => { 
        this.detalleCliente = res;

        const clientInfo = res?.Cliente;
        // TODO::: actualiza los datos del form con lo que viene aqui (faltan algunos campos en la peticion)
        // TODO::: parsear strings a bool para los checks
        this.changeValueForm(res);
        this.client = this.editorRegistroForm.value;
        this.client["CentrosIds"] = clientInfo?.CentrosIds;
        this.client['GruposTarifas'] = clientInfo?.GruposTarifas;

        this.valuedChanged();

        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.client);

         //grupos tarifas
        this.gruposTarifasSinAsignar = this.gruposTarifasService.filtrarGruposTarifas();
        this.gruposTarifasAsignados = this.gruposTarifasService.getArrGruposTarifasAsignadas(clientInfo?.GruposTarifas);
        
        this.loading = false;
        //this.changeStatusClientCode(false)
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        this.destroy();
      }
    );
  }
  valuedChanged() {
    this.editorRegistroForm.valueChanges.subscribe((value) => {
      if (this.client != null) {
        this.panelService.setDiscardChanges(false);
        if (JSON.stringify(value) === JSON.stringify(this.client)) {
          this.panelService.setDiscardChanges(true);
        }
      }
    });
  }
  async loadData() {
    await this.CargarDatosTiposEntradas();
    await this.CargarDatosTiposPromociones();
    await this.CargarDatosTiposAbonados();

    /*   await this.servicioAPI.ObtenerCustomanCanales().then(res => {
      });
      await this.servicioAPI.ObtenerCustomanGrupoTipoAbonado().then(res => {
      }); */

    this.servicioAPI.traerGruposTarifas().subscribe(
      (data) => {
        const hasData = data?.DatosListas && data?.DatosListas.length > 0;
        this.gruposTarifasService.setGruposTarifas(
          hasData ? data?.DatosListas : []
        );
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    )

    this.listaPaises = this.maestras.getMasters("paises");
    await this.servicioAPI.getZonaComercial().subscribe(
      (res) => {
        // DatosListas = null
        this.listaZonaComercial = res.DatosListas;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    await this.servicioAPI.getFormasPagoAsync().then(
      (res) => {
        // pkId - Denominacion
        if (res.DatosResult)
          this.listaFormasPago = res.DatosResult.customanFormaPago;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    await this.servicioAPI.getGruposClienteAsync().then(
      (res) => {
        // ERROR: No se han encontrado datos
        if (res.DatosResult)
          this.listaGruposClientes = res.DatosResult.customanGrupoCliente;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    await this.servicioAPI.getTiposClienteAsync().then(
      (res) => {
        // pkId - Nombre
        if (res.DatosResult)
          this.listaTiposClientes = res.DatosResult.tipoClientes;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    await this.servicioAPI.ObtenerCustomanCanales().then(
      (res) => {
        if (res.DatosResult)
          this.listaCanales = res.DatosResult.Lista.filter(
            (f) => f.Seleccionable == "1"
          );
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );

    await this.servicioAPI.getIdiomasAsync(null, null, "1").then(
      (res) => {
        if (res.DatosResult) this.listaIdiomas = res.DatosResult.ListaIdiomas;
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
    this.manageForm(this.pkId, this.mode);
  }
  get f() {
    return this.editorRegistroForm.controls;
  }

  goToShowClientes(): void {
    this.router.navigate(["Cliente"], { relativeTo: this.route.parent });
  }

  esbaja() {
    this.estadebaja = this.editorRegistroForm.value.bDeBaja;
  }

  deAlta(): void {
    this.clientService.altaBajaCliente(this.pkId).subscribe(
      (data) => {
        this.alertaServicio.success(data.Avisos);
        this.loadClientData(this.pkId);
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  deBaja(): void {
    if (!this.estadebaja) {
      const dialogRef = this.dialog.open(ActivarClienteComponent, {
        width: "600px",
        height: "250px",
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.clientService.altaBajaCliente(this.pkId, result).subscribe(
            (data) => {
              this.alertaServicio.success(data.Avisos);
              this.loadData();
              //this.loadClientData(this.pkId);
            },
            (error) => {
              this.httpErrorService.identificarErrores(error);
            }
          );
        }
      });
    } else {
      if (confirm(this.translation.activarusuario)) {
        this.clientService
          .altaBajaCliente(this.pkId, null)
          .subscribe((data) => {
            this.alertaServicio.success(data.Avisos);
            this.loadData();
          });
      }
    }
  }

  guardar(): void {
    if (this.editorRegistroForm.invalid) {
      this.markFormGroupTouched(this.editorRegistroForm);
      this.alertaServicio.error(this.translation.FaltanCamposObligatorios);
    } else {
      this.clientService
        .addClient(this.editorRegistroForm.getRawValue(), this.pkId)
        .subscribe(
          (data) => {
            this.alertaServicio.success(data.Avisos);
          },
          (error) => {
            this.httpErrorService.identificarErrores(error);
          }
        );
    }
  }

  private loadClientData(id: string): void {
    this.clientService.getClientById(id).subscribe(
      (data) => {
        this.loading = false;
        const temp = ClienteMapper.convertToIU(data);
        this.activado = !temp.debaja;
        this.editorRegistroForm.patchValue(temp);
      },
      (err) => {
        this.alertaServicio.error(err);
      }
    );
  }

  generateFormGroup() {
    this.editorRegistroForm = this.formBuilder.group({
      // campos antiguos
      informacion_personal: this.formBuilder.group({
        tipo_documento: [null],
        num_documento: [null],
        nombre: [null],
        imprimir: [false],
        nombre_comercial: [""],
        primer_apellido: [""],
        segundo_apellido: [""],
      }),
      informacion_contacto: this.formBuilder.group({
        email: [null],
        email_2: [""],
        prefijo_tlf: [""],
        tlf: [""],
        tlf_2: [""],
        domicilio: [""],
        localidad: [""],
        codigo_postal: ["", [Validators.maxLength(5)]],
        provincia: [""],
        pais: [""],
      }),
      detalles_cuenta: this.formBuilder.group({
        forma_pago: [null],
        grupo_cliente: [null],
        zona_comercial: [null],
        tipo_cliente: [null],
      }),

      // Campos Nuevos
      Codigo: [""],
      TipoDocumento: [""],
      NumDocumento: [""],
      NombreComercial: [""],
      ImprimirEnTicket: [""],
      NombreCliente: ["", [Validators.required]],
      //PrimerApellido: [''],
      //SegundoApellido: [''],
      CorreoElectronico: [""],
      CorreoElectronico2: [""],
      PrefijoInternacional: [""],
      Telefono1: [""],
      Telefono2: [""],
      Movil: [""],
      Domicilio: [""],
      Localidad: [""],
      Provincia: [""],
      CodigoPostal: [""],
      Pais: [""],
      Nacionalidad: [""],
      FormaDePago: ["", [Validators.required]],
      GrupoCliente: [""],
      ZonaComercial: [""],
      TipoCliente: [""],
      bDeBaja: [""],
      FechaDeBaja: [""],
      FechaDeAltaTaquilla: [""],
      DescuentoTaquilla: [""],
      TipoDescuentoTaquilla: [""],
      ImporteDescuentoTaquilla: [""],
      RedondearPrecioTaquilla: [""],
      TicketSinDescuentoTaquilla: [""],
      VentaEnInternet: [""],
      FechaDeAltaInternet: [""],
      Usuario: [""],
      DescuentoInternet: [""],
      TipoDescuentoInternet: [""],
      ImporteDescuentoInternet: [""],
      RedondearPrecioInternet: [""],
      TicketSinDescuentoInternet: [""],
      bDeBajaInternet: [""],
      FechaDeBajaInternet: [""],
      TipoDocumentoFacturacion: [""],
      NumDocumentoFacturacion: [""],
      RazonSocial: [""],
      NombreFacturacion: [""],
      PrimerApellidoFacturacion: [""],
      SegundoApellidoFacturacion: [""],
      DomicilioFacturacion: [""],
      LocalidadFacturacion: [""],
      ProvinciaFacturacion: [""],
      CodigoPostalFacturacion: [""],
      PaisFacturacion: [""],
      PaisCodigo: [""],
      Persona: [""],
      Direccion: [""],
      Lugar: [""],
      Dias: [""],
      clienteFormGroupArray: this.clienteFormGroup,

      // Campos Nuevos
      Entradas: [""],
      Promociones: [""],
      Abonados: [""],
      Canales: [""],

      // Mas Campos Nuevos
      Observaciones: [""],
      ObsAdvertencia: [""],
      CodIdiomaVisita: [""],
      IdiomaVisita: [""],
      CodIdiomaDocumento: [""],
      IdiomaDocumento: [""],
    });

    this.editorRegistroForm
      .get("informacion_personal")
      .get("tipo_documento")
      .valueChanges.subscribe((tipo_documento) => {
        const numero_documento_control = this.editorRegistroForm
          .get("informacion_personal")
          .get("num_documento");
        if (tipo_documento === "NIF") {
          numero_documento_control.clearValidators();
          numero_documento_control.setValidators([
            Validators.required,
            CifNieValidator,
          ]);
        } else {
          numero_documento_control.clearValidators();
          numero_documento_control.setValidators([Validators.required]);
        }
        numero_documento_control.updateValueAndValidity();
      });
  }

  changeValueForm(res) {
    //if (!this.duplicando)
    this.editorRegistroForm.controls["Codigo"].setValue(
      res.DatosComunes.CodigoCliente
    );
    //else
    //this.editorRegistroForm.controls['Codigo'].setValue("");

    this.editorRegistroForm.controls["TipoDocumento"].setValue(
      res.DatosComunes.TipoDocumento
    );
    this.editorRegistroForm.controls["NumDocumento"].setValue(
      res.DatosComunes.CIFoDNI
    );
    this.editorRegistroForm.controls["NombreComercial"].setValue(
      res.Cliente.NombreComercial
    );
    this.editorRegistroForm.controls["NombreCliente"].setValue(
      res.DatosComunes.NombreCliente
    );
    //this.editorRegistroForm.controls['PrimerApellido'].setValue(res.DatosComunes.PrimerApellido);
    //this.editorRegistroForm.controls['SegundoApellido'].setValue(res.DatosComunes.SegundoApellido);
    this.editorRegistroForm.controls["CorreoElectronico"].setValue(
      res.Cliente.EMail
    );
    this.editorRegistroForm.controls["CorreoElectronico2"].setValue(
      res.Cliente.EMail2
    );
    this.editorRegistroForm.controls["PrefijoInternacional"].setValue(
      res.DatosComunes.PrefijoInternacional
    );
    this.editorRegistroForm.controls["Telefono1"].setValue(
      res.DatosComunes.Telefono
    );
    this.editorRegistroForm.controls["Telefono2"].setValue(
      res.Cliente.Telefono2
    );
    this.editorRegistroForm.controls["Movil"].setValue(res.DatosComunes.Movil);
    this.editorRegistroForm.controls["Domicilio"].setValue(
      res.DatosComunes.Domicilio
    );
    this.editorRegistroForm.controls["Localidad"].setValue(
      res.DatosComunes.Localidad
    );
    this.editorRegistroForm.controls["Provincia"].setValue(
      res.DatosComunes.Provincia
    );
    this.editorRegistroForm.controls["CodigoPostal"].setValue(
      res.DatosComunes.CP
    );
    this.editorRegistroForm.controls["Pais"].setValue(res.DatosComunes.Pais);
    this.editorRegistroForm.controls["Nacionalidad"].setValue(
      res.DatosComunes.Nacionalidad
    );
    this.editorRegistroForm.controls["FormaDePago"].setValue(
      res.Cliente.TipoPagoId
    );
    this.editorRegistroForm.controls["GrupoCliente"].setValue(
      res.Cliente.CodigoGrupos
    );
    this.editorRegistroForm.controls["ZonaComercial"].setValue(
      res.Cliente.CodigoZonaComercial
    );
    this.editorRegistroForm.controls["TipoCliente"].setValue(
      res.Cliente.TipoClienteId == "0" ? "" : res.Cliente.TipoClienteId
    );
    this.editorRegistroForm.controls["bDeBaja"].setValue(
      res.Cliente.DeBaja == "1" ? true : false
    );
    this.editorRegistroForm.controls["FechaDeBaja"].setValue(
      this.aFecha(res.Cliente.FechaBaja)
    );
    //
    this.editorRegistroForm.controls["ImprimirEnTicket"].setValue(
      res.DatosComunes.ImprimirEnTicket == "1" ? true : false
    );

    // Venta en taquilla
    this.editorRegistroForm.controls["FechaDeAltaTaquilla"].setValue(
      this.aFecha(res.Cliente.FechaAlta)
    );
    this.editorRegistroForm.controls["DescuentoTaquilla"].setValue(
      res.Cliente.TieneDescuento == "1" ? true : false
    );
    this.editorRegistroForm.controls["TipoDescuentoTaquilla"].setValue(
      res.Cliente.TipoDescuento
    );
    this.editorRegistroForm.controls["ImporteDescuentoTaquilla"].setValue(
      res.Cliente.CantidadDescuento
    );
    this.editorRegistroForm.controls["RedondearPrecioTaquilla"].setValue(
      res.Cliente.RedondeoPrecios == "1" ? true : false
    );
    this.editorRegistroForm.controls["TicketSinDescuentoTaquilla"].setValue(
      res.Cliente.TicketSinDescuento == "1" ? true : false
    );
    this.editorRegistroForm.controls["VentaEnInternet"].setValue(
      res.ClienteInternet.VentaEnInternet == "1" ? true : false
    );
    this.editorRegistroForm.controls["FechaDeAltaInternet"].setValue(
      this.aFecha(res.ClienteInternet.FechaAlta)
    );
    this.editorRegistroForm.controls["Usuario"].setValue(
      res.ClienteInternet.Usuario
    );

    this.editorRegistroForm.controls["DescuentoInternet"].setValue(
      res.ClienteInternet.TieneDescuento == "1" ? true : false
    );
    this.editorRegistroForm.controls["TipoDescuentoInternet"].setValue(
      res.ClienteInternet.TipoDescuento
    );
    this.editorRegistroForm.controls["ImporteDescuentoInternet"].setValue(
      res.ClienteInternet.CantidadDescuento
    );
    this.editorRegistroForm.controls["RedondearPrecioInternet"].setValue(
      res.ClienteInternet.RedondeoPrecios == "1" ? true : false
    );
    this.editorRegistroForm.controls["TicketSinDescuentoInternet"].setValue(
      res.ClienteInternet.TicketSinDescuento == "1" ? true : false
    );
    this.editorRegistroForm.controls["bDeBajaInternet"].setValue(
      res.ClienteInternet.DeBaja == "1" ? true : false
    );
    this.editorRegistroForm.controls["FechaDeBajaInternet"].setValue(
      this.aFecha(res.ClienteInternet.FechaBaja)
    );
    this.editorRegistroForm.controls["Observaciones"].setValue(
      res.ClienteInternet.Observaciones
    );
    this.editorRegistroForm.controls["ObsAdvertencia"].setValue(
      res.ClienteInternet.ObsAdvertencia
    );
    this.editorRegistroForm.controls["CodIdiomaVisita"].setValue(
      res.ClienteInternet.CodIdiomaVisita
    );
    this.editorRegistroForm.controls["CodIdiomaDocumento"].setValue(
      res.ClienteInternet.CodIdiomaDocumento
    );

    // Datos de facturacion (¿ClienteAdicional?)
    this.editorRegistroForm.controls["TipoDocumentoFacturacion"].setValue(
      res.ClienteAdicional.TipoDocumento
    );
    this.editorRegistroForm.controls["NumDocumentoFacturacion"].setValue(
      res.ClienteAdicional.NumeroDocumento
    );
    this.editorRegistroForm.controls["RazonSocial"].setValue(
      res.ClienteAdicional.NombreClienteFactura
    );
    this.editorRegistroForm.controls["NombreFacturacion"].setValue(
      res.ClienteAdicional.RazonSocial
    );
    this.editorRegistroForm.controls["PrimerApellidoFacturacion"].setValue(
      res.ClienteAdicional.PrimerApellido
    );
    this.editorRegistroForm.controls["SegundoApellidoFacturacion"].setValue(
      res.ClienteAdicional.SegundoApellido
    );
    this.editorRegistroForm.controls["DomicilioFacturacion"].setValue(
      res.ClienteAdicional.Domicilio
    );
    this.editorRegistroForm.controls["LocalidadFacturacion"].setValue(
      res.ClienteAdicional.Localidad
    );
    this.editorRegistroForm.controls["ProvinciaFacturacion"].setValue(
      res.ClienteAdicional.Provincia
    );
    this.editorRegistroForm.controls["CodigoPostalFacturacion"].setValue(
      res.ClienteAdicional.CodigoPostal
    );
    this.editorRegistroForm.controls["PaisFacturacion"].setValue(
      res.ClienteAdicional.Pais
    );
    this.editorRegistroForm.controls["PaisCodigo"].setValue(
      res.ClienteAdicional.PaisCodigo
    );

    // Datos de pago (xxxCobro)
    this.editorRegistroForm.controls["Persona"].setValue(
      res.DatosComunes.PersonaCobro
    );
    this.editorRegistroForm.controls["Direccion"].setValue(
      res.DatosComunes.DireccionCobro
    );
    this.editorRegistroForm.controls["Lugar"].setValue(
      res.DatosComunes.LugarCobro
    );
    this.editorRegistroForm.controls["Dias"].setValue(
      this.aFecha(res.DatosComunes.DiaPago)
    );

    const TipoCliente = this.editorRegistroForm.get("TipoCliente");
    const VentaInternet = this.editorRegistroForm.get("VentaEnInternet");

    if (VentaInternet.value == true) {
      TipoCliente.clearValidators();
      TipoCliente.setValidators(Validators.required);
      TipoCliente.updateValueAndValidity();
    }

    this.esbaja();

    if (this.listaClientes === undefined) {
      this.listaClientes = [];
    }

    if (res.Cliente.Contactos.ListadoContacto) {
      res.Cliente.Contactos.ListadoContacto.forEach((contacto) => {
        let loadContacto = new FormGroup({
          Id: new FormControl(),
          Nombre: new FormControl(),
          PrimerApellido: new FormControl(),
          SegundoApellido: new FormControl(),
          Email: new FormControl(),
          Localidad: new FormControl(),
          Provincia: new FormControl(),
        });

        loadContacto.setValue({
          Id: contacto.pkId,
          Nombre: contacto.Nombre,
          PrimerApellido: contacto.Apellido1,
          SegundoApellido: contacto.Apellido2,
          Email: contacto.Email,
          Localidad: contacto.Localidad,
          Provincia: contacto.Provincia,
        });

        this.clienteFormGroup.push(loadContacto);
        this.listaClientes.push(new clientePlantilla()); //clientePlantilla formulario de entrada
        //this.actualizarclienteFormGroup()
        this.setDataSourceClientes();
      });
    }

    // Campos Nuevos (a la espera de que se incorporen al API)

    // antiguos
    /*
    this.editorRegistroForm.controls['Entradas'].setValue(res.Cliente.GruposEntradas);
    this.editorRegistroForm.controls['Promociones'].setValue(res.Cliente.GruposPromociones);
    this.editorRegistroForm.controls['Abonados'].setValue(res.Cliente.GruposAbonado);
    */
    this.editorRegistroForm.controls["Entradas"].setValue(
      this.idToList(res.Cliente.GruposEntradas, "Entradas")
    );
    this.editorRegistroForm.controls["Promociones"].setValue(
      this.idToList(res.Cliente.GruposPromociones, "Promociones")
    );
    this.editorRegistroForm.controls["Abonados"].setValue(
      this.idToList(res.Cliente.GruposAbonado, "Abonados")
    );

    this.editorRegistroForm.controls["Canales"].setValue(res.Cliente.Canales);

    // Mas campos nuevos
    this.editorRegistroForm.controls["Observaciones"].setValue(
      res.ClienteInternet.Observaciones
    );
    this.editorRegistroForm.controls["ObsAdvertencia"].setValue(
      res.ClienteInternet.ObsAdvertencia
    );
    this.editorRegistroForm.controls["CodIdiomaVisita"].setValue(
      res.ClienteInternet.CodIdiomaVisita
    );
    this.editorRegistroForm.controls["IdiomaVisita"].setValue(
      res.ClienteInternet.IdiomaVisita
    );
    this.editorRegistroForm.controls["CodIdiomaDocumento"].setValue(
      res.ClienteInternet.CodIdiomaDocumento
    );
    this.editorRegistroForm.controls["IdiomaDocumento"].setValue(
      res.ClienteInternet.IdiomaDocumento
    );

    this.getFechaBaja();
    this._cds.detectChanges();
  }

  changeValueFormReset(res) {
    //if (!this.duplicando)
    this.editorRegistroForm.controls["Codigo"].setValue(res.Codigo);
    //else
    //this.editorRegistroForm.controls['Codigo'].setValue("");

    this.editorRegistroForm.controls["TipoDocumento"].setValue(
      res.TipoDocumento
    );
    this.editorRegistroForm.controls["NumDocumento"].setValue(res.NumDocumento);
    this.editorRegistroForm.controls["NombreComercial"].setValue(
      res.NombreComercial
    );
    this.editorRegistroForm.controls["NombreCliente"].setValue(
      res.NombreCliente
    );
    //this.editorRegistroForm.controls['PrimerApellido'].setValue(res.PrimerApellido);
    //this.editorRegistroForm.controls['SegundoApellido'].setValue(res.SegundoApellido);
    this.editorRegistroForm.controls["CorreoElectronico"].setValue(
      res.CorreoElectronico
    );
    this.editorRegistroForm.controls["CorreoElectronico2"].setValue(
      res.CorreoElectronico2
    );
    this.editorRegistroForm.controls["PrefijoInternacional"].setValue(
      res.PrefijoInternacional
    );
    this.editorRegistroForm.controls["Telefono1"].setValue(res.Telefono1);
    this.editorRegistroForm.controls["Telefono2"].setValue(res.Telefono2);
    this.editorRegistroForm.controls["Movil"].setValue(res.Movil);
    this.editorRegistroForm.controls["Domicilio"].setValue(res.Domicilio);
    this.editorRegistroForm.controls["Localidad"].setValue(res.Localidad);
    this.editorRegistroForm.controls["Provincia"].setValue(res.Provincia);
    this.editorRegistroForm.controls["CodigoPostal"].setValue(res.CodigoPostal);
    this.editorRegistroForm.controls["Pais"].setValue(res.Pais);
    this.editorRegistroForm.controls["Nacionalidad"].setValue(res.Nacionalidad);
    this.editorRegistroForm.controls["FormaDePago"].setValue(res.FormaDePago);
    this.editorRegistroForm.controls["GrupoCliente"].setValue(res.GrupoCliente);
    this.editorRegistroForm.controls["ZonaComercial"].setValue(
      res.ZonaComercial
    );
    this.editorRegistroForm.controls["TipoCliente"].setValue(res.TipoCliente);
    this.editorRegistroForm.controls["bDeBaja"].setValue(res.bDeBaja);
    this.editorRegistroForm.controls["FechaDeBaja"].setValue(res.FechaDeBaja);
    //
    this.editorRegistroForm.controls["ImprimirEnTicket"].setValue(
      res.ImprimirEnTicket
    );

    // Venta en taquilla
    this.editorRegistroForm.controls["FechaDeAltaTaquilla"].setValue(
      res.FechaDeAltaTaquilla
    );
    this.editorRegistroForm.controls["DescuentoTaquilla"].setValue(
      res.DescuentoTaquilla
    );
    this.editorRegistroForm.controls["TipoDescuentoTaquilla"].setValue(
      res.TipoDescuentoTaquilla
    );
    this.editorRegistroForm.controls["ImporteDescuentoTaquilla"].setValue(
      res.ImporteDescuentoTaquilla
    );
    this.editorRegistroForm.controls["RedondearPrecioTaquilla"].setValue(
      res.RedondearPrecioTaquilla
    );
    this.editorRegistroForm.controls["TicketSinDescuentoTaquilla"].setValue(
      res.TicketSinDescuentoTaquilla
    );
    this.editorRegistroForm.controls["VentaEnInternet"].setValue(
      res.VentaEnInternet
    );
    this.editorRegistroForm.controls["FechaDeAltaInternet"].setValue(
      res.FechaDeAltaInternet
    );
    this.editorRegistroForm.controls["Usuario"].setValue(res.Usuario);

    this.editorRegistroForm.controls["DescuentoInternet"].setValue(
      res.DescuentoInternet
    );
    this.editorRegistroForm.controls["TipoDescuentoInternet"].setValue(
      res.TipoDescuentoInternet
    );
    this.editorRegistroForm.controls["ImporteDescuentoInternet"].setValue(
      res.ImporteDescuentoInternet
    );
    this.editorRegistroForm.controls["RedondearPrecioInternet"].setValue(
      res.RedondearPrecioInternet
    );
    this.editorRegistroForm.controls["TicketSinDescuentoInternet"].setValue(
      res.TicketSinDescuentoInternet
    );
    this.editorRegistroForm.controls["bDeBajaInternet"].setValue(
      res.bDeBajaInternet
    );
    this.editorRegistroForm.controls["FechaDeBajaInternet"].setValue(
      res.FechaDeBajaInternet
    );
    this.editorRegistroForm.controls["Observaciones"].setValue(
      res.Observaciones
    );
    this.editorRegistroForm.controls["ObsAdvertencia"].setValue(
      res.ObsAdvertencia
    );
    this.editorRegistroForm.controls["CodIdiomaVisita"].setValue(
      res.CodIdiomaVisita
    );
    this.editorRegistroForm.controls["CodIdiomaDocumento"].setValue(
      res.CodIdiomaDocumento
    );

    // Datos de facturacion (¿ClienteAdicional?)
    this.editorRegistroForm.controls["TipoDocumentoFacturacion"].setValue(
      res.TipoDocumentoFacturacion
    );
    this.editorRegistroForm.controls["NumDocumentoFacturacion"].setValue(
      res.NumDocumentoFacturacion
    );
    this.editorRegistroForm.controls["RazonSocial"].setValue(res.RazonSocial);
    this.editorRegistroForm.controls["NombreFacturacion"].setValue(
      res.NombreFacturacion
    );
    this.editorRegistroForm.controls["PrimerApellidoFacturacion"].setValue(
      res.PrimerApellidoFacturacion
    );
    this.editorRegistroForm.controls["SegundoApellidoFacturacion"].setValue(
      res.SegundoApellidoFacturacion
    );
    this.editorRegistroForm.controls["DomicilioFacturacion"].setValue(
      res.DomicilioFacturacion
    );
    this.editorRegistroForm.controls["LocalidadFacturacion"].setValue(
      res.LocalidadFacturacion
    );
    this.editorRegistroForm.controls["ProvinciaFacturacion"].setValue(
      res.ProvinciaFacturacion
    );
    this.editorRegistroForm.controls["CodigoPostalFacturacion"].setValue(
      res.CodigoPostalFacturacion
    );
    this.editorRegistroForm.controls["PaisFacturacion"].setValue(
      res.PaisFacturacion
    );
    this.editorRegistroForm.controls["PaisCodigo"].setValue(res.PaisCodigo);

    // Datos de pago (xxxCobro)
    this.editorRegistroForm.controls["Persona"].setValue(res.Persona);
    this.editorRegistroForm.controls["Direccion"].setValue(res.Direccion);
    this.editorRegistroForm.controls["Lugar"].setValue(res.Lugar);
    this.editorRegistroForm.controls["Dias"].setValue(res.Dias);

    this.esbaja();

    if (this.listaClientes === undefined) {
      this.listaClientes = [];
    }

    /*  res.Cliente.Contactos.ListadoContacto.forEach(contacto => {
   
       let loadContacto = new FormGroup({
         Id: new FormControl(),
         Nombre: new FormControl(),
         PrimerApellido: new FormControl(),
         SegundoApellido: new FormControl(),
         Email: new FormControl(),
         Localidad: new FormControl(),
         Provincia: new FormControl()
       });
   
       loadContacto.setValue({
         Id: contacto.pkId,
         Nombre: contacto.Nombre,
         PrimerApellido: contacto.Apellido1,
         SegundoApellido: contacto.Apellido2,
         Email: contacto.Email,
         Localidad: contacto.Localidad,
         Provincia: contacto.Provincia
       });
   
       this.clienteFormGroup.push(loadContacto);
       this.listaClientes.push(new clientePlantilla); //clientePlantilla formulario de entrada
       //this.actualizarclienteFormGroup()
       this.setDataSourceClientes();
     }); */

    // Campos Nuevos (a la espera de que se incorporen al API)

    // antiguos
    /*
    this.editorRegistroForm.controls['Entradas'].setValue(res.Cliente.GruposEntradas);
    this.editorRegistroForm.controls['Promociones'].setValue(res.Cliente.GruposPromociones);
    this.editorRegistroForm.controls['Abonados'].setValue(res.Cliente.GruposAbonado);
    */

    if (res.Entradas) {
      this.editorRegistroForm.controls["Entradas"].setValue(
        this.idToList(res.Entradas, "Entradas")
      );
    }
    if (res.Promociones) {
      this.editorRegistroForm.controls["Promociones"].setValue(
        this.idToList(res.Promociones, "Promociones")
      );
    }
    if (res.Abonados) {
      this.editorRegistroForm.controls["Abonados"].setValue(
        this.idToList(res.Abonados, "Abonados")
      );
    }
    this.editorRegistroForm.controls["Canales"].setValue(res.Canales);

    // Mas campos nuevos
    this.editorRegistroForm.controls["Observaciones"].setValue(
      res.Observaciones
    );
    this.editorRegistroForm.controls["ObsAdvertencia"].setValue(
      res.ObsAdvertencia
    );
    this.editorRegistroForm.controls["CodIdiomaVisita"].setValue(
      res.CodIdiomaVisita
    );
    this.editorRegistroForm.controls["IdiomaVisita"].setValue(res.IdiomaVisita);
    this.editorRegistroForm.controls["CodIdiomaDocumento"].setValue(
      res.CodIdiomaDocumento
    );
    this.editorRegistroForm.controls["IdiomaDocumento"].setValue(
      res.IdiomaDocumento
    );

    this.getFechaBaja();
    this._cds.detectChanges();
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private checkPermission() {
    this.clientService.checkPermission(this.route).then((permision) => {
      this.permision = permision;
    });
  }

  actualizarContactosFormGroup() {
    let newFormGroup = [];
    for (let i = 0; this.listaContactos.length > i; i++) {
      var promocionEntradaFormControls = new FormGroup({
        Nombre: new FormControl(this.listaContactos[i].Nombre),
        PrimerApellido: new FormControl(this.listaContactos[i].PrimerApellido),
        SegundoApellido: new FormControl(
          this.listaContactos[i].SegundoApellido
        ),
        Email: new FormControl(this.listaContactos[i].Email),
        Localidad: new FormControl(this.listaContactos[i].Localidad),
        Provincia: new FormControl(this.listaContactos[i].Provincia),
      });
      newFormGroup.push(promocionEntradaFormControls);
    }
    this.ContactosFormGroup = newFormGroup;
  }

  updateListaContactos(value, index, field) {
    switch (field) {
      case "Nombre":
        this.listaContactos[index].Nombre = value;
        break;
      case "PrimerApellido":
        this.listaContactos[index].PrimerApellido = value;
        break;
      case "SegundoApellido":
        this.listaContactos[index].SegundoApellido = value;
        break;
      case "Email":
        this.listaContactos[index].Email = value;
        break;
      case "Localidad":
        this.listaContactos[index].Localidad = value;
        break;
      case "Provincia":
        this.listaContactos[index].Provincia = value;
        break;
    }
  }

  aFechaddmmyyyy(fecha: string): Date {
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      if (parts[0] != "Invalid date") {
        return new Date(
          parseInt(parts[2].substring(0, 4)),
          parseInt(parts[1]) - 1,
          parseInt(parts[0])
        );
      } else {
        return null;
      }
    }
  }

  aFecha(fecha: string): Date {
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      if (parts[0] != "Invalid date") {
        //
        //return new Date(parseInt(parts[0].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[2]));
        return new Date(
          parseInt(parts[0].substring(0, 4)),
          parseInt(parts[1]) - 1,
          parseInt(parts[2].substring(0, 2))
        );
      } else {
        return null;
      }
    }
  }

  public setDate(event, formControl): void {
    //
    this.editorRegistroForm.controls[formControl].setValue(
      moment(event.value).format("YYYY/MM/DD 0:00:00")
    );
  }

  borrarContacto(row: any) {
    // TODO: verifica que esto funciona bien

    this.listaContactos = this.listaContactos.filter((obj) => obj != row);

    this.actualizarContactosFormGroup();

    this.dataSourceContactos = new MatTableDataSource(this.listaContactos);
  }

  /* addContactoPopup() {
    const dialogRef = this.dialog.open(AddContactoPopupComponent, {
      height: '580px',
      width: '600px',
      data: {
        mensaje: 'test',
        name: 'test',
        eMails: 'test'
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      // 1 = Cancelar
      // 2 = Añadir Contacto

      switch (result.action) {
        case 1: // Cancelar
          break;
        case 2: // Añadir Contacto
          if (this.listaContactos == undefined) {
            this.listaContactos = [];
          }



          let item = new Contacto();
          // TODO::: obtener los datos del result
          item.Nombre = result.data.Nombre;
          item.PrimerApellido = result.data.PrimerApellido;
          item.SegundoApellido = result.data.SegundoApellido;
          item.Email = result.data.Email;
          item.Localidad = result.data.Localidad;
          item.Provincia = result.data.Provincia;

          this.listaContactos.push(item);

          this.actualizarContactosFormGroup()
          this.dataSourceContactos = new MatTableDataSource(this.listaContactos);



          break;
      }
    });
  } */

  addContacto() {
    if (this.listaContactos == undefined) {
      this.listaContactos = [];
    }

    this.listaContactos.push(new Contacto());

    this.actualizarContactosFormGroup();
    this.dataSourceContactos = new MatTableDataSource(this.listaContactos);
  }

  newGuardar(): void {
    //this.anadirCliente();
    let tablaContactos;
    if (this.dataSourceCliente) tablaContactos = this.dataSourceCliente.data;

    let bodyCliente = this.buildBodyClient();
    this.clienteFormGroup.value;
    // TODO::: llamar a insertarCliente, si funciona, llama a insertar contacto con el id del cliente

    this.clientesAPI.insUpdClienteNew(bodyCliente).subscribe(
      (res) => {
        if (
          res.Avisos === "Se ha insertado correctamente" ||
          res.Avisos === "Se ha modificado correctamente"
        ) {
          this.clienteFormGroup.value.forEach((item) => {
            if (item.Nombre !== null) {
              let bodyContacto = {
                idContacto: item.Id !== null ? item.Id : "",
                Nombre: item.Nombre !== null ? item.Nombre : "",
                CifoDni: "",
                Email: item.Email !== null ? item.Email : "",
                Telefono: "",
                Fax: "",
                Domicilio: "",
                Localidad: item.Localidad !== null ? item.Localidad : "",
                Provincia: item.Provincia !== null ? item.Provincia : "",
                CP: "",
                Pais: "",
                Apellido1:
                  item.PrimerApellido !== null ? item.PrimerApellido : "",
                Apellido2:
                  item.SegundoApellido !== null ? item.SegundoApellido : "",
                FechaNacimiento: "",
                PublicidadInterna: "",
                PublicidadExterna: "",
                Sexo: "",
                CodigoCliente: this.editorRegistroForm.value.Codigo,
              };

              this.clientesAPI.insUpdContacto(bodyContacto).subscribe(
                (res) => {},
                (error) => {
                  this.httpErrorService.identificarErrores(error);
                }
              );
            }
          });
          this.save();
          this.snackvar_service.openSnackBar(
            "Se ha guardado correctamente",
            "",
            "green-snackbar"
          );
          this.destroy();
        }
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        this.snackvar_service.openSnackBar(error, "", "red-snackbar");
        this.loading = false;
      }
    );
  }

  buildBodyClient() {
    let SelectedNombrePais;

    if (this.editorRegistroForm.value.PaisCodigo.trim() != "") {
      let SelectedPais;

      SelectedPais = this.listaPaises.filter(
        (e) => e.CodPais == this.editorRegistroForm.value.PaisCodigo
      );
      if (SelectedPais[0]) SelectedNombrePais = SelectedPais[0].NombrePais;
    } else {
      SelectedNombrePais = "";
    }

    let bodyCliente = {
      RegistrarModificar: this.RegistrarModificar,
      DatosComunes: {
        CodigoCliente: this.editorRegistroForm.value.Codigo,
        TipoDocumento: this.editorRegistroForm.value.TipoDocumento,
        CIFoDNI: this.editorRegistroForm.value.NumDocumento,
        ImprimirEnTicket: this.editorRegistroForm.value.ImprimirEnTicket,
        NombreCliente: this.editorRegistroForm.value.NombreCliente,
        PrimerApellido: this.detalleCliente?.DatosComunes?.PrimerApellido ? this.detalleCliente.DatosComunes.PrimerApellido : "",
        SegundoApellido: this.detalleCliente?.DatosComunes?.SegundoApellido ? this.detalleCliente.DatosComunes.SegundoApellido : "",
        PrefijoInternacional:
          this.editorRegistroForm.value.PrefijoInternacional,
        Telefono: this.editorRegistroForm.value.Telefono1,
        Movil: this.editorRegistroForm.value.Movil,
        Domicilio: this.editorRegistroForm.value.Domicilio,
        Localidad: this.editorRegistroForm.value.Localidad,
        Provincia: this.editorRegistroForm.value.Provincia,
        CP: this.editorRegistroForm.value.CodigoPostal,
        Pais: SelectedNombrePais,
        Nacionalidad: this.editorRegistroForm.value.Nacionalidad,
        PersonaCobro: this.editorRegistroForm.value.Persona,
        DireccionCobro: this.editorRegistroForm.value.Direccion,
        LugarCobro: this.editorRegistroForm.value.Lugar,
        DiaPago: this.editorRegistroForm.value.Dias,
        NoValidar: true,
      },
      Cliente: {
        TipoPagoId: this.editorRegistroForm.value.FormaDePago,
        TipoClienteId: this.editorRegistroForm.value.TipoCliente,
        NombreComercial: this.editorRegistroForm.value.NombreComercial,
        Email: this.editorRegistroForm.value.CorreoElectronico,
        EMail2: this.editorRegistroForm.value.CorreoElectronico2,
        Telefono2: this.editorRegistroForm.value.Telefono2,
        CodigoGrupos: this.editorRegistroForm.value.GrupoCliente,
        CodigoZonaComercial: this.editorRegistroForm.value.ZonaComercial,
        DeBaja: this.editorRegistroForm.value.bDeBaja,
        FechaBaja: this.editorRegistroForm.value.FechaDeBaja,
        //FechaBaja: moment((this.editorRegistroForm.get("FechaDeBaja").value)).format('DD/MM/YYYY 0:00:00'),
        FechaAlta: this.editorRegistroForm.value.FechaDeAltaTaquilla,
        TieneDescuento: this.editorRegistroForm.value.DescuentoTaquilla,
        TipoDescuento: this.editorRegistroForm.value.TipoDescuentoTaquilla,
        CantidadDescuento:
          this.editorRegistroForm.value.ImporteDescuentoTaquilla,
        RedondeoPrecios: this.editorRegistroForm.value.RedondearPrecioTaquilla,
        TicketSinDescuento:
          this.editorRegistroForm.value.TicketSinDescuentoTaquilla,
        FraccionamientoPago: null, // falta este campo en el front
        Canales: this.editorRegistroForm.value.Canales.toString(),

        // lista antigua
        /*
        GruposEntradas: this.tiposTipoEntradaTallerAsignadas,
        GruposPromociones: this.tiposTipoPromocionTallerAsignadas,
        GruposAbonado: this.tiposTipoAbonadoTallerAsignados
        */

        // lista nueva
        GruposEntradas: this.listToId(
          this.tiposTipoEntradaTallerAsignadas,
          "Entrada"
        ),
        GruposPromociones: this.listToId(
          this.tiposTipoPromocionTallerAsignadas,
          "Promocion"
        ),
        GruposAbonado: this.listToId(
          this.tiposTipoAbonadoTallerAsignados,
          "Abonado"
        ),
        CentrosIds: this.gsCentros.obtenercentrosAsociadosconOrden(
          this.centrosAsignados
        ),
        GruposTarifas: this.gruposTarifasService.getGruposTarifasAsignadasJoin(this.gruposTarifasAsignados)
      },
      ClienteInternet: {
        DeBaja: this.editorRegistroForm.value.bDeBajaInternet,
        FechaBaja: this.editorRegistroForm.value.FechaDeBajaInternet,
        FechaAlta: this.editorRegistroForm.value.FechaDeAltaInternet,
        Usuario: this.editorRegistroForm.value.Usuario,
        TieneDescuento: this.editorRegistroForm.value.DescuentoInternet,
        TipoDescuento: this.editorRegistroForm.value.TipoDescuentoInternet,
        CantidadDescuento:
          this.editorRegistroForm.value.ImporteDescuentoInternet,
        RedondeoPrecios: this.editorRegistroForm.value.RedondearPrecioInternet,
        TicketSinDescuento:
          this.editorRegistroForm.value.TicketSinDescuentoInternet,
        Observaciones: this.editorRegistroForm.value.Observaciones,
        ObsAdvertencia: this.editorRegistroForm.value.ObsAdvertencia,
        CodIdiomaVisita: this.editorRegistroForm.value.CodIdiomaVisita,
        CodIdiomaDocumento: this.editorRegistroForm.value.CodIdiomaDocumento,
        VentaEnInternet: this.editorRegistroForm.value.VentaEnInternet,
      },
      ClienteAdicional: {
        TipoDocumento: this.editorRegistroForm.value.TipoDocumentoFacturacion,
        NumeroDocumento: this.editorRegistroForm.value.NumDocumentoFacturacion,
        RazonSocial: this.editorRegistroForm.value.NombreFacturacion,
        NombreClienteFactura: this.editorRegistroForm.value.RazonSocial,
        PrimerApellido: this.editorRegistroForm.value.PrimerApellidoFacturacion,
        SegundoApellido:
          this.editorRegistroForm.value.SegundoApellidoFacturacion,
        Domicilio: this.editorRegistroForm.value.DomicilioFacturacion,
        Localidad: this.editorRegistroForm.value.LocalidadFacturacion,
        Provincia: this.editorRegistroForm.value.ProvinciaFacturacion,
        CodigoPostal: this.editorRegistroForm.value.CodigoPostalFacturacion,
        Pais: this.editorRegistroForm.value.PaisFacturacion,
        PaisCodigo: this.editorRegistroForm.value.PaisCodigo,
      },
    };
    return bodyCliente;
  }
  listToId(list, type) {
    let idList = "";

    switch (type) {
      case "Entrada":
        list.forEach((element) => {
          idList = idList.concat(element.pkId + ",");
        });
        idList = idList.slice(0, -1);
        break;
      case "Promocion":
        list.forEach((element) => {
          idList = idList.concat(element.pkId + ",");
        });
        idList = idList.slice(0, -1);
        break;
      case "Abonado":
        list.forEach((element) => {
          idList = idList.concat(element.pkId + ",");
        });
        idList = idList.slice(0, -1);
        break;
    }
    return idList;
  }

  idToList(list, type) {
    switch (type) {
      case "Entradas":
        if (list != "") {
          const entradasIds = list.split(",");
          entradasIds.forEach((entradasId) => {
            this.asignarTipoentrada(
              {
                NombreGrupo: this.tiposTipoEntrada.find(function (te) {
                  return te.pkId == entradasId;
                }).NombreGrupo,
                pkId: entradasId,
              },
              null
            );
          });
        }
        break;
      case "Promociones":
        if (list != "" && this.tiposTipoPromocion != undefined) {
          const promoIds = list.split(",");
          promoIds.forEach((promoId) => {
            this.asignarTipopromocion(
              {
                NombreGrupo: this.tiposTipoPromocion.find(function (tp) {
                  return tp.pkId == promoId;
                }).NombreGrupo,
                pkId: promoId,
              },
              null
            );
          });
        }
        break;
      case "Abonados":
        if (list != "") {
          const abonadosIds = list.split(",");
          abonadosIds.forEach((abonadoId) => {
            this.asignarTipoabonado(
              {
                Nombre: this.tiposTipoAbonado.find(function (tp) {
                  return tp.pkId == abonadoId;
                }).Nombre,
                pkId: abonadoId,
              },
              null
            );
          });
        }
        break;
    }
  }

  daTiposEntrada(): TipoEntrada[] {
    let respuesta = [];
    respuesta = this.tiposTipoEntradaTallerSinAsignar;
    respuesta = this.tiposTipoEntradaTallerAsignadas;

    return respuesta;
  }

  filtrarTiposEntrada(): TipoEntrada[] {
    let respuesta = [];
    let entradasRecinto = this.tiposTipoEntradaTallerSinAsignar;
    let entradasTaller = this.tiposTipoEntradaTallerAsignadas;

    if (entradasTaller != null && entradasTaller.length > 0) {
      respuesta = this.tiposTipoEntrada.filter(function (item) {
        return (
          entradasTaller.findIndex(function (itemSub) {
            return itemSub.pkId == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tiposTipoEntrada;
    }

    if (entradasRecinto != null && entradasRecinto.length > 0) {
      respuesta = this.tiposTipoEntrada.filter(function (item) {
        return (
          entradasRecinto.findIndex(function (itemSub) {
            return itemSub.pkId == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tiposTipoEntrada;
    }

    return respuesta;
  }

  asignarTipoentrada(item, evento) {
    this.tiposTipoEntradaTallerAsignadas.push(item);
    this.tiposTipoEntradaTallerSinAsignar.push(item);
  }

  desasignarTipoentrada(item, evento) {
    let arrtemp = [];

    arrtemp = this.tiposTipoEntradaTallerAsignadas.filter(function (x) {
      return x.pkId != item.pkId;
    });
    this.tiposTipoEntradaTallerAsignadas = arrtemp;

    arrtemp = this.tiposTipoEntradaTallerSinAsignar.filter(function (x) {
      return x.pkId != item.pkId;
    });
    this.tiposTipoEntradaTallerSinAsignar = arrtemp;
  }

  deasignarTodas() {
    this.tiposTipoEntradaTallerAsignadas = [];
    this.tiposTipoEntradaTallerSinAsignar = [];
  }

  asignarTodas() {
    this.tiposTipoEntradaTallerAsignadas = this.tiposTipoEntrada;
    this.tiposTipoEntradaTallerSinAsignar = this.tiposTipoEntrada;
  }

  async CargarDatosTiposEntradas(
    tipo?: number,
    recinto?: string
  ): Promise<boolean> {
    //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
    let resultado: boolean = false;
    await this.servicioAPI.getGruposTiposEntrada().subscribe(
      (data) => {
        this.tiposTiposEntradaAPI = new TiposEntrada_Respuesta().deserialize(
          data
        );
        if (this.tiposTiposEntradaAPI.DatosResult != null) {
          switch (tipo) {
            case 1:
              this.tiposTipoEntradaTallerSinAsignar =
                this.tiposTiposEntradaAPI.DatosResult.customanGrupoTipoEntrada;
              break;
            case 2:
              this.tiposTipoEntradaTallerAsignadas =
                this.tiposTiposEntradaAPI.DatosResult.customanGrupoTipoEntrada;
              break;
            default:
              this.tiposTipoEntrada =
                this.tiposTiposEntradaAPI.DatosResult.customanGrupoTipoEntrada;
              break;
          }
          resultado = true;
        } else {
          switch (tipo) {
            case 1:
              this.tiposTipoEntradaTallerSinAsignar = [];
              break;
            case 2:
              this.tiposTipoEntradaTallerAsignadas = [];
              break;
            default:
              this.tiposTipoEntrada = [];
              break;
          }
          resultado = false;
        }
        this.tiposTipoEntradaTallerSinAsignar = this.filtrarTiposEntrada();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );

    return resultado;
  }

  borrarCliente(index: number) {
    let clienteABorrar: any = this.listaClientes.filter((obj, i) => i == index);

    this.listaClientes.splice(index, 1);
    //this.listaClientes = this.listaClientes.filter((obj, i) => i != index);
    this.clienteFormGroup.removeAt(index);

    this.dataSourceCliente = new MatTableDataSource(this.listaClientes);

    this.clientesAPI.delContacto(clienteABorrar[0].Id).subscribe(
      (res) => {},
      (error) => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  anadirCliente() {
    if (this.listaClientes === undefined) {
      this.listaClientes = [];
    }

    if (
      this.clienteFormGroup.controls.length == 0 ||
      this.validateEntradas(this.clienteFormGroup)
    ) {
      this.clienteFormGroup.push(
        this.formBuilder.group({
          Id: [""],
          Nombre: ["", [Validators.required]],
          PrimerApellido: [""],
          SegundoApellido: [""],
          Email: ["", [Validators.required]],
          Localidad: [""],
          Provincia: [""],
        })
      );
      this.listaClientes.push(new clientePlantilla()); //clientePlantilla formulario de entrada
      //this.actualizarclienteFormGroup()
      this.setDataSourceClientes();
    }
  }

  setDataSourceClientes() {
    this.listaClientes = [];

    this.clienteFormGroup.controls.forEach((element, i) => {
      if (element instanceof FormGroup) {
        let cliente = new clientePlantilla();

        cliente.Id = element.value.Id;
        cliente.Nombre = element.value.Nombre;
        cliente.PrimerApellido = element.value.PrimerApellido;
        cliente.SegundoApellido = element.value.SegundoApellido;
        cliente.Email = element.value.Email;
        cliente.Localidad = element.value.Localidad;
        cliente.Provincia = element.value.Provincia;

        this.listaClientes.push(cliente);
      }
    });
    this.dataSourceCliente = new MatTableDataSource(this.listaClientes);
  }

  validateEntradas(clienteFormGroup) {
    let ret = true;

    if (clienteFormGroup) {
      clienteFormGroup.controls.forEach((element) => {
        if (
          element instanceof FormGroup &&
          (element.value["Nombre"] == "" || element.value["Nombre"] == null)
        )
          ret = false;
      });
    }
    return ret;
  }

  hasTableRows() {
    let ret = false;
    if (!this.clienteFormGroup || this.clienteFormGroup.length < 1) ret = false;
    else ret = true;

    return ret;
  }

  validateTable(clienteFormGroup) {
    let ret = true;
    if (this.hasTableRows()) {
      if (clienteFormGroup)
        clienteFormGroup.controls.forEach((element) => {
          if (
            (element instanceof FormGroup && element.value["Nombre"] == "") ||
            element.value["Nombre"] == null
          )
            ret = false;
        });
    }
    //else {
    //  ret = false;
    //}

    return ret;
  }

  iValidezaCero(ev, form) {
    if (ev.value) form.patchValue({ iValidez: 0 });
  }

  isnovalid() {
    let ret = true;
    if (
      //this.clienteFormGroup &&
      //this.clienteFormGroup.length > 0 &&
      this.validateTable(this.clienteFormGroup) &&
      //!this.f.NumDocumento.invalid &&
      !this.f.NombreCliente.invalid &&
      //!this.f.CorreoElectronico.invalid &&
      //!this.f.Telefono1.invalid &&
      //!this.f.Domicilio.invalid &&
      //!this.f.Localidad.invalid &&
      //!this.f.Provincia.invalid &&
      //!this.f.CodigoPostal.invalid &&
      //!this.f.Nacionalidad.invalid &&
      //!this.f.FormaDePago.invalid &&
      //!this.f.ZonaComercial.invalid &&
      //!this.f.TipoCliente.invalid &&
      //!this.f.ImporteDescuentoTaquilla.invalid &&
      //!this.f.NombreFacturacion.invalid &&
      (this.f["VentaEnInternet"].value != true ||
        (!this.f.Usuario.invalid &&
          this.f.Usuario.value != "" &&
          this.f["VentaEnInternet"].value == true))
    ) {
      ret = false;
      this.tablavalida = true;
      //this._cds.detectChanges();
    } else {
      if (!this.validateTable(this.clienteFormGroup) && this.hasTableRows()) {
        this.tablavalida = false;
        //this._cds.detectChanges();
      }
    }

    return ret;
  }

  atras() {
    this.editando = false;
    this.idClienteEnEdicion = "";

    //this.clienteFormGroup.controls.forEach( e =>  { if (e instanceof FormGroup) this.clienteFormGroup.removeAt(0); });
    while (this.clienteFormGroup.length !== 0) {
      this.clienteFormGroup.removeAt(0);
    }
    this.setDataSourceClientes();
  }

  ngAfterViewChecked() {
    if (this.validateTable(this.clienteFormGroup)) {
      this.tablavalida = true;
      this._cds.detectChanges();
    } else {
      this.tablavalida = false;
      this._cds.detectChanges();
    }
  }

  daTiposPromocion(): any[] {
    let respuesta = [];
    respuesta = this.tiposTipoPromocionRecintoSinAsignar;
    respuesta = this.tiposTipoPromocionTallerAsignadas;
    return respuesta;
  }

  filtrarTiposPromocion(): any[] {
    let respuesta = [];
    let promocionesRecinto = this.tiposTipoPromocionRecintoSinAsignar;
    let promocionesTaller = this.tiposTipoPromocionTallerAsignadas;

    if (promocionesTaller != null && promocionesTaller.length > 0) {
      respuesta = this.tiposTipoPromocion.filter(function (item) {
        return (
          promocionesTaller.findIndex(function (itemSub) {
            return itemSub.pkId == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tiposTipoPromocion;
    }

    if (promocionesRecinto != null && promocionesRecinto.length > 0) {
      respuesta = this.tiposTipoPromocion.filter(function (item) {
        return (
          promocionesRecinto.findIndex(function (itemSub) {
            return itemSub.pkId == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tiposTipoPromocion;
    }

    return respuesta;
  }

  asignarTipopromocion(item, evento) {
    this.tiposTipoPromocionTallerAsignadas.push(item);
    this.tiposTipoPromocionRecintoSinAsignar.push(item);
  }

  desasignarTipopromocion(item, evento) {
    let arrtemp = [];

    arrtemp = this.tiposTipoPromocionTallerAsignadas.filter(function (x) {
      return x.pkId != item.pkId;
    });
    this.tiposTipoPromocionTallerAsignadas = arrtemp;

    arrtemp = this.tiposTipoPromocionRecintoSinAsignar.filter(function (x) {
      return x.pkId != item.pkId;
    });
    this.tiposTipoPromocionRecintoSinAsignar = arrtemp;
  }

  deasignarTodasPromocion() {
    this.tiposTipoPromocionTallerAsignadas = [];
    this.tiposTipoPromocionRecintoSinAsignar = [];
  }

  asignarTodasPromocion() {
    this.tiposTipoPromocionTallerAsignadas = this.tiposTipoPromocion;
    this.tiposTipoPromocionRecintoSinAsignar = this.tiposTipoPromocion;
  }

  async CargarDatosTiposPromociones(): Promise<boolean> {
    //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
    let resultado: boolean = false;
    await this.servicioAPI.getGrupoTipoBono().subscribe(
      (data) => {
        this.tiposTiposPromocionAPI = data;
        if (this.tiposTiposPromocionAPI.DatosResult != null) {
          this.tiposTipoPromocion =
            this.tiposTiposPromocionAPI.DatosResult.customanGrupoTipoBono;
          resultado = true;
        } else {
          this.tiposTipoPromocion = [];
          resultado = false;
        }
        this.tiposTipoPromocionRecintoSinAsignar = this.filtrarTiposPromocion();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    return resultado;
  }

  daTiposAbonado(): any[] {
    let respuesta = [];
    respuesta = this.tiposTipoAbonadoRecintoSinAsignar;
    respuesta = this.tiposTipoAbonadoTallerAsignados;

    return respuesta;
  }

  filtrarTiposAbonado(): any[] {
    let respuesta = [];
    let abonadosRecinto = this.tiposTipoAbonadoRecintoSinAsignar;
    let abonadosTaller = this.tiposTipoAbonadoTallerAsignados;

    if (abonadosTaller != null && abonadosTaller.length > 0) {
      respuesta = this.tiposTipoAbonado.filter(function (item) {
        return (
          abonadosTaller.findIndex(function (itemSub) {
            return itemSub.pkId == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tiposTipoAbonado;
    }

    if (abonadosRecinto != null && abonadosRecinto.length > 0) {
      respuesta = this.tiposTipoAbonado.filter(function (item) {
        return (
          abonadosRecinto.findIndex(function (itemSub) {
            return itemSub.pkId == item.pkId;
          }) === -1
        );
      });
    } else {
      respuesta = this.tiposTipoAbonado;
    }

    return respuesta;
  }

  asignarTipoabonado(item, evento) {
    this.tiposTipoAbonadoTallerAsignados.push(item);
    this.tiposTipoAbonadoRecintoSinAsignar.push(item);
  }

  desasignarTipoabonado(item, evento) {
    let arrtemp = [];

    arrtemp = this.tiposTipoAbonadoTallerAsignados.filter(function (x) {
      return x.pkId != item.pkId;
    });
    this.tiposTipoAbonadoTallerAsignados = arrtemp;

    arrtemp = this.tiposTipoAbonadoRecintoSinAsignar.filter(function (x) {
      return x.pkId != item.pkId;
    });
    this.tiposTipoAbonadoRecintoSinAsignar = arrtemp;
  }

  deasignarTodasAbonado() {
    this.tiposTipoAbonadoTallerAsignados = [];
    this.tiposTipoAbonadoRecintoSinAsignar = [];
  }

  asignarTodasAbonado() {
    this.tiposTipoAbonadoTallerAsignados = this.tiposTipoAbonado;
    this.tiposTipoAbonadoRecintoSinAsignar = this.tiposTipoAbonado;
  }

  VentaInternetCheck(e) {
    const TipoCliente = this.editorRegistroForm.get("TipoCliente");
    TipoCliente.clearValidators();
    if (e.checked) {
      this.VentaInternet = true;
      this.editorRegistroForm.controls["bDeBajaInternet"].setValue(false);
      TipoCliente.setValidators(Validators.required);
      /*       var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      let todaystr = yyyy + '/' + mm + '/' + dd; */
      this.editorRegistroForm.controls["FechaDeBajaInternet"].setValue("");
    } else {
      this.VentaInternet = false;
      TipoCliente.clearValidators();
      this.editorRegistroForm.controls["bDeBajaInternet"].setValue(true);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      let todaystr = yyyy + "/" + mm + "/" + dd;
      this.editorRegistroForm.controls["FechaDeBajaInternet"].setValue(
        todaystr
      );
    }
    TipoCliente.updateValueAndValidity();
  }

  onChangeBajaGeneral(ob: MatSlideToggleChange) {
    if (ob.checked) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      let todaystr = yyyy + "/" + mm + "/" + dd;
      this.editorRegistroForm.controls["FechaDeBaja"].setValue(todaystr);
    }
  }

  onChangeBajaInternet(ob: MatSlideToggleChange) {
    if (ob.checked) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      let todaystr = yyyy + "/" + mm + "/" + dd;
      this.editorRegistroForm.controls["FechaDeBajaInternet"].setValue(
        todaystr
      );
    }
  }

  getFechaBaja(): Date {
    return this.aFechaddmmyyyy(
      moment(this.editorRegistroForm.controls["FechaDeBaja"].value).format(
        "DD/MM/YYYY 0:00:00"
      )
    );
  }

  getFechaAlta(): Date {
    return this.aFechaddmmyyyy(
      moment(
        this.editorRegistroForm.controls["FechaDeAltaTaquilla"].value
      ).format("DD/MM/YYYY 0:00:00")
    );
  }

  getFechaDeBajaInternet(): Date {
    return this.aFechaddmmyyyy(
      moment(
        this.editorRegistroForm.controls["FechaDeBajaInternet"].value
      ).format("DD/MM/YYYY 0:00:00")
    );
  }

  getFechaDeAltaInternet(): Date {
    return this.aFechaddmmyyyy(
      moment(
        this.editorRegistroForm.controls["FechaDeAltaInternet"].value
      ).format("DD/MM/YYYY 0:00:00")
    );
  }

  getFechaDiasDePago(): Date {
    return this.aFechaddmmyyyy(
      moment(this.editorRegistroForm.controls["Dias"].value).format(
        "DD/MM/YYYY 0:00:00"
      )
    );
  }

  async CargarDatosTiposAbonados(): Promise<boolean> {
    //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
    let resultado: boolean = false;
    await this.servicioAPI.ObtenerCustomanGrupoTipoAbonado().then(
      (data) => {
        this.tiposTiposAbonadoAPI = data;
        if (this.tiposTiposAbonadoAPI.DatosResult != null) {
          this.tiposTipoAbonado = this.tiposTiposAbonadoAPI.DatosResult.Lista;
          resultado = true;
        } else {
          this.tiposTipoAbonado = [];
          resultado = false;
        }
        this.tiposTipoAbonadoRecintoSinAsignar = this.filtrarTiposAbonado();
      },
      (error) => {
        this.httpErrorService.identificarErrores(error);
        resultado = false;
      }
    );
    return resultado;
  }
  /*  insertDataInForm(){
    if((<HTMLInputElement>document.getElementById("FormaDePago-hidden")))
      this.editorRegistroForm.get("FormaDePago").setValue((<HTMLInputElement>document.getElementById("FormaDePago-hidden")).value)
    if((<HTMLInputElement>document.getElementById("GrupoCliente-hidden")))
      this.editorRegistroForm.get("GrupoCliente").setValue((<HTMLInputElement>document.getElementById("GrupoCliente-hidden")).value)
    if((<HTMLInputElement>document.getElementById("ZonaComercial-hidden")))
      this.editorRegistroForm.get("ZonaComercial").setValue((<HTMLInputElement>document.getElementById("ZonaComercial-hidden")).value)
    if((<HTMLInputElement>document.getElementById("TipoCliente-hidden")))
      this.editorRegistroForm.get("TipoCliente").setValue((<HTMLInputElement>document.getElementById("TipoCliente-hidden")).value)

 
  } */

  closeInputFilter(enventValues) {
    //this.insertDataInForm()
    this.editorRegistroForm.get(enventValues.name).setValue(enventValues.value);

    // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)
  }
}
