import { Injectable, OnInit, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LayoutService {
  sidebar_opened : EventEmitter<boolean> = new EventEmitter(true);
  urlclicked : EventEmitter<string> = new EventEmitter();

  OnInit() {
  }
}
