import {Deserializable} from "./deserializable.model";

export class Recintos_Respuesta implements Deserializable {
    DatosResult?: DatosResult_recintos;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class Recintos implements Deserializable {

  PkId: string;
  Nombre: string;
  Activo: string;
  ControlarAforo: string;
  ControlarAforoReserva: string;
  TipoAforo: string;
  AforoMaximo: string;
  LimiteAforo: string;
  HoraInicio: string;
  HoraFin: string;
  PorDefecto: string;
  MinimoAforo: string;
  Taller: string;
  Butacas: string;
  MostrarInternet: string;
  MaximoInternet: string;
  GrupoActividadId: string;
  CodigoTipoControl: string;
  Secciones: string;
  GrupoRecintosId: string;
  Shape: string;
  Coords: string;
  OrdenFilas: string;
  OrdenButacas: string;
  Numeracion: string;
  NumSesion: string;
  TiempoExtra: string;
  subGrupoRecinto: string;
  
  Idiomas: IdiomaR[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
//^
//v
export class IdiomaR implements Deserializable {
  CodIdioma: string;
  Nombre: string;
  Descripcion: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_recintos implements Deserializable {
  ListaRecintos: Recintos[]; 

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}