import {Deserializable} from "./deserializable.model";

export class TipoButaca_Respuesta implements Deserializable {
    DatosResult?: DatosResult_TipoButaca;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoButaca implements Deserializable {
  Id: string;
  Nombre: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_TipoButaca implements Deserializable {
  DatosListas: TipoButaca[]; 

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

