import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_TipoSesion_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    id: string;
}

//insUpd
export class peticion_Insercion_Upd_TipoSesion {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    pkId: string;
    Descripcion: string;
    chTipoSesion: string;
    Recinto: string;
    FechaInicio: string;
    FechaFin: string;
    HoraInicio: string;
    HoraFin: string;
    Intervalo: string;
    Lunes: string;
    Martes: string;
    Miercoles: string;
    Jueves: string;
    Viernes: string;
    Sabado: string;
    Domingo: string;
    Evento: string;
    TipoButaca: string;
    AforoMaximo: string;
    MinimoAforo: string;
    MaximoInternet: string;
    Butacas: string;
    Duracion: string;
    EspectaculoId: string;
    EnvioExternos: string;
    NumeroGrupos:string;
    CodIdioma: string;
    Idiomas: any[];
    Funcion: string;
}

//del
export class peticion_Del_TipoSesion {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}