import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditEntryTypeComponent } from "./edit-entry-type/edit-entry-type.component";

@Component({
  selector: "app-entry-type",
  templateUrl: "./entry-type.component.html",
  styleUrls: ["./entry-type.component.scss"],
})
export class EntryTypeComponent extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditEntryTypeComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
