import {CLienteAPi} from "./cliente-api.model";
import {EMListados, CaracteristicasAdicionales} from "./enlaces-menu-Respuesta.model"

//get
export class peticion_EnlacesMenu_Lista {
    iConexionId: number;
    clienteAPI: CLienteAPi;
    
    GrupoInternet: number;
}

//insUpd 
export class peticion_Insercion_Upd_EnlacesMenu {
    conexionId: number;
    clienteAPI: CLienteAPi;

    pkid: number;
    chNombre: string;
    chDescripcion: string;
    iSinFecha?: number;
    iDiasValidezSinFecha?: number;
    chCalendario: string;
    iAudioguia?: number;
    iVerCodigosPromocionales?: number;
    iActivo?: number;
    iVisible?: number;
    dtTiempoVenta?: string;
    dtFechaMinVenta?: string;
    dtTiempoReservar?: string;
    dtFechaMinReserva?: string;
    iNivel?: any;
    iPadre?: any;
    iOrden?: number;
    chSeleccionProductos: string;
    chBotones: string;
    iTipoEnlace: number;
    chTablaTarifas: string;
    iMaximoGratuitas?: number;
    iValidarGratuitas?: number;
    iDiasCalendario?: number;
    iTiempoVentaMin?: any;
    chTiempoVentaMinTipo: string;
    iTiempoVentaMax?: any;
    chTiempoVentaMaxTipo: string;
    iTiempoReservaMin?: any;
    chTiempoReservaMinTipo: string;
    iTiempoReservaMax?: any;
    chTiempoReservaMaxTipo: string;
    dtSinFechaHasta?: any;
    iCheckAcepto?: number;
    iLogueo?: number;
    iTodasMisReservas?: number;
    iTodasMisCompras?: number;
    iNoMostrarPrecioGratuitas?: number;
    iEsCliente?: number;
    iAsignarAgrupadas?: any;
    iTotalizarAgrupadas?: any;
    iSolicitaVerificarRegistro?: any;
    iSolicitaFactura?: number;
    chTiposClientes: string;
    iRecorrido?: number;
    GruposInternetAsociados: string;
    DatosEMListados?: EMListados[];
    CaracteristicasAdicionales?: CaracteristicasAdicionales;

}

//del TODO
export class peticion_Del_EnlacesMenu {
    // ConexionIacpos: number;
    // clienteAPI: CLienteAPi;
    // PkId: string;

}