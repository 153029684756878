<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div *ngIf="lasesion" [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>

  <div class="editor-registro">
    <div class="divForm">
      <form [formGroup]="editRegistroForm" (onSubmit)="submit()">
        <div class="form-group row">
          <div class="col-sm-12 col-md-6">
            <app-input-filter
              *ngIf="lasesion && listaEspectaculos"
              formControlText="iEspectaculoId"
              [disabled]="listaEspectaculos.length < 1 || mode == 'U'"
              [label]="translation.Espectaculo"
              [placeHolderTranslation]="translation.Espectaculo"
              [list]="listaEspectaculos"
              textShow="chNombreEspectaculo"
              dataInput="pkId"
              required="false"
              [data]="lasesion.iEspectaculoId"
              (close)="closeInputFilter($event)"
            ></app-input-filter>
          </div>

          <div class="col-sm-12 col-md-6">
            <app-input-filter
              *ngIf="lasesion && listaGruposRecinto"
              formControlText="pkIdGR"
              [disabled]="mode == 'U'"
              [label]="translation.GrupoRecinto"
              [placeHolderTranslation]="translation.GrupoRecinto"
              [list]="listaGruposRecinto"
              textShow="Nombre"
              dataInput="pkId"
              required="false"
              [data]="lasesion.pkIdGR"
              (close)="closeInputFilter($event)"
            ></app-input-filter>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 col-md-6">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{ translation.Fecha }}</mat-label>
              <input
                matInput
                [matDatepicker]="FechaSesion"
                placeholder="{{ translation.FechaDeBaja }}"
                formControlname="dtFecha"
                (dateChange)="setDate($event, 'dtFecha')"
                [value]="getFechaBaja()"
                [disabled]="mode == 'U'"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="FechaSesion"
              ></mat-datepicker-toggle>
              <mat-datepicker #FechaSesion></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.HoraInicio }}</mat-label>
              <input
                matInput
                formControlName="dtHoraInicio"
                class="editor-campo-registro time-picker"
                placeholder="{{ translation.HoraInicio }}"
                type="time"
                [readonly]="mode == 'U'"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 col-md-12">
            <div class="grupo-editor-campo-registro">
              <mat-slide-toggle color="primary" formControlName="Activa">
                {{ translation.Activo }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <customan-sesiones-mapa
    *ngIf="datosespectaculo && mode == 'U'"
    [GrupoRecinto]="lasesion.pkIdGR"
    [chPathMapaSVG]="datosespectaculo.NombreFicheroSVG"
    [espectaculoId]="datosespectaculo.EspectaculosId"
    [sesionId]="lasesion.pkId"
    [fechaSesion]="FechaSesionStr"
    [horaInicioSesion]="HoraSesion"
    svgheight="1000"
    svgwidth="1000"
  >
  </customan-sesiones-mapa>
</div>
