import { Injectable } from '@angular/core';
import { Butaca } from '../modelos/butaca.model';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: any[] = [];
  info: any;
  butacas: Butaca[];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(id: string, info?: any, butacas?: Butaca[],localizador?,numeroPedido?) {
    // open modal specified by id
    if (info) {
      this.info = info;
    }
    if (butacas) {
      this.butacas = butacas;
    }
    const modal: any = this.modals.filter(x => x.id === id)[0];
    modal.open(localizador, numeroPedido);
  }

  close(id: string) {
    // close modal specified by id
    const modal: any = this.modals.filter(x => x.id === id)[0];
    if (modal) {
      modal.close();
    }
  }
}
