import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditClientService {

  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService,
  ) {

  }


  insUpdClienteNew(body: any): Observable<any> {
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanCliente', body)
      .pipe(
        map(data => {
          if (data.DatosResult && data.DatosResult.Avisos) {
            return data.DatosResult;
          } else {
            throw new Error(data.Mensajes[0].DescripcionMensaje);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
    return response;
  }
}
