<!--https://tburleson-layouts-demos.firebaseapp.com/#/docs-->
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading === true }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>

  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <div class="divForm" [ngClass]="{ panelalpha: loading == true }">
    <form [formGroup]="editSinButacasForm" (onSubmit)="submit()">
      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <app-input-filter
            #recintoInput
            *ngIf="sinbutacadata && Recintos"
            formControlText="Recinto"
            [label]="translation.Recinto"
            [placeHolderTranslation]="translation.Recinto"
            [list]="Recintos"
            textShow="Nombre"
            dataInput="pkId"
            [data]="sinbutacadata.Recinto"
            required="false"
            [disabled]="recintoDisabled"
            (close)="closeInputFilter($event)"
            (focusout)="cambiodeRecinto($event)"
            (change)="cambiodeRecinto($event)"
          ></app-input-filter>

          <!--     <mat-form-field class="input-select" appearance="outline" *ngIf="sinbutacadata && Recintos" >
      <mat-label>{{translation.Recinto}}</mat-label>
      <mat-select placeholder="{{translation.Recinto}}" formControlName="Recinto"  [disabled]=recintoDisabled  (selectionChange)="cambiodeRecintoSelect($event)">
        <mat-option *ngFor="let recinto of Recintos"
          [value]="recinto.pkId">
          {{recinto.Nombre}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
        </div>
        <!-- <mat-form-field class="input-select" appearance="outline" fxFlex="30">
          <mat-label>{{translation.Recinto}}</mat-label>
          <mat-select placeholder="{{translation.Recinto}}" formControlName="Recinto"  [disabled]="(mode == 'U') && (pkId!='0') "
          (selectionChange)="CambioRecintoSeleccionado($event);">
            <mat-option *ngFor="let recinto of Recintos"
              [value]="recinto.pkId">
              {{recinto.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Idioma }}</mat-label>
            <mat-select
              placeholder="{{ translation.Idioma }}"
              formControlName="CodIdioma"
            >
              <mat-option value=""> --- --- </mat-option>
              <mat-option
                *ngFor="let idioma of idiomasApp"
                [value]="idioma.CodIdioma"
              >
                {{ idioma.Nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Descripcion }}</mat-label>
            <input matInput formControlName="Descripcion" />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <!--           <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{translation.chTipoSesion}}</mat-label>
              <mat-select placeholder="{{translation.Tipossesion}}" formControlName="TipoSesion"  [disabled]="(mode == 'U') && (pkId!='0') "  (selectionChange)="cambiodeTipoSesionSelect($event)">
                <mat-option *ngFor="let tiposesion of TiposSesion"
                  [value]="tiposesion.pkId">
                  {{tiposesion.Descripcion}}
                </mat-option>
              </mat-select>
          </mat-form-field> -->
          <app-input-filter
            #tiposesionInput
            *ngIf="sinbutacadata && TiposSesion"
            formControlText="TipoSesion"
            [label]="translation.chTipoSesion"
            [disabled]="mode == 'U' && pkId != '0'"
            [placeHolderTranslation]="translation.chTipoSesion"
            [list]="TiposSessionOfRecinto"
            textShow="Descripcion"
            dataInput="pkId"
            [data]="sinbutacadata.TipoSesion"
            required="false"
            (close)="closeInputFilter($event)"
            (change)="cambiodeTipoSesion($event)"
          ></app-input-filter>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="tipoaforo" *ngIf="RecintoInfo">
            {{ RecintoInfo.TipoAforoDesc }}
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.MaximoAforo }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="MaximoAforo"
              min="0"
            />
            <div
              *ngIf="formControls.MaximoAforo.errors"
              class="invalid-feedback"
            >
              <div *ngIf="formControls.MaximoAforo.hasError('noenRango')">
                Superado el máximo disponible
              </div>
            </div>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.LimiteAforo }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="LimiteAforo"
              min="0"
            />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.AforoInternet }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="MaximoInternet"
              min="0"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.FechaDesde }}</mat-label>
            <input
              matInput
              formControlName="Fecha"
              [matDatepicker]="dpdesde"
              placeholder="{{ translation.FechaDesde }}"
              [min]="fecha_minDesde"
              [max]="fecha_maxDesde"
              (dateChange)="onDate($event, 'desde')"
              readonly
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dpdesde"
            ></mat-datepicker-toggle>
            <mat-datepicker #dpdesde></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.FechaHasta }}</mat-label>
            <input
              matInput
              formControlName="FechaHasta"
              [matDatepicker]="dphasta"
              placeholder="{{ translation.FechaHasta }}"
              [min]="fecha_minDesde"
              [max]="fecha_maxDesde"
              (dateChange)="onDate($event, 'hasta')"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dphasta"
            ></mat-datepicker-toggle>
            <mat-datepicker #dphasta></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.HoraInicio }}</mat-label>
            <input
              matInput
              formControlName="HoraInicio"
              type="time"
              placeholder="{{ translation.SeleccionaHoraDesde }}"
              (change)="reloadVal('HoraFin')"
              [attr.disabled]="horainiciodisable ? '' : null"
            />
            <div *ngIf="f.HoraInicio.errors" class="invalid-feedback">
              <div *ngIf="f.HoraInicio.hasError('noenRango')">
                {{ translation.NoConcuerdaConElHorarioDelRecinto }}
              </div>
            </div>
          </mat-form-field>
        </div>
        <!--mat-form-field appearance="outline" class="cuatro">
        <mat-label>{{translation.FechaHasta}}</mat-label>
        <input matInput formControlName="FechaFin" [matDatepicker]="dphasta" placeholder="{{translation.FechaHasta}}" [min]="fecha_minHasta" [max]="fecha_maxHasta"
          (dateChange)="cambiahasta($event)">
        <mat-datepicker-toggle matSuffix [for]="dphasta"></mat-datepicker-toggle>
        <mat-datepicker #dphasta></mat-datepicker>
      </mat-form-field-->
        <div class="col-sm-12 col-md-6 col-lg-3">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.HoraFin }}</mat-label>
            <input
              matInput
              formControlName="HoraFin"
              type="time"
              placeholder="{{ translation.SeleccionaHoraHasta }}"
              (change)="cambiaHorahasta($event)"
            />
            <div *ngIf="f.HoraFin.errors" class="invalid-feedback">
              <div *ngIf="f.HoraFin.hasError('noenRango')">
                {{ translation.NoConcuerdaConElHorarioDelRecinto }}
              </div>
            </div>
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <!--mat-form-field appearance="outline" class="cuatro">
        <mat-label>{{translation.Duracion}} {{translation.Hor}}</mat-label>
        <input matInput type="number" formControlName="duracionhora" placeholder="{{translation.Hor}}">
      </mat-form-field>
      <mat-form-field appearance="outline" class="cuatro">
        <mat-label>{{translation.Duracion}} {{translation.Min}}</mat-label>
        <input matInput type="number" formControlName="duracionminuto" placeholder="{{translation.Min}}">
      </mat-form-field-->
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{ translation.Didactica }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="Didactica"
              placeholder="{{ translation.Didactica }}"
            />
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="flex">
            <div>
              <mat-label
                >{{ translation.Activa }}
                <mat-slide-toggle formControlName="Activa"></mat-slide-toggle
              ></mat-label>
            </div>
            <div appearance="outline">
              <mat-label
                >{{ translation.SesionBloqueada }}
                <mat-slide-toggle
                  formControlName="SesionBloqueada"
                ></mat-slide-toggle
              ></mat-label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-label
            >{{ translation.Grupos }}
            <mat-slide-toggle formControlName="haygrupos"></mat-slide-toggle
          ></mat-label>
        </div>
        <div class="col-sm-12 col-md-6">
          <mat-form-field
            *ngIf="editSinButacasForm.controls['haygrupos'].value === true"
            appearance="outline"
            class="cuatro"
          >
            <mat-label>{{ translation.NumeroDeGrupos }}</mat-label>
            <!--         <input matInput type="number" formControlName="ControlaHorario" size="60">
 -->
            <input
              matInput
              type="number"
              formControlName="MaximoGrupos"
              size="60"
            />
          </mat-form-field>
        </div>
      </div>

      <div style="margin: 20px 5px 0 5px; text-align: center">
        <mat-label>{{ translation.Centros }}</mat-label>

        <customan-dual-list
          [source]="centrosSinAsignar"
          [(destination)]="centrosAsignados"
          key="pkId"
          display="Nombre"
          [format]="format"
          [filter]="true"
        >
        </customan-dual-list>
      </div>

      <div class="form-group row">
        <div class="col-md-3 col-sm-12">
          <h2 class="h2">
            {{ translation.DisponibleTotal }}: {{ LabelDisponibleTotaltxt }}
          </h2>
        </div>
        <div class="col-md-3 col-sm-12">
          <h2 class="h2">
            {{ translation.DisponibleOnline }}: {{ LabelDisponibleOnlinetxt }}
          </h2>
        </div>
        <div class="col-md-3 col-sm-12">
          <h2 class="h2">{{ translation.AforoInicial }}: {{ AforoInicial }}</h2>
        </div>
        <div class="col-md-3 col-sm-12">
          <h2 class="h2">
            {{ translation.DisponibleGrupo }}: {{ LabelDisponibleGrupo }}
          </h2>
        </div>
      </div>
    </form>
  </div>
</div>
