<div id="component">
  <app-buttons
    (open)="openPanel()"
    [buttonsWrap]="'add'"
    [addbut]="addbut"
  ></app-buttons>

  <app-filter [tableName]="'usuarios'"></app-filter>

  <app-table [tableName]="'usuarios'" (addbut)="addbut = $event"></app-table>
</div>
<app-panelcustoman
  *ngIf="customanpanel !== undefined"
  [ConfigPanel]="customanpanel"
  [mode]="'I'"
>
</app-panelcustoman>
