import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditSinButacasComponent } from "./edit-sinbutacas/edit-sinbutacas.component";

@Component({
  selector: "app-sinbutacas",
  templateUrl: "./sinbutacas.component.html",
  styleUrls: ["./sinbutacas.component.scss"],
})
export class SinButacasComponent extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditSinButacasComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
