<!-- <ng-container  fxLayout="column">
  <div fxLayout="row" fxFlex="100%">AAAAAA</div>
  <div fxLayout="row" fxFlex="100%">BBBBBB</div>
</ng-container>
 -->

<div *ngIf="StringJSON != ''">
  <form [formGroup]="jsonFormGroup" [matTooltip]="contextHelp">
    <mat-card>
      <mat-card-subtitle>
        {{ textHeader }}
      </mat-card-subtitle>
      <ng-container *ngFor="let element of controls">
        <div [matTooltip]="element['help']">
          <mat-form-field
            appearance="fill"
            class="cajanombre"
            [ngClass]="{ inputoculta: element.title != '' }"
          >
            <input
              matInput
              type="text"
              color="primary"
              [formControlName]="'name_' + element.name"
              placeholder="Nombre del Campo"
              [value]="element.name"
            />
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            class="cajanombre"
            [ngClass]="{ inputoculta: element.title == '' }"
          >
            <input
              matInput
              type="text"
              color="primary"
              [formControlName]="'title_' + element.name"
              placeholder="Nombre del Campo"
              [value]="element.title"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="dospuntos">
            <input matInput type="text" color="primary" value=":" readonly />
          </mat-form-field>
          <mat-form-field appearance="fill" class="cajavalor">
            <input
              matInput
              [type]="element.type"
              color="primary"
              [formControlName]="'value_' + element.name"
              placeholder="Valor del Campo"
              [value]="element.value"
            />
          </mat-form-field>
          <button
            *ngIf="mayremove"
            class="botonguardar"
            mat-icon-button
            color="warn"
            [matTooltip]=""
            (click)="deleteItem(element.name)"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </ng-container>

      <button
        *ngIf="mayadd && subscribedValidity == 'INVALID'"
        class="botonguardar"
        mat-icon-button
        color="primary"
        [matTooltip]=""
        (click)="addElement()"
      >
        <mat-icon>add</mat-icon>
      </button>
    </mat-card>
  </form>

  <!-- <form  [formGroup]="jsonFormGroup">
<ng-container *ngFor="let element of controls" fxFlex fxLayout="column" >
<div fxLayout="row" fxFlex="100%">   
  <mat-form-field appearance="fill" class="nombre" fxFlex="45%" class="cajanombre">
    <input matInput type="text" color="primary" [formControlName]="'name_'+element.name" placeholder="Nombre del Campo" [value]="element.name">
  </mat-form-field>
  <mat-form-field appearance="fill" class="nombre" fxFlex="10%" class="dospuntos">
    <input matInput type="text" color="primary" value=":" readonly >
  </mat-form-field>
  <mat-form-field appearance="fill" class="nombre" fxFlex="45%">
    <input matInput type="text" color="primary" [formControlName]="'value_'+element.name" placeholder="Valor del Campo" [value]="element.value">
  </mat-form-field>
</div>
</ng-container>
</form> -->

  <!-- <form>
<ng-container *ngFor="let element of controls" fxFlex fxLayout="column" >
  <div fxLayout="row" fxFlex="100%">   
    <mat-form-field appearance="fill" class="nombre" fxFlex="45%" class="cajanombre">
      <input matInput type="text" color="primary" [name]="'name_'+element.name" placeholder="Nombre del Campo" [value]="element.name">
    </mat-form-field>
    <mat-form-field appearance="fill" class="nombre" fxFlex="10%" class="dospuntos">
      <input matInput type="text" color="primary" value=":" readonly >
    </mat-form-field>
    <mat-form-field appearance="fill" class="nombre" fxFlex="45%">
      <input matInput type="text" color="primary" [name]="'value_'+element.name" placeholder="Valor del Campo" [value]="element.value">
    </mat-form-field>
  </div>
  </ng-container>
</form> -->
</div>
