import { ClienteUI, Cliente, TipoDescuento } from './client.interface';

export class ClienteMapper {

  static convertToIU(data: Cliente): ClienteUI {

    return {
      debaja: data.Cliente.DeBaja,
      codigo_cliente: data.DatosComunes.CodigoCliente,
      informacion_personal: {
        tipo_documento: data.DatosComunes.TipoDocumento,
        num_documento: data.DatosComunes.CIFoDNI,
        nombre: data.DatosComunes.NombreCliente,
        nombre_comercial: data.Cliente.NombreComercial,
        imprimir: data.DatosComunes.ImprimirEnTicket,
        primer_apellido: data.ClienteAdicional.PrimerApellido,
        segundo_apellido: data.ClienteAdicional.SegundoApellido,
      },
      informacion_contacto: {
        prefijo_tlf: data.DatosComunes.PrefijoInternacional,
        tlf: data.DatosComunes.Telefono,
        tlf_2: data.Cliente.Telefono2,
        email: data.Cliente.EMail,
        email_2: data.Cliente.EMail2,
        codigo_postal: data.DatosComunes.CP,
        domicilio: data.DatosComunes.Domicilio,
        localidad: data.DatosComunes.Localidad,
        pais: data.DatosComunes.Pais,
        provincia: data.DatosComunes.Provincia
      },
      detalles_cuenta: {
        forma_pago: data.Cliente.TipoPagoId,
        grupo_cliente: data.Cliente.CodigoGrupos,
        tipo_cliente: data.Cliente.TipoClienteId,
        zona_comercial: data.Cliente.CodigoZonaComercial
      },

      venta_taquilla: {
        fecha_de_alta: data.Cliente.FechaAlta,
        descuento: data.Cliente.TieneDescuento,
        tipo_descuento: data.Cliente.TipoDescuento,
        importe_descuento: data.Cliente.CantidadDescuento,
        redondear_precio: data.Cliente.RedondeoPrecios,
        ticket_sin_descuento: data.Cliente.TicketSinDescuento,
      },
      informacion_facturacion: {
        datos_facturacion: {
          tipo_documento: data.ClienteAdicional.TipoDocumento,
          num_documento: data.ClienteAdicional.NumeroDocumento,
          codigo_postal: data.ClienteAdicional.CodigoPostal,
          domicilio: data.ClienteAdicional.Domicilio,
          nombre: data.ClienteAdicional.NombreClienteFactura,
          provincia: data.ClienteAdicional.Provincia,
          pais: data.ClienteAdicional.Pais,
          primer_apellido: data.DatosComunes.PrimerApellido,
          segundo_apellido: data.DatosComunes.SegundoApellido,
          localidad: data.ClienteAdicional.Localidad,
          razon_social: data.ClienteAdicional.RazonSocial
        },
        datos_pago: {
          dias: data.DatosComunes.DiaPago,
          direccion: data.DatosComunes.DireccionCobro,
          lugar: data.DatosComunes.LugarCobro,
          persona: data.DatosComunes.PersonaCobro
        },
      },
      venta_internet: !data.ClienteInternet ? null :
        {
          fecha_de_alta: data.ClienteInternet.FechaAlta,
          usuario: data.ClienteInternet.Usuario,
          descuento: data.ClienteInternet.TieneDescuento,
          tipo_descuento: data.ClienteInternet.TipoDescuento,
          importe_descuento: data.ClienteInternet.CantidadDescuento,
          redondear_precio: data.ClienteInternet.RedondeoPrecios,
          ticket_sin_descuento: data.ClienteInternet.TicketSinDescuento,
          activar_baja: data.ClienteInternet.DeBaja
        }
    };
  }

  static converToAPI(data: ClienteUI): Cliente {
    return {
      DatosComunes: {
        CodigoCliente: data.codigo_cliente,
        TipoDocumento: data.informacion_personal.tipo_documento,
        CIFoDNI: data.informacion_personal.num_documento,
        ImprimirEnTicket: data.informacion_personal.imprimir,
        NombreCliente: data.informacion_personal.nombre,
        PrimerApellido: data.informacion_personal.primer_apellido,
        SegundoApellido: data.informacion_personal.segundo_apellido,
        PrefijoInternacional: data.informacion_contacto.prefijo_tlf,
        Telefono: data.informacion_contacto.tlf,
        Movil: '',
        Domicilio: data.informacion_contacto.domicilio,
        Localidad: data.informacion_contacto.localidad,
        Provincia: data.informacion_contacto.provincia,
        CP: data.informacion_contacto.codigo_postal,
        Pais: data.informacion_contacto.pais,
        PersonaCobro: data.informacion_facturacion.datos_pago.persona,
        DireccionCobro: data.informacion_facturacion.datos_pago.direccion,
        LugarCobro: data.informacion_facturacion.datos_pago.lugar,
        DiaPago: data.informacion_facturacion.datos_pago.dias
      },
      Cliente: {
        TipoPagoId: data.detalles_cuenta.forma_pago,
        TipoClienteId: data.detalles_cuenta.tipo_cliente,
        NombreComercial: data.informacion_personal.nombre_comercial,
        EMail: data.informacion_contacto.email,
        EMail2: data.informacion_contacto.email_2,
        Telefono2: data.informacion_contacto.tlf_2,
        CodigoGrupos: data.detalles_cuenta.grupo_cliente,
        CodigoZonaComercial: data.detalles_cuenta.zona_comercial,
        DeBaja: data.debaja,
        FechaBaja: '',
        FechaAlta: data.venta_taquilla.fecha_de_alta,
        TieneDescuento: data.venta_taquilla.descuento,
        TipoDescuento: data.venta_taquilla.tipo_descuento,
        CantidadDescuento: data.venta_taquilla.importe_descuento,
        RedondeoPrecios: data.venta_taquilla.redondear_precio,
        TicketSinDescuento: data.venta_taquilla.ticket_sin_descuento
      },
      ClienteAdicional: {
        TipoDocumento: data.informacion_facturacion.datos_facturacion.tipo_documento,
        NumeroDocumento: data.informacion_facturacion.datos_facturacion.num_documento,
        RazonSocial: data.informacion_facturacion.datos_facturacion.razon_social,
        NombreClienteFactura: data.informacion_facturacion.datos_facturacion.nombre,
        PrimerApellido: data.informacion_facturacion.datos_facturacion.primer_apellido,
        SegundoApellido: data.informacion_facturacion.datos_facturacion.segundo_apellido,
        Domicilio: data.informacion_facturacion.datos_facturacion.domicilio,
        Localidad: data.informacion_facturacion.datos_facturacion.localidad,
        Provincia: data.informacion_facturacion.datos_facturacion.provincia,
        CodigoPostal: data.informacion_facturacion.datos_facturacion.codigo_postal,
        Pais: data.informacion_facturacion.datos_facturacion.pais
      },
      ClienteInternet: data.venta_internet ? {
        DeBaja: data.venta_internet.activar_baja,
        FechaAlta: data.venta_internet.fecha_de_alta,
        FechaBaja: '',
        Usuario: data.venta_internet.usuario,
        TieneDescuento: data.venta_internet.descuento,
        TipoDescuento: data.venta_internet.tipo_descuento,
        CantidadDescuento: data.venta_internet.importe_descuento,
        RedondeoPrecios: data.venta_internet.redondear_precio,
        TicketSinDescuento: data.venta_internet.ticket_sin_descuento
      } : {
          DeBaja: false,
          FechaAlta: '',
          FechaBaja: '',
          Usuario: '',
          TieneDescuento: false,
          TipoDescuento: TipoDescuento.IMPORTE,
          CantidadDescuento: 0,
          RedondeoPrecios: false,
          TicketSinDescuento: false,
        }
    };
  }
}
