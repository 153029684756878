import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Request_GrupoRecintos_ins_upd, Request_GrupoRecintos_list, GruposRecintos, peticion_Del_GrupoRecintos } from '@sharedV11/classes/recintos/grupo-recintos';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { IdiomaGR } from '../../../classes/recintos/grupo-recintos';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class EditGrupoRecintosService {

  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService
  ) { }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  findGrupoRecintoPkId(pkId: string): Observable<any> {

    let peticion: Request_GrupoRecintos_list = new Request_GrupoRecintos_list();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = "";
    peticion.Descripcion = "";
    peticion.pkId = pkId
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerGruposRecintos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }


/*
  insUpdGrupoRecintos(grupo: GruposRecintos, idiomas: any[]): Observable<any> {


    let peticion: Request_GrupoRecintos_ins_upd = new Request_GrupoRecintos_ins_upd();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId = grupo.pkId;
    (grupo.pkId === "0") ? peticion.Funcion = 'I' : peticion.Funcion = 'U';
    peticion.Nombre = grupo.Nombre;
    (grupo.Activo === "true") ? peticion.Activo = '1' : peticion.Activo = '0';
    //peticion.Activo = grupo.Activo;
    peticion.CentroId = grupo.CentroId;
    //peticion.subGrupo = grupo.subGrupo;
    (grupo.subGrupo === "true") ? peticion.subGrupo = '1' : peticion.subGrupo = '0';
    //peticion.Idiomas 
    peticion.Idiomas = [];

    idiomas.map(idioma => {
      let idiomaIns = {
        codIdioma: idioma.CodIdioma,
        Nombre: grupo["Nombre-" + idioma.CodIdioma],
        Descripcion: grupo["Descripcion-" + idioma.CodIdioma]
      };
      /*
      idiomaIns.codIdioma = idioma.CodIdioma;
      idiomaIns.Nombre = grupo["Nombre-" + idioma.CodIdioma];
      idiomaIns.Descripcion = grupo["Descripcion-" + idioma.CodIdioma];
      
      peticion.Idiomas.push(idiomaIns);
    })


    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGruposRecintos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
*/
insGrupoRecintos(recintogrupo,mode): Observable<any> {
  //Montamos la peticion insercion/Update
  let peticion = recintogrupo;
  peticion.clienteAPI = this.global.clienteapi; //TODO
  peticion.Funcion = mode;
  //Enviamos la peticion
  return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanGruposRecintos ', JSON.stringify(peticion), httpOptions).pipe(
    map(this.extractData),
    catchError(this.handleError<any>('InsertarCustomanGruposRecintos '))
  );
}
updGrupoRecintos(recintogrupo,mode): Observable<any> {
  //Montamos la peticion insercion/Update
  let peticion = recintogrupo;
  peticion.clienteAPI = this.global.clienteapi; //TODO
  peticion.Funcion = mode;
  //Enviamos la peticion
  return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanGruposRecintos', JSON.stringify(peticion), httpOptions).pipe(
    map(this.extractData),
    catchError(this.handleError<any>('ActualizarCustomanGruposRecintos'))
  );
}
insUpdGrupoRecintos(recintogrupo,mode): Observable<any> {
  //Montamos la peticion insercion/Update
  let peticion = recintogrupo;
  peticion.clienteAPI = this.global.clienteapi; //TODO
  peticion.Funcion = mode;
  //Enviamos la peticion
  return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGruposRecintos', JSON.stringify(peticion), httpOptions).pipe(
    map(this.extractData),
    catchError(this.handleError<any>('InsertarActualizarCustomanGruposRecintos'))
  );
}
  delGrupoRecintos(PkId: string): Observable<any> {
    let peticion: peticion_Del_GrupoRecintos = new peticion_Del_GrupoRecintos();
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = PkId;

    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanGrupoRecintos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
}
