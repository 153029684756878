import {Deserializable} from "./deserializable.model";

export class TiposControl_Respuesta implements Deserializable {
    DatosResult?: DatosResult_TiposControl;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoControl implements Deserializable {
  pkId: string;
  CodigoTipoControl: string;
  Nombre: string;
  Descripcion: string;
  CompHoraria: string;
  PermisoSinFecha: string;
  CompDiaria: string;
  CompIntervalo: string;
  CompDiaSemana: string;
  CompPasoPrevio: string;
  CompCalendario: string;
  NumPasosPrevios: string;
  CompCalendHorario: string;
  DenegarAcceso: string;
  DiasIntervalo: string;
  AccesoLunes: string;
  AccesoMartes: string;
  AccesoMiercoles: string;
  AccesoJueves: string;
  AccesoViernes: string;
  AccesoSabado: string;
  AccesoDomingo: string;
  PorDefecto: string;
  NivelEntrada: string;
  NumPasosPreviosDiarios: string;
  DiasIntervaloDesde: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_TiposControl implements Deserializable {
  customanTiposControl: TipoControl[];
  ListadoGruposAsociados: TipoControl[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}