import { ChangeDetectorRef, Component } from "@angular/core";
import { PanelService } from "@sharedV11/services/panel/panel.service";
import { SuperClassComponent } from "@sharedV11/super-class-edit/super-class/super-class.component";
import { EditAccessControlTypeComponent } from "./edit-type-access-control/edit-type-access-control.component";

@Component({
  selector: "app-type-access-control",
  templateUrl: "./type-access-control.component.html",
  styleUrls: ["./type-access-control.component.scss"],
})
export class TypeAccessControlComponent extends SuperClassComponent {
  constructor(
    protected panelService: PanelService,
    protected cdRef: ChangeDetectorRef
  ) {
    super(panelService, cdRef);
  }

  openPanel() {
    this.customanpanel = EditAccessControlTypeComponent;
    this.editChangeCss();
    this.cdRef.detectChanges();
  }
}
