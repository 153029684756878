import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CLienteAPi } from '@sharedV11/classes/userRequest/client_api';
import { environment } from 'src/environments/environment';
import { GlobalService } from '../../global/global.service';
import { Translator } from '../../translator/translator.service';
import { ActivatedRoute, Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    }
  )};

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {
  private config: Object = null;
  private configClient: Object =null
  private env: Object = null;
  private chIdioma: string = 'es-ES';

  firstLoad=false;


  constructor(
    private http: HttpClient,
    private translator:Translator,
    private router:Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  async getConexionIACPOS() {
  //  await this.getConexionPrivada().then(res => {
      const traerConexionPrivadaBody = {
        chClaveEmpresa: this.getConfig('chClaveEmpresa'),
        chNombreProyecto: this.getConfig('chNombreProyecto'),
        chEntornoConexion: this.getConfig('chEntornoConexion'),
        chOrigenConexion: this.getConfig('chOrigenConexion')
      };
     // if (res) {
        this.traerConexion(traerConexionPrivadaBody).subscribe(data => {
            this.traerToken().subscribe(tokenData=>{
              this.TraerProyectoPrivada().subscribe(result => {
                localStorage.setItem('Language', result.chIdioma);
                this.translator.setLocale(result.chIdioma);
                this.translator.use(result.chIdioma).then((result)=>{
                  console.log({
                    result
                  })
                })
              });
            });
          });
  }



  traerConexion(traerConexionPrivadaBody:any): Observable<any> {
    return this.http.post<any>(this.getConfig('API_URL') + 'TraerConexionPrivada', traerConexionPrivadaBody).pipe(
      map((resp: any) => {
        const conexionIACPOS = resp.pkid;
        localStorage.setItem('conexionIACPOS', conexionIACPOS);
      })
    );
  }

  traerToken(): Observable<any>{
   // localStorage.setItem('conexionIACPOS', "1301");
    const body = {
      id: this.generarSessionId(),
      nombre: this.getConfig("NUMSERIE")
    };
    return this.http.post<any>(this.getConfig('API_URL')  + 'IniciarLog', body).pipe(
      map((respo: any) => {
        const token = respo.DatosResult;
        const currentSession: CLienteAPi = {
          Id: body.id,
          Token: token,
          EnlaceMenu: null
        };
        localStorage.setItem('currentToken', JSON.stringify(currentSession));
        })
      );
  }

  getConexionPrivada() {
      
    return new Promise((resolve, reject) => {
      this.http
        .get('assets/configurations/env.json', httpOptions)
        .pipe(
          map((res: any, err: any) => {
            if (err) {
              resolve(true);
              return Observable.throw(err.json().error || 'Server error');
            }
            return res;
          })
        )
        .subscribe(envResponse => {
          this.env = envResponse;
          let request: any = null;
           switch (envResponse.env) {
            case 'production':
              {
                request = this.http.get(
                  'assets/configurations/config.' + envResponse.env + '.json', httpOptions
                );
              }
              break;

            case 'development':
              {
                request = this.http.get(
                  'assets/configurations/config.' + envResponse.env + '.json', httpOptions
                );
              }
              break;

            case 'test':
              {
                request = this.http.get(
                  'assets/configurations/config.' + envResponse.env + '.json', httpOptions
                );
              }
              break;
              case 'preproduccion':
                {
                  request = this.http.get(
                    'assets/configurations/config.' + envResponse.env + '.json', httpOptions
                  );
                }
                break;
              case '':
              request = this.http.get(
                  'assets/configurations/config.json', httpOptions
              );
                break;
            case 'default':
              {
                console.error('Environment file is not set or invalid');
                resolve(true);
              }
              break;
          } 

          if (request) {
            request
              .pipe(map(res => res))
              .pipe(
                catchError((error: any) => {
                  console.error('Error reading ' + envResponse.env + ' configuration file');
                  resolve(error);
                  return Observable.throw(error.json().error || 'Server error');
                })
              )
              .subscribe(responseData => {
                this.config = responseData;
                resolve(true);
              });
          } else {
            console.error('Env config file "env.json" is not valid');
            resolve(true);
          }
        });
    });
  }

    /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    if (environment){
      return environment[key];

    } else {
      return '';
    }
  }

  private generarSessionId(): string {
    const valorFechaActual = new Date().valueOf();
    const valorAleatorio = Math.random()
      .toString()
      .slice(-5);
    return '' + valorAleatorio + valorFechaActual;
  }

  TraerProyectoPrivada() {
    const token = JSON.parse(localStorage.getItem('currentToken'));
    const body = {
      chClaveEmpresa: this.getConfig('chClaveEmpresa'),
      chNombreProyecto: this.getConfig('chNombreProyecto')
    };
    return this.http.post<any>(this.getConfig('API_URL') + 'TraerProyectoPrivada', body);
  };

  //client
  getClientConf(chTheme){
    let rute="";
    switch(chTheme){
      case "pedrera":
        rute='assets/configurations/clients/config.pedrera.json'
        break;
      default:
        rute='assets/configurations/clients/config.json'
        break;
    }
    return this.http.get(rute);

  }
}
