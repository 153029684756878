import {CLienteAPi} from "./cliente-api.model";

//get
export class peticion_Sesion_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    Nombre: string;
    id: string;
    Recinto: string;
    TipoSesion: string;
    FechaInicio: string;
    FechaFin: string;
    HoraInicio: string;
    HoraFin: string;
    Activa: string;
    SesionBloqueada: string;
    Filtro: string; //Filtro de texto general
    
    NumeroRegistros: string;
    Pagina: string;

}

//insUpd
export class peticion_Insercion_Upd_Sesion {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;

    pkId: string;
    Recinto: string;
    TipoSesion: string;
    Fecha: string;
    Descripcion: string;
    HoraInicio: string;
    HoraFin: string;
    Reservadas: string;
    Vendidas: string;
    Creado: string;
    Activa: string;
    ControlaHorario: string;
    LimiteAforo: string;
    Didactica: string;
    MaximoAforo: string;
    MaximoInternet: string;
    VendidasInternet: string;
    SesionBloqueada: string;
    NumSesion: string;
    AforoInicial: string;
    FechaHasta: string;

    Funcion: string;
}

//del
export class peticion_Del_Sesion {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}