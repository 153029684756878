export class TipoBono{
    pkId: string;
    chCodigo: string;
    chNombrePromo: string;
    chDescripcionNombre: string;
    chDescripcion: string;
    chTextoPantalla: string;
    chTextoImpresion: string;
    chTextoDisplay: string;
    chTipoValidez: string;
    iValidez: string;
    iGrupoId: string;
    iTipoProductoId: string;
    iGrupoAsociadoId: string;
    iTipoVisitaId: string;
    iTipoNivelId: string;
    siCodigoTipoControl: string;
    iCategoriaId: string;
    iTEDatosId: string;
    iNominar: string;
    bALaVenta: string;
    bImprimirPromo: string;
    bImprimirPrecio: string;
    bImprimirTicket: string;
    bControlarAcceso:string;
    bImpresionConjunta:string;
    chTeclaRapida: string;
    CodigoBarras: string;
    BarCodeInternet: string;
    
    GrupoMotivoId: string;
    TipoBonoEntadas:tipoBonoEntradaPlantilla[];
    Entradas:tipoBonoEntradaPlantilla[];
    CentrosIds: string;
}

export class tipoBonoEntradaPlantilla {
    pkId: string = '';
    NombreTipoEntrada: string = '';
    dtFechaFin: string = '';
    dtFechaInicio: string = '';
    iNumEntradas: string = '';
    iTipoBonoEntradasId: string = '';
    iTipoBonoId: string = '';
    iTipoEntradaId: string = '';
    rPorcentaje: string = '';
  }

export class TipoBno{

    /*
    EntradaId: string;
    nombre: string;
    NombreGrupo: string;
    descripcion: string;
    PrecioUnitario: string;
    DivisaId: string;
    alaventa: string;
    FormatoId: string;
    textopantalla: string;
    CondicionesVenta: string;
    textoimpresion: string;
    textodisplay: string;
    imprimircbarras: string;
    IVA: string;
    BaseImponible: string;
    tipoabreviado: string;
    MaximoPorDia: string;
    grupoentradacontrol: string;
    imprimirticket: string;
    contolaacceso: string;
    tipocontrol: string;
    TeclaRapida: string;
    grupoentradamotivo: string;
    tipoimpresora: string;
    TicketVendido: string;
    imprimirprecio: string;
    tipoControlTipoButacas: string;
    cuentavisitante: string;
    CaracteristicaId: string;
    ArgumentosCaracteristica: string;
    TipoButacaId: string;
    imprimircbarrasonline: string;
    RecintosAsociados: string;
    ActividadesAsociadas:string; 
  
    // Nuevos campos
    tipoproductocontrol: string;
    grupoasociadocontrol: string;
    tipovisitacontrol: string;
    tiponivelcontrol: string;
    categoriacontrol: string;
    TEDatoscontrol: string;
    tipoTipoControlBotonera: string;
    ConfiguracionAdicional: string;
    pkId: string;
    CentrosIds: string;
    centroCosteId: string;
    canalId: string;

    */
}