import {Deserializable} from "./deserializable.model";

export class TipoSesion_Respuesta implements Deserializable {
    DatosResult?: DatosResult_TipoSesion;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class TipoSesion implements Deserializable {

    pkId: string;
    Descripcion: string;
    chTipoSesion: string;
    Recinto: string;
    FechaInicio: string;
    FechaFin: string;
    HoraInicio: string;
    HoraFin: string;
    Intervalo: string;
    Lunes: string;
    Martes: string;
    Miercoles: string;
    Jueves: string;
    Viernes: string;
    Sabado: string;
    Domingo: string;
    Evento: string;
    Didactica:string;
    TipoButaca: string;
    AforoMaximo: string;
    MinimoAforo: string;
    MaximoInternet: string;
    Butacas: string;
    Duracion: string;
    EspectaculoId: string;
    EnvioExternos: string;
    NumeroGrupos:string;
    duracionhora:string;
    duracionminuto: string
    
  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_TipoSesion implements Deserializable {
  Lista: TipoSesion[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}