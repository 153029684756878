import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "./shared/translate.service";
import { environment } from "src/environments/environment";
import { Translator } from "@sharedV11/services/translator/translator.service";
import { ApiTokenService } from "@sharedV11/services/api/token/api-token.service";

@Injectable()
export class AppConfiguration {
	private config: Object = null;
	private env: Object = null;

	constructor(
		private http: HttpClient,
		private translator: Translator,
		private tokenService: ApiTokenService
	) {}

	public getProyecto() {
		return environment["chClaveEmpresa"];
	}

	public getConfig(key: any) {
		//return this.config[key];
		return environment[key];
	}

	public getEnv(key: any) {
		return environment[key];
	}

	public async load() {
		let lastLanguage = localStorage.getItem("Language");

		if (!lastLanguage) {
			lastLanguage = "es-ES";
		}

		await this.translator.use(lastLanguage);
	}
}
