import { CLienteAPi } from "../client_api";


export class user_request {
    ConexionIacpos!: number;
    clienteAPI!: CLienteAPi;
    NombreUsuario!: string;
    ClaveUsuario!: string;
    IdAplicacion!: string;
    AplicacionCod?: string;
    CodigoAuth?:string;
    TokenOrigen?:string;

}
