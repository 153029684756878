import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface dataEmail {
  mensaje: string;
  name: string;
  eMails: string;
}
@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: ["./email.component.scss"],
})
export class EmailComponent implements OnInit {
  myGroup;

  /*   public data:dataEmail ={
    mensaje: "",
    name: "",
    eMails: ""
  } */
  constructor(
    public dialogRef: MatDialogRef<EmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataEmail
  ) {}

  ngOnInit(): void {
    this.myGroup = new FormGroup({
      ListEmails: new FormControl(this.data.eMails),
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
