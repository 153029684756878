import {Deserializable} from "./deserializable.model";
import {IdiomaGR} from "./grupos-recintos-Respuesta.model"

export class Tarifas_Respuesta implements Deserializable {
    DatosResult?: DatosResult_tarifas;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class Tarifas implements Deserializable {
  PkId:                    string;
  Internet:                string;
  Taquilla:                string;
  TaqAutomatica:           string;
  AlaVenta:                string;
  iTipoEntradaId:          string;
  iTipoPromocionId:        string;
  FecIniVisita:            string;
  FecFinVisita:            string;
  NoFecFinVisita:          string;
  PrecioPVP:               string;
  PorcIva:                 string;
  Comision:                string;
  PorIvaComision:          string;
  iDivisaId:               string;
  iTipoAbonadoId:          string;
  Agrupar:                 string;
  Orden:                   string;
  Minimo:                  string;
  Maximo:                  string;
  iGrupoInternetId:        string;
  FecCompraDesde:          string;
  FecCompraHasta:          string;
  HoraIni:                 string;
  HoraFin:                 string;
  iTipoIvaId:              string;
  iTipoIvaComisionId:      string;
  BaseImponible:           string;
  ComisionBI:              string;
  DesglosarComision:       string;
  Lunes:                   string;
  Martes:                  string;
  Miercoles:               string;
  Jueves:                  string;
  Viernes:                 string;
  Sabado:                  string;
  Domingo:                 string;
  DescripcionEntTPromTAbo: string;
  TotalRegistros:          string;
  chPrefijo:               string;

  Idiomas: IdiomaGR[];
  TarifaPromocion: tarifaPromocion[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class DatosResult_tarifas implements Deserializable {
  //ListaTarifas: { [key: string]: Tarifas };
  ListaTarifas : Map<string, Tarifas>;
  
    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

//Aux
export class tarifaPromocion implements Deserializable  {
  pkid:string;
  iTarifaId:string;
  iTipoEntradaId:string;
  porcIva:string;
  iTipoBonoEntradaId:string;
  iTipoIvaId:string;
  precio:string;
  baseImponible:string;
  numEntradas:string;
  nomTipoEntrada:string;

  deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}