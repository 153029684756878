import { DatosResult } from "./data_result";
import { Deserializable } from "../deserializable";
import { Message } from "../message/message";

export class userResponse implements Deserializable {
  DatosResult?: DatosResult;
  Mensajes: Message[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
