import { Deserializable } from "../deserializable";

export class IdiomaGR implements Deserializable {
  CodIdioma: string;
  Nombre: string;
  Descripcion: string;
  Descripcion1: string
  Descripcion2: string

  URL:string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}