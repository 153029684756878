<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{ zonaoculta: loading }">
  <app-buttons
    (save)="submit()"
    (close)="destroy()"
    (discard)="discard()"
    [buttonsWrap]="'panel'"
    [modificar]="modificar"
  ></app-buttons>

  <div class="editor-registro">
    <div class="divForm">
      <form [formGroup]="editRegistroForm" (onSubmit)="submit()">
        <!--       <div class="form-group row">

        <div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Nombre}}</mat-label>
            <input matInput formControlName="Nombre" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Nombre')">
              {{getErrorMessage("Denominacion")}}
            </mat-error>
          </mat-form-field>
        </div>
      </div> -->

        <div class="form-group">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Nombre }}</mat-label>
              <input
                matInput
                formControlName="chNombreEspectaculo"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('chNombreEspectaculo')">
                {{ getErrorMessage("chNombreEspectaculo") }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.MapaSvg }}</mat-label>
              <input
                matInput
                formControlName="chPathMapaSVG"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('chPathMapaSVG')">
                {{ getErrorMessage("chPathMapaSVG") }}
              </mat-error>
            </mat-form-field>
            <!--           <mat-form-field appearance="outline">
            <mat-select placeholder="{{translation.Idioma}}" formControlName="chIdioma">
              <mat-option *ngFor="let idioma of idiomas"
                [value]="idioma.CodIdioma">
                {{idioma.Nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.Promotor }}</mat-label>
              <input
                matInput
                formControlName="Promotor"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('Promotor')">
                {{ getErrorMessage("Promotor") }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ translation.NifCifFiscal }}</mat-label>
              <input
                matInput
                formControlName="CIF"
                class="editor-campo-registro"
              />
              <mat-error *ngIf="validateFields('CIF')">
                {{ getErrorMessage("CIF") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!--         <div class="form-group row">
        <div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Orden}}</mat-label>
            <input type="number" matInput formControlName="Orden" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Orden')">
              {{getErrorMessage("Orden")}}
            </mat-error>
          </mat-form-field>
        </div>
      </div> -->

        <div class="form-group row">
          <div class="col-md-6 col-sm-12">
            <div class="grupo-editor-campo-registro">
              <mat-slide-toggle color="primary" formControlName="Activo">
                {{ translation.Activo }}
              </mat-slide-toggle>
            </div>
          </div>
        </div>

        <mat-accordion *ngFor="let idioma of idiomas; let i = index">
          <mat-expansion-panel [expanded]="i == 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ idioma.Nombre }}
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field appearance="outline">
              <mat-label
                >{{ translation.Nombre }} # ({{ idioma.CodIdioma }})</mat-label
              >
              <input
                matInput
                type="text"
                placeholder="{{ translation.Nombre }} en {{ idioma.Nombre }}"
                [value]="idioma.Nombre"
                (change)="
                  cambiaNombre($any($event.target).value, idioma.CodIdioma)
                "
                formControlName="Nombre-{{ idioma.CodIdioma }}"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label
                >{{ translation.Descripcion }} ({{
                  idioma.CodIdioma
                }})</mat-label
              >
              <textarea
                matInput
                type="text"
                placeholder="{{ translation.Descripcion }} {{ idioma.Nombre }}"
                [value]="idioma.Descripcion"
                formControlName="Descripcion-{{ idioma.CodIdioma }}"
              ></textarea>
            </mat-form-field>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="form-group row">
          <div class="col-sm-12 col-md-12">
            <div style="margin: 20px 5px 0 5px; text-align: center">
              <mat-label>{{ translation.Entradas }}</mat-label>

              <customan-dual-list
                [source]="entradasSinAsignar"
                [(destination)]="entradasAsignadas"
                key="pkId"
                display="Nombre"
                [format]="format"
                [filter]="true"
              >
              </customan-dual-list>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-12 col-md-12">
            <div style="margin: 20px 5px 0 5px; text-align: center">
              <mat-label>{{ translation.Recintos }}</mat-label>

              <customan-dual-list
                [source]="grSinAsignar"
                [(destination)]="grAsignadas"
                key="pkId"
                display="Nombre"
                [format]="format"
                [filter]="true"
              >
              </customan-dual-list>
            </div>
          </div>
        </div>

        <!--       <div class="form-group row">
        <div class="col-sm-12 col-md-12">
          <div style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Entradas}}</mat-label>

            <customan-dual-list [source]="entradasSinAsignar" [(destination)]="entradasAsignadas" key="pkId"
              display="Nombre" [format]="format" [filter]='true'>
            </customan-dual-list>
          </div> 
        </div>
      </div> -->

        <div style="margin: 20px 5px 0 5px; text-align: center">
          <mat-label>{{ translation.Centros }}</mat-label>

          <customan-dual-list
            [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados"
            key="pkId"
            display="Nombre"
            [format]="format"
            [filter]="true"
          >
          </customan-dual-list>
        </div>
      </form>
    </div>
  </div>

  <!--   <mat-tab-group dynamicHeight>
    <mat-tab label="Short tab">
      <div class="example-small-box mat-elevation-z4">
        Small content
      </div>
    </mat-tab>
    <mat-tab label="Long tab">
      <div class="example-large-box mat-elevation-z4">
        Large content
      </div>
    </mat-tab>
  </mat-tab-group> -->
</div>
