import { Deserializable } from "../deserializable";

export class InternetGroups_show implements Deserializable {
    pkId:                    string;
    Nombre:                  string;
    Descripcion:             string;
    Minimo:                  string;
    Maximo:                  string;
    Orden:                   string;
    TipoReserva:           string;
    ValidarGratuitas:        string;
    EnlacesMenu:             string;
    "caNombre":              string;
    "en-GBNombre":           string;
    "es-ESNombre":           string;


 
  
    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }


    assign(param,data){
      this[param] = data
    }

  }
