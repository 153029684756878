import { CLienteAPi } from "../client_api";

//getTiposCliente
export class peticion_TiposCliente_Lista {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    NombreTipoCliente: string;
    CodigoTipoCliente: string;
}

//insUpdTiposCliente 
export class peticion_Insercion_Upd_TiposCliente {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
    Codigo: string;
    Nombre: string;
    Funcion: string;
}

//delTiposCliente
export class peticion_Del_TiposCliente {
    ConexionIacpos: number;
    clienteAPI: CLienteAPi;
    PkId: string;
}