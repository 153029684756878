import { Component } from '@angular/core';
import { ApiTokenService } from './shared/services/api/token/api-token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'customan-angular11';

  isLoaded: boolean = false;

  constructor(
    private tokenService: ApiTokenService
  ) {
    // Esperar a que se resuelva la promesa de getConexionIACPOS y luego cargar la aplicación
    this.tokenService.getConexionIACPOS().then(() => {
      this.isLoaded = true;
    });
  }


}
