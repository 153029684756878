import { Deserializable } from "@sharedV11/classes/deserializable";

export class grupos_cliente_Respuesta implements Deserializable {
    DatosResult?: DatosResult_grupos_cliente;
    Mensajes: Mensaje[];

    deserialize(input: any): this {
      Object.assign(this, input);
      return this;
    }
}

export class GrupoClientes implements Deserializable {
    pkId: string;
    CodigoGruposClientes: string;
    NombreGruposClientes: string;
    CentrosIds:string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class TipoControlAccesos implements Deserializable {
  pkId: string;
  CodigoTipoControl: string;
  Nombre: string;
  Descripcion: string;
  CentrosIds:string;

deserialize(input: any): this {
  Object.assign(this, input);
  return this;
}
}


export class TipoClientes implements Deserializable {
  pkId: string;
  Codigo: string;
  Nombre: string;
  CentrosIds:string;
  Afiliado: string | number;

deserialize(input: any): this {
  Object.assign(this, input);
  return this;
}
}

export class tipos_cliente_Respuesta implements Deserializable {
  DatosResult?: DatosResult_grupos_cliente;
  Mensajes: Mensaje[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}


export class tipos_control_accesos_Respuesta implements Deserializable {
  DatosResult?: DatosResult_grupos_cliente;
  Mensajes: Mensaje[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}


export class DatosResult_grupos_cliente implements Deserializable {
    customanGrupoCliente: GrupoClientes[];

    deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class Mensaje implements Deserializable {
  CodigoMensaje: string;
  DescripcionMensaje: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}